import emailSent1 from "../../../assets/email-sent-1.svg"
import emailSent2 from "../../../assets/email-sent-2.svg"
import emailSent3 from "../../../assets/email-sent-3.svg"
import emailSent4 from "../../../assets/email-sent-4.svg"
import received1 from "../../../assets/received-1.svg"
import received2 from "../../../assets/received-2.svg"
import received3 from "../../../assets/received-3.svg"
import received4 from "../../../assets/received-4.svg"
import opened1 from "../../../assets/opened-1.svg"
import opened2 from "../../../assets/opened-2.svg"
import opened3 from "../../../assets/opened-3.svg"
import opened4 from "../../../assets/opened-4.svg"
import bounced1 from "../../../assets/bounced-1.svg"
import bounced2 from "../../../assets/bounced-2.svg"
import bounced3 from "../../../assets/bounced-3.svg"
import bounced4 from "../../../assets/bounced-4.svg"
import reminderGray from "../../../assets/reminder-gray.svg"
import reminderBlue from "../../../assets/reminder-blue.svg"
import reminderGreen from "../../../assets/reminder-green.svg"
import reminderRed from "../../../assets/reminder-red.svg"
import cancelEmailFollowups from "../../../assets/cancel-email-follow-ups.svg"

export { emailSent1, emailSent2, emailSent3, emailSent4, received1, received2, received3, received4, opened1, opened2, opened3, opened4, bounced1, bounced2, bounced3, bounced4, reminderGray, reminderBlue, reminderGreen, reminderRed, cancelEmailFollowups }