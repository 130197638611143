exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);", ""]);
// Module
exports.push([module.id, "html, body {\n  margin: 0px !important;\n  padding: 0px !important; }\n\n._2-M0SFX14M-H1gc28R6fgX {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  background: rgba(255, 255, 255, 0.8);\n  top: 0;\n  left: 0;\n  z-index: 900;\n  overflow: hidden; }\n\n._2v7d7XHdITnIaD0W-rIaZ6 {\n  border: 1px solid #4e185b !important; }\n\n._2UProiDMP4gDX5afcjb3nP {\n  margin: 0px !important;\n  position: absolute !important;\n  top: 0 !important;\n  right: 0 !important; }\n\n._351Mo1uG46PfYhBCg9R0na {\n  width: 110px;\n  border-radius: 20px !important;\n  text-transform: capitalize !important;\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  font-weight: 400 !important;\n  font-size: 10px !important;\n  line-height: 20px !important;\n  padding: 2px 15px !important; }\n\n._20DwMDxdm-NtEQKfaOl-cl {\n  font-size: 14px !important; }\n  ._20DwMDxdm-NtEQKfaOl-cl svg {\n    margin-right: 7px; }\n  ._20DwMDxdm-NtEQKfaOl-cl:hover {\n    background-color: #D4C5D9 !important; }\n\n._3AXOTXDSrM9Qn89oViFBMq {\n  width: 110px;\n  border-radius: 20px !important;\n  text-transform: capitalize !important;\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  font-weight: 400 !important;\n  font-size: 10px !important;\n  line-height: 20px !important;\n  padding: 2px 15px !important;\n  display: flex !important;\n  justify-content: center !important;\n  border: 1px solid #000; }\n\n.v8uygPHPA5J_mDK9H6zI {\n  margin-right: 10px; }\n", ""]);
// Exports
exports.locals = {
	"spinner": "_2-M0SFX14M-H1gc28R6fgX",
	"moreListWrapper": "_2v7d7XHdITnIaD0W-rIaZ6",
	"moreButton": "_2UProiDMP4gDX5afcjb3nP",
	"buttonStatus": "_351Mo1uG46PfYhBCg9R0na",
	"moreList": "_20DwMDxdm-NtEQKfaOl-cl",
	"statusButton": "_3AXOTXDSrM9Qn89oViFBMq",
	"optionIcons": "v8uygPHPA5J_mDK9H6zI"
};