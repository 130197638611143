import { IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import React from 'react';
import styles from "./Toast.module.scss";

type Props = {
    open: boolean,
    content: string
    setOpen?: Function
}

const Toast = (props: Props) => {
    const { open, content, setOpen } = props;

    const handleCloseSnackbar = (_event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        if (setOpen) setOpen(false);
    };

    return (
        <Snackbar 
            open={open}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={6000}
            classes={{ root: styles.root }}
            onClose={handleCloseSnackbar}
        >
            <Alert 
                variant="filled" 
                severity="success"
                classes={{
                    filledSuccess: styles.alertSuccess,
                    icon: styles.alertIcon
                }}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => setOpen && setOpen(false)}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                <div className={styles.toastText} dangerouslySetInnerHTML={{ __html: content }} />
            </Alert>
        </Snackbar>
    )
}

export default Toast