import React, { useContext, useState } from "react"
import ShowChangesTable from "../ShowChangesTable"
import ClientChangesContext from "../../../../../../components/Context/ClientChangesContext"
import {
  findBySummaryId,
  compareItems,
  formatCurrency,
} from "../../../../../../lib/utils"
import { i_ACStates } from "../../interface"

const PrimaryIncomeAdditional = () => {
  const { income1Addition, ACStates, setACStates } = useContext(
    ClientChangesContext
  )

  const indexOnPortal: boolean[] = []
  const indexOnType: boolean[] = []

  income1Addition &&
    income1Addition.portalChanges.forEach((income: any) =>
      indexOnPortal.push(false)
    )
  income1Addition &&
    income1Addition.typeChanges.forEach((income: any) =>
      indexOnType.push(false)
    )

  const [switchIndexPortal, setSwitchIndexPortal] = useState<boolean[]>(
    indexOnPortal
  )

  const [switchIndexType, setSwitchIndexType] = useState<boolean[]>(indexOnType)

  const changeIncomeHandler = (
    objProp: string,
    currValue: string | boolean | number,
    prevValue: string | boolean | number,
    summaryId: string,
    newSwitch: boolean[],
    index: number
  ) => {
    const incomeBreakdown = ACStates?.client1Income.incomeBreakdown
    const updateValues = ACStates?.client1Income.incomeBreakdown.map(
      (income: any) => {
        if (income.summaryId === summaryId) {
          if (newSwitch !== undefined && newSwitch[index]) {
            income[objProp] = currValue
          } else {
            income[objProp] = prevValue
          }
        }
        return income
      }
    )

    const prevIncomeIndex = incomeBreakdown.findIndex(
      (x: any) => x.summaryId === ""
    )

    if (
      incomeBreakdown.length > 1 &&
      prevIncomeIndex !== incomeBreakdown.length - 1
    ) {
      // make sure previous income is on the last position
      const prevIncome = updateValues[prevIncomeIndex]
      updateValues.splice(prevIncomeIndex, 1)
      updateValues.push(prevIncome)
    }

    const shouldAddValue = findBySummaryId(updateValues, summaryId)

    if (shouldAddValue === undefined) {
      let newItem: any = {
        brokerInput: 0,
        portal: 0,
        type: "",
        summaryId,
        period: "Monthly",
        previousYearIncome: null,
      }

      if (newSwitch !== undefined && newSwitch[index]) {
        newItem[objProp] = currValue
      }

      updateValues.push(newItem)
    } else {
      const checkedAddedPortal = findBySummaryId(
        income1Addition?.portalChanges,
        summaryId
      )

      const checkedAddedType = findBySummaryId(
        income1Addition?.typeChanges,
        summaryId
      )

      const checkUpdatedValues = findBySummaryId(
        ACStates?.client1Income.incomeBreakdown,
        summaryId
      )

      if (
        compareItems(
          checkUpdatedValues.portal,
          checkedAddedPortal.prevPortal
        ) &&
        compareItems(checkUpdatedValues.type, checkedAddedType.prevType)
      ) {
        const position = updateValues.findIndex(
          (uValues: { summaryId: string }) => uValues.summaryId === summaryId
        )
        if (position !== -1) updateValues.splice(position, 1)
      }
    }
    return updateValues
  }

  const incomeAdditionalHandler = (
    summaryId: string,
    index: number,
    currValue: any,
    prevValue: any,
    toChange: string
  ) => {
    let newSwitch: boolean[]
    let changeValue
    switch (toChange) {
      case "portal":
        newSwitch = switchIndexPortal && [...switchIndexPortal]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changeIncomeHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexPortal(newSwitch)
        break
      case "type":
        newSwitch = switchIndexType && [...switchIndexType]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changeIncomeHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexType(newSwitch)
        break
      default:
        newSwitch = []
    }

    let redoIncomBreakdown: i_ACStates = { ...ACStates }
    redoIncomBreakdown.client1Income.incomeBreakdown = changeValue

    if (ACStates !== undefined) {
      setACStates && setACStates(redoIncomBreakdown)
    }
  }

  return (
    <>
      {/* start of portal changes */}
      {income1Addition?.portalChanges.length !== 0 ? (
        income1Addition?.portalChanges.map(
          (
            rental: {
              prevPortal: any
              currPortal: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevPortal, currPortal, summaryId } = rental
            return (
              <ShowChangesTable
                prevVal=""
                currVal={formatCurrency(currPortal)}
                index={index}
                switchIndex={switchIndexPortal}
                updatesCaller={() => {
                  incomeAdditionalHandler(
                    summaryId,
                    index,
                    currPortal,
                    prevPortal,
                    "portal"
                  )
                }}
                type="portal"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* start of portal changes */}
      {/* start of type changes */}
      {income1Addition?.typeChanges.length !== 0 ? (
        income1Addition?.typeChanges.map(
          (
            rental: {
              prevType: any
              currType: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevType, currType, summaryId } = rental
            return (
              <ShowChangesTable
                prevVal=""
                currVal={currType}
                index={index}
                switchIndex={switchIndexType}
                updatesCaller={() => {
                  incomeAdditionalHandler(
                    summaryId,
                    index,
                    currType,
                    prevType,
                    "type"
                  )
                }}
                type="incomeType"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* start of portal changes */}
    </>
  )
}

export default PrimaryIncomeAdditional
