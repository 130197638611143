exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3Y-4SFqDYbgx8AIovLK9Ro {\n  font-size: 16px !important;\n  flex: none !important; }\n\n._2v1LHKHsYeVqqozJ1ENsFp {\n  min-height: 0 !important;\n  border: 1px solid #aaa;\n  padding: 15px !important;\n  background: #eee !important; }\n\n._1wSUZzvZ3yiotZf6xS_XVN {\n  margin: 0 !important; }\n\n._1TUO1AGxRkFKonUh5WBry2 {\n  padding-top: 0 !important;\n  padding-bottom: 0 !important; }\n\n.hGlq5WIV3fdPJWyBrXJ9s {\n  flex: 1;\n  font-size: 16px !important; }\n\n._7EzRwzcxx8B_iptkzftCf {\n  margin-bottom: 10px;\n  box-shadow: 1px 1px 1px #999;\n  padding: 0; }\n\n._2j_Xrmh68VQitphJsSBFh8 {\n  justify-content: space-between;\n  padding: 5px !important; }\n\n._3L_sIy-1VGtyfgA83W5Ejj {\n  font-size: 12px !important;\n  margin-left: 20px !important; }\n", ""]);
// Exports
exports.locals = {
	"accordionTotal": "_3Y-4SFqDYbgx8AIovLK9Ro",
	"expansionSummaryContainer": "_2v1LHKHsYeVqqozJ1ENsFp",
	"expansionSummary": "_1wSUZzvZ3yiotZf6xS_XVN",
	"expandIcon": "_1TUO1AGxRkFKonUh5WBry2",
	"heading": "hGlq5WIV3fdPJWyBrXJ9s",
	"accordion": "_7EzRwzcxx8B_iptkzftCf",
	"expansionPanelDetails": "_2j_Xrmh68VQitphJsSBFh8",
	"commentToggle": "_3L_sIy-1VGtyfgA83W5Ejj"
};