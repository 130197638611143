import React, { useContext, useState } from "react"
import ClientChangesContext from "../../../../../../components/Context/ClientChangesContext"
import { formatCurrency } from "../../../../../../lib/utils"
import Switch from "react-switch"
import FlexExpenseChangesHeader from "./FlexExpenseChangesHeader"
import FlexExpenseChangesRow from "./FlexExpenseChangesRow"

const FlexExpenseUpdatesMedical = () => {
  const { flexExpenseUpdates, ACStates, setACStates } = useContext(
    ClientChangesContext
  )

  const medicalChanges = flexExpenseUpdates?.medicalChanges
  const numberOfLoops = medicalChanges?.medicalChangesArr.length ?? 0
  const indexOnChanges: boolean[] = []
  for (let i = 0; i < numberOfLoops; i++) {
    indexOnChanges.push(false)
  }
  const [switchIndex, setSwitchIndex] = useState(indexOnChanges)

  const medicalChangesHandler = (
    index: number,
    summaryId: string,
    prevValue: any,
    currValue: any
  ) => {
    let newSwitch = [...switchIndex]
    newSwitch[index] = !newSwitch[index]

    let changeValue = ACStates?.expenses.map((expense: any) => {
      if (expense.flexType === "Medical & Health(ex Insurance)") {
        expense.items.map((item: any) => {
          if (item.summaryId === summaryId) {
            if (newSwitch !== undefined && newSwitch[index]) {
              item.monthlyAmount = currValue
            } else {
              item.monthlyAmount = prevValue
            }
          }
        })
        const newTotalAmount = expense.items
          .map((item: { monthlyAmount: number }) => item.monthlyAmount)
          .reduce(
            (prevAmount: number, currAmount: number) => prevAmount + currAmount,
            0
          )
        expense.monthlyAmount = newTotalAmount
      }
      return expense
    })

    if (ACStates !== undefined) {
      setACStates && setACStates({ ...ACStates, expenses: changeValue })
    }

    setSwitchIndex(newSwitch)
  }

  return (
    <>
      {" "}
      {numberOfLoops !== 0 ? (
        <FlexExpenseChangesHeader
          prevMonthly={medicalChanges?.prevMonthlyTotalAmount ?? 0}
          currMonthly={medicalChanges?.monthlyTotalAmount ?? 0}
          description={"Medical & Health(ex Insurance)"}
        />
      ) : null}
      {medicalChanges?.medicalChangesArr.length !== 0
        ? medicalChanges?.medicalChangesArr.map((uExpense, index: number) => {
            const { prevAmount, currAmount, desc, summaryId } = uExpense
            const prevEdit = prevAmount ?? 0
            const currEdit = currAmount ?? 0
            return (
              <>
                <FlexExpenseChangesRow
                  desc={desc}
                  prevEdit={prevEdit}
                  currEdit={currEdit}
                  index={index}
                  switchIndex={switchIndex}
                  updatesCaller={() =>
                    medicalChangesHandler(index, summaryId, prevEdit, currEdit)
                  }
                />
              </>
            )
          })
        : null}
    </>
  )
}

export default FlexExpenseUpdatesMedical
