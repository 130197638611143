import React from "react"
import { formatCurrency } from "../../../../../../lib/utils"
import Switch from "react-switch"
import styles from "../ClientChangesModal.scss"

interface i_flexExpenseChangesProps {
  desc: string
  prevEdit: number
  currEdit: number
  updatesCaller: () => void
  switchIndex: boolean[]
  index: number
}

const FlexExpenseChangesRow = (props: i_flexExpenseChangesProps) => {
  const { desc, prevEdit, currEdit, updatesCaller, switchIndex, index } = props
  return (
    <tr>
      <td>{desc}</td>
      <td>
        <div className={styles.filler}></div>
      </td>
      <td>
        <p className={styles.updateBtn}>Update</p>
      </td>
      <td>{formatCurrency(prevEdit)}</td>
      <td>{formatCurrency(currEdit)}</td>
      <td>
        <Switch
          onChange={updatesCaller}
          checked={switchIndex && switchIndex[index]}
        />
      </td>
    </tr>
  )
}

export default FlexExpenseChangesRow
