import orange from "@material-ui/core/colors/orange";
import grey from "@material-ui/core/colors/grey";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";

export const FontFamilyPoppins = '"Poppins", sans-serif';

export const FontSizeBody2 = "0.875em";

export const ColorPrimary = orange[500];

export const ColorPrimaryDark = orange[700];

export const ColorPrimaryLight = orange[300];

export const ColorGrey = grey[700];

export const ColorLightGrey = grey[400];

export const ColorLighterGrey = grey[200];

const theme = createMuiTheme({
  palette: {
    primary: {
      main: ColorPrimary,
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: "white",
        textTransform: "none",
        fontSize: "1em",
        marginLeft: "0.25em",
        marginRight: "0.25em",
        padding: "0.25em 1em",
      },
      containedSizeLarge: {
        fontSize: "1.25em",
      },
    },
    MuiIconButton: {
      colorPrimary: {
        fontFamily: FontFamilyPoppins,
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "1em",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "1em",
      },
    },
    MuiSelect: {
      root: {
        padding: "0.5em 1em",
        height: "1em",
      },
      icon: {
        top: "0.25em",
        height: "1.5em",
      },
    },
    MuiTypography: {
      h1: {
        fontSize: "2.75em",
        fontFamily: FontFamilyPoppins,
      },
      h2: {
        fontSize: "2.25em",
        fontFamily: FontFamilyPoppins,
      },
      h3: {
        fontSize: "1.75em",
        fontFamily: FontFamilyPoppins,
        color: "black",
      },
      h4: {
        fontSize: "1.5em",
        fontFamily: FontFamilyPoppins,
        fontWeight: "normal",
        color: "black",
      },
      h5: {
        fontSize: "1.25em",
        fontFamily: FontFamilyPoppins,
        color: "black",
      },
      h6: {
        fontSize: "1em",
        fontFamily: FontFamilyPoppins,
        fontWeight: "bold",
        color: "black",
      },
      body1: {
        fontFamily: FontFamilyPoppins,
        fontSize: "1em",
      },
      body2: {
        fontFamily: FontFamilyPoppins,
        fontSize: FontSizeBody2,
      },
    },
  },
});

export default theme;

export const useThemeStyles = makeStyles({
  colorPrimaryLight: {
    color: ColorPrimaryLight,
  },
  iconContainedPrimary: {
    backgroundColor: ColorPrimary,
    color: "white",
    "&:hover": {
      backgroundColor: orange[600],
      color: "white",
    },
  },
  mb2: {
    marginBottom: "2em",
  },
  articleTitle: {
    textAlign: "center",
    color: ColorPrimaryDark,
  },
});
