import { Chip, Input, InputAdornment } from '@material-ui/core'
import { Add, Close } from '@material-ui/icons';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import styles from "./TagsInput.module.scss"

const TagsInput: React.FC<TagsInputProps> = props => {
    const { defaultTags, onChangeTags } = props;
    const [tagsInputValue, setTagsInputValue] = useState<string>('');

    const handleAddTag = (tag: string) => {
		if (tag == '') return;
		tag = tag.trim();

		if(!(defaultTags.indexOf(tag) > -1)) {
			let concatTags = defaultTags.concat([tag]);
			updateTags(concatTags);
		}

		updateTagValue('');
	}

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && typeof tagsInputValue !== 'undefined') {
            handleAddTag(tagsInputValue);
        }
    }

    const updateTagValue = (value: string) => {
        if (value === ' ') return;
        setTagsInputValue(value as string);
    }

    const handleRemoveTag = (removeTag: string) => {
		let foundTags = defaultTags.filter((tag: string) => tag !== removeTag);
        updateTags(foundTags);
	}

    const updateTags = (tags: string[]) => {
        if (onChangeTags) {
            onChangeTags(tags)
        }
	}

    return <>
        <Input
            type="text"
            className={classNames(styles.inputControls, styles.txtName, tagsInputValue !== '' ? styles.backgroundNone : styles.customBackgroundTags )}
            value={tagsInputValue}
            onChange={e => updateTagValue(e.target.value)}
            onKeyDown={handleOnKeyDown}
            startAdornment={<InputAdornment position="start"><Add /></InputAdornment>}
        />
        {
            defaultTags && defaultTags.length > 0 &&
            <div>
                { defaultTags.map((tag, index) => <Chip key={index} label={tag} deleteIcon={<Close />} className={styles.tags} onDelete={() => handleRemoveTag(tag)} />) }
            </div>
        }
    </>
}

interface TagsInputProps {
    defaultTags: string[];
    onChangeTags?: (tags: string[]) => void;

}

export default TagsInput