import React, { useContext, useState } from "react"
import ClientChangesContext from "../../../../../../components/Context/ClientChangesContext"
import ShowChangesTable from "../ShowChangesTable"
import {
  findBySummaryId,
  compareItems,
  formatCurrency,
} from "../../../../../../lib/utils"
import moment from "moment"

const HomeLoansChangesAddition = () => {
  const { homeLoansAddition, ACStates, setACStates } = useContext(
    ClientChangesContext
  )

  const indexOnLender: boolean[] = []
  const indexOnLoanType: boolean[] = []
  const indexOnPrimaryPurpose: boolean[] = []
  const indexOnBorrower: boolean[] = []
  const indexOnRepaymentType: boolean[] = []
  const indexOnRepayment: boolean[] = []
  const indexOnPeriod: boolean[] = []
  const indexOnLoanExpiry: boolean[] = []
  const indexOnBalance: boolean[] = []
  const indexOnAccountNumber: boolean[] = []
  const indexOnIoExp: boolean[] = []
  const indexOnFixedIO: boolean[] = []
  const indexOnAddress: boolean[] = []
  const indexOnBaseRate: boolean[] = []
  const indexOnDiscountRate: boolean[] = []
  const indexOnInterestRate: boolean[] = []
  homeLoansAddition &&
    homeLoansAddition.lenderChanges.forEach(() => indexOnLender.push(false))

  homeLoansAddition &&
    homeLoansAddition.baseRateChanges.forEach(() => indexOnBaseRate.push(false))

  homeLoansAddition &&
    homeLoansAddition.discountRateChanges.forEach(() =>
      indexOnDiscountRate.push(false)
    )

  homeLoansAddition &&
    homeLoansAddition.interestRateChanges.forEach(() =>
      indexOnInterestRate.push(false)
    )

  homeLoansAddition &&
    homeLoansAddition.loanTypeChanges.forEach(() => indexOnLoanType.push(false))

  homeLoansAddition &&
    homeLoansAddition.primaryPurposeChanges.forEach(() =>
      indexOnPrimaryPurpose.push(false)
    )

  homeLoansAddition &&
    homeLoansAddition.borrowerChanges.forEach(() => indexOnBorrower.push(false))

  homeLoansAddition &&
    homeLoansAddition.repaymentTypeChanges.forEach(() =>
      indexOnRepaymentType.push(false)
    )

  homeLoansAddition &&
    homeLoansAddition.repaymentChanges.forEach(() =>
      indexOnRepayment.push(false)
    )

  homeLoansAddition &&
    homeLoansAddition.periodChanges.forEach(() => indexOnPeriod.push(false))

  homeLoansAddition &&
    homeLoansAddition.loanExpiryChanges.forEach(() =>
      indexOnLoanExpiry.push(false)
    )

  homeLoansAddition &&
    homeLoansAddition.balanceChanges.forEach(() => indexOnBalance.push(false))

  homeLoansAddition &&
    homeLoansAddition.accountNumberChanges.forEach(() =>
      indexOnAccountNumber.push(false)
    )

  homeLoansAddition &&
    homeLoansAddition.ioExpChanges.forEach(() => indexOnIoExp.push(false))

  homeLoansAddition &&
    homeLoansAddition.fixedIOChanges.forEach(() => indexOnFixedIO.push(false))

  homeLoansAddition &&
    homeLoansAddition.addressChanges.forEach(() => indexOnAddress.push(false))

  const [switchIndexLender, setSwitchIndexLender] = useState<boolean[]>(
    indexOnLender
  )

  const [switchIndexBaseRate, setSwitchIndexBaseRate] = useState<boolean[]>(
    indexOnBaseRate
  )

  const [switchIndexInterestRate, setSwitchIndexInterestRate] = useState<
    boolean[]
  >(indexOnInterestRate)

  const [switchIndexDiscountRate, setSwitchIndexDiscountRate] = useState<
    boolean[]
  >(indexOnDiscountRate)

  const [switchIndexLoanType, setSwitchIndexLoanType] = useState<boolean[]>(
    indexOnLoanType
  )

  const [switchIndexPrimaryPurpose, setSwitchIndexPrimaryPurpose] = useState<
    boolean[]
  >(indexOnPrimaryPurpose)

  const [switchIndexBorrower, setSwitchIndexBorrower] = useState<boolean[]>(
    indexOnBorrower
  )

  const [switchIndexRepaymentType, setSwitchIndexRepaymentType] = useState<
    boolean[]
  >(indexOnRepaymentType)

  const [switchIndexRepayment, setSwitchIndexRepayment] = useState<boolean[]>(
    indexOnRepayment
  )

  const [switchIndexPeriod, setSwitchIndexPeriod] = useState<boolean[]>(
    indexOnPeriod
  )

  const [switchIndexLoanExpiry, setSwitchIndexLoanExpiry] = useState<boolean[]>(
    indexOnLoanExpiry
  )

  const [switchIndexBalance, setSwitchIndexBalance] = useState<boolean[]>(
    indexOnBalance
  )

  const [switchIndexAccountNumber, setSwitchIndexAccountNumber] = useState<
    boolean[]
  >(indexOnAccountNumber)

  const [switchIndexIoExp, setSwitchIndexIoExp] = useState<boolean[]>(
    indexOnIoExp
  )

  const [switchIndexFixedIO, setSwitchIndexFixedIO] = useState<boolean[]>(
    indexOnFixedIO
  )

  const [switchIndexAddress, setSwitchIndexAddress] = useState<boolean[]>(
    indexOnAddress
  )

  const changePropertyHandler = (
    objProp: string,
    currValue: any,
    prevValue: any,
    summaryId: string,
    newSwitch: boolean[],
    index: number
  ) => {
    const updateValues = ACStates?.homeLoans.map((home: any) => {
      if (home.summaryId === summaryId) {
        if (newSwitch !== undefined && newSwitch[index]) {
          home[objProp] = currValue
        } else {
          home[objProp] = prevValue
        }
      }
      return home
    })

    const shouldAddValue = findBySummaryId(updateValues, summaryId)

    if (shouldAddValue === undefined) {
      let newItem: any = {
        lender: "",
        loanType: "",
        primaryPurpose: "",
        borrower: "",
        repaymentType: "",
        repayment: 0,
        period: "Weekly",
        loanExpiry: "",
        balance: 0,
        accountNumber: "",
        ioExp: "",
        fixedIO: "",
        address: [""],
        summaryId,
        baseRate: 0,
        discountRate: 0,
        interestRate: 0,
      }

      if (newSwitch !== undefined && newSwitch[index]) {
        newItem[objProp] = currValue
      }

      updateValues.push(newItem)
    } else {
      const checkAddedLender = findBySummaryId(
        homeLoansAddition?.lenderChanges,
        summaryId
      )
      const checkAddedLoanType = findBySummaryId(
        homeLoansAddition?.loanTypeChanges,
        summaryId
      )
      const checkAddedPrimaryPurpose = findBySummaryId(
        homeLoansAddition?.primaryPurposeChanges,
        summaryId
      )
      const checkAddedBorrower = findBySummaryId(
        homeLoansAddition?.borrowerChanges,
        summaryId
      )
      const checkAddedRepaymentType = findBySummaryId(
        homeLoansAddition?.repaymentTypeChanges,
        summaryId
      )
      const checkAddedRepayment = findBySummaryId(
        homeLoansAddition?.repaymentChanges,
        summaryId
      )
      const checkAddedPeriod = findBySummaryId(
        homeLoansAddition?.periodChanges,
        summaryId
      )
      const checkAddedLoanExpiry = findBySummaryId(
        homeLoansAddition?.loanExpiryChanges,
        summaryId
      )
      const checkAddedBalance = findBySummaryId(
        homeLoansAddition?.balanceChanges,
        summaryId
      )
      const checkAddedAccountNumber = findBySummaryId(
        homeLoansAddition?.accountNumberChanges,
        summaryId
      )
      const checkAddedIoExp = findBySummaryId(
        homeLoansAddition?.ioExpChanges,
        summaryId
      )
      const checkAddedFixedIO = findBySummaryId(
        homeLoansAddition?.fixedIOChanges,
        summaryId
      )
      const checkAddedAddress = findBySummaryId(
        homeLoansAddition?.addressChanges,
        summaryId
      )
      const checkAddedBaseRate = findBySummaryId(
        homeLoansAddition?.baseRateChanges,
        summaryId
      )
      const checkAddedDiscountRate = findBySummaryId(
        homeLoansAddition?.discountRateChanges,
        summaryId
      )
      const checkAddedInterestRate = findBySummaryId(
        homeLoansAddition?.interestRateChanges,
        summaryId
      )
      const checkUpdatedValues = findBySummaryId(ACStates?.homeLoans, summaryId)

      if (
        compareItems(checkAddedLender.prevLender, checkUpdatedValues.lender) &&
        compareItems(
          checkAddedLoanType.prevLoanType,
          checkUpdatedValues.loanType
        ) &&
        compareItems(
          checkAddedBaseRate.prevBaseRate,
          checkUpdatedValues.baseRate
        ) &&
        compareItems(
          checkAddedDiscountRate.prevDiscountRate,
          checkUpdatedValues.discountRate
        ) &&
        compareItems(
          checkAddedInterestRate.prevInterestRate,
          checkUpdatedValues.interestRate
        ) &&
        compareItems(
          checkAddedPrimaryPurpose.prevPrimaryPurpose,
          checkUpdatedValues.primaryPurpose
        ) &&
        compareItems(
          checkAddedBorrower.prevBorrower,
          checkUpdatedValues.borrower
        ) &&
        compareItems(
          checkAddedRepaymentType.prevRepaymentType,
          checkUpdatedValues.repaymentType
        ) &&
        compareItems(
          checkAddedRepayment.prevRepayment,
          checkUpdatedValues.repayment
        ) &&
        compareItems(checkAddedPeriod.prevPeriod, checkUpdatedValues.period) &&
        compareItems(
          checkAddedLoanExpiry.prevLoanExpiry,
          checkUpdatedValues.loanExpiry
        ) &&
        compareItems(
          checkAddedBalance.prevBalance,
          checkUpdatedValues.balance
        ) &&
        compareItems(
          checkAddedAccountNumber.prevAccountNumber,
          checkUpdatedValues.accountNumber
        ) &&
        compareItems(checkAddedIoExp.prevIoExp, checkUpdatedValues.ioExp) &&
        compareItems(
          checkAddedFixedIO.prevFixedIO,
          checkUpdatedValues.fixedIO
        ) &&
        compareItems(checkAddedAddress.prevAddress, checkUpdatedValues.address)
      ) {
        const position = updateValues.findIndex(
          (uValues: { summaryId: string }) => uValues.summaryId === summaryId
        )
        if (position > 0) updateValues.splice(position, 1)
      }
    }
    return updateValues
  }

  const homeLoansAdditionHandler = (
    summaryId: string,
    index: number,
    currValue: any,
    prevValue: any,
    toChange: string
  ) => {
    let newSwitch: boolean[]
    let changeValue

    switch (toChange) {
      case "lender":
        newSwitch = switchIndexLender && [...switchIndexLender]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexLender(newSwitch)
        break
      case "baseRate":
        newSwitch = switchIndexBaseRate && [...switchIndexBaseRate]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexBaseRate(newSwitch)
        break
      case "discountRate":
        newSwitch = switchIndexDiscountRate && [...switchIndexDiscountRate]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexDiscountRate(newSwitch)
        break
      case "interestRate":
        newSwitch = switchIndexInterestRate && [...switchIndexInterestRate]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexInterestRate(newSwitch)
        break
      case "loanType":
        newSwitch = switchIndexLoanType && [...switchIndexLoanType]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexLoanType(newSwitch)
        break
      case "primaryPurpose":
        newSwitch = switchIndexPrimaryPurpose && [...switchIndexPrimaryPurpose]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexPrimaryPurpose(newSwitch)
        break
      case "borrower":
        newSwitch = switchIndexBorrower && [...switchIndexBorrower]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexBorrower(newSwitch)
        break
      case "repaymentType":
        newSwitch = switchIndexRepaymentType && [...switchIndexRepaymentType]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexRepaymentType(newSwitch)
        break
      case "repayment":
        newSwitch = switchIndexRepayment && [...switchIndexRepayment]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexRepayment(newSwitch)
        break
      case "period":
        newSwitch = switchIndexPeriod && [...switchIndexPeriod]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexPeriod(newSwitch)
        break
      case "loanExpiry":
        newSwitch = switchIndexLoanExpiry && [...switchIndexLoanExpiry]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexLoanExpiry(newSwitch)
        break
      case "balance":
        newSwitch = switchIndexBalance && [...switchIndexBalance]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexBalance(newSwitch)
        break
      case "accountNumber":
        newSwitch = switchIndexAccountNumber && [...switchIndexAccountNumber]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexAccountNumber(newSwitch)
        break
      case "ioExp":
        newSwitch = switchIndexIoExp && [...switchIndexIoExp]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexIoExp(newSwitch)
        break
      case "fixedIO":
        newSwitch = switchIndexFixedIO && [...switchIndexFixedIO]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexFixedIO(newSwitch)
        break
      case "address":
        newSwitch = switchIndexAddress && [...switchIndexAddress]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexAddress(newSwitch)
        break
      default:
        newSwitch = []
    }

    if (ACStates !== undefined) {
      setACStates && setACStates({ ...ACStates, homeLoans: changeValue })
    }
  }

  return (
    <>
      {/* start of rental address changes */}
      {homeLoansAddition?.lenderChanges.length !== 0 ? (
        homeLoansAddition?.lenderChanges.map(
          (
            home: {
              prevLender: any
              currLender: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevLender, currLender, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={currLender}
                index={index}
                switchIndex={switchIndexLender}
                updatesCaller={() => {
                  homeLoansAdditionHandler(
                    summaryId,
                    index,
                    currLender,
                    prevLender,
                    "lender"
                  )
                }}
                type="lenderHL"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansAddition?.loanTypeChanges.length !== 0 ? (
        homeLoansAddition?.loanTypeChanges.map(
          (
            home: {
              prevLoanType: any
              currLoanType: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevLoanType, currLoanType, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={currLoanType}
                index={index}
                switchIndex={switchIndexLoanType}
                updatesCaller={() => {
                  homeLoansAdditionHandler(
                    summaryId,
                    index,
                    currLoanType,
                    prevLoanType,
                    "loanType"
                  )
                }}
                type="loanType"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansAddition?.primaryPurposeChanges.length !== 0 ? (
        homeLoansAddition?.primaryPurposeChanges.map(
          (
            home: {
              prevPrimaryPurpose: any
              currPrimaryPurpose: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevPrimaryPurpose, currPrimaryPurpose, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={currPrimaryPurpose}
                index={index}
                switchIndex={switchIndexPrimaryPurpose}
                updatesCaller={() => {
                  homeLoansAdditionHandler(
                    summaryId,
                    index,
                    currPrimaryPurpose,
                    prevPrimaryPurpose,
                    "primaryPurpose"
                  )
                }}
                type="primaryPurpose"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansAddition?.borrowerChanges.length !== 0 ? (
        homeLoansAddition?.borrowerChanges.map(
          (
            home: {
              prevBorrower: any
              currBorrower: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevBorrower, currBorrower, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={currBorrower}
                index={index}
                switchIndex={switchIndexBorrower}
                updatesCaller={() => {
                  homeLoansAdditionHandler(
                    summaryId,
                    index,
                    currBorrower,
                    prevBorrower,
                    "borrower"
                  )
                }}
                type="borrower"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansAddition?.repaymentTypeChanges.length !== 0 ? (
        homeLoansAddition?.repaymentTypeChanges.map(
          (
            home: {
              prevRepaymentType: any
              currRepaymentType: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevRepaymentType, currRepaymentType, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={currRepaymentType}
                index={index}
                switchIndex={switchIndexRepaymentType}
                updatesCaller={() => {
                  homeLoansAdditionHandler(
                    summaryId,
                    index,
                    currRepaymentType,
                    prevRepaymentType,
                    "repaymentType"
                  )
                }}
                type="repaymentType"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansAddition?.repaymentChanges.length !== 0 ? (
        homeLoansAddition?.repaymentChanges.map(
          (
            home: {
              prevRepayment: any
              currRepayment: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevRepayment, currRepayment, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={formatCurrency(currRepayment)}
                index={index}
                switchIndex={switchIndexRepayment}
                updatesCaller={() => {
                  homeLoansAdditionHandler(
                    summaryId,
                    index,
                    currRepayment,
                    prevRepayment,
                    "repayment"
                  )
                }}
                type="repayment"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansAddition?.periodChanges.length !== 0 ? (
        homeLoansAddition?.periodChanges.map(
          (
            home: {
              prevPeriod: any
              currPeriod: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevPeriod, currPeriod, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={currPeriod}
                index={index}
                switchIndex={switchIndexPeriod}
                updatesCaller={() => {
                  homeLoansAdditionHandler(
                    summaryId,
                    index,
                    currPeriod,
                    prevPeriod,
                    "period"
                  )
                }}
                type="period"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansAddition?.loanExpiryChanges.length !== 0 ? (
        homeLoansAddition?.loanExpiryChanges.map(
          (
            home: {
              prevLoanExpiry: any
              currLoanExpiry: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevLoanExpiry, currLoanExpiry, summaryId } = home
            if (prevLoanExpiry === "" && currLoanExpiry === "") return null
            const currLoanDate = moment(new Date(currLoanExpiry)).format(
              "YYYY-MM-DD"
            )
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={currLoanDate}
                index={index}
                switchIndex={switchIndexLoanExpiry}
                updatesCaller={() => {
                  homeLoansAdditionHandler(
                    summaryId,
                    index,
                    currLoanDate,
                    prevLoanExpiry,
                    "loanExpiry"
                  )
                }}
                type="loanExpiry"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansAddition?.balanceChanges.length !== 0 ? (
        homeLoansAddition?.balanceChanges.map(
          (
            home: {
              prevBalance: any
              currBalance: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevBalance, currBalance, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={formatCurrency(currBalance)}
                index={index}
                switchIndex={switchIndexBalance}
                updatesCaller={() => {
                  homeLoansAdditionHandler(
                    summaryId,
                    index,
                    currBalance,
                    prevBalance,
                    "balance"
                  )
                }}
                type="balance"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansAddition?.accountNumberChanges.length !== 0 ? (
        homeLoansAddition?.accountNumberChanges.map(
          (
            home: {
              prevAccountNumber: any
              currAccountNumber: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevAccountNumber, currAccountNumber, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={currAccountNumber}
                index={index}
                switchIndex={switchIndexAccountNumber}
                updatesCaller={() => {
                  homeLoansAdditionHandler(
                    summaryId,
                    index,
                    currAccountNumber,
                    prevAccountNumber,
                    "accountNumber"
                  )
                }}
                type="accountNumber"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansAddition?.ioExpChanges.length !== 0 ? (
        homeLoansAddition?.ioExpChanges.map(
          (
            home: {
              prevIoExp: any
              currIoExp: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevIoExp, currIoExp, summaryId } = home
            if (prevIoExp === "" && currIoExp === "") return null
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={currIoExp}
                index={index}
                switchIndex={switchIndexIoExp}
                updatesCaller={() => {
                  homeLoansAdditionHandler(
                    summaryId,
                    index,
                    currIoExp,
                    prevIoExp,
                    "ioExp"
                  )
                }}
                type="ioExp"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansAddition?.fixedIOChanges.length !== 0 ? (
        homeLoansAddition?.fixedIOChanges.map(
          (
            home: {
              prevFixedIO: any
              currFixedIO: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevFixedIO, currFixedIO, summaryId } = home
            if (prevFixedIO === "" && currFixedIO === "") return null
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={currFixedIO}
                index={index}
                switchIndex={switchIndexFixedIO}
                updatesCaller={() => {
                  homeLoansAdditionHandler(
                    summaryId,
                    index,
                    currFixedIO,
                    prevFixedIO,
                    "fixedIO"
                  )
                }}
                type="fixedIO"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansAddition?.addressChanges.length !== 0 ? (
        homeLoansAddition?.addressChanges.map(
          (
            home: {
              prevAddress: any
              currAddress: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevAddress, currAddress, summaryId } = home
            if (prevAddress === "" && currAddress === "") return null
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={currAddress}
                index={index}
                switchIndex={switchIndexAddress}
                updatesCaller={() => {
                  homeLoansAdditionHandler(
                    summaryId,
                    index,
                    currAddress,
                    prevAddress,
                    "address"
                  )
                }}
                type="addressHL"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of baseRate changes */}
      {homeLoansAddition?.baseRateChanges.length !== 0 ? (
        homeLoansAddition?.baseRateChanges.map(
          (
            rate: {
              prevBaseRate: any
              currBaseRate: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevBaseRate, currBaseRate, summaryId } = rate
            return (
              <ShowChangesTable
                prevVal={`${prevBaseRate}%`}
                currVal={`${currBaseRate}%`}
                index={index}
                switchIndex={switchIndexBaseRate}
                updatesCaller={() => {
                  homeLoansAdditionHandler(
                    summaryId,
                    index,
                    currBaseRate,
                    prevBaseRate,
                    "baseRate"
                  )
                }}
                type="baseRate"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of baseRate changes */}
      {/* start of discount rate changes */}
      {homeLoansAddition?.discountRateChanges.length !== 0 ? (
        homeLoansAddition?.discountRateChanges.map(
          (
            rate: {
              prevDiscountRate: any
              currDiscountRate: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevDiscountRate, currDiscountRate, summaryId } = rate
            return (
              <ShowChangesTable
                prevVal={`${prevDiscountRate}%`}
                currVal={`${currDiscountRate}%`}
                index={index}
                switchIndex={switchIndexDiscountRate}
                updatesCaller={() => {
                  homeLoansAdditionHandler(
                    summaryId,
                    index,
                    currDiscountRate,
                    prevDiscountRate,
                    "discountRate"
                  )
                }}
                type="discountRate"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of discount rate changes */}
      {/* start of interest rate changes */}
      {homeLoansAddition?.interestRateChanges.length !== 0 ? (
        homeLoansAddition?.interestRateChanges.map(
          (
            rate: {
              prevInterestRate: any
              currInterestRate: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevInterestRate, currInterestRate, summaryId } = rate
            return (
              <ShowChangesTable
                prevVal={`${prevInterestRate}%`}
                currVal={`${currInterestRate}%`}
                index={index}
                switchIndex={switchIndexInterestRate}
                updatesCaller={() => {
                  homeLoansAdditionHandler(
                    summaryId,
                    index,
                    currInterestRate,
                    prevInterestRate,
                    "interestRate"
                  )
                }}
                type="interestRate"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of interest rate changes */}
    </>
  )
}

export default HomeLoansChangesAddition
