import * as React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import moment from "moment";
import { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tag from "../../components/list/Tag";
import Subinfo from "../../components/list/Subinfo";
import ListItem from "../../components/list/ListItem";
import { mapTextSlug } from "../../lib/textMap";
import SurveyFormView from "./SurveyFormView";
import { SurveyDoc } from "./surveys.types";
import { StateDone } from "./surveys.query";

const useStyles = makeStyles({
  name: {
    flexGrow: 1,
  },
  mr1: {
    marginRight: "1em",
  },
  mr2: {
    marginRight: "2em",
  },
  actions: {
    minWidth: "5.75em",
    display: "flex",
    flexDirection: "row-reverse",
  },
});

export type SurveysListItemProps = {
  data: SurveyDoc;
};

const SurveysListItem = ({ data }: SurveysListItemProps) => {
  const { _id: id, name, state, createdAt, updatedAt } = data;
  const styles = useStyles();
  const [isView, setIsView] = React.useState(false);
  const openSurvey = useCallback(() => setIsView(true), []);
  const closeSurvey = useCallback(() => setIsView(false), []);

  return (
    <>
      <ListItem>
        <div className={`${styles.name} ${styles.mr1}`}>
          <Typography variant="h5" color="primary">
            <strong>{name}</strong>
          </Typography>
        </div>

        <div className={styles.mr1}>
          <Subinfo
            title={mapTextSlug("createdOn")}
            body={moment(createdAt).format("DD/MM/YYYY")}
          />
        </div>

        <div className={styles.mr2}>
          <Subinfo
            title={mapTextSlug("updatedOn")}
            body={moment(updatedAt).format("DD/MM/YYYY")}
          />
        </div>

        <div className={styles.mr2}>
          <Tag>{mapTextSlug(`${state}State`)}</Tag>
        </div>

        <div className={styles.actions}>
          {state === StateDone ? (
            <Button color="primary" variant="contained" onClick={openSurvey}>
              View
            </Button>
          ) : null}
        </div>
      </ListItem>

      <SurveyFormView
        surveyId={id}
        isVisible={isView}
        onClose={closeSurvey}
        isReadonly
      />
    </>
  );
};

export default SurveysListItem;
