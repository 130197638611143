export const FilterConst = {
    REQUEST_DATE_TYPE: {
        All_Time: { key: 'all-time', value: 'All time' },
        Last_30Days: { key: 'last-30days', value: 'Last 30 days' },
        Last_3Months: { key: 'last-3months', value: 'Last 3 months' },
        Last_6Months: { key: 'last-6months', value: 'Last 6 months' },
        Last_12Months: { key: 'last-12months', value: 'Last 12 months' },
        Last_2Years: { key: 'last-12years', value: 'Last 2 years' },
    },
    REQUEST_STATUS_TYPE: {
        All_Status: { key: 'all', value: 'All' },
        Current_Status: { key: 'current', value: 'Current' },
        Past_Status: { key: 'past', value: 'Past' },
    }
}