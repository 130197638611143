import * as React from "react";
import { memo } from "react";
import {
  GETSurveyData,
  SurveyItemRadioInput,
  SurveyItemRankInput,
  SurveyItemTextInput,
} from "./surveys.types";
import SurveyRadioInput from "./SurveyRadioInput";
import SurveyRankInput from "./SurveyRankInput";
import SurveyTextInput from "./SurveyTextInput";
import { UseMemoFormResult } from "../../lib/useMemoForm";

export type SurveyItemProps = {
  data: SurveyItemTextInput | SurveyItemRankInput | SurveyItemRadioInput;
  form: UseMemoFormResult;
  isVisible?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange?: (name: string, value: string) => void;
  isReadonly?: boolean;
  initialValues?: GETSurveyData["values"];
};

const SurveyItem = ({
  data,
  form,
  isVisible,
  onChange,
  isReadonly,
  initialValues,
}: SurveyItemProps) => {
  if (isVisible === false) return <></>;

  const [stringValue, rankValues] = getCleanValue(
    initialValues,
    data.inputName
  );

  if (data.inputType === "radio") {
    return (
      <SurveyRadioInput
        form={form}
        onChange={onChange}
        data={data}
        isReadonly={isReadonly}
        initialValue={stringValue}
      />
    );
  }

  if (data.inputType === "rank") {
    return (
      <SurveyRankInput
        form={form}
        data={data}
        isReadonly={isReadonly}
        initialValues={rankValues}
      />
    );
  }

  return (
    <SurveyTextInput
      form={form}
      onChange={onChange}
      data={data}
      isReadonly={isReadonly}
      initialValue={stringValue}
    />
  );
};

export default memo(SurveyItem);

export const getCleanValue = (
  values: GETSurveyData["values"] | undefined,
  inputName: string
) => {
  const value = values?.[inputName];

  if (typeof value === "string") {
    return [value, undefined] as const;
  }

  return [undefined, value] as const;
};
