import React, { useState } from "react"
import VersionControl from "./VersionControl"
import AssessmentCalculator from "./AssessmentCalculator"
import {
  i_UnsecuredLiabilities,
  i_FinancialAssets,
  i_CreditCard,
  i_HomeLoans,
  i_IncomeBreakDown,
  i_RentalIncome,
  i_FlexExpense,
} from "./AssessmentCalculator/interface"
import VersionAssessmentContext from "../../../components/Context/VersionAssessmentContext"

interface PAProps {
  uid: String
  owners: {
    client1: {
      fullName: String
      id: String
      selected: boolean
    }
    client2: {
      fullName: String
      id: String
      selected: boolean
    }
  }
  allFinancialAssets: i_FinancialAssets[]
  allNonFinancialAssets: i_FinancialAssets[]
  creditCard: i_CreditCard[]
  flexExpenses: i_FlexExpense[]
  homeLoans: i_HomeLoans[]
  incomeBreakdownClient1: i_IncomeBreakDown[]
  incomeBreakdownClient2: i_IncomeBreakDown[]
  rentalIncome: i_RentalIncome[]
  unsecuredLiabilities: i_UnsecuredLiabilities[]
  navigatePage: Function
}

const PreliminaryAssessment = (props: PAProps) => {
  const [currentPage, setCurrentPage] = useState("VersionControl")
  const [selectedVC, setSelectedVC] = useState("")
  const goto = (page: string, VCId: string) => {
    setSelectedVC(VCId)
    setCurrentPage(page)
  }
  const {
    allFinancialAssets,
    allNonFinancialAssets,
    owners,
    creditCard,
    flexExpenses,
    homeLoans,
    incomeBreakdownClient1,
    incomeBreakdownClient2,
    rentalIncome,
    unsecuredLiabilities,
  } = props

  const [calcIsCompleted, setIsCalcCompleted] = useState("")

  return (
    <>
      <VersionAssessmentContext.Provider
        value={{
          isCompleted: calcIsCompleted,
          setIsCalcCompleted,
        }}
      >
        {currentPage === "VersionControl" && (
          <VersionControl
            uid={props.uid}
            navigatePage={(page: string) => props.navigatePage(page)}
            goto={goto}
          />
        )}
        {currentPage === "AssessmentCalculator" && (
          <AssessmentCalculator
            uid={props.uid}
            id={selectedVC}
            client1={owners.client1}
            client2={owners.client2}
            createdOn={new Date()}
            updatedOn={new Date()}
            goto={goto}
            allFinancialAssets={allFinancialAssets}
            allNonFinancialAssets={allNonFinancialAssets}
            creditCard={creditCard}
            flexExpenses={flexExpenses}
            homeLoans={homeLoans}
            incomeBreakdownClient1={incomeBreakdownClient1}
            incomeBreakdownClient2={incomeBreakdownClient2}
            rentalIncome={rentalIncome}
            unsecuredLiabilities={unsecuredLiabilities}
          />
        )}
      </VersionAssessmentContext.Provider>
    </>
  )
}
export default PreliminaryAssessment
