import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {ColorGrey, FontSizeBody2} from "../../lib/theme";

const useStyles = makeStyles({
  outer: {
    fontSize: FontSizeBody2,
    color: ColorGrey,
  },
  main: {
    border: `0.1em solid ${ColorGrey}`,
    padding: "0.125em 0.25em",
    borderRadius: "0.25em",
    boxSizing: "border-box",
    minWidth: "6.75em",
    textAlign: "center",
  },
});

export type TagProps = {
  color?: string;
  children?: React.ReactNode;
  fontSize?: string;
};

const Tag = ({children}: TagProps) => {
  const styles = useStyles();

  return (
    <div className={styles.outer}>
      <div className={styles.main}>{children}</div>
    </div>
  );
};

export default Tag;
