import { Box, Button, Checkbox, CircularProgress, ClickAwayListener, IconButton, Input, ListItemText, Popper, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ArrowDropDownSharp, Close } from "@material-ui/icons";
import { Autocomplete } from '@material-ui/lab';
import moment from "moment";
import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { FadeLoader } from "react-spinners";
import CheckboxIcon from "../../../assets/checkbox-outline.svg";
import CheckedBoxIcon from "../../../assets/checked-box-outline.svg";
import RemoveOutlineIcon from "../../../assets/delete-icon.svg";
import DownloadIcon from "../../../assets/download-outline.svg";
import EditOutlineIcon from "../../../assets/edit-icon.svg";
import EyeIcon from "../../../assets/eye-outline.svg";
import FilterAltIcon from "../../../assets/filter-icon.svg";
import SearchIconBlack from "../../../assets/search-icon-black.svg";
import SearchAltIcon from "../../../assets/search-icon.svg";
import { DownloadFile, ViewFile } from "../../../lib/services";
import { sortByCreatedAtDate, sortByRequestedDate, sortByUpdatedAtDate } from "../../../lib/sortingDataTable";
import { deleteFile } from "../../../scenes/DocumentCollection/Documents/Documents.controller";
import { i_DocumentPoolFile, i_FileTag } from "./Documents.interfaces";
import styles from "./Documents.module.scss";

const DATE_FORMAT = "DD/MM/YYYY h:mma"
const DEFAULT_FOLDER = "Mortgages"
type InputEvent = ChangeEvent<HTMLInputElement>;
type ButtonEvent = MouseEvent<HTMLButtonElement>;
type AnchorEvent = MouseEvent<HTMLAnchorElement>;

export const customStyles = {
    tableWrapper: {
        style: {
            fontFamily: '"Poppins", sans-serif',
        },
    },
    header: {
        style: {
            fontSize: '20px',
            color: '#541868',
            lineHeight: '28px',
            fontWeight: 400
        },
    },
    head: {
        style: {
            fontSize: '12px',
            color: '#541868',
            lineHeight: '17px',
            fontWeight: 300,
        },
    },
    headRow: {
        style: {
            minHeight: 'unset',
            height: '35px',

            '& div[data-column-id=fileLabel] > div[role=columnheader] > span.__rdt_custom_sort_icon__': {
                order: 2
            }
        },
    },
    rows: {
        style: {
            backgroundColor: '#FAFAFA',
            borderRadius: '4px',
        
            '&:not(:first-child)': {
                marginBottom: '5px',
                marginTop: '5px',
            },
            '&:first-child': {
                marginTop: '23px',
                marginBottom: '5px',
            },
  
            '& > div:first-child': {
                '& svg.MuiSvgIcon-root': {
                    fontSize: '30px',
                    color: '#541868',
                }
            },
        },
        headCells: {
            style: {
                justifyContent: 'flex-start !important',
            },
        },
    },
    headCells: {
        style: {
            justifyContent: 'flex-start',
            fontSize: '12px',
            lineHeight: '17px',
            fontWeight: 300,
            color: '#541868',
    
            '& span.__rdt_custom_sort_icon__': {
                display: 'flex',
                '& svg': {
                    height: '20px',
                    width: '20px',
                    opacity: '0.3',
                },
            },
        },
    },
    cells: {
        style: {
            justifyContent: 'flex-start',
            marginBottom: '9px',
            marginTop: '9px',
            fontSize: '12px',
            lineHeight: '17px',
            color: '#000',
            fontWeight: 300,
        },
    },
};

const DocumentTable: React.FC<DocumentTableProps> = props => {
    const {
      isLoading,
      isLoadingTags,
      item,
      clientId,
      tagsList,
      onFileUploaderModalOpen,
      refreshRequestedDocuments,
      checkMultiDownloadHandler,
      isResetSelectedRow,
      setIsResetSelectedRow,
      isAdmin
    } = props;

    const [tableRowsData, setTableRowsData] = useState<i_DocumentPoolFile[]>([]);
    const [searchKey, setSearchKey] = useState<string>('');
    const [tagsItem, setTagsItem] = useState<i_FileTag[]>([]);

    const [toggleSearch, setToggleSearch] = useState<boolean>(false);

    const [loadingView, setLoadingView] = useState<any>();
    const [loadingDownload, setLoadingDownload] = useState<any>();
    const [anchorElTags, setAnchorElTags] = useState<null | HTMLElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleFileNameSearchChange = (e: InputEvent) => {
        e.stopPropagation();
        setSearchKey(e.target.value);
    }

    const handleFileNameSearchClear = (e: ButtonEvent) => {
        e.stopPropagation();
        setSearchKey('');
    }

    const handleOnClickSearchIcon = (e: AnchorEvent) => {
        e.stopPropagation();
        setToggleSearch(!toggleSearch)
    };

    const handleTagItemChange = (_: ChangeEvent<{}>, values: i_FileTag[]) => {
        setTagsItem(values);
        const filteredByNameItems = filterByRoles(item).filter((fileItem: i_DocumentPoolFile) => fileItem.fileLabel.toString().toLowerCase().includes(searchKey.toString().toLowerCase()));

        const searchData = filteredByNameItems.filter((fileItem: i_DocumentPoolFile) => {
            if (fileItem?.tags && fileItem.tags.find((tagItem: any) => values.find((selectedTag: any) => 
                tagsList.find((tag: any) => tag._id === selectedTag._id) && tagsList.find((tag: any) => tag._id === selectedTag._id)?.name.toLowerCase() === tagItem.toString().toLowerCase())
            )) {
                return fileItem;
            }

            if (values.length === 0) {
                return fileItem;
            }
        });

        setTableRowsData(searchData);
    };

    const actionViewFile = (item: i_DocumentPoolFile) => {
        setLoadingView({ [item._id]: true });
        ViewFile(item.AWSFileKey.split("/").pop() as String, clientId, DEFAULT_FOLDER);
        setTimeout(() => {
            setLoadingView({ [item._id]: false });
        }, 2000)
    }

    const actionDownloadFile = (item: i_DocumentPoolFile) => {
        setLoadingDownload({ [item._id]: true });
        DownloadFile(item.AWSFileKey.split("/").pop() as String, item.fileLabel, clientId, DEFAULT_FOLDER);
        setTimeout(() => {
            setLoadingDownload({ [item._id]: false });
        }, 2000)
    }

    const actionDeleteFile = async (item: i_DocumentPoolFile) => {
        setIsResetSelectedRow && setIsResetSelectedRow(true);
        await deleteFile({ uid: clientId, fileId: item._id });
        await refreshRequestedDocuments();
    }

    const handleClickTagsFilter = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorElTags(event.currentTarget);
        setIsOpen(!isOpen);
    };

    const renderActions = (item: i_DocumentPoolFile) => {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <a href="#">
            {
                loadingView && loadingView[item._id] ?
                <CircularProgress
                    className={styles.circularProgress}
                    size="1.8rem"
                /> :
                <img
                    src={EyeIcon}
                    className={styles.iconsMultiple}
                    alt="view-icon"
                    onClick={() => actionViewFile(item)}
                />
            }
          </a>
          <a href="#">
            {
                loadingDownload && loadingDownload[item._id] ?
                <CircularProgress
                    className={styles.circularProgress}
                    size="1.8rem"
                /> :
                <img
                    src={DownloadIcon}
                    className={styles.iconsMultiple}
                    alt="download-icon"
                    onClick={() => actionDownloadFile(item)}
                />
            }
          </a>
          <a href="#">
            <img
                src={EditOutlineIcon}
                className={styles.iconsMultiple}
                alt="edit-icon"
                onClick={onFileUploaderModalOpen(item._id, true, item.documentType || (item?.dcid && item.dcid.documentType), item.fileLabel, item?.tags ? item.tags : [], item?.statementPeriod, item?.referenceNumber)}
            />
          </a>
          <a href="#">
            <img
                src={RemoveOutlineIcon}
                className={styles.iconsMultiple}
                alt="remove-icon"
                onClick={() => {
                    if (window.confirm("Are you sure you want to proceed delete?")) {
                        actionDeleteFile(item)
                    }
                }}
            />
          </a>
        </div>
    }

    const handleChange = (selected: any) => {
        checkMultiDownloadHandler(selected.selectedRows);
    };

    const clickAwayHandler = () => {
        if (isOpen) setIsOpen(false);
    }

    const filterByRoles = (documents: i_DocumentPoolFile[]) => {
        const userRole = isAdmin && isAdmin['input'];
        if (!userRole || !documents) return documents;
        return documents.filter((document: any) => document.roles ? document.roles.includes(userRole) : true)
    }

    const id = isOpen ? 'simple-popper' : undefined;

    const headerResponsive: TableColumn<i_DocumentPoolFile>[] = [
        {
            id: "documentType",
            selector: row => row.documentType,
            name: "Category",
            sortable: true
        },
        {   
            id: "fileLabel",
            selector: row => row.fileLabel,
            name: (
                <>
                    <span style={{ order: 1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>File Name</span>
                    <a id='file-name-icon' style={{ order: 3 }} onClick={handleOnClickSearchIcon}>
                        <img
                            src={SearchAltIcon}
                            className={styles.iconsMultiple}
                            alt="file-name-icon"
                        />
                    </a>
                    <div className={styles.searchBox} style={{ visibility: toggleSearch ? 'visible' : 'hidden' }}>
                        <Input
                            type="text"
                            placeholder="Search"
                            className={styles.inputControls}
                            value={searchKey}
                            onChange={handleFileNameSearchChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton className={styles.editIcon} onClick={handleFileNameSearchClear}>
                                        <Close htmlColor="#C4C4C4" />
                                    </IconButton>
                                </InputAdornment>
                            }
                            onClick={e => e.stopPropagation()}
                        />
                    </div>
                </>
            ),
            sortable: true,
            grow: 2.5
        },
        {
            id: "tags",
            selector: row => row?.tags ? row.tags.join(", ") : "",
            name: (
                <div className={styles.searchWrapper}>
                    <span>Tags</span>
                    <a id='tag-icon' aria-describedby={id} onClick={handleClickTagsFilter}>
                        <img
                            src={FilterAltIcon}
                            className={styles.iconsMultiple}
                            alt="tag-icon"  
                        />
                    </a>

                    <div className={styles.searchBoxWrapper}>
                        <ClickAwayListener onClickAway={clickAwayHandler}>
                            <Popper id={id} open={isOpen} placement='right' className={styles.outerPopper} anchorEl={anchorElTags} >
                            <Autocomplete
                                id="tag-multiple-checkbox"
                                multiple
                                disableCloseOnSelect
                                forcePopupIcon={false}
                                open={isOpen}
                                loading={isLoadingTags}
                                options={tagsList}
                                getOptionLabel={(option: i_FileTag) => option.name}
                                value={tagsItem}
                                onChange={handleTagItemChange}
                                getOptionSelected={(option, value) => option._id === value._id}
                                renderOption={(option: i_FileTag, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox 
                                            value={option._id}
                                            checked={selected} 
                                            style={{color: '#4e185b'}} 
                                            icon={<img src={CheckboxIcon} className={styles.checkboxIcon} alt="tag-icon" />}
                                            checkedIcon={<img src={CheckedBoxIcon} className={styles.checkboxIcon} alt="tag-icon" />}
                                        />
                                        <ListItemText className={styles.itemTagText} primary={`${option.name}`} />
                                    </React.Fragment>
                                )}
                                renderInput={params => (
                                    <div ref={params.InputProps.ref} className={styles.searchTagBox} onClick={e => e.stopPropagation()}>
                                        <a id='search-tag-icon' {...params.InputLabelProps} onClick={e => e.stopPropagation()}>
                                            <img src={SearchIconBlack} className={styles.iconSearchMultipleTag} alt="tag-icon" />
                                        </a>
                                        <TextField 
                                            {...params}
                                            type="text"
                                            placeholder="Search Tags"
                                            className={styles.searchTagInput}
                                            onChange={e => e.stopPropagation()}
                                            onClick={e => e.stopPropagation()}
                                        />
                                    </div>
                                )}
                                renderTags={(values) => values.map(value => value.name).join(', ')}
                                classes={{
                                    paper: styles.autocompletPaper,
                                    listbox: styles.autocompletListBox,
                                    option: styles.autocompleteOption,
                                    endAdornment: styles.autocompleteEndAdornment,
                                    clearIndicator: styles.autocompleteClearIndicator,
                                }}
                            />
                            </Popper>
                        </ClickAwayListener>
                    </div>
                </div>
            ),
            grow: 1.5
        },
        {
            id: "requestSubmitAt",
            selector: row => row?.requestSubmitAt ? moment(row.requestSubmitAt).valueOf() : moment().valueOf(),
            name: "Requested Date",
            sortable: true,
            cell: (row: i_DocumentPoolFile) => row?.requestSubmitAt && moment(row.requestSubmitAt, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid() ? moment(row.requestSubmitAt).format(DATE_FORMAT) : '',
            sortFunction: sortByRequestedDate
        },
        {
            id: "createdAt",
            selector: row => row?.createdAt ? moment(row.createdAt).valueOf() : moment().valueOf(),
            name: "Created On",
            sortable: true,
            cell: (row: i_DocumentPoolFile) => row.createdAt && moment(row.createdAt, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid() ? moment(row.createdAt).format(DATE_FORMAT) : '',
            sortFunction: sortByCreatedAtDate
        },
        {
            id: "updatedAt",
            selector: row => row?.updatedAt ? moment(row.updatedAt).valueOf() : moment().valueOf(),
            name: "Last Modified",
            sortable: true,
            cell: (row: i_DocumentPoolFile) => row?.updatedAt && moment(row.updatedAt, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid() ? moment(row.updatedAt).format(DATE_FORMAT) : '',
            sortFunction: sortByUpdatedAtDate
        },
        {
            id: "",
            selector: _row => "",
            cell: (row) => renderActions(row),
            name: "",
            sortable: false,
            grow: 1.2,
            style: {
                justifyContent: 'center'
            }
        }
    ];

    useEffect(() => {
        const filteredData = filterByRoles(item);
        setTableRowsData(filteredData);

        if (tagsItem.length > 0 || searchKey !== '') {
            const filteredByNameItems = filterByRoles(item).filter((fileItem: i_DocumentPoolFile) => fileItem.fileLabel.toString().toLowerCase().includes(searchKey.toString().toLowerCase()));
            const searchData = filteredByNameItems.filter((fileItem: i_DocumentPoolFile) => {
                if (fileItem?.tags && fileItem.tags.find((tagItem: any) => tagsItem.find((selectedTag: any) => 
                    tagsList.find((tag: any) => tag._id === selectedTag._id) && tagsList.find((tag: any) => tag._id === selectedTag._id)?.name.toLowerCase() === tagItem.toString().toLowerCase())
                )) {
                    return fileItem;
                }

                if (tagsItem.length === 0) {
                    return fileItem;
                }
            });

            setTableRowsData(searchData);
        }
    },[item, searchKey, tagsItem])

    useEffect(() => {
        if (isLoading) {
            setTagsItem([]);
            setSearchKey('');
        }
    },[isLoading])

    return (
        <DataTable
            columns={headerResponsive}
            noDataComponent={<Box className={styles.noDataBox}>There is currently no data associated in this section.</Box>}
            data={tableRowsData}
            progressPending={isLoading}
            progressComponent={<div className={[styles.spinner, "loading-spinner", styles.noFixed].join(" ")}>
                <FadeLoader height={20} width={5} radius={10} margin={10} color="#4e185b"/>
            </div>}
            defaultSortFieldId="documentType"
            defaultSortAsc
            sortIcon={<ArrowDropDownSharp />}
            selectableRowsComponent={Checkbox}
            selectableRowsComponentProps={{
                icon: <img 
                    src={CheckboxIcon}
                    className={styles.checkboxIcon}
                    alt="tag-icon"  
                />,
                checkedIcon: <img
                    src={CheckedBoxIcon}
                    className={styles.checkboxIcon}
                    alt="tag-icon"  
                />,
                classes: { root: styles.checkboxRoot }
            }}
            onSelectedRowsChange={handleChange}
            selectableRows
            pagination
            fixedHeader
            persistTableHead
            clearSelectedRows={Boolean(isResetSelectedRow)}
            customStyles={customStyles}
            paginationPerPage={Number(process.env.REACT_APP_ITEM_PER_PAGE) || 5}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
        />
    );
}

interface DocumentTableProps {
    item: i_DocumentPoolFile[]
    tagsList: i_FileTag[]
    clientId: string | undefined
    onFileUploaderModalOpen: Function
    refreshRequestedDocuments: Function
    isLoading: boolean
    isLoadingTags: boolean
    checkMultiDownloadHandler: Function
    isResetSelectedRow?: boolean
    setIsResetSelectedRow?: Function
    isAdmin?: boolean | null | string[] | any
}

export default DocumentTable;