import { FormControl, MenuItem, Select } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { FilterConst } from '../../constants/requestHistoryOptions';
import styles from "./MonthRangeFilter.module.scss";
const { REQUEST_DATE_TYPE } = FilterConst;

export interface DateFilterProps {
    startDate: string,
    endDate: string
}

type Props = {
    currentValue?: DateFilterProps,
    isReset?: boolean,
    onChange?: (value: DateFilterProps) => void,
}

const MonthRangeFilter = (props: Props) => {
    const [selMonthsFilter, setSelMonthsFilter] = useState<string>(() => REQUEST_DATE_TYPE.All_Time['key']);

    const handleChangeFilterByMonth = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelMonthsFilter(event.target.value as string);

        let toDate    = moment();
        let fromDate  = moment();

        switch (event.target.value) {
            case (REQUEST_DATE_TYPE.Last_30Days['key']):
                fromDate = moment().add(-30, 'days');
                break;
            case (REQUEST_DATE_TYPE.Last_3Months['key']):
                fromDate = moment().add(-3, 'months');
                break;
            case (REQUEST_DATE_TYPE.Last_6Months['key']):
                fromDate = moment().add(-6, 'months');
                break;
            case (REQUEST_DATE_TYPE.Last_12Months['key']):
                fromDate = moment().add(-12, 'months');
                break;
            case (REQUEST_DATE_TYPE.Last_2Years['key']):
                fromDate = moment().add(-2, 'years');
                break;
        
            default:
                fromDate = moment('1990-01-01')
                toDate = moment('2100-12-31')
                break;
        }

        props.onChange && props.onChange({
            startDate: fromDate.format('YYYY-MM-DD'),
            endDate: toDate.format('YYYY-MM-DD'),
        })
    };

    return (
        <div className={styles.filterByMonth}>
            <FormControl className={styles.formControl}>
                <Select
                    className={styles.selectEmpty}
                    classes={{ 
                        root: styles.selectMenu,
                        icon: styles.selectMenuIcon
                    }}
                    MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        classes: { paper: styles.selectMenuList }
                    }}
                    displayEmpty
                    value={selMonthsFilter}
                    onChange={handleChangeFilterByMonth}>
                    <MenuItem value={REQUEST_DATE_TYPE.All_Time['key']}>{REQUEST_DATE_TYPE.All_Time['value']}</MenuItem>
                    <MenuItem value={REQUEST_DATE_TYPE.Last_30Days['key']}>{REQUEST_DATE_TYPE.Last_30Days['value']}</MenuItem>
                    <MenuItem value={REQUEST_DATE_TYPE.Last_3Months['key']}>{REQUEST_DATE_TYPE.Last_3Months['value']}</MenuItem>
                    <MenuItem value={REQUEST_DATE_TYPE.Last_6Months['key']}>{REQUEST_DATE_TYPE.Last_6Months['value']}</MenuItem>
                    <MenuItem value={REQUEST_DATE_TYPE.Last_12Months['key']}>{REQUEST_DATE_TYPE.Last_12Months['value']}</MenuItem>
                    <MenuItem value={REQUEST_DATE_TYPE.Last_2Years['key']}>{REQUEST_DATE_TYPE.Last_2Years['value']}</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

export default MonthRangeFilter