// ============= DataTable Columns  ===============================

import _ from "lodash"

export const CREDIT_CARD_LIMIT_INDEX = 0
export const PROPOSED_CREDIT_CARD_INDEX = 1
export const MONTHLY_REPAYMENT_INDEX = 1
export const OUTSTANDING_BALANCE_INDEX = 0
export const FIRST_AND_ONLY_INDEX = 0
export const RENTAL_INCOME_CLIENT1_INDEX = 1
export const RENTAL_INCOME_CLIENT2_INDEX = 2
export const FINANCIAL_ASSETS_BALANCE_KEY = 0
export const FINANCIAL_ASSETS_TOTAL_KEY = 1
export const FINANCIAL_ASSETS_CLIENT1_INDEX = 2
export const FINANCIAL_ASSETS_CLIENT2_INDEX = 3
export const NON_FINANCIAL_ASSETS_BALANCE_KEY = 0
export const NON_FINANCIAL_ASSETS_TOTAL_KEY = 1
export const RENTAL_INCOME_INDEX = 0

export const IncomeColumns = [
  "Type",
  "Portal",
  "Broker Input",
  "Period",
  "p/w",
  "p/f",
  "p/m",
  "p/a",
  "Add",
]

export const YTDCalcColumns = [
  "Start Date",
  "Finish Date",
  "# of Days",
  "Weeks",
  "YTD Pay",
  "Ave p/w",
  "Annualised",
  "Add",
]

export const DeductionsColumns = [
  "Type",
  "Amount",
  "Period",
  "p/w",
  "p/f",
  "p/m",
  "p/a",
  "Owner",
  "Voluntary",
  "Add",
]

export const RentalIncomeColumns = [
  "Address",
  "Rent",
  "Period",
  "p/w",
  "p/f",
  "p/m",
  "p/a",
  "Client 1(%)",
  "Client 2(%)",
  "Client 1($) p/a",
  "Client 2($) p/a",
  "Value",
  "Yield",
  "Add",
]

export const HomeLoansColumns = [
  "Address",
  "Lender",
  "Loan Type",
  "Primary Purpose",
  "Borrower",
  "Repayment Type",
  "Loan Expiry",
  "IO Exp",
  "Fixed IO",
  "Refi?",
  "Balance",
  "Redraw",
  "Repayment",
  "Period",
  "Base",
  "Discount",
  "Interest",
  "Account #",
]

export const UnsecuredLiabilitiesColumns = [
  "Type",
  "Balance",
  "Repayment",
  "Period",
  "p/w",
  "p/f",
  "p/m",
  "p/a",
  "Rate",
  "Owner",
  "Add",
]

export const CreditCardColumns = [
  "Lender",
  "Limit",
  "Proposed Limit",
  "Owner",
  "Add",
]

export const FinancialAssetsColumns = [
  "Type",
  "Balance",
  "Owner",
  "Income p/m",
  "Income p/a",
  "Add",
]

export const NonFinancialAssetsColumns = ["Type", "Balance", "Owner", "Add"]

export const ExpensesColumns = ["", "Expenses", "Total", "@50%"]

// ============= Select Input Options  ===============================

export const incomeTypeOptions = [
  "PAYG",
  "Salary - Gross",
  "Overtime Gross",
  "Rental Income - Existing",
  "Rental Income New-",
  "Other Investment",
  "Salary - Net",
  "Overtime - Net",
  "Family Allowance",
  "Pensions & Social Security",
  "Allowances",
  "Bonus",
  "Commission",
  "Dividends",
  "Mainternance and Support",
  "Self Emp/Company - Amortisatipn",
  "Self Emp/ Company - Depreciation",
  "SelfEmp/ Comp-Interset HP Lease",
  "SelfEmp/Company - Profit Self",
  "Emp.Comp - Superannuation",
  "Other Income",
]
export const periodOptions = [
  "Weekly",
  "Fortnightly",
  "Monthly",
  "Bi-Monthly",
  "Quarterly",
  "Yearly",
]

export const ownerOptions = (owner: any) => {
  const clients = _.pickBy(owner, function(value, key) {
    return key === "client1" || key === "client2"
  })
  const options = _.concat(_.map(clients, "fullName"), "Both", "Other")
  return options
}

export const selectedOwner = (owner: any) => {
  const clients = _.pickBy(owner, function(value, key) {
    return key === "client1" || key === "client2"
  })
  const selected = _.filter(clients, "selected")
  if (_.size(selected) === 2) return "Both"
  else if (_.size(selected) === 0) return "Other"
  else return _.toString(_.map(selected, "fullName"))
}

export const getDefaultRowValue = (owners: any, rowName: String) => {
  let options = {}
  if (rowName === "incomeBreakdown") {
    options = {
      period: "Weekly",
      brokerInput: 0,
      portal: 0,
      type: "",
    }
  } else if (rowName === "ytdCalculator") {
    options = {
      incomeType: "",
      ytdPay: 0,
    }
  } else if (rowName === "deductions") {
    options = {
      type: "",
      amount: 0,
      period: "Weekly",
      voluntary: false,
      owner: owners,
    }
  } else if (rowName === "rentalIncome") {
    options = {
      address: "Proposed Property",
      rent: 0,
      period: "Weekly",
      ownershipPercentageClient1: 0,
      ownershipPercentageClient2: 0,
      propertyValue: 0,
      isCustom: false,
    }
  } else if (rowName === "homeLoans") {
    options = {}
  } else if (rowName === "unsecuredLiabilities") {
    options = {
      repayment: 0,
      balance: 0,
      type: "",
      period: "Weekly",
      rate: 0,
      owner: owners,
    }
  } else if (rowName === "creditCard") {
    options = {
      lender: "",
      limit: 0,
      proposedLimit: 0,
      owner: owners,
    }
  } else if (rowName === "financialAssets") {
    options = {
      type: "",
      balance: 0,
      owner: owners,
      yearlyIncome: 0,
      ownershipPercentageClient1: 0,
      ownershipPercentageClient2: 0,
      client1Income: 0,
      client2Income: 0,
    }
  } else if (rowName === "nonFinancialAssets") {
    options = {
      type: "",
      balance: 0,
      owner: owners,
    }
  } else if (rowName === "expenses") {
    options = {}
  }
  return options
}

// =================[ Income Tax Reference ]===============
/** Given Values from the 2020 / 2021 Income Tax Scales and Thresholds */
// Income Tax Ref
interface incomeTaxType {
  min: number
  max: number
  excessRate: number
  tax: number
}
export const incomeTaxRef: incomeTaxType[] = [
  { min: 0, max: 18200, excessRate: 0, tax: 0 },
  { min: 18201, max: 45000, excessRate: 19 / 100, tax: 0 },
  { min: 45001, max: 120000, excessRate: 32.5 / 100, tax: 5092 },
  { min: 120001, max: 180000, excessRate: 37 / 100, tax: 29467 },
  { min: 180001, excessRate: 45 / 100, tax: 51667, max: 180001 },
]
//  Low Middle Income
export const lowIncomeTaxRef: incomeTaxType[] = [
  { max: 37000, excessRate: 0, tax: 255, min: 37000 },
  { min: 37001, max: 48000, excessRate: 7.5 / 100, tax: 255 },
  { min: 48001, max: 90000, excessRate: 0, tax: 1080 },
  { min: 90001, max: 126000, excessRate: -3 / 100, tax: 1080 },
  { min: 126001, excessRate: 0, tax: 0, max: 126001 },
]
//  Low Income
export const lowIncomeTaxRef2: incomeTaxType[] = [
  { min: 0, max: 37500, excessRate: 0, tax: 700 },
  { min: 37501, max: 45000, excessRate: -5 / 100, tax: 700 },
  { min: 45001, max: 66667, excessRate: -1.5 / 100, tax: 325 },
  { min: 66668, excessRate: 0, tax: 0, max: 66668 },
]
// Medicare Levy
export const medicalLevyRef = [
  { min: 0, max: 22801, excessRate: 0, tax: 0 },
  { min: 22802, max: 28501, excessRate: 10 / 100, tax: 0 },
  { min: 28502, excessRate: 2 / 100, tax: 570, max: 0 },
]
