import { Checkbox, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, ListItem, makeStyles } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { ExpanderComponentProps } from "react-data-table-component";
import { RequestHistoryProps } from "./RequestHistory.interfaces";
import styles from "./RequestHistory.module.scss";
import RequestHistoryItem from "./RequestHistoryItem";
import CheckboxIcon from "../../../assets/checkbox-outline.svg";
import CheckedBoxIcon from "../../../assets/checked-box-outline.svg";

const useStyles = makeStyles({
    requestItemWrapper: {
      border: '1px solid #CCCCCC',
      marginLeft: '10px',
      "& .MuiListItem-root.MuiListItem-gutters": {
        paddingLeft: '40px',
        paddingRight: '40px',
      }
    },
    noDataWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      "& > p": {
        color: '#CCC',
        margin: 0
      }
    },
    fileGroup: {
      // padding: "9px 0",
      // height: '35px'
    },
    expansionPanelRoot: {
      margin: '0 !important',
      boxShadow: 'none !important'
    },
    listDocumentType: {
      background: '#C4C4C4 !important',
      borderBottom: '1px #bbb solid !important',
      color:' black !important',
      fontFamily: "'Poppins', 'sans-serif', -apple-system, monospace !important",
      minHeight: '35px !important',
      flexDirection: 'row-reverse',
      boxShadow: 'none !important',
      padding: '0px',
      "& .MuiExpansionPanelSummary-content": {
        margin: '5px 0'
      },
      "& .MuiExpansionPanelSummary-expandIcon": {
        padding: '0 12px',
        margin: '0'
      }
    },
    expansionPanelDetails: {
      padding: '0px'
    }
});

interface Props extends ExpanderComponentProps<RequestHistoryProps> {
    clientId?: any;
    checkDownloadHandler?: Function,
    resetItemComponent?: boolean,
    isAdmin?: boolean | null | string[] | any
}

const RequestHistoryGroup: React.FC<Props> = ({ data, clientId, checkDownloadHandler, resetItemComponent, isAdmin }) => {
    const userRole = isAdmin && isAdmin['input'];
    const filterRequestedDocuments = data.requested_documents.filter((document: any) => document.roles ? document.roles.includes(userRole) : true)

    const classes = useStyles();
    const [filesInAGroup] = useState(filterRequestedDocuments.map(doc => doc.files?.length || false).find(Boolean))
    const [isCheckedAll, setIsCheckedAll] = useState(false)

    const groupedRequestedDocuments = _.chain(filterRequestedDocuments)
    .groupBy("dcid.documentType")
    .map((value, key) => ({ documentType: key, documents: value }))
    .value();

    const requestHistoryItem = groupedRequestedDocuments.map(
      ({ documentType, documents }, index) => {
        return (
          <>
            <ExpansionPanel defaultExpanded className={classes.expansionPanelRoot}>
                <ExpansionPanelSummary expandIcon={
                  <KeyboardArrowDown fontSize="large" className={styles.textOrange} />
                } aria-controls={`panel${index}-content`}id={`panel${index}-header`} className={classes.listDocumentType}>
                  <span className={styles.docType}>{documentType}</span>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                  <ListItem className={styles.fileItemContainer} style={{ flexDirection: 'column'}}>
                    <RequestHistoryItem documents={documents} transactionId={data._id} clientId={clientId} checkDownloadHandler={checkDownloadHandler} isCheckAllGroup={isCheckedAll} resetItemComponent={resetItemComponent}/>
                  </ListItem>
                </ExpansionPanelDetails>
              </ExpansionPanel>
          </>
        )
      }
    )

    const onCheckAllHandler = (e: any) => {
      const isChecked = e.target.checked;

      setIsCheckedAll(isChecked)
      let arrFiles: any = []

      groupedRequestedDocuments.forEach(({ documents }) => {
        // This implementation strongly needs a nested loop using forEach
        // instead of map because of the data structure.
  
        documents.forEach((doc: any) => {
          doc.files.forEach((i: any) => {
            arrFiles.push({
              AWSFileKey: ["DocumentCollection", clientId, i.file_name].join("/"),
                checked: isChecked,
              createdAt: i.createdAt,
                documentType: doc.documentType,
              fileLabel: i.label,
              _id: i._id,
            })
          })
        })
      })
  
      checkDownloadHandler && checkDownloadHandler(arrFiles)
    }

    useEffect(() => {
      if (resetItemComponent) {
        setIsCheckedAll(false);
      }
    },[resetItemComponent])

    return <div className={classes.requestItemWrapper}>
    {
      requestHistoryItem.length > 0 ? 
        <>
          {filesInAGroup && (
            <ListItem>
              <div className={classes.fileGroup}>
                <label className={styles.label}>
                  <Checkbox 
                    checked={isCheckedAll} 
                    style={{color: '#4e185b'}}
                    icon={<img src={CheckboxIcon} className={styles.checkboxIcon}/>}
                    checkedIcon={<img src={CheckedBoxIcon} className={styles.checkboxIcon}/>}
                    onChange={onCheckAllHandler}
                  />{" "}
                  Select All
                </label>
              </div>
            </ListItem>
          )}
          {requestHistoryItem}
        </>
      : <div className={classes.noDataWrapper}><p>No request document data</p></div>
    }
    </div>
}


export default RequestHistoryGroup