exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);", ""]);
// Module
exports.push([module.id, "html, body {\n  margin: 0px !important;\n  padding: 0px !important; }\n\n.aN8TUva96OFir94QnGWld {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  background: rgba(255, 255, 255, 0.8);\n  top: 0;\n  left: 0;\n  z-index: 900;\n  overflow: hidden; }\n\n._2xfr49oxn9D0GPqjKCydME {\n  top: 60px !important; }\n\n._1KJMjI7H1sc5kjp54Rzfhv {\n  font-style: normal !important;\n  font-size: 14px !important;\n  font-weight: 400 !important;\n  line-height: 20px !important;\n  color: #FFF;\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important; }\n\n._1p4n3Iep0rXdIcQ1Pg7teQ {\n  background-color: #45BC0B !important; }\n\n._1M8cXoTJimJ91VFkOzpALb {\n  align-items: center; }\n", ""]);
// Exports
exports.locals = {
	"spinner": "aN8TUva96OFir94QnGWld",
	"root": "_2xfr49oxn9D0GPqjKCydME",
	"toastText": "_1KJMjI7H1sc5kjp54Rzfhv",
	"alertSuccess": "_1p4n3Iep0rXdIcQ1Pg7teQ",
	"alertIcon": "_1M8cXoTJimJ91VFkOzpALb"
};