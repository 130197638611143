
import { Box, Button, FormControlLabel, FormGroup, Grid, IconButton, Input, InputLabel, MenuItem, Select, Typography } from '@material-ui/core'
import { AddCircleOutline, CheckBox, Close, SyncDisabled } from "@material-ui/icons"
import classNames from 'classnames'
import _ from "lodash"
import React, { useEffect, useState } from 'react'
import Modal from '../Modal'
import styles from "./AddDocumentItemsModal.module.scss"
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { addDocumentType, addGeneralDocument, getAllDocumentTypes, i_Document, i_resultAPIs, updateGeneralDocument } from '../../lib/services'
import Checkbox from '@material-ui/core/Checkbox';
import { ModalModes, userRoles } from './../../lib/constants';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
const LockIcon = (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.8125 9.625C4.63016 9.625 4.4553 9.69743 4.32636 9.82636C4.19743 9.9553 4.125 10.1302 4.125 10.3125V18.5625C4.125 18.7448 4.19743 18.9197 4.32636 19.0486C4.4553 19.1776 4.63016 19.25 4.8125 19.25H17.1875C17.3698 19.25 17.5447 19.1776 17.6736 19.0486C17.8026 18.9197 17.875 18.7448 17.875 18.5625V10.3125C17.875 10.1302 17.8026 9.9553 17.6736 9.82636C17.5447 9.69743 17.3698 9.625 17.1875 9.625H4.8125ZM4.8125 8.25H17.1875C17.7345 8.25 18.2591 8.4673 18.6459 8.85409C19.0327 9.24089 19.25 9.76549 19.25 10.3125V18.5625C19.25 19.1095 19.0327 19.6341 18.6459 20.0209C18.2591 20.4077 17.7345 20.625 17.1875 20.625H4.8125C4.26549 20.625 3.74089 20.4077 3.35409 20.0209C2.9673 19.6341 2.75 19.1095 2.75 18.5625V10.3125C2.75 9.76549 2.9673 9.24089 3.35409 8.85409C3.74089 8.4673 4.26549 8.25 4.8125 8.25Z" fill="black" />
        <path d="M11 11.6875C11.1823 11.6875 11.3572 11.7599 11.4861 11.8889C11.6151 12.0178 11.6875 12.1927 11.6875 12.375V16.5C11.6875 16.6823 11.6151 16.8572 11.4861 16.9861C11.3572 17.1151 11.1823 17.1875 11 17.1875C10.8177 17.1875 10.6428 17.1151 10.5139 16.9861C10.3849 16.8572 10.3125 16.6823 10.3125 16.5V12.375C10.3125 12.1927 10.3849 12.0178 10.5139 11.8889C10.6428 11.7599 10.8177 11.6875 11 11.6875ZM15.125 8.25V6.875C15.125 5.78098 14.6904 4.73177 13.9168 3.95818C13.1432 3.1846 12.094 2.75 11 2.75C9.90598 2.75 8.85677 3.1846 8.08318 3.95818C7.3096 4.73177 6.875 5.78098 6.875 6.875V8.25H15.125ZM11 1.375C12.4587 1.375 13.8576 1.95446 14.8891 2.98591C15.9205 4.01736 16.5 5.41631 16.5 6.875V9.625H5.5V6.875C5.5 5.41631 6.07946 4.01736 7.11091 2.98591C8.14236 1.95446 9.54131 1.375 11 1.375Z" fill="black" />
    </svg>
)

type AddDocumentItemsModalProps = {
    showModal: boolean
    onClose: () => void
    clientId: string | undefined
    setDeleteDocumentType?: Function
    openConfirmDeleteModal?: Function
    setMode: Function
    triggerDeleteDocumentType?: string
    refreshPage?: () => void
    mode: string
    document: any
    addDocToListMethod?: Function
    setIsAddRequestDocument?: Function
    isModalRequested?: boolean
    setTxtMessage?: Function
    setShowMessageModal?: Function
    editFromRightSide?: boolean
    showAddCategory?: boolean
}
const AddDocumentItemsModal: React.FC<AddDocumentItemsModalProps> = props => {
    const { showModal, onClose, clientId, openConfirmDeleteModal, setDeleteDocumentType, setMode, triggerDeleteDocumentType, refreshPage, mode, document, addDocToListMethod, setIsAddRequestDocument, isModalRequested, setTxtMessage, setShowMessageModal, editFromRightSide, showAddCategory } = props
    const [documentName, setDocumentName] = useState<string>('');
    const [documentNameError, setDocumentNameError] = useState<string>('');
    const [selectedFileType, setSelectedFileType] = useState<string>("");
    const [documentType, setDocumentType] = useState<string>('');
    const [documentTypeError, setDocumentTypeError] = useState<string>('');
    const [documents, setDocuments] = useState([]);
    const [stackHolders, setStackHolders] = useState<any>({});
    const [isRestrict, setIsRestrict] = useState<boolean>(false)
    const [ListOpen, setlistOpen] = useState(false)

    const init = async () => {
        const docs = await getAllDocumentTypes();
        setDocuments(docs);
        if (mode === ModalModes.EDIT) {
            setDocumentName(document.label);
            setSelectedFileType(document.documentType);
            setIsRestrict(Boolean(document.isRestrict));
            getAccessRoles(document.roles);
        } else {
            setSelectedFileType("");
        }
    }
    const getArrowIcon = () => {
        if (ListOpen) {
            return <ExpandLessIcon className={styles.dropDownIcon} />
        } else {
            return <ExpandMoreIcon className={styles.dropDownIcon} />
        }
    }
    const handleChangeDocumentName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDocumentName(e.target.value)
        if (e.target.value === '') {
            setDocumentNameError('Please input Document name');
        } else {
            setDocumentNameError('')
        }
    }
    const handleChangeNewFileTypeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDocumentType(e.target.value)
        if (e.target.value === '') {
            setDocumentTypeError('Please input File Type Name');
        } else {
            setDocumentTypeError('')
        }
    }

    const resetModal = async () => {
        await onClose()
        setTimeout(() => {
            setDocumentName('');
            setDocumentNameError('');
            setSelectedFileType("")
            setDocumentType("")
            setDocumentTypeError("");
            setDocuments([])
            setStackHolders({})
            setIsRestrict(false);
            if (setDeleteDocumentType) setDeleteDocumentType("")
            setMode("")
        }, 200)

    }

    const handleChangeFileType = (
        e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
        _child: React.ReactNode
    ): void => {
        setSelectedFileType(e.target.value as string)
    }

    const handleSubmit = async () => {
        if (selectedFileType === "Add Category") {
            if (documentType === '') {
                setDocumentTypeError('Please input File Type Name');
            } else if (documentNameError === '') {
                const result: i_resultAPIs = await addDocumentType(documentType);
                if (result.status === "success") {
                    resetModal();
                    if (setIsAddRequestDocument) setIsAddRequestDocument(true)
                } else if (result.status === 400) {
                    setDocumentTypeError(result.message);
                } else {
                    if (setShowMessageModal) setShowMessageModal(true);
                    if (setTxtMessage) setTxtMessage(result.message)
                }
            }
        } else if (selectedFileType && documentName) {
            let result: i_resultAPIs;
            const roles = Object.keys(stackHolders).filter(key => stackHolders[key])
            if (!editFromRightSide) {
                if (mode === ModalModes.EDIT) {
                    result = await updateGeneralDocument(document._id, selectedFileType, documentName, isRestrict, roles);
                } else {
                    const uid = isModalRequested ? clientId : undefined
                    result = await addGeneralDocument(selectedFileType, documentName, uid, isRestrict, roles);
                }

                if (result.status === "success") {
                    if (addDocToListMethod) addDocToListMethod(result.data.document)
                    resetModal();
                    if (setIsAddRequestDocument) setIsAddRequestDocument(true)
                } else if (result.status === 400) {
                    if (setShowMessageModal) setShowMessageModal(true);
                    if (setTxtMessage) setTxtMessage(result.message)
                }
            } else if (editFromRightSide) {
                const uid = editFromRightSide ? clientId : undefined
                let currentDocument = { ...document, documentType: selectedFileType, isRestrict, uid, label: documentName, roles }
                if (addDocToListMethod) addDocToListMethod(currentDocument)
                resetModal();
            }
        }
    }

    const onCloseModal = () => {
        resetModal();
    }
    const handleChangeStackHolders = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStackHolders({ ...stackHolders, [event.target.name]: event.target.checked });
    };
    const handleChangeIsRestrict = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsRestrict(event.target.checked);
    };
    const checkForUserAndSuperAdminRole = (role: string) => {
        if (role === "super_admin" || role === "user") {
            return true;
        } else {
            return false;
        }
    }
    const removeDocumentType = (documentType: string) => {
        if (setDeleteDocumentType) setDeleteDocumentType(documentType)
        setMode("delete document type")
        if (openConfirmDeleteModal) openConfirmDeleteModal()
    }

    const getAccessRoles = (roles: string[]) => {
        let obj: any = {}
        userRoles.map(role => roles.includes(role) ? obj[role] = true : obj[role] = false)
        setStackHolders(obj)
    }

    useEffect(() => {
        if (showModal) init();
        if (mode === "show access roles") {
            getAccessRoles(document.roles)
        } else {
            getAccessRoles(userRoles)
        }

        if (mode === ModalModes.EDIT) {
            getAccessRoles(document.roles || userRoles);
        }
    }, [showModal, triggerDeleteDocumentType]);

    useEffect(() => {
        if (isRestrict) {
            let roles: any = {}
            userRoles.map(role => role === "super_admin" || role === "user" ? roles[role] = true : roles[role] = false)
            setStackHolders(roles)
        } else {
            let roles: any = {}
            userRoles.map(role => roles[role] = true)
            setStackHolders(roles)
        }
    }, [isRestrict])
    return (
        <Modal showModal={showModal} paperClassName={styles.modalPaper}
            onClose={(_, reason) => {
                if (reason === "backdropClick") {
                    resetModal();
                }
            }}
        >
            <Box className={styles.headerRow}>
                <Box className={styles.headerTitle}>
                    {mode === "show access roles" ? <>
                        <IconButton
                            classes={{ root: styles.iconButtons }}

                        >
                            {LockIcon}
                        </IconButton>
                        <Box className={styles.textMaxLength2}>{document.label}</Box>
                    </>
                        : "Document Items"}
                </Box>
                <IconButton onClick={onCloseModal}>
                    <Close classes={{ root: styles.closeIcon }} />
                </IconButton>
            </Box>
            {mode === "show access roles" ?
                <Box className={styles.contentRow}>
                    <Box className={styles.userRoles}>
                        <FormGroup row>
                            <Grid container>
                                <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                    <FormControlLabel
                                        control={<Checkbox checked={document.isRestrict} inputProps={{ 'aria-label': 'disabled checked checkbox' }}
                                            color="secondary"
                                            className={styles['checkbox-icon']}
                                            disabled
                                        />}
                                        label={<Typography variant="h5" color="textPrimary">Limit Access to file. Viewable by:</Typography>}
                                    />
                                </Grid>
                                <Grid container className={styles.checkboxContainer}>
                                    {document && userRoles.map((role: string) => <Grid key={role} item xs={6} style={{ paddingLeft: '20px' }}>
                                        <FormControlLabel
                                            control={<Checkbox
                                                color="primary"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                checked={stackHolders[role]}
                                                disabled
                                            />}
                                            label={<Typography variant="h6" color={(!isRestrict || checkForUserAndSuperAdminRole(role)) ? `textSecondary` : `textPrimary`}>{role}</Typography>}
                                            className={styles.groupCheckBoxCss}
                                        />
                                    </Grid>)}
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </Box>
                </Box> :

                <>
                    <Box className={styles.contentRow}>
                        {selectedFileType !== "Add Category" &&
                            <Box className={styles.inputGroup}>
                                <InputLabel className={styles.inputGroup1}>Document Item Name</InputLabel>
                                <Input
                                    placeholder={"Enter File Name"}
                                    className={classNames(styles.inputControls, styles.txtName)}
                                    value={documentName}
                                    onChange={handleChangeDocumentName} />
                                <div
                                    className={styles.errorMsgEmail}
                                    style={{
                                        display: documentNameError !== '' ? 'block' : 'none',
                                        marginTop: documentNameError !== '' ? '8px' : '0px'
                                    }}
                                >
                                    {documentNameError}
                                </div>
                            </Box>
                        }

                        <Box className={styles.inputGroup}>
                            <InputLabel className={styles.inputGroup1}>File Type</InputLabel>
                            <Select
                                displayEmpty
                                value={selectedFileType}
                                onChange={handleChangeFileType}
                                className={classNames(styles.inputControls, styles.selectTemplate)}
                                classes={{
                                    root: styles.selectMenu,
                                    icon: styles.selectMenuIcon
                                }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    classes: { paper: styles.selectMenuList }
                                }}
                                onOpen={() => setlistOpen(true)}
                                onClose={() => setlistOpen(false)}
                                IconComponent={() => getArrowIcon()}
                                renderValue={selectedFileType !== "" ? (value: any) => {
                                    return <>{value}</>
                                } : () => ""}
                            >

                                {documents && documents.map((key: string) => <MenuItem key={`template-${key}`} className={styles.inputGroupListItem} value={key} >
                                    <Grid container >
                                        <Grid item xs={11} style={{ display: "flex", alignItems: "center", color: "gray" }}>
                                            <Box className={styles.textMaxLength} > {key}</Box>
                                        </Grid>
                                        <Grid item xs={1} style={{ display: "flex", alignItems: "center" }} onClick={() => { removeDocumentType(key) }}>
                                            <div className={classNames(styles.staffButtonGroup, styles.addStaffGroup)}>
                                                <IconButton classes={{ root: styles.iconButtons }} >
                                                    <RemoveCircleOutlineIcon classes={{ root: styles.staffIcon }} />
                                                </IconButton>
                                            </div>
                                        </Grid>
                                    </Grid>

                                </MenuItem>)}
                                {showAddCategory &&
                                    <MenuItem value="Add Category" className={styles.inputGroupListItem}>
                                        <Grid container>
                                            <Grid item xs={11} style={{ display: "flex", alignItems: "center", color: "gray" }}>
                                                Add Category
                                            </Grid>
                                            <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
                                                <div className={classNames(styles.staffButtonGroup, styles.addStaffGroup)}>
                                                    <IconButton classes={{ root: styles.iconButtons }} >
                                                        <AddCircleOutline classes={{ root: styles.staffIcon }} htmlColor="#541868" />
                                                    </IconButton>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                }
                            </Select>
                        </Box>
                        {selectedFileType === "Add Category" &&
                            <Box className={styles.inputGroup}>
                                <Input
                                    placeholder={"New File Type Name"}
                                    className={classNames(styles.inputControls, styles.txtName)}
                                    value={documentType}
                                    onChange={handleChangeNewFileTypeName} />
                                <div
                                    className={styles.errorMsgEmail}
                                    style={{
                                        display: documentTypeError !== '' ? 'block' : 'none',
                                        marginTop: documentTypeError !== '' ? '8px' : '0px'
                                    }}
                                >
                                    {documentTypeError}
                                </div>
                            </Box>

                        }
                        {selectedFileType !== "Add Category" &&
                            <Box className={styles.userRoles}>
                                <FormGroup row>
                                    <Grid container>
                                        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                            <FormControlLabel
                                                control={<Checkbox checked={isRestrict} inputProps={{ 'aria-label': 'disabled checked checkbox' }}
                                                    color="primary"
                                                    onChange={handleChangeIsRestrict}
                                                    className={styles['checkbox-icon']}
                                                    style={isRestrict ? { color: '#541868' } : { color: 'grey' }}
                                                />}
                                                label={<Typography variant="h5" color="textPrimary">Limit Access to file. Viewable by:</Typography>}
                                            />
                                        </Grid>
                                        <Grid container className={styles.checkboxContainer}>
                                            {userRoles.map((role: string) => <Grid key={role} item xs={6} style={{ paddingLeft: '20px' }}>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        name={role}
                                                        checked={stackHolders[role]}
                                                        defaultChecked={checkForUserAndSuperAdminRole(role)}
                                                        disabled={!isRestrict || checkForUserAndSuperAdminRole(role)}
                                                        onChange={handleChangeStackHolders}
                                                        style={(!isRestrict || checkForUserAndSuperAdminRole(role)) ? { color: 'grey' } : { color: '#541868' }}
                                                    />}
                                                    label={<Typography variant="h6" color={(!isRestrict || checkForUserAndSuperAdminRole(role)) ? `textSecondary` : `textPrimary`}>{role}</Typography>}
                                                    className={styles.groupCheckBoxCss}
                                                />
                                            </Grid>)}
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            </Box>
                        }
                    </Box>

                    <Box className={styles.footerRow}>
                        <Button
                            className={classNames(styles.actionButtons, styles.cancelButton)}
                            onClick={onCloseModal}>
                            Cancel
                        </Button>
                        <Button
                            className={classNames(styles.actionButtons, styles.yesButton)}
                            onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Box>
                </>
            }
        </Modal >
    )
}

export default AddDocumentItemsModal;

