exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);", ""]);
// Module
exports.push([module.id, "html, body {\n  margin: 0px !important;\n  padding: 0px !important; }\n\n._242XR81w9TCD_e3PonnFj- {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  background: rgba(255, 255, 255, 0.8);\n  top: 0;\n  left: 0;\n  z-index: 900;\n  overflow: hidden; }\n\n._2FhyRIAUc5V5fRv-FhaGxF {\n  padding: 20px;\n  font-size: 14px; }\n\n._1bahmS-tA_GnMP4NQjAQC4 {\n  color: green !important;\n  font-size: 40px !important; }\n\n._3YqcobLiedkGQwXsjXip5T {\n  display: flex;\n  align-items: center;\n  margin-bottom: 2rem;\n  border-bottom: 1px solid #dfdfdf; }\n  ._3YqcobLiedkGQwXsjXip5T h2 {\n    flex: 1;\n    font-size: 24px;\n    font-family: Poppins, sans-serif, -apple-system, monospace;\n    font-weight: 600; }\n\n._2cY5TJ2Scal8ObT6F1wtgC {\n  display: flex;\n  flex-direction: row-reverse;\n  margin-bottom: 1.3rem; }\n  ._2cY5TJ2Scal8ObT6F1wtgC button {\n    font-size: 14px;\n    color: green;\n    padding: 6px 12px;\n    border-radius: 2px;\n    box-shadow: none;\n    width: 100px;\n    height: 34px; }\n", ""]);
// Exports
exports.locals = {
	"spinner": "_242XR81w9TCD_e3PonnFj-",
	"versionControlContainer": "_2FhyRIAUc5V5fRv-FhaGxF",
	"addIcon": "_1bahmS-tA_GnMP4NQjAQC4",
	"titleContainer": "_3YqcobLiedkGQwXsjXip5T",
	"buttonGroup": "_2cY5TJ2Scal8ObT6F1wtgC"
};