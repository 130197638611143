import React, { useState, useEffect } from "react"
import DataTable from "../DataTable/DataTable"
import Table from "@material-ui/core/Table"
import TableFooter from "@material-ui/core/TableFooter"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import styles from "./../DataTable/DataTable.module.scss"
import { formatMoney } from "./../../scenes/MBWorkbench/PreliminaryAssessment/AssessmentCalculator/calculations" // need to transfer this to clobal utils

const MultiDataTable = (props: any) => {
  const {
    datas,
    handleUpdate,
    table,
    columns,
    rows,
    handleAdd,
    handleDelete,
    overAllTotal,
    getOverAllTotal,
    funcGetTotal,
  } = props

  const [ftotal, setFtotal] = useState<any>()
  const FIRST_ROW_INDEX = 0

  return (
    <div className={styles.multiDataTable}>
      {datas.map((row: any, index: any) => (
        <>
          <DataTable
            key={index}
            handleUpdate={(
              table: String,
              item: any,
              id: Number,
              newValue: any
            ) => {
              return handleUpdate(table, item, index, newValue)
            }}
            table={table}
            columns={columns}
            rows={rows(row)}
            handleAdd={handleAdd}
            handleDelete={(i: any) => handleDelete(i, row)}
            funcGetTotal={(rows: any) => {
              return funcGetTotal(rows)
            }}
            hideTotal={true}
          />
        </>
      ))}
      <TableContainer className={styles.dataTableContainer} component={Paper}>
        <Table>
          <TableFooter>
            <TableRow>
              {datas[FIRST_ROW_INDEX] &&
                Object.keys(datas[FIRST_ROW_INDEX])
                  .slice(0, 10)
                  .map((item: any, index: any) =>
                    index === 0 ? (
                      <TableCell style={{ fontWeight: "bold" }}>
                        Total
                      </TableCell>
                    ) : (
                      <TableCell>
                        {overAllTotal[index] && (
                          <span>${formatMoney(overAllTotal[index])}</span>
                        )}
                      </TableCell>
                    )
                  )}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  )
}
export default MultiDataTable
