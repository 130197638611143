import { ThemeProvider } from "@material-ui/core/styles";
import React, { useEffect } from "react";

import MyQueryClientProvider from "../../components/MyQueryClientProvider";
import apiService from "../../lib/apiService";
import theme from "../../lib/theme";
import SurveyFormView from "./SurveyFormView";

export type LendingSurveyProps = {
  /**
   * Lending Survey Document Id
   */
  id: string;

  apiBaseUrl: string;

  onClose?: () => void;

  onSave?: () => void;
};

const LendingSurvey = ({
  id,
  apiBaseUrl,
  onClose,
  onSave,
}: LendingSurveyProps) => {
  useEffect(() => {
    apiService.defaults.baseURL = apiBaseUrl;
  }, [apiBaseUrl]);

  return (
    <MyQueryClientProvider apiBaseUrl={apiBaseUrl}>
      <ThemeProvider theme={theme}>
        <SurveyFormView surveyId={id} onClose={onClose} onSave={onSave} />
      </ThemeProvider>
    </MyQueryClientProvider>
  );
};

export default LendingSurvey;
