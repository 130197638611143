import React, { useContext, useState } from "react"
import ClientChangesContext from "../../../../../../components/Context/ClientChangesContext"
import ShowChangesTable from "../ShowChangesTable"
import { formatCurrency } from "../../../../../../lib/utils"
import { i_ACStates } from "../../interface"

const SecondaryIncomeUpdates = () => {
  const { income2Updates, ACStates, setACStates } = useContext(
    ClientChangesContext
  )
  const indexOnProperty: boolean[] = []
  const indexOnType: boolean[] = []

  income2Updates &&
    income2Updates.portalChanges.forEach((change: any) =>
      indexOnProperty.push(false)
    )
  income2Updates &&
    income2Updates.typeChanges.forEach((change: any) => indexOnType.push(false))

  const [switchIndexPortal, setSwitchIndexPortal] = useState<boolean[]>(
    indexOnProperty
  )
  const [switchIndexType, setSwitchIndexType] = useState<boolean[]>(indexOnType)

  const changeIncomeHandler = (
    objProp: string,
    currValue: string | boolean | number,
    prevValue: string | boolean | number,
    summaryId: string,
    newSwitch: boolean[],
    index: number
  ) => {
    const incomeBreakdownChanges = ACStates?.client2Income.incomeBreakdown.map(
      (income: any) => {
        if (income.summaryId === summaryId) {
          if (newSwitch !== undefined && newSwitch[index]) {
            income[objProp] = currValue
          } else {
            income[objProp] = prevValue
          }
        }
        return income
      }
    )

    return incomeBreakdownChanges
  }

  const incomeUpdatesHandler = (
    summaryId: string,
    index: number,
    currValue: any,
    prevValue: any,
    toChange: string
  ) => {
    let newSwitch: boolean[]
    let changeValue

    switch (toChange) {
      case "portal":
        newSwitch = switchIndexPortal && [...switchIndexPortal]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changeIncomeHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexPortal(newSwitch)
        break
      case "type":
        newSwitch = switchIndexType && [...switchIndexType]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changeIncomeHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexType(newSwitch)
        break
      default:
        newSwitch = []
    }
    let redoIncomBreakdown: i_ACStates = { ...ACStates }
    redoIncomBreakdown.client2Income.incomeBreakdown = changeValue
    if (ACStates !== undefined) {
      setACStates && setACStates(redoIncomBreakdown)
    }
  }

  return (
    <>
      {income2Updates?.portalChanges.length !== 0 ? (
        income2Updates?.portalChanges.map(
          (
            income: {
              prevPortal: any
              currPortal: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevPortal, currPortal, summaryId } = income
            return (
              <ShowChangesTable
                prevVal={formatCurrency(prevPortal)}
                currVal={formatCurrency(currPortal)}
                index={index}
                switchIndex={switchIndexPortal}
                updatesCaller={() => {
                  incomeUpdatesHandler(
                    summaryId,
                    index,
                    currPortal,
                    prevPortal,
                    "portal"
                  )
                }}
                type="secondaryPortal"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {income2Updates?.typeChanges.length !== 0 ? (
        income2Updates?.typeChanges.map(
          (
            income: {
              prevType: any
              currType: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevType, currType, summaryId } = income
            return (
              <ShowChangesTable
                prevVal={prevType}
                currVal={currType}
                index={index}
                switchIndex={switchIndexType}
                updatesCaller={() => {
                  incomeUpdatesHandler(
                    summaryId,
                    index,
                    currType,
                    prevType,
                    "type"
                  )
                }}
                type="secondaryIncomeType"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
    </>
  )
}

export default SecondaryIncomeUpdates
