import React, { useState, Dispatch, useEffect } from "react"
import AdminRequest from "./AdminRequest/AdminRequest"
import ClientResponse from "./ClientResponse/ClientResponse"
import RequestHistory from "./RequestHistory/RequestHistory"
import {
  sendRequestDocument,
  i_Document,
  i_RequestedDocument,
  getCurrentUser,
  getRequestById,
  getAllRequestsByStatus,
  updateTemplate,
  i_resultAPIs,
  createNewTrackings,
} from "./../../lib/services"
import NavBar from "./../../components/NavBar/NavBar"
import Documents from "./Documents/Documents"
import MultiRequestModal, { AddEditMode } from "../../components/AddMultiRequestModal"
import AddEditTemplateModal from "../../components/AddEditTemplateModal"
import AddConfirmDeleteModal from "../../components/AddConfirmDeleteModal"
import { RequestHistoryProps, RequestHistoryStaff, StaffEmailsProps } from "./RequestHistory/RequestHistory.interfaces"
import _ from "lodash"
import NoticeModal from "../../components/NoticeModal"
import AdminTemplate from "./AdminTemplate"
import { adminTemplateActionMessages, reminderTemplates } from "../../lib/constants"
import { TemplateProps } from "./AdminTemplate/AdminTemplate.interface"
import Toast from "../../components/Toast"
import SuccessModal from "../../components/SuccessModal/SuccessModal"
import { RecipientProps, SequencetProps, UserProps } from "./AdminRequest/AdminRequest.interface"

interface i_setCurrentPageParam {
  page: string
}
const DocumentCollectionIndex = ({
  setContainerToHaveChanges,
  isAdmin,
  client_id,
  user_info,
  client_email,
  client_info,
  triggerSave,
  triggerDiscard,
  toStateProceed,
  stateToChange,
  user_id,
}: i_DocumentCollectionIndex) => {
  const [toSave, setToSave] = useState(triggerSave)
  const [toDiscard, setToDiscard] = useState(triggerDiscard)
  const [showNewRequestTab, setShowNewRequestTab] = useState(false)
  const [showAdminNewRequestTab, setShowAdminNewRequestTab] = useState(false)
  const [showEditTemplateModal, setShowEditTemplateModal] = useState<boolean>(false)
  const [showMultipleRequestModal, setShowMultipleRequestModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [currentUserInfo, setCurrentUserInfo] = useState<RequestHistoryStaff>();
  const [addOrEditMode, setAddOrEditMode] = useState<AddEditMode>(AddEditMode.ADD);
  const [requestData, setRequestData] = useState<RequestHistoryProps>();
  const [showNoticeModal, setShowNoticeModal] = useState(false)
  const [noticeTextModal, setNoticeTextModal] = useState<string>('')
  const [newRequestHaveChanges, setNewRequestHaveChanges] = useState(false);
  const [newRequestLength, setNewRequestLength] = useState<number>(0);
  const [showAdminTemlate, setShowAdminTemlate] = useState<boolean>(false)
  const [template, setTemplate] = useState<TemplateProps>()
  const [deleteDocumentType, setDeleteDocumentType] = useState()
  const [deleteDocumentItem, setDeleteDocumentItem] = useState()
  const [mode, setMode] = useState<string>("")
  const [templateNameError, setTemplateNameError] = useState<boolean>(false);
  const [isResetAdminTemplate, setIsResetAdminTemplate] = useState<boolean>(false);
  const [isTemplateHaveChanges, setIsTemplateHaveChanges] = useState<boolean>(false);
  const [isOpenAlert, setIsOpenAlert] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const AdminRequestSubmitHandler = (
    request_id: String,
    name: string,
    emails: StaffEmailsProps[],
    documents: i_Document[],
    sender: UserProps | undefined,
    recipients: UserProps[] | undefined,
    status: string,
    isFollowUp: boolean,
    sequences: string[] | undefined
  ) => {
    const requestedClientResponse: i_RequestedDocument[] = documents.map(
      document => {
        if (!document.dcid) {
          return {
            dcid: document._id,
            documentLabel: document.label,
            documentType: document.documentType,
            notes: document.notes,
            status: status,
            files: document?.files ?? [],
            isRestrict: document.isRestrict,
            roles: document.roles && [...document.roles]
          }
        } else {
          return {
            dcid: document.dcid,
            documentLabel: document.label,
            documentType: document.documentType,
            notes: document.notes,
            status: status,
            files: document?.files ?? [],
            isRestrict: document.isRestrict !== undefined ? document.isRestrict : document.dcid.isRestrict,
            roles: document.roles ? [...document.roles] : document.dcid.roles && [...document.dcid.roles],
          }
        }
      }
    )

    const strSender = sender && sender.email || '';
    const strRecipients = recipients && recipients.map((recipient: UserProps) => { return { email: recipient.email || '', fname: recipient.fname || ''}});

    return sendRequestDocument(
      requestedClientResponse,
      client_id,
      null,
      status,
      name,
      emails,
      user_id,
      request_id,
      null,
      strSender,
      strRecipients,
      isFollowUp
    ).then(() => {
      if (status !== 'submitByAdmin') {
        setShowSuccessModal(true);
      } else {
        if (isFollowUp) {
          setAlertContent('Your Document Request has now be sent.<br/>Your client will receive an email notifying them of the new request.');
        } else {
          setAlertContent('The Request is now available for the client to Action.<br/>Please email client to let them know!');
        }

        setIsOpenAlert(true);
        if (isFollowUp && sequences) {
          const sequenceObject: SequencetProps[] = [];
          sequences && sequences.map((sequence: string,index) => {
            sequenceObject.push({
              nextReminder: sequence,
              status: "Draft",
              emailTemplate: reminderTemplates[index]
            })
          })

          // adjust as Wil's suggestion: move reminder 0 to the end of the sequence
          sequenceObject.push({
            nextReminder: 0,
            status: "Draft",
            emailTemplate: reminderTemplates[3]
          })

            
          recipients && recipients.forEach((recipient: UserProps, index: number) => {
            const emailObject: RecipientProps = {
              email: recipient?.email ? [recipient.email] : []
            }

            if (recipients.length > 0 && recipients.length <= 1) {
              emailObject.fname1 = recipient.fname
            } else {
              const currentIndex = index;
              const prevIndex = index - 1;
              const nextIndex = index + 1;
              if (currentIndex === 0) {
                emailObject.fname1 = recipients[currentIndex].fname
                emailObject.fname2 = recipients[nextIndex].fname
              } else {
                emailObject.fname1 = recipients[prevIndex].fname
                emailObject.fname2 = recipients[currentIndex].fname
              }
            }

            createNewTrackings({
              reminderSequence: sequenceObject,
              currentSequence: 0,
              sender: sender,
              recipients: emailObject,
              transaction: {
                transactionId: request_id,
                transactionName: name
              }
            });
          })
        }
      }

      setCurrentPage({ page: "RequestHistory" })
    })

  }

  useEffect(() => {
    setToSave(triggerSave)
    setToDiscard(triggerDiscard)
  }, [triggerDiscard, triggerSave])

  useEffect(() => {
    getAllRequestsByStatus(client_id, 'submitByAdmin').then(res => {
      if (res && res.length > 0) {
        setShowNewRequestTab(true)
        setNewRequestLength(res.length)
      }
    })
    setAlertContent('')
    setIsOpenAlert(false);
  }, []) // eslint-disable-line

  const [currentPage, setCurrentPage] = useState<i_setCurrentPageParam>({
    page: isAdmin ? "RequestHistory" : "Documents",
  })

  useEffect(() => {
    if (showNewRequestTab === true && !isAdmin) {
      setCurrentPage({ page: "ClientResponse" })
    }
  }, [showNewRequestTab])

  useEffect(() => {
    if (showAdminNewRequestTab && isAdmin) {
      setCurrentPage({ page: "AdminRequest" })
    }
  }, [showAdminNewRequestTab])

  useEffect(() => {
    if (isAdmin && currentPage.page !== "AdminRequest") {
      setShowAdminNewRequestTab(false);
    }

    if (isAdmin && isAdmin['input'] === "super_admin" && currentPage.page !== "AdminTemplate") {
      setShowAdminTemlate(false);
    }

  }, [currentPage.page])


  const navHandler = (
    setCurrentPage: Dispatch<i_setCurrentPageParam>,
    page: string
  ) => {

    if (!newRequestHaveChanges && !templateNameError && !isTemplateHaveChanges) setCurrentPage({ page: page });

    if (newRequestHaveChanges === true) {
      setShowNoticeModal(true);
      setNoticeTextModal("You've made changes to this request<br/>Please save or discard before leaving")
    }

    if (isAdmin && isAdmin['input'] === 'super_admin') {
      if (isTemplateHaveChanges) {
        setShowNoticeModal(true);
        setNoticeTextModal("You've made changes to this template<br/>Please save or discard before leaving")
      }

      if (templateNameError) {
        setShowNoticeModal(true);
        setNoticeTextModal(adminTemplateActionMessages.TEMPLATE_NAME_WARNING)
      }
    }
  }

  const openMultiRequestModal = () => setShowMultipleRequestModal(true)

  const closeMultiRequestModal = () => setShowMultipleRequestModal(false)
  const openEditTemplateModal = () => setShowEditTemplateModal(true)
  const closeEditTemplateModal = () => setShowEditTemplateModal(false)
  const openConfirmDeleteModal = () => setShowConfirmDeleteModal(true)
  const closeConfirmDeleteModal = () => setShowConfirmDeleteModal(false)

  const handleSubmitMultiRequestModal = (
    name: string,
    documents: i_Document[],
    emails: StaffEmailsProps[],
    status: string,
    mode?: AddEditMode,
    templateId?: string,
    request_id?: String
  ) => {
    const requestedClientResponse: i_RequestedDocument[] = documents.map(
      document => {
        const newDocument = {
          documentLabel: document.documentLabel ?? '',
          documentType: document.documentType,
          notes: document.notes,
          status: status,
          files: document?.files ?? [],
        }

        if (!document.dcid) {
          return {
            ...newDocument,
            dcid: document._id,
            isRestrict: document.isRestrict,
            roles: document.roles && [...document.roles]
          }
        } else {
          return {
            ...newDocument,
            dcid: document.dcid,
            isRestrict: document.isRestrict !== undefined ? document.isRestrict : document.dcid.isRestrict,
            roles: document.roles ? [...document.roles] : document.dcid.roles && [...document.dcid.roles],
          }
        }
      }
    );

    if (mode === AddEditMode.ADD) {
      return sendRequestDocument(
        requestedClientResponse,
        client_id,
        null,
        status,
        name,
        emails,
        user_id,
        null,
        templateId
      ).then(res => {
        closeMultiRequestModal();
        if (res.id) {
          loadRequestItem(res.id);
        }

      })
    } else if (mode === AddEditMode.EDIT && (request_id && request_id !== '')) {
      return sendRequestDocument(
        requestedClientResponse,
        client_id,
        null,
        status,
        name,
        emails,
        user_id,
        request_id,
        templateId
      ).then(() => {
        closeMultiRequestModal();
        if (request_id)
          loadRequestItem(request_id);
      })
    }

  }
  const createDuplicateRequest = (
    requestName: string,
    documents: i_Document[],
    emails: StaffEmailsProps[],
    status: string,
    templateId?: string
  ) => {
    const requestedClientResponse: i_RequestedDocument[] = documents.map(
      document => {
        const newDocument = {
          documentLabel: document.documentLabel ?? '',
          documentType: document.documentType,
          notes: document.notes,
          status: status,
          files: [],
        }

        if (!document.dcid) {
          return {
            ...newDocument,
            dcid: document._id,
            isRestrict: document.isRestrict,
            roles: document.roles && [...document.roles]
          }
        } else {
          return {
            ...newDocument,
            dcid: document.dcid,
            isRestrict: document.isRestrict !== undefined ? document.isRestrict : document.dcid.isRestrict,
            roles: document.roles ? [...document.roles] : document.dcid.roles && [...document.dcid.roles],
          }
        }
      }
    );
    return sendRequestDocument(
      requestedClientResponse,
      client_id,
      null,
      status,
      requestName,
      emails,
      user_id,
      null,
      templateId
    ).then(res => {
      if (res.id) {
        loadRequestItem(res.id);
      }
    })
  }

  const onOpenMultiRequestModal = (mode: AddEditMode, itemData?: RequestHistoryProps, fromAdminRequest?: boolean) => {
    setAddOrEditMode(mode)
    getCurrentUser("users/me").then(userInfo => {
      if (userInfo) {
        if ((fromAdminRequest && mode === AddEditMode.EDIT) || (!fromAdminRequest && mode === AddEditMode.ADD)) {
          openMultiRequestModal();
        }
        setCurrentUserInfo(userInfo);
      }
    });

    if (itemData && itemData._id) {
      loadRequestItem(itemData._id);
    }
  }

  const loadRequestItem = (requestId: String) => {
    getRequestById(requestId).then(request => {
      if (!_.isEmpty(request)) {
        setShowAdminNewRequestTab(true);
        setRequestData(request);
      }
    })
  }

  const adminTemplateSubmitHandler = (
    templateId: string,
    templateName: string,
    documents: i_Document[]
  ) => {
    const templateDocs: i_RequestedDocument[] = documents.map(
      document => {
        return {
          dcid: !document.dcid ? document._id : { ...document.dcid },
          documentLabel: document.label,
          documentType: document.documentType,
          notes: document.notes,
          isRestrict: document.isRestrict,
          roles: document.roles && [...document?.roles],
        }
      }
    )

    return updateTemplate(templateId, templateName, templateDocs, user_id)
      .then((response: i_resultAPIs) => {
        if (response.status === 'success') {
          setCurrentPage({ page: "RequestHistory" })
          setShowNoticeModal(true)
          setNoticeTextModal(adminTemplateActionMessages.SAVE_SUCCESS_MESSAGE)
        } else if (response.status === 400) {
          setShowNoticeModal(true)
          setTemplateNameError(true);
          setNoticeTextModal(response.message);
        } else {
          console.log(`ERROR: ${response.message}`)
          setShowNoticeModal(true)
          setNoticeTextModal(adminTemplateActionMessages.SAVE_ERROR_MESSAGE)
        }
      });

  }

  return (
    <>
      <SuccessModal
        showModal={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
      />
      {isAdmin && isAdmin[0] === "admin" ? (
        <>
          <NavBar
            currentPage={currentPage.page}
            setCurrentPage={(page: string) => navHandler(setCurrentPage, page)}
            pages={[
              { name: "RequestHistory", label: "Requests", show: true },
              { name: "Documents", label: "Client Documents", show: true },
              { name: "AdminRequest", label: "New Request", show: showAdminNewRequestTab },
              { name: "AdminTemplate", label: "Edit Template", show: showAdminTemlate },
            ]}
            openEditTemplateModal={openEditTemplateModal}
            isAdmin={isAdmin}

          />
          {currentPage.page === "AdminRequest" && (
            <AdminRequest
              onSubmit={AdminRequestSubmitHandler}
              clientId={client_id}
              user_info={user_info || null}
              setContainerToHaveChanges={setContainerToHaveChanges}
              triggerSave={toSave || false}
              triggerDiscard={toDiscard || false}
              toStateProceed={toStateProceed}
              stateToChange={stateToChange}
              onEditRequest={onOpenMultiRequestModal}
              requestInfo={requestData}
              newRequestHaveChanges={setNewRequestHaveChanges}
              clientInfo={client_info}
              setMode={setMode}
              mode={mode}
              isAdmin={isAdmin}
            />
          )}
          {currentPage.page === "Documents" && (
            <Documents clientId={client_id} client_email={client_email} isAdmin={isAdmin} />
          )}
          {currentPage.page === "RequestHistory" && (
            <RequestHistory
              clientId={client_id}
              onAddRequest={onOpenMultiRequestModal}
              isAdmin={isAdmin}
              onDuplicateRequest={createDuplicateRequest}
            />
          )}
          {currentPage.page === "AdminTemplate" && (
            <AdminTemplate
              template={template}
              onSubmit={adminTemplateSubmitHandler}
              isAdmin={isAdmin}
              setTemplateNameError={setTemplateNameError}
              clientId={client_id}
              setDeleteDocumentType={setDeleteDocumentType}
              setDeleteDocumentItem={setDeleteDocumentItem}
              openConfirmDeleteModal={openConfirmDeleteModal}
              setMode={setMode}
              mode={mode}
              deleteDocumentType={deleteDocumentType}
              isReset={isResetAdminTemplate}
              setIsReset={setIsResetAdminTemplate}
              setIsTemplateHaveChanges={setIsTemplateHaveChanges}
            />
          )}
        </>
      ) : (
        <>
          <NavBar
            currentPage={currentPage.page}
            setCurrentPage={(page: string) => navHandler(setCurrentPage, page)}
            pages={[
              { name: "Documents", label: "My Documents", show: true },
              {
                name: "ClientResponse",
                label: "New Requests",
                bagdes: newRequestLength,
                show: showNewRequestTab,
              },

            ]}
            isAdmin={isAdmin}
          />
          {currentPage.page === "ClientResponse" ? (
            <ClientResponse
              clientId={client_id}
              client_email={client_email}
              setCurrentPage={(page: string) =>
                navHandler(setCurrentPage, page)
              }
              setNewRequestLength={setNewRequestLength}
            />
          ) : (
            <Documents clientId={client_id} client_email={client_email} isAdmin={isAdmin} />
          )}

        </>
      )}
      <MultiRequestModal
        showModal={showMultipleRequestModal}
        userInfo={currentUserInfo || null}
        onClose={closeMultiRequestModal}
        mode={addOrEditMode}
        onSubmit={handleSubmitMultiRequestModal}
        defaultValues={requestData} />
      <AddEditTemplateModal
        showModal={showEditTemplateModal}
        onClose={closeEditTemplateModal}
        setShowAdminTemlate={setShowAdminTemlate}
        setCurrentPage={setCurrentPage}
        setTemplate={setTemplate}
        isAdmin={isAdmin}
        triggerTemplate={template}
        openConfirmDeleteModal={openConfirmDeleteModal}
        showEditTemplateModal={showEditTemplateModal}
        setMode={setMode}
        setShowNoticeModal={setShowNoticeModal}
        setNoticeTextModal={setNoticeTextModal}
      />
      <NoticeModal
        showModal={showNoticeModal}
        onClose={() => setShowNoticeModal(false)}
        infoText={noticeTextModal}
      />
      <AddConfirmDeleteModal
        showModal={showConfirmDeleteModal}
        onClose={closeConfirmDeleteModal}
        template={template}
        setTemplate={setTemplate}
        setDeleteDocumentType={setDeleteDocumentType}
        deleteDocumentType={deleteDocumentType}
        setDeleteDocumentItem={setDeleteDocumentItem}
        deleteDocumentItem={deleteDocumentItem}
        setIsResetAdminTemplate={setIsResetAdminTemplate}
        mode={mode}
        setIsOpenAlert={setIsOpenAlert}
        setAlertContent={setAlertContent}
      />
      <Toast
        open={isOpenAlert}
        content={alertContent}
        setOpen={setIsOpenAlert}
      />
    </>
  )
}

interface i_DocumentCollectionIndex {
  setContainerToHaveChanges: Function
  toStateProceed: Function
  stateToChange: boolean
  isAdmin?: boolean | null | string[] | any
  client_id?: string | undefined
  user_info?: any | undefined
  client_email?: string | undefined
  triggerSave?: boolean
  triggerDiscard?: boolean
  user_id?: string | undefined
  client_info?: object | undefined
}
export default DocumentCollectionIndex
