import React from "react"
import { Grid } from "@material-ui/core"
import Modal from "../Modal"
interface SuccesModalProps {
  showModal: boolean
  onClose: () => void
}

const SuccessModal: React.FC<SuccesModalProps> = props => {
  const { showModal, onClose } = props;
  return (
    <Modal showModal={showModal} onClose={onClose}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h3 style={{ textAlign: "center", color: "#541868" }}>
            Documents are saved but not requested!
          </h3>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default SuccessModal
