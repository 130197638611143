exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);", ""]);
// Module
exports.push([module.id, "html, body {\n  margin: 0px !important;\n  padding: 0px !important; }\n\n._1ThdT75JM99kCPDUbaYJYb {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  background: rgba(255, 255, 255, 0.8);\n  top: 0;\n  left: 0;\n  z-index: 900;\n  overflow: hidden; }\n\n._2rzJ7lLojIXLfNzzwX0Kk7 {\n  color: #444;\n  width: 100%; }\n\n._3ieUlPSulZYvTjAI6X59B2 {\n  cursor: pointer;\n  border: 1px dashed #C4C4C4;\n  border-radius: 8px;\n  height: 200px;\n  display: flex;\n  justify-content: center;\n  font-size: 14px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-end; }\n  ._3ieUlPSulZYvTjAI6X59B2._1AQPgfbmXDeI-buosN-PFM {\n    border-width: 5px;\n    border-color: #4e185b; }\n  ._3ieUlPSulZYvTjAI6X59B2 p {\n    text-align: center;\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 300;\n    font-size: 12px;\n    line-height: 17px;\n    color: #828282; }\n    ._3ieUlPSulZYvTjAI6X59B2 p span {\n      font-weight: bold;\n      color: #0675e4; }\n", ""]);
// Exports
exports.locals = {
	"spinner": "_1ThdT75JM99kCPDUbaYJYb",
	"fileUploader": "_2rzJ7lLojIXLfNzzwX0Kk7",
	"fileUploaderDrop": "_3ieUlPSulZYvTjAI6X59B2",
	"ondropmode": "_1AQPgfbmXDeI-buosN-PFM"
};