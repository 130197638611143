import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import classNames from "classnames";
import Modal from "../Modal";
import QuickModalActions, { QuickModalActionsProps } from "./QuickModalActions";

export const useQuickModalStyles = makeStyles({
  modalPaper: {
    padding: 0,
    width: 480,
  },
  item: {
    padding: "1em",
    borderBottom: "1px solid #eee",
  },
  actions: {
    padding: "1em",
    display: "flex",
  },
});

export type QuickModalProps = React.HTMLProps<HTMLDivElement> & {
  isVisible: boolean;
  title?: React.ReactNode;
  children: React.ReactNode;
  onClose?: () => void;
  actions?: QuickModalActionsProps["data"];
  actionsFlexDirection?: "row-reverse";
};

const QuickModal = ({
  isVisible,
  onClose,
  title,
  children,
  actions,
  actionsFlexDirection = "row-reverse",
  className,
  ...props
}: QuickModalProps) => {
  const styles = useQuickModalStyles();

  const actionsFinal: QuickModalProps["actions"] = React.useMemo(
    () =>
      actions || [
        {
          name: "Close",
          type: "close",
        },
      ],
    [actions]
  );

  return (
    <Modal
      showModal={isVisible}
      onClose={onClose}
      paperClassName={styles.modalPaper}
    >
      <div className={styles.item}>
        <Typography variant="h6">{title}</Typography>
      </div>

      <div className={classNames(styles.item, className)} {...props}>
        {typeof children === "string" ? (
          <Typography variant="body1">{children}</Typography>
        ) : (
          children
        )}
      </div>

      <div
        className={styles.actions}
        style={{ flexDirection: actionsFlexDirection }}
      >
        <QuickModalActions data={actionsFinal} onClose={onClose} />
      </div>
    </Modal>
  );
};

export default React.memo(QuickModal);
