import { Box, Button, Checkbox, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, IconButton, Input, InputLabel, MenuItem, Select } from '@material-ui/core'
import { Close, KeyboardArrowDown } from '@material-ui/icons'
import classNames from 'classnames'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import CheckboxIcon from "../../assets/checkbox-outline.svg"
import CheckedBoxIcon from "../../assets/checked-box-outline.svg"
import HelpIcon from "../../assets/help-icon.svg"
import { validateEmail } from '../../lib/utils'
import { RequestReadyModalProps, UserProps } from '../../scenes/DocumentCollection/AdminRequest/AdminRequest.interface'
import Modal from '../Modal'
import styles from "./RequestReadyModal.module.scss"

type emailType = {
	_id?: String,
	value?: string,
	name?: string,
	fname?: string,
	lname?: string,
}

const defaultDays = {
	FIRST_DAY: '2',
	SECOND_DAY: '7',
	THIRD_DAY: '10',
}

type errorType = {
	isError: boolean,
	errorMessage: string
}

const DEFAULT_FORMAT = 'D MMM YYYY'


const RequestReadyModal: React.FC<RequestReadyModalProps> = props => {
	const { showModal, onSubmit, onClose, user_info, clientInfo } = props;

	const [senderEmail, setSenderEmail] = useState<string>('');
	const [senderErrMessage, setSenderErrMessage] = useState<string>('');
	const [recipientEmail, setRecipientEmail] = useState<string[]>([]);
	const [recipientEmailOptions, setRecipientEmailOptions] = useState<emailType[]>([]);
	const [isFollowUp, setIsFollowUp] = useState<boolean>(true);

	const [txtFirstReminder, setTxtFirstReminder] = useState<string>('');
	const [txtSecondReminder, setTxtSecondReminder] = useState<string>('');
	const [txtThirdReminder, setTxtThirdReminder] = useState<string>('');

	const [txtFirstRender, setTxtFirstRender] = useState<string>();
	const [txtSecondRender, setTxtSecondRender] = useState<string>();
	const [txtThirdRender, setTxtThirdRender] = useState<string>();

	const [txtFirstError, setTxtFirstError] = useState<errorType>();
	const [txtSecondError, setTxtSecondError] = useState<errorType>();
	const [txtThirdError, setTxtThirdError] = useState<errorType>();
	
	const [openInnerModal, setOpenInnerModal] = useState<boolean>(false);
	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	useEffect(() => {
		resetModal();
		setSenderEmail(user_info.email);
	}, [showModal]);

	useEffect(() => {
		if (clientInfo) {
			const clientEmails: emailType[] = [];
			if (clientInfo.hasOwnProperty('client1')) {
				clientEmails.push({ 
					_id: clientInfo?.client1?._id, 
					value: clientInfo?.client1?.email, 
					name: clientInfo?.client1?.email ? `Person 1 (${clientInfo?.client1?.email})` : '',
					fname: clientInfo?.client1?.fName,
					lname: clientInfo?.client1?.lName
				 })
			}

			if (clientInfo.hasOwnProperty('client2')) {
				clientEmails.push({ 
					_id: clientInfo?.client2?._id, 
					value: clientInfo?.client2?.email, 
					name: clientInfo?.client2?.email ? `Person 2 (${clientInfo?.client2?.email})` : '',
					fname: clientInfo?.client2?.fName,
					lname: clientInfo?.client2?.lName
				})
			}

			if (clientEmails.length === 0) {
				clientEmails.push({ _id: '0', value: '', name: 'Blank email' })
			}

			setRecipientEmailOptions(clientEmails);
			clientEmails[0].value && setRecipientEmail([clientEmails[0].value]);
		}
	}, [clientInfo]);

	// Validation for txtFirstReminder
	useEffect(() => {
		const errorObject = { isError: false, errorMessage: '' }

		if (!txtFirstReminder || txtFirstReminder === '') {
			errorObject.isError = true;
			errorObject.errorMessage = "1st reminder is required";
		} else if (isNaN(Number(txtFirstReminder))) {
			errorObject.isError = true;
			errorObject.errorMessage = "Reminder must be a number";
		} else if (Number(txtFirstReminder) < 1 || Number(txtFirstReminder) > 365) {
			errorObject.isError = true;
			errorObject.errorMessage = "Reminder must be between 1 and 365 days";
		} else if (Number(txtFirstReminder) >= Number(txtSecondReminder)) {
			errorObject.isError = true;
			errorObject.errorMessage = "The 1st reminder email must occur before the 2nd reminder email";
		}

		setTxtFirstError(errorObject);
		!errorObject.isError && setTxtFirstRender(calculateNumberToDate(Number(txtFirstReminder)));
	}, [txtFirstReminder]);

	// Validation for txtSecondReminder
	useEffect(() => {
		const errorObject = { isError: false, errorMessage: '' }

		if (!txtSecondReminder || txtSecondReminder === '') {
			errorObject.isError = true;
			errorObject.errorMessage = "2nd reminder is required";
		} else if (isNaN(Number(txtSecondReminder))) {
			errorObject.isError = true;
			errorObject.errorMessage = "Reminder must be a number";
		} else if (Number(txtSecondReminder) < 1 || Number(txtSecondReminder) > 365) {
			errorObject.isError = true;
			errorObject.errorMessage = "Reminder must be between 1 and 365 days";
		} else if (Number(txtSecondReminder) >= Number(txtThirdReminder)) {
			errorObject.isError = true;
			errorObject.errorMessage = "The 2nd reminder email must occur before the 3rd reminder email";
		} else if (Number(txtSecondReminder) <= Number(txtFirstReminder)) {
			errorObject.isError = true;
			errorObject.errorMessage = "The 2nd reminder email must occur after the 1st reminde email";
		}

		setTxtSecondError(errorObject);
		!errorObject.isError && setTxtSecondRender(calculateNumberToDate(Number(txtSecondReminder)));
	}, [txtSecondReminder]);

	// Validation for txtThirdReminder
	useEffect(() => {
		const errorObject = { isError: false, errorMessage: '' }

		if (!txtThirdReminder || txtThirdReminder === '') {
			errorObject.isError = true;
			errorObject.errorMessage = "3rd reminder is required";
		} else if (isNaN(Number(txtThirdReminder))) {
			errorObject.isError = true;
			errorObject.errorMessage = "Reminder must be a number";
		} else if (Number(txtThirdReminder) < 1 || Number(txtThirdReminder) > 365) {
			errorObject.isError = true;
			errorObject.errorMessage = "Reminder must be between 1 and 365 days";
		} else if (Number(txtThirdReminder) <= Number(txtSecondReminder)) {
			errorObject.isError = true;
			errorObject.errorMessage = "The 3rd reminder email must occur after the 2nd reminder email";
		}

		setTxtThirdError(errorObject);
		!errorObject.isError && setTxtThirdRender(calculateNumberToDate(Number(txtThirdReminder)));
	}, [txtThirdReminder]);

	const resetModal = () => {
		setTxtFirstReminder(defaultDays.FIRST_DAY);
		setTxtSecondReminder(defaultDays.SECOND_DAY);
		setTxtThirdReminder(defaultDays.THIRD_DAY);

		setTxtFirstRender(calculateNumberToDate(defaultDays.FIRST_DAY));
		setTxtSecondRender(calculateNumberToDate(defaultDays.SECOND_DAY));
		setTxtThirdRender(calculateNumberToDate(defaultDays.THIRD_DAY));

		setIsFollowUp(true);
		setSenderErrMessage("");
	}

	const calculateNumberToDate = (numberOfDays: string | number) => {
		return moment().add(numberOfDays, 'days').format(DEFAULT_FORMAT)
	}

	const handleChangeSenderEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSenderEmail(e.target.value)

		if (e.target.value === "") {
			setSenderErrMessage("Sender email cannot be blank")
		} else if (!validateEmail(e.target.value)) {
			setSenderErrMessage("Please input valid email")
		} else {
			setSenderErrMessage("");
		}
	}

	const handleChangeRecipientEmail = (
		e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
		_child: React.ReactNode): void => {
		setRecipientEmail(e.target.value as any)
	}

	const handleSubmitModal = () => {
		if (senderErrMessage === '' && !txtFirstError?.isError && !txtSecondError?.isError && !txtThirdError?.isError) {
			const sender: UserProps = {};
			const recipients: UserProps[] = [];
			const sequences: string[] = [];

			sender.email = senderEmail;
			sender.fname = user_info.fname;
			sender.lname = user_info.lname;


			sequences.push(txtFirstReminder);
			sequences.push(txtSecondReminder);
			sequences.push(txtThirdReminder);

			const filteredRecipients = recipientEmailOptions.filter(option => recipientEmail.some(recipient => recipient === option.value));
			filteredRecipients.forEach(recipient => {
				recipients.push({ 
					email: recipient?.value,
					fname: recipient?.fname,
					lname: recipient?.lname
				});
			})
			
			onSubmit(sender, recipients, isFollowUp, isFollowUp ? sequences : undefined);
			resetModal();
		}
	}

	const handleChangeFollowUpEmail = (e: any) => {
		e.stopPropagation();
		setIsFollowUp(!isFollowUp);
	}

	const handleOpenHelp = (e: any) => {
		e.stopPropagation();
		setOpenInnerModal(true);
	}

	const handleCloseHelp = () => {
		setOpenInnerModal(false);
	};

	const onChangeReminders = (event: any, reminderType: string) => {
		const { value } = event.target;
		const numberPattern = /^[0-9\b]+$/;
		
		if (value === '' || numberPattern.test(value)) {
			if (reminderType === 'first') {
				setTxtFirstReminder(value);
			} else if (reminderType === 'second') {
				setTxtSecondReminder(value);
			} else if (reminderType === 'third') {
				setTxtThirdReminder(value);
			}
		}
	};

	return (
		<React.Fragment>
			<Modal showModal={showModal} paperClassName={styles.modalWrapper}>
				<Box className={styles.headerRow}>
					<Box className={styles.headerTitle}>Request Ready to be Sent</Box>
					<IconButton onClick={onClose}>
						<Close classes={{ root: styles.closeIcon }} />
					</IconButton>
				</Box>

				<Box className={styles.contentRow}>
					<Box className={classNames(styles.inputGroup, styles.inputSender)}>
						<InputLabel className={styles.inputLabel}>Send request from (sender):</InputLabel>
						<Input
							placeholder={'Please input sender email'}
							className={classNames(styles.inputControls, styles.txtName)}
							value={senderEmail}
							onChange={handleChangeSenderEmail}
						/>
						{
							senderErrMessage !== '' &&
							<span className={styles.errorMsgEmail}>
								{senderErrMessage}
							</span>
						}
					</Box>
					<Box className={classNames(styles.inputGroup, styles.inputRecipient)}>
						<InputLabel className={styles.inputLabel}>Send request to (recipient):</InputLabel>
						<Select
							displayEmpty
							value={recipientEmail}
							onChange={handleChangeRecipientEmail}
							className={classNames(styles.inputControls, styles.selectControls)}
							classes={{
								root: styles.selectMenu,
								icon: styles.selectMenuIcon
							}}
							MenuProps={{
								anchorOrigin: {
									vertical: "bottom",
									horizontal: "left"
								},
								transformOrigin: {
									vertical: "top",
									horizontal: "left"
								},
								getContentAnchorEl: null,
								classes: { paper: styles.selectMenuList }
							}}
							renderValue={recipientEmail.length > 0 ? (value: any) => {
								if (value.length >= 2) {
									return `Both (${value[0]} & ${value[1]})`
								} else if (recipientEmailOptions.length === 2) {
									let filter = recipientEmailOptions.filter(item => item.value == value[0])
									return <>{filter[0].name}</>
								}
								else {
									let filter = recipientEmailOptions.filter(item => item.value == value[0])
									return <>{filter[0].name}</>
								}
							} : () => []}
						>
							{recipientEmailOptions.map((email: any) => {
								return <MenuItem key={`${email._id}`} className={classNames(styles.DropDownList)} value={[email.value]}>{email.name}</MenuItem>
							}
							)}
							{recipientEmailOptions && recipientEmailOptions.length >= 2 &&
								<MenuItem className={classNames(styles.DropDownList)} value={[String(recipientEmailOptions[0].value), String(recipientEmailOptions[1].value)]}> Both ({recipientEmailOptions[0].value} & {recipientEmailOptions[1].value}) </MenuItem>
							}

						</Select>
					</Box>

					<Box className={styles.inputGroup} style={{ marginBottom: isExpanded ? '25px' : '5px'}}>
						<ExpansionPanel className={styles.expansionPanel} onChange={(_e, expanded) => setIsExpanded(Boolean(expanded))}>
							<ExpansionPanelSummary
								expandIcon={<KeyboardArrowDown fontSize="large" />}
								aria-label="Expand"
								aria-controls="follow-up-email-summary"
								id="follow-up-email-header"
								className={styles.expansionPanelSummary}
							>
								<Box display="flex" alignItems="center">
									<Checkbox
										icon={<img src={CheckboxIcon} className={styles.checkboxIcon} alt="tag-icon" />}
										checkedIcon={<img src={CheckedBoxIcon} className={styles.checkboxIcon} alt="tag-icon" />}
										classes={{ root: styles.checkboxRoot }}
										checked={isFollowUp}
										onChange={handleChangeFollowUpEmail}
										onClick={event => event.stopPropagation()}
										onFocus={event => event.stopPropagation()}
									/>
									<p style={{ margin: 0 }}>
										<span className={styles.inputLabel} style={{ marginLeft: '10px' }}>Send auto email follow-ups</span>
										<img src={HelpIcon} onClick={handleOpenHelp} style={{ verticalAlign: 'baseline' }} />
									</p>
								</Box>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails className={styles.expansionPanelDetails}>
								<Box className={styles.detailWrapper}>
									<Box className={styles.detailRows}>
										<p className={classNames(styles.txtDetailNormalText, styles.txtFirstText)}>1st email reminder</p>
										<input
											type="text"
											disabled={!isFollowUp}
											className={classNames(styles.inpDetailNormalInput, styles.inputNumberOfDay)}
											value={txtFirstReminder}
											onChange={event => onChangeReminders(event, 'first')}
										/>
										<p className={classNames(styles.txtDetailNormalText, styles.txtSecondText)}>day(s) from now</p>
										<input
											type="text"
											disabled
											className={classNames(styles.inpDetailNormalInput, styles.inpuRenderDate)}
											value={txtFirstRender}
										/>
									</Box>
									{txtFirstError && txtFirstError.isError && <span className={styles.errorMsg}>{txtFirstError.errorMessage}</span>}
									<Box className={styles.detailRows}>
										<p className={classNames(styles.txtDetailNormalText, styles.txtFirstText)}>2nd email reminder</p>
										<input
											type="text"
											disabled={!isFollowUp}
											className={classNames(styles.inpDetailNormalInput, styles.inputNumberOfDay)}
											value={txtSecondReminder}
											onChange={event => onChangeReminders(event, 'second')}
										/>
										<p className={classNames(styles.txtDetailNormalText, styles.txtSecondText)}>day(s) from now</p>
										<input
											type="text"
											className={classNames(styles.inpDetailNormalInput, styles.inpuRenderDate)}
											disabled
											value={txtSecondRender}
										/>
									</Box>
									{txtSecondError && txtSecondError.isError && <span className={styles.errorMsg}>{txtSecondError.errorMessage}</span>}
									<Box className={styles.detailRows}>
										<p className={classNames(styles.txtDetailNormalText, styles.txtFirstText)}>3rd email reminder</p>
										<input
											type="text"
											disabled={!isFollowUp}
											className={classNames(styles.inpDetailNormalInput, styles.inputNumberOfDay)}
											value={txtThirdReminder}
											onChange={event => onChangeReminders(event, 'third')}
										/>
										<p className={classNames(styles.txtDetailNormalText, styles.txtSecondText)}>day(s) from now</p>
										<input
											type="text"
											className={classNames(styles.inpDetailNormalInput, styles.inpuRenderDate)}
											disabled
											value={txtThirdRender}
										/>
									</Box>
									{txtThirdError && txtThirdError.isError && <span className={styles.errorMsg}>{txtThirdError.errorMessage}</span>}
								</Box>
							</ExpansionPanelDetails>
						</ExpansionPanel>
					</Box>
					<Box className={classNames(styles.inputGroup, styles.moreInformation)}>
						<p className={styles.txtMoreInfo}>Upon submission, an email will be sent immediately to notify the<br /> client that a request is outstanding</p>
					</Box>
				</Box>

				<Box className={styles.footerRow}>
					<Button
						className={classNames(styles.actionButtons, styles.cancelButton)}
						onClick={onClose}>
						Cancel
					</Button>
					<Button
						className={classNames(styles.actionButtons, styles.yesButton)}
						onClick={handleSubmitModal}>
						Yes
					</Button>
				</Box>
			</Modal>
			<Modal showModal={openInnerModal} paperClassName={styles.innerModalWrapper} onClose={handleCloseHelp}>
				<Box className={styles.innerHeaderRow}>
					<Box className={styles.headerTitle}>Auto email follow-up sequence</Box>
					<IconButton onClick={handleCloseHelp}>
						<Close classes={{ root: styles.closeIcon }} />
					</IconButton>
				</Box>

				<Box className={styles.innerContentRow}>
					<p className={styles.innerText}>This will send automatic email follow-ups to the recipient(s) specified at 10am AEDT. By default, the emails will be sent <span className={styles.innerSpan}>until the client has completed</span> the request:</p>
						<ul>
							<li className={styles.innerText}><span className={styles.innerSpan}>2 days</span> after the submission of this form; and</li>
							<li className={styles.innerText}><span className={styles.innerSpan}>7 days</span> after the submission of this form; and</li>
							<li className={styles.innerText}><span className={styles.innerSpan}>10 days</span> after the submission of the form</li>
						</ul>
					<p className={styles.innerText}>You can cancel an automatic email follow-up chain in the request menu by choosing it from the Request's menu options.</p>
				</Box>
			</Modal>
		</React.Fragment>
	)
}

export default RequestReadyModal