import { useMemo } from "react";
import { useForm } from "react-hook-form";

export default function useMemoForm() {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    unregister,
    setFocus,
  } = useForm();

  return useMemo(
    () => ({
      register,
      handleSubmit,
      getValues,
      setValue,
      control,
      unregister,
      setFocus,
    }),
    [register, handleSubmit, getValues, setValue, control, unregister, setFocus]
  );
}

export type UseMemoFormResult = ReturnType<typeof useMemoForm>;
