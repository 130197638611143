import React, { useEffect, useState } from "react"
import { DuplicateRequestModalProps } from "./../../scenes/DocumentCollection/AdminRequest/AdminRequest.interface"
import Modal from "./../Modal"
import styles from "./DuplicateRequestModal.module.scss"
import { Button, MenuItem, Select, Input, Box, IconButton, InputLabel, Typography } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import classNames from "classnames"
import { i_Document } from '../../lib/services'

const DuplicateRequestModal: React.FC<DuplicateRequestModalProps> = props => {
    const { showModal, onClose, rowData, onSubmit, setShowDuplicateRequestModal } = props
    const [requestName, setRequestName] = useState("")

    const onFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRequestName(e.target.value)
    }
    const handleSubmit = () => {
        const templateId = rowData?.template?._id !== '' ? rowData.template?._id : undefined;
        onSubmit(requestName, rowData.requested_documents, rowData.staffEmails, 'draft', templateId);
        setRequestName("")
        setShowDuplicateRequestModal(false)
    }


    return (
        <Modal showModal={showModal} onClose={onClose} paperClassName={styles.modalPaper}>
            <Box className={styles.headerRow}>
                <Box className={styles.headerTitle}>Duplicate Document Request</Box>
                <IconButton onClick={onClose}>
                    <Close classes={{ root: styles.closeIcon }} />
                </IconButton>
            </Box>

            <Box className={styles.contentRow}>
                <Box className={styles.txtSubHeader}>You are duplicating the request.</Box>
                <Box className={styles.ReuesterName}>{rowData.name}</Box>
                <Box className={styles.inputGroup}>
                    <InputLabel className={styles.txtSubHeader}>Please assign a new name for this request :</InputLabel>
                    <Input
                        placeholder="Name of Request"
                        type="text"
                        className={classNames(styles.inputControls, styles.txtName, styles.fileName)}
                        onChange={onFileNameChange}
                        value={requestName}
                    />
                </Box>
            </Box>
            <Box className={styles.footerRow}>
                <Button
                    className={classNames(styles.actionButtons, styles.cancelButton, styles.secondaryButton)}
                    onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    className={classNames(styles.actionButtons, styles.yesButton, styles.primaryButton)}
                    onClick={handleSubmit}
                    disabled={!requestName && requestName === ""}
                >
                    Submit
                </Button>
            </Box>
        </Modal>
    )
}
export default DuplicateRequestModal
