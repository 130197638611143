import moment from "moment"
import { i_DocumentPoolFile } from "../scenes/DocumentCollection/Documents/Documents.interfaces";
import { RequestHistoryProps } from "../scenes/DocumentCollection/RequestHistory/RequestHistory.interfaces";

export const sortByName = (rowA: RequestHistoryProps, rowB: RequestHistoryProps) => {
  const item1 = rowA?.name ? rowA.name.toLowerCase() : '';
  const item2 = rowB?.name ? rowB.name.toLowerCase() : '';
  return item1.localeCompare(item2);
}

export const sortByCompletedAt = (rowA: RequestHistoryProps, rowB: RequestHistoryProps) => {
    const item1 = rowA?.completedAt ? moment(rowA.completedAt).valueOf() : moment('1990-01-01').valueOf();
    const item2 = rowB?.completedAt ? moment(rowB.completedAt).valueOf() : moment('1990-01-01').valueOf();
    return item1 - item2;
}

export const sortByRequested = (rowA: RequestHistoryProps, rowB: RequestHistoryProps) => {
    const item1 = rowA?.createdAt ? moment(rowA.createdAt).valueOf() : moment('1990-01-01').valueOf();
    const item2 = rowB?.createdAt ? moment(rowB.createdAt).valueOf() : moment('1990-01-01').valueOf();
    return item1 - item2
}

export const sortByRequester = (rowA: RequestHistoryProps, rowB: RequestHistoryProps) => {
    const requester1 = (rowA?.staff?.fname && rowA.staff.fname !== '' && rowA?.staff?.lname && rowA.staff.lname !== '') ? `${rowA.staff.fname} ${rowA.staff.lname}`.toLowerCase() : rowA?.staff?.email ? rowA.staff.email.split('@')[0].toLowerCase() : '';
    const requester2 = (rowB?.staff?.fname && rowB.staff.fname !== '' && rowB?.staff?.lname && rowB.staff.lname !== '') ? `${rowB.staff.fname} ${rowB.staff.lname}`.toLowerCase() : rowB?.staff?.email ? rowB.staff.email.split('@')[0].toLowerCase() : '';

    if (requester1 && requester2) {
      if (requester1 > requester2) {
        return -1;
      }
      
      if (requester2 > requester1) {
          return 1;
      }
    }
    
    return 0;
}

export const sortByCompletion = (rowA: RequestHistoryProps, rowB: RequestHistoryProps) => {
    const item1 = rowA?.completion ? rowA.completion.uploadedFiles : 0;
    const item2 = rowB?.completion ? rowB.completion.uploadedFiles : 0;
    return item1 - item2
}

export const sortByRequestedDate = (rowA: i_DocumentPoolFile, rowB: i_DocumentPoolFile) => {
  const item1 = rowA?.requestSubmitAt ? moment(rowA.requestSubmitAt).valueOf() : moment('1990-01-01').valueOf();
  const item2 = rowB?.requestSubmitAt ? moment(rowB.requestSubmitAt).valueOf() : moment('1990-01-01').valueOf();
  return item1 - item2
}

export const sortByCreatedAtDate = (rowA: i_DocumentPoolFile, rowB: i_DocumentPoolFile) => {
  const item1 = rowA?.createdAt ? moment(rowA.createdAt).valueOf() : moment('1990-01-01').valueOf();
  const item2 = rowB?.createdAt ? moment(rowB.createdAt).valueOf() : moment('1990-01-01').valueOf();
  return item1 - item2
}

export const sortByUpdatedAtDate = (rowA: i_DocumentPoolFile, rowB: i_DocumentPoolFile) => {
  const item1 = rowA?.updatedAt ? moment(rowA.updatedAt).valueOf() : moment('1990-01-01').valueOf();
  const item2 = rowB?.updatedAt ? moment(rowB.updatedAt).valueOf() : moment('1990-01-01').valueOf();
  return item1 - item2
}