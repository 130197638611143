import Button from "@material-ui/core/Button";
import * as React from "react";

export type QuickModalActionsProps = {
  onClose?: () => void;
  data: {
    name?: string;
    onClick?: () => void;
    type?: "submit" | "close";
  }[];
};

const QuickModalActions = ({onClose, data}: QuickModalActionsProps) => {
  return (
    <>
      {data.map(({name, type, onClick: onClickAction}) => (
        <Button
          key={name}
          color="primary"
          variant="contained"
          onClick={
            type !== "close"
              ? onClickAction
              : () => {
                  if (onClickAction) onClickAction();
                  if (onClose) onClose();
                }
          }
          type={type !== "close" ? type : undefined}
        >
          {name}
        </Button>
      ))}
    </>
  );
};

export default React.memo(QuickModalActions);
