import * as React from "react";
import { UseMemoFormResult } from "../../lib/useMemoForm";
import RankInputItem from "./RankInputItem";
import { SurveyItemRankInput } from "./surveys.types";

export type SurveyRankInputProps = {
  data: SurveyItemRankInput;
  form: UseMemoFormResult;
  isReadonly?: boolean;
  initialValues?: { [rankName: string]: undefined | string };
};

const SurveyRankInput = ({
  data,
  form,
  isReadonly,
  initialValues,
}: SurveyRankInputProps) => {
  const { options, inputName } = data;

  return (
    <>
      {options.map((option) => {
        const { value, label } =
          typeof option === "string"
            ? { value: option, label: option }
            : option;

        return (
          <RankInputItem
            label={label}
            name={`${inputName}.${value}`}
            ranks={options.length}
            form={form}
            isReadonly={isReadonly}
            initialValue={initialValues?.[value]}
          />
        );
      })}
    </>
  );
};

export default SurveyRankInput;
