import { AxiosError, AxiosRequestConfig } from "axios";

import apiService from "./apiService";

export const lendingSurveysPath = "/api/mbworkbench/lending-surveys";

export const CodeHasPending = "hasPending";

export const CodeInvalidUser = "invalidUser";

export const CodeInvalidContent = "invalidContent";

export const CodeCreated = "created";

export type POSTLendingSurveyError = AxiosError<{
  code?:
    | typeof CodeInvalidContent
    | typeof CodeInvalidUser
    | typeof CodeHasPending;
}>;

export type SurveyValues = {
  [inputName: string]: string | SurveyValues;
};

export const postLendingSurvey = async (
  name: string,
  userId: string,
  config?: AxiosRequestConfig
) => {
  return apiService.post<{ code: typeof CodeCreated }>(
    lendingSurveysPath,
    {
      name,
      userId,
    },
    config
  );
};

export const putLendingSurvey = async (
  id: string,
  values: SurveyValues,
  config?: AxiosRequestConfig
) => {
  return apiService.put(`${lendingSurveysPath}/${id}`, values, config);
};
