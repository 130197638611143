import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {ColorLightGrey} from "../../lib/theme";

const useStyles = makeStyles({
  main: {
    paddingTop: "0.25em",
    paddingBottom: "0.25em",
    paddingLeft: "1em",
    borderLeft: `1px solid ${ColorLightGrey}`,
  },
});

export type SubinfoProps = {
  title: React.ReactNode;
  body: React.ReactNode;
};

const Subinfo = ({
  title,
  body,
}: {
  title: React.ReactNode;
  body: React.ReactNode;
}) => {
  const styles = useStyles();

  return (
    <div className={styles.main}>
      <Typography variant="body2" color="primary">
        {title}
      </Typography>
      <Typography variant="body2">{body}</Typography>
    </div>
  );
};

export default Subinfo;
