import {AxiosRequestConfig} from "axios";
import Cookies from "js-cookie";

export async function withToken(config: AxiosRequestConfig) {
  const token = Cookies.get("token");

  const newConfig: AxiosRequestConfig = {
    ...config,
    headers: {
      ...config.headers,
      ...(token ? {Authorization: `Bearer ${token}`} : {}),
    },
  };

  return newConfig;
}
