import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";
import moment from "moment";
import Modal from "../../components/Modal";
import { topNavHeight } from "../../lib/constants";
import SurveyForm from "./SurveyForm";
import { FontFamilyPoppins, useThemeStyles } from "../../lib/theme";
import {
  StateDone,
  useLendingSurvey,
  useSurveyTemplate,
} from "./surveys.query";
import { t } from "../../lib/textMap";

const useStyles = makeStyles({
  modalInner: {
    maxHeight: `calc(100vh - 128px)`,
    overflowY: "auto",
    padding: 32,
    maxWidth: 820,
    fontFamily: FontFamilyPoppins,
    position: "relative",
  },
  paper: {
    padding: 0,
    marginTop: topNavHeight,
    overflowY: "hidden",
  },
  windowActions: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  doneInfo: {
    margin: "0 auto",
    maxWidth: 400,
    textAlign: "center",
  },
});

export type SurveyFormViewProps = {
  surveyId: string;
  isVisible?: boolean;
  onClose?: () => void;
  isReadonly?: boolean;
  onSave?: () => void;
};

const SurveyFormView = ({
  surveyId,
  isVisible,
  onClose,
  isReadonly,
  onSave,
}: SurveyFormViewProps) => {
  const styles = useStyles();
  const themeStyles = useThemeStyles();

  const [isModalOpen, setIsModalOpen] = React.useState(true);

  const { data: surveyResponse } = useLendingSurvey(surveyId);
  const { data: template } = useSurveyTemplate(surveyResponse?.doc.survey);

  const close = React.useCallback(() => {
    if (isVisible === undefined) setIsModalOpen(false);
    if (onClose) onClose();
  }, [isVisible, onClose]);

  React.useEffect(() => {
    if (isVisible !== undefined) setIsModalOpen(isVisible);
  }, [isVisible]);

  const templateForm = template?.form;

  if (!(templateForm && surveyResponse)) {
    return <></>;
  }

  const {
    doc: { values, state, updatedAt },
    doneByInfo: { fName, lName },
  } = surveyResponse;

  return (
    <Modal
      showModal={isModalOpen}
      onClose={close}
      paperClassName={styles.paper}
    >
      <div className={styles.modalInner}>
        <div className={styles.windowActions}>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </div>

        <Typography
          className={themeStyles.articleTitle}
          variant="h2"
          color="primary"
        >
          {t("lendingRAndO")}
        </Typography>

        <hr className={themeStyles.mb2} />

        <SurveyForm
          surveyId={surveyId}
          initialValues={values}
          data={templateForm}
          isReadonly={isReadonly}
          onSave={onSave}
        />

        {state === StateDone ? (
          <>
            <hr className={themeStyles.mb2} />

            <div className={styles.doneInfo}>
              <strong>
                Completed digitally on the
                <br />
                {moment(updatedAt).format("Do [of] MMMM YYYY")} by logged in
                user {fName} {lName}
              </strong>
            </div>
          </>
        ) : null}
      </div>
    </Modal>
  );
};

export default SurveyFormView;
