import React, { useContext, useState } from "react"
import ShowChangesTable from "../ShowChangesTable"
import { formatCurrency, selectedOwner } from "../../../../../../lib/utils"
import _ from "lodash"
import ClientChangesContext from "../../../../../../components/Context/ClientChangesContext"

const CreditCardUpdates = () => {
  const { creditUpdates, ACStates, setACStates } = useContext(
    ClientChangesContext
  )

  const indexOnLender: boolean[] = []
  const indexOnLimit: boolean[] = []
  const indexOnProposed: boolean[] = []
  const indexOnOwner: boolean[] = []

  creditUpdates &&
    creditUpdates.lenderChanges.forEach((credit: any) =>
      indexOnLender.push(false)
    )
  creditUpdates &&
    creditUpdates.limitChanges.forEach((credit: any) =>
      indexOnLimit.push(false)
    )
  creditUpdates &&
    creditUpdates.proposedLimitChanges.forEach((credit: any) =>
      indexOnProposed.push(false)
    )
  creditUpdates &&
    creditUpdates.ownerChanges.forEach((credit: any) =>
      indexOnOwner.push(false)
    )

  const [switchIndexLender, setSwitchIndexLender] = useState<boolean[]>(
    indexOnLender
  )
  const [switchIndexLimit, setSwitchIndexLimit] = useState<boolean[]>(
    indexOnLimit
  )
  const [switchIndexProposed, setSwitchIndexProposed] = useState<boolean[]>(
    indexOnProposed
  )
  const [switchIndexOwner, setSwitchIndexOwner] = useState<boolean[]>(
    indexOnOwner
  )

  const changePropertyHandler = (
    objProp: string,
    currValue: any,
    prevValue: any,
    summaryId: string,
    newSwitch: boolean[],
    index: number
  ) => {
    return ACStates?.creditCard.map((credit: any) => {
      if (credit.summaryId === summaryId) {
        if (newSwitch !== undefined && newSwitch[index]) {
          if (objProp === "owner") {
            _.mapValues(credit[objProp], function(o) {
              if (o.fullName === currValue) {
                o.selected = true
              } else if (currValue == "Both") {
                if (_.has(o, "selected")) o.selected = true
              } else if (currValue == "Other") {
                if (_.has(o, "selected")) o.selected = false
              } else {
                if (_.has(o, "selected")) o.selected = false
              }
            })
          } else {
            credit[objProp] = currValue
          }
        } else {
          credit[objProp] = prevValue
        }
      }
      return credit
    })
  }

  const creditUpdatesHandler = (
    summaryId: string,
    index: number,
    currValue: any,
    prevValue: any,
    toChange: string
  ) => {
    let newSwitch: boolean[]
    let changeValue

    switch (toChange) {
      case "lender":
        newSwitch = switchIndexLender && [...switchIndexLender]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexLender(newSwitch)
        break
      case "limit":
        newSwitch = switchIndexLimit && [...switchIndexLimit]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexLimit(newSwitch)
        break
      case "proposedLimit":
        newSwitch = switchIndexProposed && [...switchIndexProposed]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexProposed(newSwitch)
        break
      case "owner":
        newSwitch = switchIndexOwner && [...switchIndexOwner]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexOwner(newSwitch)
        break
      default:
        newSwitch = []
    }

    if (ACStates !== undefined) {
      setACStates && setACStates({ ...ACStates, creditCard: changeValue })
    }
  }

  return (
    <>
      {/* start of credit lender changes */}
      {creditUpdates?.lenderChanges.length !== 0 ? (
        creditUpdates?.lenderChanges.map(
          (
            credit: {
              prevLender: string
              currLender: string
              summaryId: string
            },
            index: number
          ) => {
            const { prevLender, currLender, summaryId } = credit
            return (
              <ShowChangesTable
                prevVal={prevLender}
                currVal={currLender}
                index={index}
                switchIndex={switchIndexLender}
                updatesCaller={() => {
                  creditUpdatesHandler(
                    summaryId,
                    index,
                    currLender,
                    prevLender,
                    "lender"
                  )
                }}
                type="creditLender"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of lender changes */}
      {/* start of limit changes */}
      {creditUpdates?.limitChanges.length !== 0 ? (
        creditUpdates?.limitChanges.map(
          (
            credit: {
              prevLimit: number
              currLimit: number
              summaryId: any
            },
            index: number
          ) => {
            const { prevLimit, currLimit, summaryId } = credit
            return (
              <ShowChangesTable
                prevVal={formatCurrency(prevLimit)}
                currVal={formatCurrency(currLimit)}
                index={index}
                switchIndex={switchIndexLimit}
                updatesCaller={() => {
                  creditUpdatesHandler(
                    summaryId,
                    index,
                    currLimit,
                    prevLimit,
                    "limit"
                  )
                }}
                type="creditLimit"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of limit changes */}
      {/* start of proposed limit changes */}
      {creditUpdates?.proposedLimitChanges.length !== 0 ? (
        creditUpdates?.proposedLimitChanges.map(
          (
            asset: {
              prevProposedLimit: number
              currProposedLimit: number
              summaryId: string
            },
            index: number
          ) => {
            const { prevProposedLimit, currProposedLimit, summaryId } = asset
            return (
              <ShowChangesTable
                prevVal={formatCurrency(prevProposedLimit)}
                currVal={formatCurrency(currProposedLimit)}
                index={index}
                switchIndex={switchIndexProposed}
                updatesCaller={() => {
                  creditUpdatesHandler(
                    summaryId,
                    index,
                    currProposedLimit,
                    prevProposedLimit,
                    "proposedLimit"
                  )
                }}
                type="creditProposed"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of propsed limti changes */}
      {/* start of owner changes */}
      {creditUpdates?.ownerChanges.length !== 0 ? (
        creditUpdates?.ownerChanges.map(
          (
            credit: {
              prevOwner: any
              currOwner: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevOwner, currOwner, summaryId } = credit
            return (
              <ShowChangesTable
                prevVal={prevOwner}
                currVal={currOwner}
                index={index}
                switchIndex={switchIndexOwner}
                updatesCaller={() => {
                  creditUpdatesHandler(
                    summaryId,
                    index,
                    currOwner,
                    prevOwner,
                    "owner"
                  )
                }}
                type="creditOwner"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of owner changes */}
    </>
  )
}

export default CreditCardUpdates
