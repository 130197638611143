import DocumentCollection from "./scenes/DocumentCollection"
import SettlementFunction from "./scenes/SettlementFunction/SettlementFunction"
import PreliminaryAssessment from "./scenes/MBWorkbench/PreliminaryAssessment"
import LendingSurvey from "./scenes/RequirementsAndObjectives/LendingSurvey"
import LendingSurveysPage from "./scenes/RequirementsAndObjectives/LendingSurveysPage"

export { DocumentCollection }
export { PreliminaryAssessment }
export { LendingSurvey }
export { LendingSurveysPage }
export default SettlementFunction
