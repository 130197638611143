import React, { useContext, useState } from "react"
import ClientChangesContext from "../../../../../../components/Context/ClientChangesContext"
import ShowChangesTable from "../ShowChangesTable"
import moment from "moment"
import _ from "lodash"
import { checkFalsy } from "../../../../../../lib/utils"
const HomeLoansChangesUpdate = () => {
  const { homeLoansUpdates, ACStates, setACStates } = useContext(
    ClientChangesContext
  )

  const indexOnLender: boolean[] = []
  const indexOnLoanType: boolean[] = []
  const indexOnPrimaryPurpose: boolean[] = []
  const indexOnBorrower: boolean[] = []
  const indexOnRepaymentType: boolean[] = []
  const indexOnRepayment: boolean[] = []
  const indexOnPeriod: boolean[] = []
  const indexOnLoanExpiry: boolean[] = []
  const indexOnBalance: boolean[] = []
  const indexOnAccountNumber: boolean[] = []
  const indexOnIoExp: boolean[] = []
  const indexOnFixedIO: boolean[] = []
  const indexOnAddress: boolean[] = []
  const indexOnBaseRate: boolean[] = []
  const indexOnDiscountRate: boolean[] = []
  const indexOnInterestRate: boolean[] = []

  homeLoansUpdates &&
    homeLoansUpdates.baseRateChanges.forEach(() => indexOnBaseRate.push(false))

  homeLoansUpdates &&
    homeLoansUpdates.discountRateChanges.forEach(() =>
      indexOnDiscountRate.push(false)
    )

  homeLoansUpdates &&
    homeLoansUpdates.interestRateChanges.forEach(() =>
      indexOnInterestRate.push(false)
    )

  homeLoansUpdates &&
    homeLoansUpdates.lenderChanges.forEach(() => indexOnLender.push(false))

  homeLoansUpdates &&
    homeLoansUpdates.loanTypeChanges.forEach(() => indexOnLoanType.push(false))

  homeLoansUpdates &&
    homeLoansUpdates.primaryPurposeChanges.forEach(() =>
      indexOnPrimaryPurpose.push(false)
    )

  homeLoansUpdates &&
    homeLoansUpdates.borrowerChanges.forEach(() => indexOnBorrower.push(false))

  homeLoansUpdates &&
    homeLoansUpdates.repaymentTypeChanges.forEach(() =>
      indexOnRepaymentType.push(false)
    )

  homeLoansUpdates &&
    homeLoansUpdates.repaymentChanges.forEach(() =>
      indexOnRepayment.push(false)
    )

  homeLoansUpdates &&
    homeLoansUpdates.periodChanges.forEach(() => indexOnPeriod.push(false))

  homeLoansUpdates &&
    homeLoansUpdates.loanExpiryChanges.forEach(() =>
      indexOnLoanExpiry.push(false)
    )

  homeLoansUpdates &&
    homeLoansUpdates.balanceChanges.forEach(() => indexOnBalance.push(false))

  homeLoansUpdates &&
    homeLoansUpdates.accountNumberChanges.forEach(() =>
      indexOnAccountNumber.push(false)
    )

  homeLoansUpdates &&
    homeLoansUpdates.ioExpChanges.forEach(() => indexOnIoExp.push(false))

  homeLoansUpdates &&
    homeLoansUpdates.fixedIOChanges.forEach(() => indexOnFixedIO.push(false))

  homeLoansUpdates &&
    homeLoansUpdates.addressChanges.forEach(() => indexOnAddress.push(false))

  const [switchIndexBaseRate, setSwitchIndexBaseRate] = useState<boolean[]>(
    indexOnBaseRate
  )

  const [switchIndexInterestRate, setSwitchIndexInterestRate] = useState<
    boolean[]
  >(indexOnInterestRate)

  const [switchIndexDiscountRate, setSwitchIndexDiscountRate] = useState<
    boolean[]
  >(indexOnDiscountRate)

  const [switchIndexLender, setSwitchIndexLender] = useState<boolean[]>(
    indexOnLender
  )

  const [switchIndexLoanType, setSwitchIndexLoanType] = useState<boolean[]>(
    indexOnLoanType
  )

  const [switchIndexPrimaryPurpose, setSwitchIndexPrimaryPurpose] = useState<
    boolean[]
  >(indexOnPrimaryPurpose)

  const [switchIndexBorrower, setSwitchIndexBorrower] = useState<boolean[]>(
    indexOnBorrower
  )

  const [switchIndexRepaymentType, setSwitchIndexRepaymentType] = useState<
    boolean[]
  >(indexOnRepaymentType)

  const [switchIndexRepayment, setSwitchIndexRepayment] = useState<boolean[]>(
    indexOnRepayment
  )

  const [switchIndexPeriod, setSwitchIndexPeriod] = useState<boolean[]>(
    indexOnPeriod
  )

  const [switchIndexLoanExpiry, setSwitchIndexLoanExpiry] = useState<boolean[]>(
    indexOnLoanExpiry
  )

  const [switchIndexBalance, setSwitchIndexBalance] = useState<boolean[]>(
    indexOnBalance
  )

  const [switchIndexAccountNumber, setSwitchIndexAccountNumber] = useState<
    boolean[]
  >(indexOnAccountNumber)

  const [switchIndexIoExp, setSwitchIndexIoExp] = useState<boolean[]>(
    indexOnIoExp
  )

  const [switchIndexFixedIO, setSwitchIndexFixedIO] = useState<boolean[]>(
    indexOnFixedIO
  )

  const [switchIndexAddress, setSwitchIndexAddress] = useState<boolean[]>(
    indexOnAddress
  )

  const changePropertyHandler = (
    objProp: string,
    currValue: any,
    prevValue: any,
    summaryId: string,
    newSwitch: boolean[],
    index: number
  ) => {
    return ACStates?.homeLoans.map((homeLoans: any) => {
      if (homeLoans.summaryId === summaryId) {
        if (newSwitch !== undefined && newSwitch[index]) {
          homeLoans[objProp] = currValue
        } else {
          homeLoans[objProp] = prevValue
        }
      }
      return homeLoans
    })
  }

  const homeLoansUpdatesHandler = (
    summaryId: string,
    index: number,
    currValue: any,
    prevValue: any,
    toChange: string
  ) => {
    let newSwitch: boolean[]
    let changeValue

    switch (toChange) {
      case "baseRate":
        newSwitch = switchIndexBaseRate && [...switchIndexBaseRate]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexBaseRate(newSwitch)
        break
      case "discountRate":
        newSwitch = switchIndexDiscountRate && [...switchIndexDiscountRate]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexDiscountRate(newSwitch)
        break
      case "interestRate":
        newSwitch = switchIndexInterestRate && [...switchIndexInterestRate]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexInterestRate(newSwitch)
        break
      case "lender":
        newSwitch = switchIndexLender && [...switchIndexLender]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexLender(newSwitch)
        break
      case "loanType":
        newSwitch = switchIndexLoanType && [...switchIndexLoanType]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexLoanType(newSwitch)
        break
      case "primaryPurpose":
        newSwitch = switchIndexPrimaryPurpose && [...switchIndexPrimaryPurpose]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexPrimaryPurpose(newSwitch)
        break
      case "borrower":
        newSwitch = switchIndexBorrower && [...switchIndexBorrower]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexBorrower(newSwitch)
        break
      case "repaymentType":
        newSwitch = switchIndexRepaymentType && [...switchIndexRepaymentType]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexRepaymentType(newSwitch)
        break
      case "repayment":
        newSwitch = switchIndexRepayment && [...switchIndexRepayment]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexRepayment(newSwitch)
        break
      case "period":
        newSwitch = switchIndexPeriod && [...switchIndexPeriod]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexPeriod(newSwitch)
        break
      case "loanExpiry":
        newSwitch = switchIndexLoanExpiry && [...switchIndexLoanExpiry]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexLoanExpiry(newSwitch)
        break
      case "balance":
        newSwitch = switchIndexBalance && [...switchIndexBalance]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexBalance(newSwitch)
        break
      case "accountNumber":
        newSwitch = switchIndexAccountNumber && [...switchIndexAccountNumber]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexAccountNumber(newSwitch)
        break
      case "ioExp":
        newSwitch = switchIndexIoExp && [...switchIndexIoExp]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexIoExp(newSwitch)
        break
      case "fixedIO":
        newSwitch = switchIndexFixedIO && [...switchIndexFixedIO]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexFixedIO(newSwitch)
        break
      case "address":
        newSwitch = switchIndexAddress && [...switchIndexAddress]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexAddress(newSwitch)
        break
      default:
        newSwitch = []
    }

    if (ACStates !== undefined) {
      setACStates && setACStates({ ...ACStates, homeLoans: changeValue })
    }
  }

  return (
    <>
      {/* start of rental address changes */}
      {homeLoansUpdates?.lenderChanges.length !== 0 ? (
        homeLoansUpdates?.lenderChanges.map(
          (
            home: {
              prevLender: any
              currLender: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevLender, currLender, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={prevLender}
                currVal={currLender}
                index={index}
                switchIndex={switchIndexLender}
                updatesCaller={() => {
                  homeLoansUpdatesHandler(
                    summaryId,
                    index,
                    currLender,
                    prevLender,
                    "lender"
                  )
                }}
                type="lenderHL"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansUpdates?.loanTypeChanges.length !== 0 ? (
        homeLoansUpdates?.loanTypeChanges.map(
          (
            home: {
              prevLoanType: any
              currLoanType: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevLoanType, currLoanType, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={prevLoanType}
                currVal={currLoanType}
                index={index}
                switchIndex={switchIndexLoanType}
                updatesCaller={() => {
                  homeLoansUpdatesHandler(
                    summaryId,
                    index,
                    currLoanType,
                    prevLoanType,
                    "loanType"
                  )
                }}
                type="loanType"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansUpdates?.primaryPurposeChanges.length !== 0 ? (
        homeLoansUpdates?.primaryPurposeChanges.map(
          (
            home: {
              prevPrimaryPurpose: any
              currPrimaryPurpose: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevPrimaryPurpose, currPrimaryPurpose, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={prevPrimaryPurpose}
                currVal={currPrimaryPurpose}
                index={index}
                switchIndex={switchIndexPrimaryPurpose}
                updatesCaller={() => {
                  homeLoansUpdatesHandler(
                    summaryId,
                    index,
                    currPrimaryPurpose,
                    prevPrimaryPurpose,
                    "primaryPurpose"
                  )
                }}
                type="primaryPurpose"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansUpdates?.borrowerChanges.length !== 0 ? (
        homeLoansUpdates?.borrowerChanges.map(
          (
            home: {
              prevBorrower: any
              currBorrower: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevBorrower, currBorrower, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={prevBorrower}
                currVal={currBorrower}
                index={index}
                switchIndex={switchIndexBorrower}
                updatesCaller={() => {
                  homeLoansUpdatesHandler(
                    summaryId,
                    index,
                    currBorrower,
                    prevBorrower,
                    "borrower"
                  )
                }}
                type="borrower"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansUpdates?.repaymentTypeChanges.length !== 0 ? (
        homeLoansUpdates?.repaymentTypeChanges.map(
          (
            home: {
              prevRepaymentType: any
              currRepaymentType: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevRepaymentType, currRepaymentType, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={prevRepaymentType}
                currVal={currRepaymentType}
                index={index}
                switchIndex={switchIndexRepaymentType}
                updatesCaller={() => {
                  homeLoansUpdatesHandler(
                    summaryId,
                    index,
                    currRepaymentType,
                    prevRepaymentType,
                    "repaymentType"
                  )
                }}
                type="repaymentType"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansUpdates?.repaymentChanges.length !== 0 ? (
        homeLoansUpdates?.repaymentChanges.map(
          (
            home: {
              prevRepayment: any
              currRepayment: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevRepayment, currRepayment, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={prevRepayment}
                currVal={currRepayment}
                index={index}
                switchIndex={switchIndexRepayment}
                updatesCaller={() => {
                  homeLoansUpdatesHandler(
                    summaryId,
                    index,
                    currRepayment,
                    prevRepayment,
                    "repayment"
                  )
                }}
                type="repayment"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansUpdates?.periodChanges.length !== 0 ? (
        homeLoansUpdates?.periodChanges.map(
          (
            home: {
              prevPeriod: any
              currPeriod: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevPeriod, currPeriod, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={prevPeriod}
                currVal={currPeriod}
                index={index}
                switchIndex={switchIndexPeriod}
                updatesCaller={() => {
                  homeLoansUpdatesHandler(
                    summaryId,
                    index,
                    currPeriod,
                    prevPeriod,
                    "period"
                  )
                }}
                type="period"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansUpdates?.loanExpiryChanges.length !== 0 ? (
        homeLoansUpdates?.loanExpiryChanges.map(
          (
            home: {
              prevLoanExpiry: any
              currLoanExpiry: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevLoanExpiry, currLoanExpiry, summaryId } = home
            const prevDate =
              checkFalsy(prevLoanExpiry) || prevLoanExpiry === ""
                ? ""
                : moment(prevLoanExpiry).format("YYYY-MM-DD")
            const currDate = moment(currLoanExpiry).format("YYYY-MM-DD")
            return (
              <ShowChangesTable
                prevVal={prevDate}
                currVal={currDate}
                index={index}
                switchIndex={switchIndexLoanExpiry}
                updatesCaller={() => {
                  homeLoansUpdatesHandler(
                    summaryId,
                    index,
                    currLoanExpiry,
                    prevLoanExpiry,
                    "loanExpiry"
                  )
                }}
                type="loanExpiry"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansUpdates?.balanceChanges.length !== 0 ? (
        homeLoansUpdates?.balanceChanges.map(
          (
            home: {
              prevBalance: any
              currBalance: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevBalance, currBalance, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={prevBalance}
                currVal={currBalance}
                index={index}
                switchIndex={switchIndexBalance}
                updatesCaller={() => {
                  homeLoansUpdatesHandler(
                    summaryId,
                    index,
                    currBalance,
                    prevBalance,
                    "balance"
                  )
                }}
                type="balance"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansUpdates?.accountNumberChanges.length !== 0 ? (
        homeLoansUpdates?.accountNumberChanges.map(
          (
            home: {
              prevAccountNumber: any
              currAccountNumber: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevAccountNumber, currAccountNumber, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={prevAccountNumber}
                currVal={currAccountNumber}
                index={index}
                switchIndex={switchIndexAccountNumber}
                updatesCaller={() => {
                  homeLoansUpdatesHandler(
                    summaryId,
                    index,
                    currAccountNumber,
                    prevAccountNumber,
                    "accountNumber"
                  )
                }}
                type="accountNumber"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansUpdates?.ioExpChanges.length !== 0 ? (
        homeLoansUpdates?.ioExpChanges.map(
          (
            home: {
              prevIoExp: any
              currIoExp: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevIoExp, currIoExp, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={prevIoExp}
                currVal={currIoExp}
                index={index}
                switchIndex={switchIndexIoExp}
                updatesCaller={() => {
                  homeLoansUpdatesHandler(
                    summaryId,
                    index,
                    currIoExp,
                    prevIoExp,
                    "ioExp"
                  )
                }}
                type="ioExp"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansUpdates?.fixedIOChanges.length !== 0 ? (
        homeLoansUpdates?.fixedIOChanges.map(
          (
            home: {
              prevFixedIO: any
              currFixedIO: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevFixedIO, currFixedIO, summaryId } = home
            return (
              <ShowChangesTable
                prevVal={prevFixedIO}
                currVal={currFixedIO}
                index={index}
                switchIndex={switchIndexFixedIO}
                updatesCaller={() => {
                  homeLoansUpdatesHandler(
                    summaryId,
                    index,
                    currFixedIO,
                    prevFixedIO,
                    "fixedIO"
                  )
                }}
                type="fixedIO"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental address changes */}
      {homeLoansUpdates?.addressChanges.length !== 0 ? (
        homeLoansUpdates?.addressChanges.map(
          (
            home: {
              prevAddress: any
              currAddress: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevAddress, currAddress, summaryId } = home
            const formattedCurrAddress =
              currAddress.length > 1 ? currAddress.join(", ") : currAddress
            const formattedPrevAddress =
              prevAddress.length > 1 ? prevAddress.join(", ") : prevAddress
            return (
              <ShowChangesTable
                prevVal={formattedPrevAddress}
                currVal={formattedCurrAddress}
                index={index}
                switchIndex={switchIndexAddress}
                updatesCaller={() => {
                  homeLoansUpdatesHandler(
                    summaryId,
                    index,
                    currAddress,
                    prevAddress,
                    "address"
                  )
                }}
                type="addressHL"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of baseRate changes */}
      {homeLoansUpdates?.baseRateChanges.length !== 0 ? (
        homeLoansUpdates?.baseRateChanges.map(
          (
            rate: {
              prevBaseRate: any
              currBaseRate: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevBaseRate, currBaseRate, summaryId } = rate
            return (
              <ShowChangesTable
                prevVal={`${prevBaseRate}%`}
                currVal={`${currBaseRate}%`}
                index={index}
                switchIndex={switchIndexBaseRate}
                updatesCaller={() => {
                  homeLoansUpdatesHandler(
                    summaryId,
                    index,
                    currBaseRate,
                    prevBaseRate,
                    "baseRate"
                  )
                }}
                type="baseRate"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of baseRate changes */}
      {/* start of discount rate changes */}
      {homeLoansUpdates?.discountRateChanges.length !== 0 ? (
        homeLoansUpdates?.discountRateChanges.map(
          (
            rate: {
              prevDiscountRate: any
              currDiscountRate: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevDiscountRate, currDiscountRate, summaryId } = rate
            return (
              <ShowChangesTable
                prevVal={`${prevDiscountRate}%`}
                currVal={`${currDiscountRate}%`}
                index={index}
                switchIndex={switchIndexDiscountRate}
                updatesCaller={() => {
                  homeLoansUpdatesHandler(
                    summaryId,
                    index,
                    currDiscountRate,
                    prevDiscountRate,
                    "discountRate"
                  )
                }}
                type="discountRate"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of discount rate changes */}
      {/* start of interest rate changes */}
      {homeLoansUpdates?.interestRateChanges.length !== 0 ? (
        homeLoansUpdates?.interestRateChanges.map(
          (
            rate: {
              prevInterestRate: any
              currInterestRate: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevInterestRate, currInterestRate, summaryId } = rate
            return (
              <ShowChangesTable
                prevVal={`${prevInterestRate}%`}
                currVal={`${currInterestRate}%`}
                index={index}
                switchIndex={switchIndexInterestRate}
                updatesCaller={() => {
                  homeLoansUpdatesHandler(
                    summaryId,
                    index,
                    currInterestRate,
                    prevInterestRate,
                    "interestRate"
                  )
                }}
                type="interestRate"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of interest rate changes */}
    </>
  )
}

export default HomeLoansChangesUpdate
