import React, { useContext } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import styles from "./ClientChangesModal.scss"
import RentalChangesTable from "./RentalChanges/RentalChangesTable"
import RentalChangesAddition from "./RentalChanges/RentalChangesAddition"
import PrimaryIncomeUpdates from "./Income1Changes/PrimaryIncomeUpdates"
import PrimaryIncomeAdditional from "./Income1Changes/PrimaryIncomeAdditional"
import FinancialAssetChangesTable from "./FinancialAssetChanges/FinancialAssetChangesTable"
import FinancialAssetChangesAddition from "./FinancialAssetChanges/FinancialAssetChangesAddition"
import NonFinancialAssetChangesTable from "./NonFinancialAssetChanges/NonFinancialAssetChangesTable"
import NonFinancialAssetChangesAddition from "./NonFinancialAssetChanges/NonFinancialAssetChangesAddition"
import SecondaryIncomeUpdates from "./Income2Changes/SecondaryIncomeUpdates"
import SecondaryIncomeAdditional from "./Income2Changes/SecondaryIncomeAdditonal"
import UnsecuredLiabilitiesUpdates from "./UnsecuredLiabilitiesChanges/UnsecuredLiabilitiesUpdates"
import UnsecuredLiabilitiesAddition from "./UnsecuredLiabilitiesChanges/UnsecuredLiabilitiesAddition"
import HomeLoansChangesTable from "./HomeLoansChanges/HomeLoansChangesUpdate"
import HomeLoansAddition from "./HomeLoansChanges/HomeLoansChangesAddition"
import CreditCardUpdates from "./CreditCardChanges/CreditCardUpdates"
import CreditCardAddition from "./CreditCardChanges/CreditCardAddition"
import FlexExpenseUpdatesRent from "./FlexExpenseChanges/FlexExpenseUpdatesRent"
import FlexExpenseUpdatesUtilities from "./FlexExpenseChanges/FlexExpenseUpdatesUtilities"
import FlexExpenseUpdatesGroceries from "./FlexExpenseChanges/FlexUpdatesGroceries"
import FlexExpenseUpdatesClothing from "./FlexExpenseChanges/FlexExpenseUpdatesClothing"
import FlexExpenseUpdatesMedical from "./FlexExpenseChanges/FlexExpenseUpdatesMedical"
import FlexExpenseUpdatesRecreation from "./FlexExpenseChanges/FlexExpenseUpdatesRecreation"
import FlexExpenseUpdatesInvestmentUtilities from "./FlexExpenseChanges/FlexUpdatesInvestmentUtilities"
import FlexExpenseUpdatesInsurance from "./FlexExpenseChanges/FlexExpenseUpdatesInsurance"
import FlexExpenseUpdatesTelephone from "./FlexExpenseChanges/FlexExpenseUpdatesTelephone"
import FlexExpenseUpdatesTransport from "./FlexExpenseChanges/FlexExpenseUpdatesTransport"
import FlexExpenseUpdatesEducation from "./FlexExpenseChanges/FlexExpenseUpdatesEducation"
import FlexExpenseUpdatesChildcare from "./FlexExpenseChanges/FlexExpenseUpdatesChildcare"
import FlexExpenseUpdatesOtherLiving from "./FlexExpenseChanges/FlexExpenseUpdatesOtherLiving"
import ClientChangesContext from "../../../../../components/Context/ClientChangesContext"

const ClientChangesModal = () => {
  const [open, setOpen] = React.useState(true)
  const { showFlexExpenseTitle } = useContext(ClientChangesContext)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        className={styles.dialog}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={false}
        maxWidth="md"
      >
        <DialogTitle id="customized-dialog-title">
          <h6 className={styles.title}>Client Data Changes</h6>
        </DialogTitle>
        <DialogContent dividers>
          <table className={styles.table}>
            <tr>
              <th>
                <h4>Description</h4>
              </th>
              <th></th>
              <th>
                <h4>Action</h4>
              </th>
              <th>
                <h4>MBAC</h4>
              </th>
              <th>
                <h4>Portal</h4>
              </th>
            </tr>
            <RentalChangesTable />
            <RentalChangesAddition />
            <PrimaryIncomeUpdates />
            <PrimaryIncomeAdditional />
            <SecondaryIncomeUpdates />
            <SecondaryIncomeAdditional />
            <FinancialAssetChangesTable />
            <FinancialAssetChangesAddition />
            <NonFinancialAssetChangesTable />
            <NonFinancialAssetChangesAddition />
            <UnsecuredLiabilitiesUpdates />
            <UnsecuredLiabilitiesAddition />
            <HomeLoansChangesTable />
            <HomeLoansAddition />
            <CreditCardUpdates />
            <CreditCardAddition />
            {showFlexExpenseTitle ? (
              <tr>
                <th>
                  <h4 className={styles.padTop}>Expense Changes</h4>
                </th>
              </tr>
            ) : null}
            <FlexExpenseUpdatesRent />
            <FlexExpenseUpdatesUtilities />
            <FlexExpenseUpdatesInsurance />
            <FlexExpenseUpdatesTelephone />
            <FlexExpenseUpdatesTransport />
            <FlexExpenseUpdatesEducation />
            <FlexExpenseUpdatesChildcare />
            <FlexExpenseUpdatesOtherLiving />
            <FlexExpenseUpdatesGroceries />
            <FlexExpenseUpdatesClothing />
            <FlexExpenseUpdatesMedical />
            <FlexExpenseUpdatesRecreation />
            <FlexExpenseUpdatesInvestmentUtilities />
          </table>
        </DialogContent>
        <DialogActions>
          <Button
            className={styles.button}
            variant="contained"
            size="large"
            autoFocus
            onClick={handleClose}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ClientChangesModal
