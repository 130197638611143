import { useQuery, UseQueryOptions } from "react-query";
import { toUrlQueryString } from "../../lib/url";
import {
  GETSurveyData,
  GETSurveysData,
  GETSurveysParams,
  SurveyItemDependencies,
  SurveyItemValue,
} from "./surveys.types";

export const StateDone = "done";

export function useLendingSurveys(
  params?: GETSurveysParams,
  options?: UseQueryOptions<GETSurveysData>
) {
  return useQuery<GETSurveysData>(
    `/api/mbworkbench/lending-surveys${toUrlQueryString({
      json: JSON.stringify(params),
    })}`,
    options
  );
}

export function useLendingSurvey(id?: string) {
  return useQuery<GETSurveyData>([
    `/api/mbworkbench/lending-surveys/`,
    id,
  ] as const);
}

export function useSurveyTemplate(id?: string) {
  return useQuery<{
    form: { dependencies: SurveyItemDependencies; items: SurveyItemValue[] };
  }>([`/api/mbworkbench/survey-templates/`, id] as const);
}
