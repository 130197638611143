import {ThemeProvider, makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

import {useEffect} from "react";
import theme from "../../lib/theme";
import AddSurveyIconButton from "./AddSurveyIconButton";
import SurveysList from "./SurveysList";
import MyQueryClientProvider from "../../components/MyQueryClientProvider";
import apiService from "../../lib/apiService";

const useStyles = makeStyles({
  main: {
    margin: "2em 1em",
    fontSize: "14px",
  },
  inner: {
    display: "flex",
    alignItems: "center",
  },
  titleWrap: {
    flexGrow: 1,
    marginRight: "1em",
  },
});

export type LendingSurveysPageProps = {
  userId: string;
  apiBaseUrl: string;
};

const LendingSurveysPage = ({userId, apiBaseUrl}: LendingSurveysPageProps) => {
  const styles = useStyles();

  useEffect(() => {
    apiService.defaults.baseURL = apiBaseUrl;
  }, [apiBaseUrl]);

  return (
    <MyQueryClientProvider apiBaseUrl={apiBaseUrl}>
      <ThemeProvider theme={theme}>
        <div className={styles.main}>
          <div className={styles.inner}>
            <div className={styles.titleWrap}>
              <Typography variant="h4">
                Client Lending Requirements & Objectives
              </Typography>
            </div>

            <div>
              <AddSurveyIconButton userId={userId} />
            </div>
          </div>

          <hr />

          <SurveysList userId={userId} />
        </div>
      </ThemeProvider>
    </MyQueryClientProvider>
  );
};

export default LendingSurveysPage;
