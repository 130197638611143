exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);", ""]);
// Module
exports.push([module.id, "html, body {\n  margin: 0px !important;\n  padding: 0px !important; }\n\n._2Okhl6qfC2FV9KvRehp3j3 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  background: rgba(255, 255, 255, 0.8);\n  top: 0;\n  left: 0;\n  z-index: 900;\n  overflow: hidden; }\n\n._3vyhd1SZTtLCn7I-tQed4u {\n  display: flex;\n  align-items: center;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis; }\n  ._3vyhd1SZTtLCn7I-tQed4u input {\n    font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace;\n    font-size: 24px;\n    line-height: 26px;\n    color: #4e185b !important;\n    border: 0; }\n\n.MWhRtJiUKt-NwWGMMMOJY {\n  border-radius: 3px;\n  border: 0 !important;\n  height: 22px;\n  padding: 0 10px;\n  cursor: pointer; }\n", ""]);
// Exports
exports.locals = {
	"spinner": "_2Okhl6qfC2FV9KvRehp3j3",
	"documentsName": "_3vyhd1SZTtLCn7I-tQed4u",
	"editTemplatetIcon": "MWhRtJiUKt-NwWGMMMOJY"
};