import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import React from "react";
import { Controller } from "react-hook-form";
import { mapTextSlug } from "../../lib/textMap";
import { useThemeStyles } from "../../lib/theme";
import { UseMemoFormResult } from "../../lib/useMemoForm";
import { SurveyItemRadioInput } from "./surveys.types";

const useStyles = makeStyles({
  main: {
    marginStart: "2em",
  },
});

export type SurveyRadioInputProps = {
  data: SurveyItemRadioInput;
  form: UseMemoFormResult;
  // eslint-disable-next-line no-unused-vars
  onChange?: (name: string, value: string) => void;
  isReadonly?: boolean;
  initialValue?: string;
};

const SurveyRadioInput = ({
  data: { options, inputName, required, row },
  form: { control },
  onChange,
  isReadonly,
  initialValue,
}: SurveyRadioInputProps) => {
  const themeStyles = useThemeStyles();
  const styles = useStyles();

  return (
    <Controller
      name={inputName}
      control={control}
      defaultValue={initialValue}
      rules={{
        required,
      }}
      render={({
        field: { onChange: onFieldChange, name, ref, value: inputValue },
      }) => {
        return (
          <RadioGroup
            className={styles.main}
            row={row}
            name={name}
            onChange={(ev) => {
              onFieldChange(ev);
              if (onChange) onChange(name, ev.target.value);
            }}
            defaultValue={inputValue}
          >
            {options.map((option) => {
              const { value, label } =
                typeof option === "string"
                  ? {
                      value: option,
                      label: option,
                    }
                  : option;

              return (
                <FormControlLabel
                  key={value}
                  value={value}
                  ref={ref}
                  label={mapTextSlug(label)}
                  control={
                    <Radio
                      color="primary"
                      checkedIcon={<CheckBoxIcon fontSize="large" />}
                      disabled={isReadonly}
                      icon={
                        <CheckBoxOutlineBlankIcon
                          className={themeStyles.colorPrimaryLight}
                          fontSize="large"
                        />
                      }
                    />
                  }
                />
              );
            })}
          </RadioGroup>
        );
      }}
    />
  );
};

export default SurveyRadioInput;
