import { Box, Checkbox, Grid, makeStyles } from "@material-ui/core";
import { CheckSharp, ErrorOutline } from "@material-ui/icons";
import _ from "lodash";
import React, { ReactElement, useEffect, useState } from "react";
import FileItem from "../../../components/FileItem/FileItem";
import { Document } from "./RequestHistory.interfaces";
import styles from "./RequestHistory.module.scss";
import CheckboxIcon from "../../../assets/checkbox-outline.svg";
import CheckedBoxIcon from "../../../assets/checked-box-outline.svg";

const useStyles = makeStyles({
  docLabelRow: {
    backgroundColor: '#F2F2F2',
    padding: '15px 40px',
    borderRadius: '4px'
  },
  fileRow: {
    marginLeft: '85px',
    '& .MuiPaper-root': {
      border: '1px solid #CCC',
      padding: '10px'
    }
  },
  container: {
    width: '100%',
    padding: '5px 0'
  }
});

const RequestHistoryItem = ({
    documents,
    transactionId,
    clientId,
    checkDownloadHandler,
    isCheckAllGroup,
    resetItemComponent
}: {
    documents: ReadonlyArray<Document>
    transactionId: String
    clientId: any,
    checkDownloadHandler?: Function,
    isCheckAllGroup?: boolean,
    resetItemComponent?: boolean
  }): ReactElement => {
    const classes = useStyles();
    
    // const groupedDocuments = _.chain(documents)
    // .groupBy("dcid.label")
    // .map((value, key) => ({ documentLabel: key, docs: value }))
    // .value();

    const groupedDocuments = _.chain(documents)
    .groupBy("_id")
    .map(function(value, key) {
      const filterLabel = _.map(value, function(o) { return o._id === key ? o.documentLabel || (o.dcid && o.dcid.label) : ''});
      const filterNotes = _.map(value, function(o) { return o._id === key ? o.notes : ''});
        
      return {
        documentLabel: filterLabel[0],
        documentNotes: filterNotes[0],
        docs: value
      }
    })
    .value();

    const [isLabelCheckedAll, setIsLabelCheckedAll] = useState(new Array(groupedDocuments.length).fill(false));
    
    useEffect(() => {
      setIsLabelCheckedAll(new Array(groupedDocuments.length).fill(Boolean(isCheckAllGroup)));
    },[isCheckAllGroup])

    useEffect(() => {
      if (resetItemComponent) {
        setIsLabelCheckedAll(new Array(groupedDocuments.length).fill(false));
      }
    },[resetItemComponent])

    const renderFileItem = (args: any) => {
        const params = {
          _id: args._id,
          item: args.item,
          files: args.files,
          notes: args.notes,
          display: {
            view: true,
            isShowNote: true,
            isShowCheckbox: true,
            isShowIconEdit: false,
            download: true,
            isShowIconFolder: false,
            isShowIconDelete: false,
            isShowDateCreated: false,
            isShowDateUpdated: false,
            isShowStatus:
              status === "not-completed" && args.files && args.files.length === 0
                ? false
                : true,
            ...args.display,
          },
          label: args.documentLabel
        }
    
        return (
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.fileRow}>
            <FileItem
              item={params.item}
              onFileUploaderModalOpen={() => {}}
              refreshRequestedDocuments={() => {}}
              clientId={clientId}
              isFromDocument
              folder="Mortgages"
              display={params.display}
              notes={params.notes}
              files={params.files}
              isReadOnlyNotes={true}
            />
          </Grid>
        )
    }

    const onCheckAllDocLabelHandler = (position: number, docLabel: string, docNotes: string) => {
      const updatedCheckedState = isLabelCheckedAll.map((item, index) => index === position ? !item : item);
      setIsLabelCheckedAll(updatedCheckedState);

      const newGroupedDocuments = groupedDocuments.filter(({ documentNotes, documentLabel }, index) => index === position && documentLabel === docLabel && documentNotes === docNotes);

      let arrFiles: any = []
      newGroupedDocuments.forEach(({ docs }) => {
        // This implementation strongly needs a nested loop using forEach
        // instead of map because of the data structure.
        docs.forEach((doc: any) => {
          doc.files.forEach((i: any) => {
            arrFiles.push({
              AWSFileKey: ["DocumentCollection", clientId, i.file_name].join("/"),
              checked: updatedCheckedState[position],
              createdAt: i.createdAt,
              documentType: doc.documentType,
              fileLabel: i.label,
              _id: i._id,
            })
          })
        })
      })

      checkDownloadHandler && checkDownloadHandler(arrFiles)
    }
    
    return <>{
        groupedDocuments.map(({ docs, documentLabel, documentNotes }, index) => {
          const newDocumentLabel = documentLabel && documentLabel !== '' ? documentLabel : '';
          const newDocumentNotes  = documentNotes && documentNotes !== '' ? documentNotes : '';

          return <Grid container key={index}>
            {
              docs.map(({ dcid, _id, notes, files }, idx) => {
                return <React.Fragment key={idx}>
                  <Grid container className={classes.docLabelRow}>
                      <Grid item xs={4}>
                          <Checkbox 
                            style={{color: '#4e185b'}}
                            icon={<img src={CheckboxIcon} className={styles.checkboxIcon}/>}
                            checkedIcon={<img src={CheckedBoxIcon} className={styles.checkboxIcon}/>}
                            checked={isLabelCheckedAll[index]} 
                            onChange={() => onCheckAllDocLabelHandler(index, newDocumentLabel, newDocumentNotes)}
                          />
                        <span className={styles.docLabel}>{documentLabel}</span>
                      </Grid>
                      <Grid item xs={7}>
                        <span className={styles.docNotes}>{notes && notes !== "" && `Notes: ${notes}`}</span></Grid>
                      <Grid item xs={1}>
                        <Box display="flex" alignItems="center">
                          { files && files.length > 0 ? <CheckSharp htmlColor="#27AE60" /> : <ErrorOutline htmlColor="#EB5757"/> }
                          &nbsp;
                          <span className={styles.docFiles}>{ files && files.length > 0 ? files.length : 0 }&nbsp;files uploaded</span>
                        </Box>
                      </Grid>
                  </Grid>
                  {
                    files && files.length > 0 &&
                    <div className={classes.container}>
                      {
                        files.map((i: any) =>
                          renderFileItem({
                            _id,
                            files,
                            item: i,
                            display: {
                              isShowNote: false,
                              isShowCheckbox: false,
                              isShowStatus: false,
                              isShowDateCreated: true,
                              isShowTag: true,
                              isMultipleRequest: true,
                            },
                          })
                        )
                      }
                    </div>
                  }
                </React.Fragment>
              })
            }
          </Grid>
        })
    }</>
}

export default RequestHistoryItem

