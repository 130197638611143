export const conv_number = (expr, decplaces) => {
  let str = "" + Math.round(eval(expr) * Math.pow(10, decplaces))
  while (str.length <= decplaces) {
    str = "0" + str
  }

  const decpoint = str.length - decplaces
  return str.substring(0, decpoint) + "." + str.substring(decpoint, str.length)
}

export const pv = (rate, nper, pmt) => {
  // reference : https://gist.github.com/ghalimi/4638848
  return (pmt / rate) * (1 - Math.pow(1 + rate, -nper))
}

export const fv = (rate, nper, pmt, pv) => {
  // rate = parseFloat(rate),
  // nper = parseFloat(nper),
  // pmt = parseFloat(pmt),
  // pv = parseFloat(pv);
  let fv_value

  if (nper === 0) {
    alert("Why do you want to test me with zeros?")
    return 0
  }
  if (rate === 0) {
    // Interest rate is 0
    fv_value = -(pv + pmt * nper)
  } else {
    const x = Math.pow(1 + rate, nper)
    fv_value = -(-pmt + x * pmt + rate * x * pv) / rate
  }
  fv_value = conv_number(fv_value, 2)
  return fv_value
}
