import Grid from "@material-ui/core/Grid"
import classNames from "classnames"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import DeleteIcon from "../../../assets/delete-icon.svg"
import FileUploader from "../../../components/FileUploader/index"
import Modal from "../../../components/Modal"
import MultiDownloadButton from "../../../components/MultiDownloadButton/MultiDownloadButton"
import MultipleRequestUploader from "../../../components/MultipleRequestUploader"
import NoticeModal from "../../../components/NoticeModal"
import {
  getAllSettingsOther, getClientNames, i_Document_2, uploadDocument, validateAndAddTags
} from "../../../lib/services"
import { addFileRecords, deleteFile, editFile, fetchLists, getAllTagList } from "./Documents.controller"
import {
  i_clientName,
  i_DocumentPoolFile, i_Documents, i_FileStatementPeriod, i_FileTag
} from "./Documents.interfaces"
import styles from "./Documents.module.scss"
import DocumentTable from "./DocumentTable"

//  Internally, customStyles will deep merges your customStyles with the default styling.
export const customStyles = {
  tableWrapper: {
		style: {
			fontFamily: '"Poppins", sans-serif',
		},
	},
  header: {
		style: {
			fontSize: '20px',
			color: '#541868',
      lineHeight: '28px',
      fontWeight: 400
		},
	},
  head: {
		style: {
			fontSize: '12px',
			color: '#541868',
      lineHeight: '17px',
      fontWeight: 300,
		},
	},
  headRow: {
		style: {
      minHeight: 'unset',
			height: '35px',

      // '& > div:first-child': {
      //   display: 'none'
      // },
      // '& > div:nth-child(2)': {
      //   paddingRight: '60px'
      // }
		},
	},
  rows: {
		style: {
			backgroundColor: '#FAFAFA',
      borderRadius: '4px',
      
      '&:not(:first-child)': {
        marginBottom: '5px',
        marginTop: '5px',
			},
      '&:first-child': {
        marginTop: '23px',
        marginBottom: '5px',
			},

      '& > div:first-child': {

        '& svg.MuiSvgIcon-root': {
          fontSize: '30px',
          color: '#541868',
        }
			},

      '& .rdt_TableCell': {
        marginBottom: '9px',
        marginTop: '9px',

        'div[data-tag=allowRowEvents]': {
          fontSize: '12px',
          lineHeight: '21px',
          color: '#4F4F4F',
          fontWeight: '400',
          whiteSpace: 'break-spaces',
          textAlign: 'center'
        },

        // '&:not(:first-child):not(:nth-child(2))': {
        //   'div[data-tag=allowRowEvents]': {
        //     color: '#000000',
        //   },
        // },
        // '&:nth-child(3), &:nth-child(7)': {
        //   'div[data-tag=allowRowEvents]': {
        //     fontSize: '12px',
        //   },
        // }
      },

      'div[data-column-id=emailStatus]': {
        maxWidth: 'fit-content',
        minWidth: '55px'
      },

      'div[data-column-id=name]': {
        padding: '0 32px 0 0'
      }
		},
  },
  headCells: {
    style: {
      '&[data-column-id=emailStatus]': {
        maxWidth: 'fit-content',
        minWidth: '50px',
        padding: 0,

        '& .rdt_TableCol_Sortable': {
          justifyContent: 'flex-start !important'
        }
      },

      // '&:not(:nth-child(2))': {
      //   '& > .rdt_TableCol_Sortable': {
      //     justifyContent: 'center'
      //   }
      // },

      '& .rdt_TableCol_Sortable': {

        '& span.__rdt_custom_sort_icon__': {
          display: 'flex',

          '& svg': {
            height: '20px',
            width: '20px',
          }
        },
        
        '&.fFBlx span.__rdt_custom_sort_icon__ svg': {
          opacity: '0.3',
        }
      },
		},
  },
};

const Documents: React.FC<i_Documents> = ({ clientId, client_email, isAdmin }) => {
  // =================================[ State ]====================================

  const [otherDocuments, setOtherDocuments] = useState<i_Document_2[]>([])
  const [droppedFile, setDroppedFile] = useState<File | undefined>()
  const [modalError, setModalError] = useState<string | undefined>()
  const [files, setFiles] = useState<any>([])
  const [showFileUploaderModal, setShowFileUploaderModal] = useState(false)
  const [isModalSubmitting, setIsModalSubmitting] = useState(false)
  const [currentFileEdited, setCurrentFileEdited] = useState("")
  const [modalIsEdit, setModalIsEdit] = useState(false)
  const [fileLabel, setFileLabel] = useState("")
  const [fileType, setFileType] = useState("")
  const [showSubmitSuccessModal, setShowSubmitSuccessModal] = useState(false)
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState("")
  const [checkedDownload, setCheckedDownload] = useState<any[]>([])
  const [clientName, setClientName] = useState<i_clientName | undefined | void>(undefined)
  const [checkSwitch, setCheckSwitch] = useState<any[]>([]) // true, 'semi', or false
  //rerender count is used to trigger render by force when a state changed is an array or an object. We changed the single state value to make a force rerender for checkSwitch
  const [rerenderCount, setRerenderCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [tags, setTags] = useState<string[]>([]);
  const [isLoadingTags, setIsLoadingTags] = useState<boolean>(false);
  const [tagsItemSelectable, setTagsItemSelectable] = useState<i_FileTag[]>([]);
  const [isResetSelectedRow, setIsResetSelectedRow] = useState<boolean>(false);

  const [statementStartDate, setStatementStartDate] = useState<any>(null);
  const [statementEndDate, setStatementEndDate] = useState<any>(null);
  const [defReferNo, setDefReferNo] = useState<string>("");

  const SEMI = "semi"

  // =================================[ Functions ]==========================

  const refreshRequestedDocuments = async () => {
    setIsLoading(true)
    setIsLoadingTags(true);
    const data = await fetchLists({ uid: clientId });
    
    let groupedFiles = _.groupBy(data[0]?.files, "documentType")
    const checkall = Object.keys(groupedFiles).map(item => false)
    setCheckSwitch(checkall)
    setFiles(groupedFiles)
    
    //  update checkedDownload, to remove on the array what's being removed on the fetched array.
    const fileIds = data[0]?.files.map((file: i_DocumentPoolFile) => file._id)
    const newCheckedDownload = checkedDownload.filter(checkedDownloadSingle => fileIds.includes(checkedDownloadSingle._id))
    setCheckedDownload(newCheckedDownload)
    
    setTimeout(() => {
      if (clientId) {
        getAllTagList(clientId).then(({ tags }) => {
          if (tags && tags.length > 0) {
            setTagsItemSelectable(tags);
          } else {
            setTagsItemSelectable([])
          }
          setIsLoadingTags(false);
        })
      }
    }, 3000);

    setTimeout(() => {
      setIsLoading(false)
      setIsResetSelectedRow(false);
    }, 500);
  }

  const checkMultiDownloadHandler = (selectedFiles: i_DocumentPoolFile[]) => {
    removeChecked();
    let groupedFiles = _.groupBy(selectedFiles, "documentType")
    let newFilesType = Object.entries(groupedFiles).map(([key, value]) => {
      return files[key].map((item: any) => {
        return value.map(itm => {
          if (item._id === itm._id) {
            item.checked = true
          }
          return item
        })
      })
    });

    const newFiles = {...files};
    Object.entries(groupedFiles).map(([key, value]) => {
      if (newFiles[key] === key) {
        newFiles[key] = newFilesType;
      }
    })
    setFiles(newFiles);

    const allCheckedFiles = Object.keys(files).map(key => {
      return files[key].filter((item: any) => item.checked)
    });

    const conCatChecked = allCheckedFiles.reduce((prev, curr) => {
      const allCurrent = curr.filter((item: any) => item.checked)
      const allPrev = prev.filter((item: any) => item.checked)
      const finalArr = [...allCurrent, ...allPrev]
      return finalArr
    }, []);

    setCheckedDownload(conCatChecked)
  }

  const removeChecked = () => {
      const newFiles = {...files};
      Object.keys(newFiles).map(key => {
        newFiles[key].map((item: any) => {
          item.checked = false
          return item
        })
      })
      setFiles(newFiles);
  }

  const deleteMultipleFiles = async () => {
    setIsResetSelectedRow(true);
    await checkedDownload.map(async (item: i_DocumentPoolFile) => {
      await deleteFile({ uid: clientId, fileId: item._id });
    });
    await refreshRequestedDocuments();
  }

  const onFileUploaderModalOpen = (
    fileId: string,
    isEdit: boolean,
    defaultFileType: string,
    fileLabel: string,
    fileTags: string[],
    staPeriod?: i_FileStatementPeriod,
    referNo?: string
  ) => () => {
    // unsure why we need curried here...
    setModalIsEdit(isEdit)
    setFileLabel(fileLabel)
    setFileType(defaultFileType)
    setCurrentFileEdited(fileId)
    setShowFileUploaderModal(true)
    setTags(fileTags);
    setStatementStartDate(staPeriod && staPeriod?.start ? staPeriod.start : null);
    setStatementEndDate(staPeriod && staPeriod?.end ? staPeriod.end : null);
    setDefReferNo(referNo ? referNo : "");
    setCheckedDownload([]);
    setIsResetSelectedRow(true);
    removeChecked();
  }

  const onFileUploaderModalSubmit = (
    dcid: string | null,
    fileName: string,
    label: string,
    fileType: string,
    fileTags: string[],
    file?: File | undefined,
    staPeriod?: i_FileStatementPeriod,
    referNo?: string
  ) => {
    if (fileTags.length > 0 && clientId) {
      validateAndAddTags(clientId, fileTags);
    }

    if (modalIsEdit) {
      setIsModalSubmitting(true)
      setModalError("")
      const document: any = {
        uid: clientId,
        fileId: currentFileEdited,
        fileLabel: label,
        documentType: fileType,
        tags: fileTags
      }

      if (referNo) {
        document.referenceNumber = referNo
      }
      
      if (staPeriod) {
        document.statementPeriod = staPeriod
      }

      editFile(document)
        .then(res => {
          refreshRequestedDocuments()
          setIsModalSubmitting(false)
          setShowFileUploaderModal(false)
        })
        .catch(() => {
          setModalError("Unable to connect with AWS S3")
        })
    } else {
      setIsModalSubmitting(true)
      setModalError("")
      uploadDocument(file, fileType, clientId, client_email)
        .then((data: any) => {
          const document: any = {
            label: label,
            fileName: fileName,
            documentType: fileType,
            tags: fileTags
          }

          if (referNo) {
            document.referenceNumber = referNo
          }
          
          if (staPeriod) {
            document.statementPeriod = staPeriod
          }

          addFileRecords({
            requestedDocuments: document,
            clientId: clientId,
            isSingle: true,
          }).then(res => {
            if (!_.isNil(res)) {
              const message = res.message ? res.message : ""
              setSubmitSuccessMessage(message)
              setShowSubmitSuccessModal(true)
              refreshRequestedDocuments()
              setIsModalSubmitting(false)
              setShowFileUploaderModal(false)
            } else {
              setSubmitSuccessMessage(
                "Something is wrong on the uploading process. Please try again."
              )
              setShowSubmitSuccessModal(true)
              setIsModalSubmitting(false)
              setShowFileUploaderModal(false)
            }
          })
        })
        .catch(() => {
          setModalError("Unable to connect with AWS S3")
        })
    }
  }

  // =================================[ Hooks ]==========================

  useEffect(() => {
    setModalError("")
  }, [fileLabel, files, checkedDownload])
  
  useEffect(() => {
    setCheckedDownload([]);
    setIsResetSelectedRow(true);
    removeChecked();
  }, [isResetSelectedRow])

  useEffect(() => {
    refreshRequestedDocuments()
    getAllSettingsOther().then(settings => {
      setOtherDocuments(settings)
    })
    getClientNames("client/getClientNames").then(clientName => {
      setClientName(clientName)
    })
  }, [clientId])

  // All Switch toggle status handler
  useEffect(() => {
    if (files) {
      Object.keys(files).map((key: string, index: number) => {
        const _file = files[key]
        const newCheckSwitch = checkSwitch
        if (_file.filter((f: any) => f.checked).length === _file.length) {
          newCheckSwitch[index] = true
        } else if (_file.filter((f: any) => f.checked).length !== 0) {
          newCheckSwitch[index] = SEMI
        } else {
          newCheckSwitch[index] = false
        }
        setRerenderCount(rerenderCount + 1)
        setCheckSwitch(newCheckSwitch) // needs the rerendercount to change in order to trigger the rerender since this is an array, it needs a single value state to change to force rerendering
        return true
      })
    }
  }, [
    files,
    checkedDownload,
    setCheckSwitch,
    checkSwitch,
    showSubmitSuccessModal,
  ])

  // =================================[ Renders ]==========================
  return (
    <>
      <div className={styles.root}>
        <div className={styles.selectActions}>
          <div className={classNames({ [styles.selectActionsHidden]: checkedDownload.length <= 0 })}>
            <MultiDownloadButton
              checkedDownload={checkedDownload}
              clientId={clientId}
              clientName={clientName}
              setCheckedDownload={setCheckedDownload}
              removeChecked={removeChecked}
              setShowSubmitSuccessModal={setShowSubmitSuccessModal}
              setSubmitSuccessMessage={setSubmitSuccessMessage}
              setIsResetSelectedRow={setIsResetSelectedRow}
            />
          </div>
          <div className={classNames({ [styles.selectActionsHidden]: checkedDownload.length <= 0 })}>
            <div className={styles.downloadAllContainer} onClick={() => {
              if (window.confirm("Are you sure you want to delete the selected item(s)?")) {
                deleteMultipleFiles()
              }
            }}>
              <img
                src={DeleteIcon}
                className={styles.downloadIcon}
                alt="delete-icon"
              />
              <p className={styles.selectAll}>
                Delete Selected
              </p>
            </div>
          </div>
        </div>


        <Grid container spacing={3} className={styles.listWrapper}>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            {
              Object.keys(files)?.length > 0 && 
                <DocumentTable
                  item={Object.keys(files).reduce((accum: any, key) => {
                    if (files[key]) {
                      accum.push(...files[key])
                    }
                    return accum;
                  },[])}
                  tagsList={tagsItemSelectable}
                  clientId={clientId}
                  isLoading={isLoading}
                  isLoadingTags={isLoadingTags}
                  isResetSelectedRow={isResetSelectedRow}
                  setIsResetSelectedRow={setIsResetSelectedRow}
                  onFileUploaderModalOpen={onFileUploaderModalOpen}
                  refreshRequestedDocuments={refreshRequestedDocuments}
                  checkMultiDownloadHandler={checkMultiDownloadHandler}
                  isAdmin={isAdmin}
              />
            }
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <FileUploader
              type="modal-details"
              openModalUpload={onFileUploaderModalOpen("", false, "", "", [], undefined, "")}
              droppedFile={(file: File) => {
                if (file) {
                  setDroppedFile(file)
                }
              }}
            />
          </Grid>
        </Grid>
      </div>

      <MultipleRequestUploader
        showModal={showFileUploaderModal}
        defaultFileType={fileType}
        defaultFileLabel={fileLabel}
        defaultTags={tags}
        defaultStatementStart={statementStartDate}
        defaultStatementEnd={statementEndDate}
        defaultReferNo={defReferNo}
        onClose={() => setShowFileUploaderModal(false)}
        onSubmit={onFileUploaderModalSubmit}
        droppedFile={droppedFile}
        isEdit={modalIsEdit}
        unsetDroppedFile={(toUnset: Boolean) => {
          if (toUnset) setDroppedFile(undefined)
        }}
        submitting={isModalSubmitting}
        otherDocumentsChoices={otherDocuments}
        error={modalError}
        isFromDocument={true} // all fileuploader modal in documents.tsx should be able to change file type
      />

      <NoticeModal
        showModal={showSubmitSuccessModal}
        onClose={() => setShowSubmitSuccessModal(false)}
        infoText={submitSuccessMessage}
      />
    </>
  )
}

export default Documents
