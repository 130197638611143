import React, { useState, useEffect, useRef } from "react"
import { i_RequestedDocumentFile, i_Document_2 } from "./../../lib/services"
import Alert from "@material-ui/lab/Alert"
import Modal from "../Modal"
import styles from "./MultipleRequestUploader.module.scss"
import Button from "@material-ui/core/Button"
import { v4 as uuidv4 } from "uuid"
import { Box, IconButton, Input, InputLabel, MenuItem, Select, Typography } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import classNames from "classnames"
import TagsInput from "../TagsInput"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import moment from "moment"
import MomentUtils from "@date-io/moment"
import { i_FileStatementPeriod } from "../../scenes/DocumentCollection/Documents/Documents.interfaces"

const DEFAULT_FORMAT = "YYYY-MM-DD";

const MultipleRequestUploader: React.FC<FileUploaderModalProps> = props => {
  const {
    showModal,
    defaultFileType,
    defaultFileLabel,
    onClose,
    onSubmit,
    dcid,
    droppedFile,
    unsetDroppedFile,
    uploadedFile,
    isEdit,
    submitting,
    otherDocumentsChoices,
    error,
    isFromDocument,
    defaultTags,
    defaultStatementStart,
    defaultStatementEnd,
    defaultReferNo
  } = props

  const randomString = uuidv4()
  const [file, setFile] = useState<any>()
  const [fileName, setFileName] = useState<string>("")
  const [initFileLabel, setInitFileLabel] = useState<string>("")
  const [fileLabel, setFileLabel] = useState<string>("")
  const [fileType, setFileType] = useState<string>("")
  const [newDcid, setNewDcid] = useState<any>("")
  const fileTypeInput = useRef<any>()
  const fileLabelInput = useRef<any>()
  const fileInput = useRef<any>()
  const [isValidFile, setIsValidFile] = useState<boolean>(true);
  const [tags, setTags] = useState<string[]>([]);
  
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [referNo, setReferNo] = useState<string>("");

  useEffect(() => {
    if (Array.isArray(droppedFile)) {
      unsetDroppedFile(true)
      setFileName(droppedFile[0].name)
      setFileLabel(droppedFile[0].name.replace(/\.[^/.]+$/, "")) // excluding file extension
      setInitFileLabel(droppedFile[0].name.replace(/\.[^/.]+$/, ""))
      setFile(droppedFile[0])
    }
    if (dcid !== "") {
      setNewDcid(dcid)
    }
  }, [droppedFile, unsetDroppedFile])

  useEffect(() => {
    if (showModal) {
      setFileType(defaultFileType && defaultFileType !== "" ?  defaultFileType : "")
      setTags(defaultTags && defaultTags.length > 0 ? defaultTags : [])
      setFileLabel(defaultFileLabel && defaultFileLabel !== "" ? defaultFileLabel : "")
      setInitFileLabel(defaultFileLabel ? defaultFileLabel : "")
      setStartDate(defaultStatementStart ? moment(new Date(defaultStatementStart)).format(DEFAULT_FORMAT) : null)
      setEndDate(defaultStatementEnd ? moment(new Date(defaultStatementEnd)).format(DEFAULT_FORMAT) : null) 
      setReferNo(defaultReferNo ? defaultReferNo : "")
    } else {
      resetModal();
    }
    
    if (dcid !== "") {
      setNewDcid(dcid)
    }
  }, [showModal]) // eslint-disable-line

  useEffect(() => {
    if (uploadedFile) {
      setFileType(uploadedFile.file_type || defaultFileType || "")
      if (!isEdit) {
        setFileName(
          [
            defaultFileLabel,
            uploadedFile.file_name && uploadedFile.file_name.split(".").pop(),
          ].join(".") || ""
        )
        setFileLabel(
          [
            defaultFileLabel,
            uploadedFile.file_name && uploadedFile.file_name.split(".").pop(),
          ].join(".") || ""
        )
      }
    }
    if (dcid !== "") {
      setNewDcid(dcid)
    }
  }, [uploadedFile])

  const resetModal = () => {
    setFile(undefined)
    setFileName("")
    setFileType("")
    setFileLabel("")
    setInitFileLabel("")
    setStartDate(null)
    setEndDate(null)
    setReferNo("")
    setTags([])
  }

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files && e.target.files[0]?.type !== "") {
        const name: any = e.target.files[0].name
        setFile(e.target.files[0])
        const strName = name.replace(/\.[^/.]+$/, "");
        let strFileLabel = strName;

        let strEndDate = endDate ? endDate.split('-').reverse().join('') : '';
        if (referNo) {
          strFileLabel = endDate ? strFileLabel.concat(' ', referNo.concat(' ', strEndDate)) : strFileLabel.concat(' ', referNo);
        } else if (endDate) {
          strFileLabel = strFileLabel.concat(' ', strEndDate)
        }

        setFileLabel(strFileLabel) // excluding the extension
        setInitFileLabel(strName)
        setFileName(strFileLabel)
        setIsValidFile(true);
    }
    else {     
      setIsValidFile(false);
    }
  }

  const onFileLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileLabel(e.target.value)
    setInitFileLabel(e.target.value)
    if (fileLabelInput && fileLabelInput.current) {
      fileLabelInput.current.style = ""
    }
    if (!isEdit) {
      setFileName([e.target.value, fileName.split(".").pop()].join("."))
    }
  }

  const onFileTypeChange2 = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
		_child: React.ReactNode) => {
    setNewDcid(e.target.value)
  }

  const onFileTypeChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
		_child: React.ReactNode) => {
    setFileType(e.target.value as string)
  }

  const renderSelectFilType = () => {
    if (isFromDocument) {
      return (
        <Select
          displayEmpty
          ref={fileTypeInput}
          className={classNames(styles.inputControls, styles.selectTemplate)}
          classes={{ 
            root: styles.selectMenu,
            icon: styles.selectMenuIcon
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null,
            classes: { paper: styles.selectMenuList }
          }}
          onClick={() => {
            if (fileTypeInput && fileTypeInput.current) {
              fileTypeInput.current.style = ""
            }
          }}
          onChange={onFileTypeChange}
          value={fileType}
        >
          <MenuItem value="">[Select File Type]</MenuItem>

          {otherDocumentsChoices.sort().map(item => (
            <MenuItem
              selected={item.documentType === fileType}
              value={item.documentType}
            >
              {item.documentType}
            </MenuItem>
          ))}
        </Select>
      )
    } else if (defaultFileType === "") {
      return (
        <Select
          ref={fileTypeInput}
          className={classNames(styles.inputControls, styles.selectTemplate, styles.fileName)}
          classes={{ 
							root: styles.selectMenu,
							icon: styles.selectMenuIcon
          }}
          onClick={() => {
            if (fileTypeInput && fileTypeInput.current) {
              fileTypeInput.current.style = ""
            }
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null,
            classes: { paper: styles.selectMenuList }
          }}
          onChange={onFileTypeChange2}
        >
          <MenuItem value="">[Select File Type]</MenuItem>
          {otherDocumentsChoices.sort().map(item => (
            <MenuItem key={`${item._id}`} value={item._id}>{item.documentType}</MenuItem>
          ))}
        </Select>
      )
    } else {
      return (
        <Input
          type="text"
          readOnly
          disabled={true}
          className={classNames(styles.inputControls, styles.txtName, styles.fileName)}
          onChange={onFileLabelChange}
          value={defaultFileType}
        />
      )
    }
  }

  const handleChangeTags = (tags: string[]) => {
    setTags(tags)
  }

  const handleUpdateFileLabel = (referenceNo: string, statementEnd: any) => {
    let strFileLabel = initFileLabel;

    if (defaultReferNo) {
      strFileLabel = strFileLabel.replace(defaultReferNo, '').trim()
    }

    if (defaultStatementEnd) {
      let strDefaultStatementEnd = moment(new Date(defaultStatementEnd)).format(DEFAULT_FORMAT);
      strDefaultStatementEnd = strDefaultStatementEnd.split('-').reverse().join('');
      strFileLabel = strFileLabel.replace(strDefaultStatementEnd, '').trim();
    }

    const strEndDate = statementEnd ? statementEnd.split('-').reverse().join('') : '';
    const typeOfReplace = strFileLabel !== '' ? ' ' : '';

    if (referenceNo) {
      strFileLabel = statementEnd ? strFileLabel.concat(typeOfReplace, referenceNo.concat(' ', strEndDate)) : strFileLabel.concat(typeOfReplace, referenceNo);
    } else if (statementEnd) {
      strFileLabel = strFileLabel.concat(typeOfReplace, strEndDate)
    }

    setFileLabel(strFileLabel);
  }

  const handleReferNoChange = (e: React.ChangeEvent<HTMLInputElement>) => { 
    const pattern = /^[a-zA-Z0-9]*$/;
    const inputText = e.target.value;

    if (pattern.test(inputText) && inputText.length <= 15) {
      setReferNo(inputText);
      handleUpdateFileLabel(inputText, endDate);
    }
  }

  const handleChangeDates = (value: any, type: string) => {
    let parseValue = value !== null ? moment(new Date(value)).format(DEFAULT_FORMAT) : null;

    if (type === 'start') {
      setStartDate(parseValue)
    } else if (type === 'end') {
      setEndDate(parseValue);
      handleUpdateFileLabel(referNo, parseValue);
    }
  }

  return (
    <>
      <Modal showModal={showModal} onClose={onClose} paperClassName={styles.modalPaper}>
        <Box className={styles.headerRow}>
            <Box className={styles.headerTitle}>{ isEdit ? 'Edit File' : 'Upload File' }</Box>
            <IconButton onClick={onClose}>
                <Close classes={{ root: styles.closeIcon }} />
            </IconButton>
        </Box>

        <InputLabel className={styles.txtSubHeader}>Select File and Document Type. Apply tags to help with sorting files.</InputLabel>
        <Box className={styles.contentRow}>
            {error && (
              <Alert
                  variant="filled"
                  severity="error"
                  className={classNames(styles.alertControls, styles.alertMessage)}>
                  {error}
              </Alert>
            )}

            {!isEdit && fileName && (
              <Box className={styles.inputGroup}>
                  <InputLabel className={styles.inputLabel}>File Selected</InputLabel>
                  <Input
                    type="text"
                    readOnly
                    className={classNames(styles.inputControls, styles.txtName, file ? styles.fileNameSelected : styles.inputError)}
                    value={
                        file 
                        ? [fileName, " - note : Double-click to replace"].join(" ")
                        : "Please upload file first. Double-click to replace"
                    }
                    onDoubleClick={() => {
                        setFileLabel("")
                        setFileName("")
                        setInitFileLabel("")
                    }}
                  />
              </Box>
            )}

            {!isEdit && !fileName && (
              <Box className={styles.inputGroup}>
                <InputLabel className={styles.inputLabel}>File</InputLabel>
                <Input
                  className={classNames(styles.inputControls, styles.txtName, styles.fileName)}
                  onChange={onFileChange}
                  ref={fileInput}
                  type="file"
                />
              </Box>
            )}

            <Box className={styles.inputGroup}>
              <InputLabel className={styles.inputLabel}>File name</InputLabel>
              <Input
                placeholder="Enter File Name"
                ref={fileLabelInput}
                type="text"
                className={classNames(styles.inputControls, styles.txtName, styles.fileName)}
                onChange={onFileLabelChange}
                value={fileLabel}
              />
              <InputLabel className={styles.tipText2}>Tip: Please name your file carefully</InputLabel>
            </Box>

            <Box className={styles.inputGroup}>
              <InputLabel className={styles.inputLabel}>File type</InputLabel>
              {renderSelectFilType()}
            </Box>

            <Box className={styles.inputGroup}>
              <InputLabel className={styles.inputLabel}>Statement Period</InputLabel>
              <Box className={styles.rowGroup}>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                  locale="au"
                >
                  <KeyboardDatePicker
                    autoOk
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    placeholder="Start Date"
                    disableToolbar
                    clearable
                    InputProps={{ className: styles.innerDateInput, readOnly: true }}
                    className={classNames(styles.dateInputControls, styles.txtStaDate)}
                    value={startDate}
                    onChange={date => handleChangeDates(date, 'start')}
                  />
                </MuiPickersUtilsProvider>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>&nbsp;&nbsp;&nbsp;to&nbsp;&nbsp;
                </div>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                  locale="au"
                >
                  <KeyboardDatePicker
                    autoOk
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    placeholder="End Date"
                    disableToolbar
                    clearable
                    InputProps={{ className: styles.innerDateInput, readOnly: true }}
                    className={classNames(styles.dateInputControls, styles.txtStaDate)}
                    value={endDate}
                    onChange={date => handleChangeDates(date, 'end')}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <InputLabel className={styles.tipText2}>Note: Only if Applicable, e.g. for a Bank Statement</InputLabel>
            </Box>

            <Box className={styles.inputGroup}>
              <InputLabel className={styles.inputLabel}>Reference No.</InputLabel>
              <Input
                placeholder="Reference"
                type="text"
                className={classNames(styles.inputControls, styles.txtName, styles.fileName, styles.txtRefNo)}
                onChange={handleReferNoChange}
                value={referNo}
              />
              <InputLabel className={styles.tipText2}>Tip: This helps with identifying files. For example, your bank account’s last 4 digits.</InputLabel>
            </Box>
            
            <Box className={styles.inputGroup}>
              <InputLabel className={styles.inputLabel}>Tags</InputLabel>
              <TagsInput
                defaultTags={tags}
                onChangeTags={handleChangeTags}
              />
            </Box>

            {!isValidFile && (
              <Box className={styles.messageGroup}>
                <Alert severity="error">
                  <p className={styles.errorText}>
                    The file you are trying to upload is invalid. <br />
                    Please make sure that you are uploading a PDF or a JPEG. <br />
                    If you are still having issues - please log a ticket <a href="https://wealthpathlabs.atlassian.net/servicedesk/customer/portal/2/group/2/create/10026" target="_blank">here</a> so we can help you .
                  </p>
                </Alert>
              </Box>
            )}
        </Box>

        <Box className={styles.footerRow}>
          <Button 
            className={classNames(styles.actionButtons, styles.cancelButton, styles.secondaryButton)}
            onClick={onClose}
            disabled={submitting}
            >
            Cancel
          </Button>
          <Button 
            className={classNames(styles.actionButtons, styles.yesButton, styles.primaryButton)} 
            onClick={() => {
              // used for new files...
              let MutatedFile
              if (file) {
                MutatedFile = new File(
                  [file],
                  [randomString, fileName && fileName.split(".").pop()].join(
                    "."
                  ) || ""
                )
              }
              
              const statPeriod: i_FileStatementPeriod = {
                start: startDate ? startDate : "",
                end: endDate ? endDate : ""
              }
               
              // specific from Document page
              if (isFromDocument) {
                if (!isEdit) {
                  // not checking file when editing inside document
                  if (!file) {
                    fileInput.current.style.backgroundColor = "pink"
                  }
                }
                if (fileLabel === "") {
                  fileLabelInput.current.style.backgroundColor = "pink"
                }
                if (fileType === "") {
                  fileTypeInput.current.style.backgroundColor = "pink"
                }
                if (isEdit) {
                  // edit
                  if (fileType !== "" && fileLabel !== "") {
                    onSubmit(null, null, fileLabel, fileType, tags, null, statPeriod, referNo)
                  }
                } else {
                  // new
                  if (file !== "" && fileType !== "" && fileLabel !== "") {
                    onSubmit(
                      null,
                      [randomString, fileName.split(".").pop()].join(".") ||
                        "",
                      fileLabel,
                      fileType,
                      tags,
                      MutatedFile,
                      statPeriod, 
                      referNo
                    )
                  }
                }
              } else {
                if (!isEdit) {
                  // new
                  if (!file) {
                    fileInput.current.style.backgroundColor = "pink"
                  }
                  if (fileLabel === "") {
                    fileLabelInput.current.style.backgroundColor = "pink"
                  }
                  if (file !== "" && fileLabel !== "") {
                    onSubmit(
                      newDcid,
                      [randomString, fileName.split(".").pop()].join(".") ||
                        "",
                      fileLabel,
                      defaultFileType,
                      tags,
                      MutatedFile,
                      statPeriod,
                      referNo
                    )
                  }
                  // }
                } else {
                  // edit
                  onSubmit(dcid, null, fileLabel, fileType, tags, null, statPeriod, referNo)
                }
              }
            }}
            style={{ width: submitting ? '100px' : '80px' }}
            disabled={submitting || !isValidFile}>
            {submitting ? "Submitting..." : "Submit"}
          </Button>
        </Box>
      </Modal>
    </>
  )
}

interface FileUploaderModalProps {
  showModal: boolean
  defaultFileType: string
  defaultFileLabel: string
  defaultTags: string[]
  defaultStatementStart?: any
  defaultStatementEnd?: any
  defaultReferNo?: string
  onSubmit: any
  onClose: () => void
  droppedFile?: File
  unsetDroppedFile: Function
  isEdit: boolean
  uploadedFile?: i_RequestedDocumentFile
  submitting: boolean
  otherDocumentsChoices: i_Document_2[]
  error?: string | undefined | null
  isFromDocument?: boolean
  dcid?: string | null
}

export default MultipleRequestUploader