import React, { useEffect, useState } from "react"
import { AddDocumentModalProps } from "./../../scenes/DocumentCollection/AdminRequest/AdminRequest.interface"
import Modal from "./../Modal"
import styles from "./AddRequestModal.module.scss"
import { Button, MenuItem, Select, Input, Box, IconButton, InputLabel } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import classNames from "classnames"

const AddRequestModal: React.FC<AddDocumentModalProps> = props => {
  const { documentType, showModal, onSubmit, onClose } = props
  const [selectedDocumentType, setSelectedDocumentType] = useState("")
  const [fileName, setFileName] = useState("")

  useEffect(() => {
    if (!showModal) {
      setSelectedDocumentType("")
      setFileName("")
    }
  }, [showModal])

  const onDocumentTypeChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: React.ReactNode
  ): void => {
    setSelectedDocumentType(e.target.value as string)
  }

  const onFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value)
  }

  return (
    <Modal showModal={showModal} onClose={onClose} paperClassName={styles.modalPaper}>
      <Box className={styles.headerRow}>
        <Box className={styles.headerTitle}>Please Choose</Box>
        <IconButton onClick={onClose}>
          <Close classes={{ root: styles.closeIcon }} />
        </IconButton>
      </Box>

      <Box className={styles.contentRow}>
        <InputLabel className={styles.txtSubHeader}>Select File and Document Type. Apply tags to help with sorting files.</InputLabel>

        <Box className={styles.inputGroup}>
          <InputLabel className={styles.inputLabel}>File name</InputLabel>
          <Input
            placeholder="Enter File Name"
            type="text"
            className={classNames(styles.inputControls, styles.txtName, styles.fileName)}
            onChange={onFileNameChange}
            value={fileName}
          />
          <InputLabel className={styles.tipText2}>Tip: Please name your file carefully</InputLabel>
        </Box>

        <Box className={styles.inputGroup}>
          <InputLabel className={styles.inputLabel}>File type</InputLabel>
          <Select
            displayEmpty
            className={classNames(styles.inputControls, styles.selectTemplate)}
            classes={{ 
              root: styles.selectMenu,
              icon: styles.selectMenuIcon
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null,
              classes: { paper: styles.selectMenuList }
            }}
            onChange={onDocumentTypeChange}
            value={selectedDocumentType}
          >
            {documentType.map((documentType, i) => (
              <MenuItem key={`documentType-${i}`} value={documentType}>
                {documentType}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      <Box className={styles.footerRow}>
				<Button 
					className={classNames(styles.actionButtons, styles.cancelButton, styles.secondaryButton)}
					onClick={onClose}>
					Cancel
				</Button>
				<Button 
					className={classNames(styles.actionButtons, styles.yesButton, styles.primaryButton)} 
					onClick={() => onSubmit(selectedDocumentType, fileName)}>
					Add
				</Button>
			</Box>
    </Modal>
  )
}
export default AddRequestModal
