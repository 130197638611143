import { Box, Button, IconButton, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import classNames from 'classnames'
import _ from "lodash"
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { delteTemplateById, deleteGeneralDocument, i_resultAPIs, deleteDocumentTypes } from '../../lib/services';
import Modal from '../Modal';
import styles from './AddConfirmDeleteModal.module.scss'

type AddConfirmDeleteModal = {
	showModal: boolean
	onClose: () => void,
	template?: any,
	deleteDocumentType?: string,
	deleteDocumentItem?: any,
	setDeleteDocumentItem?: Function,
	setTemplate?: Function,
	setDeleteDocumentType?: Function,
	mode: string
	setIsResetAdminTemplate?: Function,
	setIsOpenAlert?: Function,
	setAlertContent?: Function
}

const ConfirmDeleteModal: React.FC<AddConfirmDeleteModal> = props => {

	const { showModal, onClose, template, deleteDocumentType, deleteDocumentItem, setDeleteDocumentItem, setTemplate, setDeleteDocumentType, mode, setIsResetAdminTemplate, setIsOpenAlert, setAlertContent } = props

	const onCloseModal = () => {
		if (onClose) onClose();
		if (setDeleteDocumentType) setDeleteDocumentType("")
		if (setDeleteDocumentItem) setDeleteDocumentItem("")
		if (mode === "delete template") {
			if (setTemplate) setTemplate("")
		}
	}

	const deleteTemplate = (id: string) => {
		delteTemplateById(id).then(result => {
			if (result.status === 204) {
				onCloseModal();
			}
		})
	}
	const removeDocumentType = (documentType: string) => {
		deleteDocumentTypes(documentType).then((result: i_resultAPIs) => {
			if (result.status === "success") {
				onCloseModal();
			}

			if (result?.message) {
				if (setIsOpenAlert) setIsOpenAlert(true);
				if (setAlertContent) setAlertContent(result.message);
			}
		})
	}
	const removeDocumentItem = (document: string) => {
		deleteGeneralDocument(document).then(result => {
			if (result.status === 204) {
				onCloseModal();
			}
		})
	}
	const handleSubmit = () => {
		if (mode === "delete template") {
			if (template) deleteTemplate(template._id)
			if (setIsResetAdminTemplate) setIsResetAdminTemplate(true)
		} else if (mode === "delete document type") {
			if (deleteDocumentType) removeDocumentType(deleteDocumentType)
			if (setIsResetAdminTemplate) setIsResetAdminTemplate(true)
		}
		else if (mode === "delete documentitem") {
			if (deleteDocumentItem) removeDocumentItem(deleteDocumentItem)
			if (setIsResetAdminTemplate) setIsResetAdminTemplate(true)
		}

	}
	const [deletePopupHeading, setDeletePopupHeading] = useState("")
	useEffect(() => {
		switch (mode.trim().toLocaleLowerCase()) {
			case 'delete template': {
				setDeletePopupHeading("Delete Template")
				break;
			}
			case 'delete documentitem': {
				setDeletePopupHeading("Delete Document Item")
				break;
			}
			case 'delete document type': {
				setDeletePopupHeading("Delete Document Type")
				break;
			}
			default:
				setDeletePopupHeading("Delete Document")
		}
	}, [mode])
	return (
		<Modal showModal={showModal} paperClassName={styles.modalPapertext} onClose={(_, reason) => {
			if (reason === "backdropClick") {
				onClose();
			}
		}}>
			<Box className={styles.headerRow}>
				<Box className={styles.headerTitle}>{deletePopupHeading}</Box>
				<IconButton onClick={onCloseModal}>
					<Close classes={{ root: styles.closeIcon }} />
				</IconButton>
			</Box>

			{deleteDocumentType ? (
				<Box className={styles.contentRow}>
					<Box>Are you sure you want to delete the document item category</Box>
					<Box >{deleteDocumentType} ?</Box>
				</Box>
			) :
				deleteDocumentItem ? (
					<Box className={styles.contentRow} >
						<Box>Are you sure you want to delete this item</Box>
						<Box>{deleteDocumentItem?.label} ?</Box>
					</Box>
				) : (
					<Box className={styles.contentRow}>
						<Box>Are you sure you want to delete the template</Box>
						<Box>{template?.name} ?</Box>
					</Box>
				)
			}


			<Box className={styles.footerRow}>
				<Button
					className={classNames(styles.actionButtons, styles.cancelButton)}
					onClick={onCloseModal}>
					Cancel
				</Button>
				<Button
					className={classNames(styles.actionButtons, styles.submitButton)}
					onClick={handleSubmit}>
					Yes
				</Button>
			</Box>
		</Modal >
	)
}

export default ConfirmDeleteModal