import React, { Component } from "react"
import DatePicker from "react-datepicker"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons"
import produce from "immer"
import Cookies from "js-cookie"
import "react-datepicker/dist/react-datepicker.css"

import "./SettlementForm.css"
import {
  financeProviders,
  primaryPurposes,
  repaymentTypes,
  loanType,
  expenseFrequencies,
  bankAccountTypes,
} from "../../lib/constants"
import { getOwnershipFromBorrowers } from "../../lib/utils"

// Starting Data for the  Borrowings
const iniitalState = {
  existingLoanID: null,
  accountNumber: "",
  baseLoan: 0,
  totalLoan: 0,
  capLMI: 0,
  initialRepaymentType: null,
  repaymentAccount: "",
  initialLoanType: "",
  interestOnlyTerm: 0,
  fixedTerm: 0,
  totalLoanTerm: 0,
  purpose: "",
  baseRate: 0,
  discountRate: 0,
  interestRate: 0,
  loanIO: 0,
  pniYears: 0,
  pni: 0,
  repaymentType: "",
  totalOffsetBenefit: 0,
  provider: "",
  offsetBenefitIO: 0,
  offsets: [],
  borrower: "",
  transactionType: null,
  loanId: null,

  startDate: null,
  repaymentFreq: "",
  paymentAccount: null,
  offset: null,
  firstPayment: null,
  // Assets
  bankAccounts: [],
  borrowers: [],

  // add new modal
  bankOwner: "",
  bankType: "",
  bankValue: null,
  bankInterestRate: null,
  bankInstitution: "",
  bankAccountNumber: "",
  bankOtherType: "",

  loanstructures: {},
  currentLoanStructureId: null,
}
const _fetch = (url, init) =>
  fetch(url, {
    ...init,
    referrer: window.location.href + process.env.REACT_APP_CLIENT_USER_ID,
    headers: {
      ...init.headers,
      Authorization: `Bearer ` + Cookies.get("token"),
    },
  })
class SettlementForm extends Component {
  state = {
    ...iniitalState,
    modalOpen: false,
    isNotification: false,
    notificationSuccess: false,
  }

  componentDidMount() {
    this.fetchClientPortalData()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.id !== prevProps.id ||
      this.props.propertyId !== prevProps.propertyId ||
      this.props.loanIndex !== prevProps.loanIndex
    ) {
      this.fetchClientPortalData()
    }
  }

  showNotification = ({ isSuccess }, callback) => {
    this.setState(
      { isNotification: true, notificationSuccess: isSuccess },
      () => {
        setTimeout(() => {
          this.setState({ isNotification: false }, callback)
        }, 1000)
      }
    )
  }

  fetchClientPortalData = () => {
    const data = JSON.stringify({
      clientId: this.props.id,
      propertyId: this.props.propertyId,
    })

    Promise.all([
      _fetch(
        `${this.props.baseUrl}/api/loanstructures/getLoanStructureForSettlement?data=${data}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      ).then(res => res.json()),
      _fetch(
        `${this.props.baseUrl}/api/client/${this.props.id}/loanSettlement`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      ).then(res => res.json()),
    ])
      .then(([loanStructureRes, loanSettlementRes]) => {
        const newLoanStructureState = this.getDerivedStateFromLoanStructureResponse(
          loanStructureRes
        )
        const newLoanSettlementState = this.getDerivedStateFromLoanSettlementResponse(
          loanSettlementRes
        )
        // This is order reliant, initialState's values will be overwritten by the newly derived state
        this.setState({
          ...iniitalState,
          ...newLoanStructureState,
          ...newLoanSettlementState,
        })
      })
      .catch(console.error)
  }

  getDerivedStateFromLoanStructureResponse = loanStructure => {
    if (!loanStructure.proposedLoan || !loanStructure.proposedLoan.loans) {
      return {}
    }

    const transactionType = loanStructure.proposedLoan.transactionType
    const offsets = []
    let loans = loanStructure.proposedLoan.loans
    let offset = null
    let loanId = null

    loans = loans[this.props.loanIndex]
    loanId = loans._id
    if (loans.hasOwnProperty("repaymentAccount")) {
      if (loans.repaymentAccount === "tba") {
        loans.repaymentAccount = ""
      }
      if (loans.repaymentAccount === null) {
        loans.repaymentAccount = ""
      }
    }

    if (loans.hasOwnProperty("offsets") && loans.offsets.length > 0) {
      loans.offsets.forEach(off => {
        offset = off.accountRef
        offsets.push({ offset })
      })
    }

    if (!offsets.length) {
      offsets.push({ offset: "" })
    }

    return {
      ...loans,
      transactionType,
      offset,
      offsets,
      loanstructures: loanStructure,
      currentLoanStructureId: loanStructure._id,
      loanId,
    }
  }

  getDerivedStateFromLoanSettlementResponse = loanSettlement => {
    const { assets, personalInfo } = loanSettlement
    let bankAccounts = []
    if (assets) {
      if (assets.hasOwnProperty("bankAccounts")) {
        bankAccounts = assets.bankAccounts
      }
    }
    const borrowers = []
    if (personalInfo) {
      let name
      let _id = ""
      if (personalInfo.hasOwnProperty("client1")) {
        name = `${personalInfo.client1.fName} ${personalInfo.client1.lName}`
        _id = personalInfo.client1._id

        borrowers.push({ name, _id })
      }

      if (personalInfo.hasOwnProperty("client2")) {
        name = `${personalInfo.client2.fName} ${personalInfo.client2.lName}`
        _id = personalInfo.client2._id

        borrowers.push({ name, _id })
        borrowers.push(
          { _id: "Joint", name: "Joint" },
          { _id: "Other", name: "Other" }
        )
      }
    }
    return { bankAccounts, borrowers }
  }

  handleOnChange = (e, state) => {
    const { value } = e.target
    this.setState({ [state]: value })
  }

  handleOnChangeDate = (e, state) => {
    this.setState({ [state]: e })
  }

  handleFormSubmit = () => {
    const {
      provider,
      borrower,
      borrowers,
      purpose,
      startDate,
      totalLoanTerm,
      accountNumber,
      baseLoan,
      capLMI,
      repaymentType,
      interestRate,
      discountRate,
      baseRate,
      repaymentFreq,
      repaymentAccount,
      loanIO,
      interestOnlyTerm,
      initialLoanType,
      fixedTerm,
      offsets,
      firstPayment,
      loanId,

      loanstructures,
      currentLoanStructureId,
    } = this.state

    if (
      !borrower ||
      !purpose ||
      !totalLoanTerm ||
      !repaymentType ||
      !initialLoanType ||
      !interestRate ||
      !startDate ||
      !firstPayment ||
      !loanIO
    ) {
      this.showNotification({ isSuccess: false })
      return
    }

    const offsetList = offsets.filter(offset => {
      return offset.offset !== ""
    })

    const submitData = {
      provider,
      borrower: getOwnershipFromBorrowers(borrower, borrowers),
      detailedPurpose: purpose,
      primaryPurpose: purpose,
      startDate: moment
        .utc(startDate, ["DD/MM/YYYY"])
        .local()
        .format("YYYY-MM-DD"),
      expiryDate: moment.utc(
        moment
          .utc(startDate)
          .add(totalLoanTerm, "years")
          .calendar(),
        ["DD/MM/YYYY"]
      ),
      accountNumber,
      originalAmount: baseLoan,
      LMI: capLMI,
      LMIBool: capLMI ? true : false,
      repaymentType,
      type: initialLoanType,
      interestRate,
      discountRate,
      baseRate,
      repaymentFreq,
      repayment: loanIO,
      paymentAccount: repaymentAccount ? repaymentAccount : null,
      term: totalLoanTerm,
      fixedTerm,
      offsets: offsetList.length ? offsetList : [],
      isOffset: offsetList.length ? true : false,
      properties: [this.props.propertyId],
      securedAgainst: [this.props.propertyId],
      firstPayment: moment
        .utc(firstPayment, ["DD/MM/YYYY"])
        .local()
        .format("YYYY-MM-DD"),
      settledLoan: loanId,
      fixedRateEndDate: moment
        .utc(startDate, ["DD/MM/YYYY"])
        .local()
        .add("years", fixedTerm)
        .format("YYYY-MM-DD"),
      interestOnlyEndDate: moment
        .utc(startDate, ["DD/MM/YYYY"])
        .local()
        .add("years", interestOnlyTerm)
        .format("YYYY-MM-DD"),
    }
    _fetch(`${this.props.baseUrl}/api/client/loanSettlement/`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        clientId: this.props.id,
        borrowings: {
          borrowing: submitData,
        },
      }),
    })
      .then(response => {
        if (!response.ok) throw new Error(response.statusText)
        return response.json()
      })
      .then(responseJson => {
        if (loanstructures.hasOwnProperty("proposedLoan")) {
          const { proposedLoan } = loanstructures

          const { loans } = proposedLoan

          if (loans) {
            loans[this.props.loanIndex].isSettled = true

            _fetch(
              `${this.props.baseUrl}/api/loanstructures/saveLoanStructureAndSettle`,
              {
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  ...loanstructures,
                  currentLoanStructureId,
                  clientId: this.props.id,
                }),
              }
            ).then(res => {
              this.showNotification({ isSuccess: true }, () => {
                this.props.onSubmit({ save: true })
                this.props.onClose()
              })
            })
          }
        }
      })
      .catch(error => {
        this.showNotification({ isSuccess: false })
        console.error(error)
      })
  }

  handleModalOpen = () => {
    this.setState(state => ({ modalOpen: !state.modalOpen }))
  }

  handleSaveModal = () => {
    const {
      borrowers,
      bankOwner,
      bankType,
      bankValue,
      bankInterestRate,
      bankInstitution,
      bankAccountNumber,
      bankOtherType,
    } = this.state

    if (!bankOwner || !bankType) {
      this.showNotification({ isSuccess: false })
      return
    }

    const bankAccount = {
      ownership: getOwnershipFromBorrowers(bankOwner, borrowers),
      accountType: bankType,
      isPrimary: false,
      last4: bankAccountNumber,
      institution: bankInstitution,
      interestRate: bankInterestRate,
      balance: bankValue,
      otherType: bankOtherType,
    }

    _fetch(
      `${this.props.baseUrl}/api/client/${this.props.id}/upsertOtherAssetsSettlement/`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          bankAccount,
        }),
      }
    )
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.errors) throw new Error()

        _fetch(
          `${this.props.baseUrl}/api/client/${this.props.id}/loanSettlement`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        )
          .then(response => response.json())
          .then(data => {
            const { assets } = data
            let bankAccounts = []
            if (assets) {
              if (assets.hasOwnProperty("bankAccounts")) {
                bankAccounts = assets.bankAccounts
              }
            }
            this.setState({
              bankAccounts,
              modalOpen: false,
              bankOwner: "",
              bankType: "",
              bankValue: null,
              bankInterestRate: null,
              bankInstitution: "",
              bankAccountNumber: "",
              bankOtherType: "",
            })
            this.showNotification({ isSuccess: true })
          })
          .catch(error => this.setState({ error, isLoading: false }))
      })
      .catch(error => {
        this.showNotification({ isSuccess: false })
        console.error(error)
      })
  }

  handleOnChangeOffsets = (e, index) => {
    const { value } = e.target
    this.setState(
      produce(draftState => {
        draftState.offsets[index].offset = value
      })
    )
  }

  handleAddnewOffset = () => {
    this.setState(
      produce(draftState => {
        draftState.offsets.push({ offset: "" })
      })
    )
  }

  handleRemoveOffset = index => {
    this.setState(
      produce(draftState => {
        draftState.offsets.splice(index, 1)
      })
    )
  }

  render() {
    const { borrowers } = this.state
    return (
      <div>
        {this.props.windowOpen && (
          <div className="container-settlements">
            {/* Notitification */}
            {this.state.isNotification && (
              <div className="notification-container">
                {this.state.notificationSuccess ? (
                  <div className="notification success">
                    <span className="notification-font">
                      Successfully Save!
                    </span>
                  </div>
                ) : (
                  <div className="notification error">
                    <span className="notification-font">
                      Failed! Error while trying to save.
                    </span>
                  </div>
                )}
              </div>
            )}

            {this.state.modalOpen && (
              <div className="modal-box-assets">
                <div style={{ display: "flex" }}>
                  <h2 className="modal-title">Bank Accounts</h2>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="modal-box-container">
                    <div style={{ display: "flex" }}>
                      <label style={{ flex: 1 }}>
                        Owner<span className="asterisk">*</span>
                      </label>
                      <select
                        style={{ flex: 1 }}
                        className={
                          "bank-owner form-input " +
                          (this.state.bankOwner ? "" : "require-fields")
                        }
                        value={this.state.bankOwner}
                        onChange={e => this.handleOnChange(e, "bankOwner")}
                        required
                      >
                        <option readOnly>Owner</option>
                        {borrowers.map((borrower, index) => (
                          <option key={index} value={borrower._id}>
                            {borrower.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="modal-box-container">
                    <div style={{ display: "flex" }}>
                      <label style={{ flex: 1 }}>
                        Type<span className="asterisk">*</span>
                      </label>
                      <select
                        style={{ flex: 1 }}
                        className={
                          "bank-type form-input " +
                          (this.state.bankType ? "" : "require-fields")
                        }
                        value={this.state.bankType}
                        onChange={e => this.handleOnChange(e, "bankType")}
                      >
                        <option disabled value="">
                          Account Type
                        </option>
                        {bankAccountTypes.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                {this.state.bankType === "Other" && (
                  <div style={{ display: "flex" }}>
                    <div className="modal-box-container"></div>
                    <div className="modal-box-container">
                      <div style={{ display: "flex" }}>
                        <label style={{ flex: 1 }}>Other Type</label>
                        <input
                          type="text"
                          className="form-input"
                          style={{ flex: 1 }}
                          value={this.state.bankOtherType}
                          onChange={e =>
                            this.handleOnChange(e, "bankOtherType")
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div style={{ display: "flex" }}>
                  <div className="modal-box-container">
                    <div style={{ display: "flex" }}>
                      <label style={{ flex: 1 }}>
                        Value<span className="asterisk">*</span>
                      </label>
                      <input
                        type="text"
                        className="bank-value form-input"
                        style={{ flex: 1 }}
                        value={this.state.bankValue}
                        onChange={e => this.handleOnChange(e, "bankValue")}
                        required
                      />
                    </div>
                  </div>
                  <div className="modal-box-container">
                    {this.state.bankType !== "Offset" && (
                      <div style={{ display: "flex" }}>
                        <label style={{ flex: 1 }}>
                          Interest Rate<span className="asterisk">*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            "form-input " +
                            (this.state.bankInterestRate
                              ? ""
                              : "require-fields")
                          }
                          style={{ flex: 1 }}
                          value={this.state.bankInterestRate}
                          onChange={e =>
                            this.handleOnChange(e, "bankInterestRate")
                          }
                          required
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="modal-box-container">
                    <div style={{ display: "flex" }}>
                      <label style={{ flex: 1 }}>Institution</label>
                      <select
                        style={{ flex: 1 }}
                        className="bank-institution form-input"
                        value={this.state.bankInstitution}
                        onChange={e =>
                          this.handleOnChange(e, "bankInstitution")
                        }
                      >
                        <option disabled value="">
                          Institution
                        </option>
                        {financeProviders.map((provider, index) => (
                          <option key={index} value={provider}>
                            {provider}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="modal-box-container">
                    {this.state.bankType === "Offset" && (
                      <div style={{ display: "flex" }}>
                        <label style={{ flex: 1 }}>
                          Last 4 Digits of Account Number
                          <span className="asterisk">*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            "bank-last-4-digits form-input " +
                            (this.state.bankAccountNumber
                              ? ""
                              : "require-fields")
                          }
                          style={{ flex: 1 }}
                          value={this.state.bankAccountNumber}
                          onChange={e =>
                            this.handleOnChange(e, "bankAccountNumber")
                          }
                          required
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ textAlign: "center", padding: "20px" }}>
                  <button
                    type="button"
                    className="btn-confirm btn-cancel"
                    onClick={this.handleModalOpen}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn-confirm"
                    onClick={this.handleSaveModal}
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
            <div className="form-box">
              <div className="form">
                <div className="form-label-main">Provider:</div>
                <div className="input-container">
                  <select
                    className="form-input"
                    value={this.state.provider}
                    onChange={e => this.handleOnChange(e, "provider")}
                  >
                    <option disabled value="">
                      Select Loan Provider
                    </option>
                    {financeProviders.map((provider, index) => (
                      <option key={index} value={provider}>
                        {provider}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form">
                <div className="form-label-main">
                  Borrower/s:<span className="asterisk">*</span>
                </div>
                <div className="input-container">
                  <select
                    className={
                      "input-borrower form-input " +
                      (this.state.borrower ? "" : "require-fields")
                    }
                    value={this.state.borrower}
                    onChange={e => this.handleOnChange(e, "borrower")}
                    required
                  >
                    <option disabled value="">
                      Select Borrower
                    </option>
                    {borrowers.map((borrower, index) => (
                      <option key={index} value={borrower._id}>
                        {borrower.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form">
                <div className="form-label-main">
                  Primary purpose:<span className="asterisk">*</span>
                </div>
                <div className="input-container">
                  <select
                    className={
                      "input-primary-purpose form-input " +
                      (this.state.purpose ? "" : "require-fields")
                    }
                    value={this.state.purpose}
                    onChange={e => this.handleOnChange(e, "purpose")}
                    required
                  >
                    <option disabled value="">
                      Select Primary Purpose
                    </option>
                    {primaryPurposes.map((purpose, index) => (
                      <option key={index} value={purpose}>
                        {purpose}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-col-2">
                <div className="form">
                  <div className="form-label-main">
                    Loan Start Date:<span className="asterisk">*</span>
                  </div>
                  <div className="input-container">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={this.state.startDate}
                      className={
                        "input-loan-start-date form-input " +
                        (this.state.startDate ? "" : "require-fields")
                      }
                      onChange={e => this.handleOnChangeDate(e, "startDate")}
                    />
                  </div>
                </div>
                <div className="form">
                  <div className="form-label">
                    Loan Term:<span className="asterisk">*</span>
                  </div>
                  <div className="input-container">
                    <input
                      type="input"
                      className={
                        "total-loan-term form-input " +
                        (this.state.totalLoanTerm ? "" : "require-fields")
                      }
                      placeholder=""
                      value={this.state.totalLoanTerm}
                      onChange={e => this.handleOnChange(e, "totalLoanTerm")}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form">
                <div className="form-label-main">Loan Account Number:</div>
                <div className="input-container">
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Loan Account Number"
                    value={this.state.accountNumber}
                    onChange={e => this.handleOnChange(e, "accountNumber")}
                  />
                </div>
              </div>
              <div className="form-40">
                <div className="form-label-main">Original Amount Borrowed:</div>
                <div className="input-container">
                  <input
                    type="text"
                    className="form-input"
                    placeholder=""
                    value={this.state.baseLoan}
                    onChange={e => this.handleOnChange(e, "baseLoan")}
                  />
                </div>
              </div>
              <div className="form-40">
                <div className="form-label-main">(LMI)</div>
                <div className="input-container">
                  <input
                    type="text"
                    className="form-input"
                    placeholder=""
                    value={this.state.capLMI}
                    onChange={e => this.handleOnChange(e, "capLMI")}
                  />
                </div>
              </div>
              <div className="form-col-2">
                <div className="form">
                  <div className="form-label-main">
                    Repayment Type:<span className="asterisk">*</span>
                  </div>
                  <div className="input-container">
                    <select
                      className={
                        "input-repayment-type form-input " +
                        (this.state.repaymentType ? "" : "require-fields")
                      }
                      value={this.state.repaymentType}
                      onChange={e => this.handleOnChange(e, "repaymentType")}
                      required
                    >
                      <option disabled value="">
                        Select Repayment Type
                      </option>
                      {repaymentTypes.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {this.state.repaymentType === "Interest Only" && (
                  <div className="form">
                    <div className="form-label">
                      Term:<span className="asterisk">*</span>
                    </div>
                    <div className="input-container">
                      <input
                        type="text"
                        className={
                          "interest-only-term form-input " +
                          (this.state.interestOnlyTerm ? "" : "require-fields")
                        }
                        placeholder=""
                        value={this.state.interestOnlyTerm}
                        onChange={e =>
                          this.handleOnChange(e, "interestOnlyTerm")
                        }
                        required
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="form-col-2">
                <div className="form-50">
                  <div className="form-label-main">
                    Loan Type:<span className="asterisk">*</span>
                  </div>
                  <div className="input-container">
                    <select
                      className={
                        "input-loan-type form-input " +
                        (this.state.initialLoanType ? "" : "require-fields")
                      }
                      value={this.state.initialLoanType}
                      onChange={e => this.handleOnChange(e, "initialLoanType")}
                      required
                    >
                      <option disabled value="">
                        Select Loan Type
                      </option>
                      {loanType.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {(this.state.initialLoanType === "Home Loan - Fixed Rate" ||
                  this.state.initialLoanType ===
                    "Investment Loan - Fixed Rate") && (
                  <div className="form">
                    <div className="form-label">
                      Term:<span className="asterisk">*</span>
                    </div>
                    <div className="input-container">
                      <input
                        type="text"
                        className={
                          "fixed-term form-input " +
                          (this.state.fixedTerm ? "" : "require-fields")
                        }
                        placeholder=""
                        value={this.state.fixedTerm}
                        onChange={e => this.handleOnChange(e, "fixedTerm")}
                        required
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="form-col-3">
                <div className="form-first">
                  <div className="form-label-main">
                    Interest Rate:<span className="asterisk">*</span>
                  </div>
                  <div className="input-container">
                    <input
                      type="text"
                      className={
                        "input-interest-rate form-input " +
                        (this.state.interestRate ? "" : "require-fields")
                      }
                      placeholder="%"
                      value={this.state.interestRate}
                      onChange={e => this.handleOnChange(e, "interestRate")}
                      required
                    />
                  </div>
                </div>
                <div className="form">
                  <div className="form-label">Discount:</div>
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-input"
                      placeholder="%"
                      value={this.state.discountRate}
                      onChange={e => this.handleOnChange(e, "discountRate")}
                    />
                  </div>
                </div>
                <div className="form">
                  <div className="form-label">Base:</div>
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-input"
                      placeholder="%"
                      value={this.state.baseRate}
                      onChange={e => this.handleOnChange(e, "baseRate")}
                    />
                  </div>
                </div>
              </div>
              <div className="form-col-2">
                <div className="form">
                  <div className="form-label-main">
                    1st repayment: <span className="asterisk">*</span>
                  </div>
                  <div className="input-container">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={this.state.firstPayment}
                      className={
                        "input-first-payment form-input " +
                        (this.state.firstPayment ? "" : "require-fields")
                      }
                      onChange={e => this.handleOnChangeDate(e, "firstPayment")}
                    />
                  </div>
                </div>
                <div className="form">
                  <div className="form-label">Repayment Frequency:</div>
                  <div className="input-container">
                    <select
                      className="form-input"
                      value={this.state.repaymentFreq}
                      onChange={e => this.handleOnChange(e, "repaymentFreq")}
                    >
                      <option disabled value="">
                        Select Repayment Frequency
                      </option>
                      {expenseFrequencies.map((frequency, index) => (
                        <option key={index} value={frequency}>
                          {frequency}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-40">
                <div className="form-label-main">
                  Current Repayments:<span className="asterisk">*</span>
                </div>
                <div className="input-container">
                  <input
                    type="text"
                    className={
                      "input-current-repayment form-input " +
                      (this.state.loanIO ? "" : "require-fields")
                    }
                    placeholder=""
                    value={this.state.loanIO}
                    onChange={e => this.handleOnChange(e, "loanIO")}
                    required
                  />
                </div>
              </div>

              <div className="form-70">
                <div className="form-label-main">Payment Account</div>
                <div className="input-container">
                  <select
                    className="form-input payment-account"
                    value={this.state.repaymentAccount}
                    onChange={e => this.handleOnChange(e, "repaymentAccount")}
                  >
                    <option disabled value="">
                      Select Payment Accounts
                    </option>
                    {this.state.bankAccounts.map((accounts, index) => {
                      if (accounts.accountType === "Offset") {
                        return (
                          <option key={index} value={accounts._id}>
                            {accounts.institution} {accounts.accountType} ({" "}
                            {accounts.last4} )
                          </option>
                        )
                      } else {
                        return (
                          <option key={index} value={accounts._id}>
                            {accounts.institution} {accounts.accountType}
                          </option>
                        )
                      }
                    })}
                  </select>
                </div>
              </div>
              {this.state.offsets &&
                this.state.offsets.length > 0 &&
                this.state.offsets.map((offset, index) => {
                  return (
                    <div key={index} className="form-70">
                      <div className="form-label-main">Offset Account</div>
                      <div className="input-container">
                        <select
                          className="form-input offset-account"
                          value={offset.offset}
                          onChange={e => this.handleOnChangeOffsets(e, index)}
                        >
                          <option value="">Select Offset Accounts</option>
                          {this.state.bankAccounts
                            .filter(
                              accounts => accounts.accountType === "Offset"
                            )
                            .map((accounts, key) => (
                              <option key={key} value={accounts._id}>
                                {accounts.institution} {accounts.last4}
                              </option>
                            ))}
                        </select>
                      </div>
                      {this.state.offsets.length - 1 === index && (
                        <button
                          type="button"
                          className="font-icon-btn"
                          onClick={this.handleAddnewOffset}
                        >
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            className="offset-add-button"
                          />
                        </button>
                      )}
                      {this.state.offsets.length > 1 && (
                        <button
                          type="button"
                          className="font-icon-btn"
                          onClick={() => this.handleRemoveOffset(index)}
                        >
                          <FontAwesomeIcon
                            icon={faMinusCircle}
                            className="offset-remove-button"
                          />
                        </button>
                      )}
                    </div>
                  )
                })}

              <div className="button-container">
                <button
                  type="button"
                  className="modal-account-open-link"
                  onClick={this.handleModalOpen}
                >
                  Add New Account
                </button>
                <button
                  type="button"
                  className="btn-confirm btn-cancel"
                  onClick={() => this.props.onClose()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn-confirm"
                  onClick={this.handleFormSubmit}
                >
                  Settle and Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default SettlementForm
