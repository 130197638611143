/**
 * Starts with `"?"` if has entries.
 */
export const toUrlQueryString = (params: Record<string, string>) => {
  const entries = Object.entries(params);

  return entries.length ? `?${toQueryString(entries)}` : "";
};

/**
 * Convert `Object` entries into query string. This does not start with
 * `"?"`. Use `toUrlQueryString()` instead.
 */
export const toQueryString = (entries: [string, string][]) => {
  return encodeURI(entries.map((entry) => entry.join("=")).join("&"));
};
