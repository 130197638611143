const action_messages = {
  INFECTED: "INFECTED",
  INFECTED_MESSAGE:
    "We have detected that this file is infected with a virus. For your safety we have prevented it from downloading. Please contact Empower Wealth with the details of the file so we can take further action.",
  GENERAL_ERROR: "GENERAL_ERROR",
  GENERAL_ERROR_MESSAGE:
    "A general error has occurred with the file download. Please contact Empower Wealth with the details of the file so we can take further action.",
  SUCCESS: "SUCCESS",
  SUCCESS_MESSAGE: "Please wait for your files to be downloaded.",
  NONE_SELECTED_MESSAGE: "Please select documents to download first.",
  DOWNLOAD_ERROR: "DOWNLOAD_ERROR",
  DOWNLOAD_ERROR_MESSAGE:
    "There was a problem downloading your files. Please contact Empower Wealth with the details of the file so we can take further action.",
};

export default action_messages;

export const topNavHeight = 52;

export const financeProviders = [
  "ANZ",
  "CBA",
  "NAB",
  "Westpac",
  "Australian Military Bank",
  "Adelaide Bank",
  "Advantedge",
  "AFG Home Loans EDGE",
  "Australian First Mortgage",
  "Australian Government",
  "AIMS",
  "Allianz",
  "AMEX",
  "Ample Capital Australia",
  "AMP",
  "AMS",
  "Anchorage",
  "ANZ Asset Finance",
  "API Home Loans",
  "Aussie Home Loans",
  "Australian Financial",
  "Australian Lending Services",
  "Australian Wholesale Lending",
  "Auswide Bank",
  "AXA",
  "Bank Australia",
  "Bank of Melbourne",
  "Bank of Queensland",
  "BankSA",
  "Bank of Sydney",
  "Banksia",
  "Bankwest",
  "Bendigo And Adelaide Bank",
  "Beyond Bank",
  "Berrima District Credit Union",
  "Better Mortgage Management",
  "Bluebay Home Loans",
  "Bluegum Home Loans",
  "Bluestone",
  "Bluestone Equity",
  "BMC",
  "BMCAdvantedge",
  "Illawarra Credit Union",
  "Challenge Bank",
  "Choice Lend",
  "Citibank",
  "Collins Securities",
  "Community CPS Australia",
  "Community Mutual Ltd",
  "Connective Home Loans Essentials",
  "Credit Union Home Loans",
  "Credit Union Australia",
  "Credit Union SA",
  "Def Credit",
  "Diners Card",
  "Direct Mortgage Solutions",
  "Director Of Housing VIC",
  "Domain",
  "Economy Home Loans",
  "eMoney",
  "Ezy Mortgage",
  "Fifty Group",
  "Finance Express Home Loans",
  "FirstFolio",
  "FirstMac",
  "Fox Symes Home Loans",
  "Gateway Credit Union",
  "Great Southern Loans",
  "Greater Building Society",
  "Heritage Bank",
  "RESIMAC Financial Services",
  "Homeloans Ltd",
  "Homeloan Centre Australia",
  "Home Path",
  "NAB Broker",
  "HomeStart Finance",
  "HSBC",
  "Iden Group",
  "ING Direct",
  "Keystart",
  "La Trobe",
  "LJ Hooker Home Loans Connect",
  "Loan Ave",
  "Loan Market Go",
  "Loan Plan",
  "Liberty Financial",
  "LJ Hooker",
  "Macquarie Bank",
  "MCCA",
  "ME Bank",
  "Mortgage Ezy",
  "Mortgage House",
  "Macquarie Securitisation Ltd",
  "MyState",
  "National Australia Bank",
  "Napiers",
  "Nationalcorp",
  "NCF Financial Services Pty Ltd",
  "Newcastle Permanent",
  "Folio Mortgage & Finance",
  "NMC",
  "NMMC",
  "OFG",
  "Paladin",
  "People's Choice Credit Union",
  "Pepper Money",
  "P&N Bank",
  "Power Home Loans",
  "Qantas Staff Credit Union",
  "Queensland Country Credit Union",
  "Qudos Bank",
  "QBANK",
  "Queenslanders Credit Union",
  "RAMS",
  "Resi",
  "Resimac",
  "RHG",
  "Sam Loans",
  "Sandhurst Trustees",
  "Smartline",
  "SGE Credit Union",
  "St. George",
  "Statewide Home Loans",
  "Suncorp Bank",
  "Sydney Home Loans",
  "Teachers Mutual Bank",
  "The Rock",
  "Think Tank",
  "Victoria Teachers Mutual Bank",
  "Virgin Money",
  "Wide Bay Australia",
  "Westpac Business",
  "Wizard Home Loans",
  "World Home Loans",
  "Yellow Brick Road",
  "Yes Home Loans",
  "Other",
];

export const primaryPurposes = [
  "Buy Real Estate",
  "Refinance Real Estate",
  "Debt Consolidation",
  "General Spending",
  "Other Purchases/Items",
];

export const repaymentTypes = ["Principal and Interest", "Interest Only"];

export const loanType = [
  "Home Loan - Basic Variable",
  "Home Loan - Standard Variable",
  "Home Loan - Fixed Rate",
  "Home Loan - Line of Credit (Personal Use)",
  "Investment Loan - Basic Variable",
  "Investment Loan - Standard Variable",
  "Investment Loan - Fixed Rate",
  "Investment Loan - Line of Credit (Investment Use)",
  "Credit Card",
  "Car Loan",
  "Personal Loan",
  "ATO or Centrelink Debt",
  "HECS/HELP Debt",
  "Hire Purchase",
  "Lease",
  "Overdraft",
  "Store Card",
  "Commercial Bill",
  "Charge Card",
  "Other",
];

export const expenseFrequencies = [
  "Weekly",
  "Fortnightly",
  "Monthly",
  "Bi-Monthly",
  "Quarterly",
  "Yearly",
];

export const bankAccountTypes = [
  "Cheque",
  "Savings",
  "High Interest/Term Deposit",
  "Offset",
  "Other",
];

export const adminTemplateConstants = {
  DEFAULT_NAME: "New Template"
}

export const adminTemplateActionMessages = {
  TEMPLATE_NAME_WARNING: "Please type another template name",
  SAVE_SUCCESS_MESSAGE: "Save the template successfully",
  SAVE_ERROR_MESSAGE: "There was a problem saving your template. Please contact Empower Wealth for further action.",
}

export const userRoles: string[] = ["super_admin", "pwp_admin", "fp_admin", "user", "mb_admin", "admin"];

export enum ModalModes {
  EDIT = "DOC_ITEM_EDIT",
}

export const reminderTemplates = ["emailTemplate1", "emailTemplate2", "emailTemplate3", "emailTemplate4"]

export const reminderEmailStatusesGroup = {
  SEND_GROUP: {
    Name: "Sent",
    Color: "#828282",
    Statuses: ["send","draft","ready","pending","deliverydelay"]
  },
  OPEN_GROUP: {
    Name: "Opened",
    Color: "#00A611",
    Statuses: ["open","click"]
  },
  BOUNCE_GROUP: {
    Name: "Bounced",
    Color: "#EB5757",
    Statuses: ["bounce","rendering failure"]
  },
  RECEIVE_GROUP: {
    Name: "Received",
    Color: "#1082FE",
    Statuses: ["delivery"]
  },
  COMPLETE_GROUP: {
    Name: "Completed",
    Color: "#00A611",
    Statuses: ["completed"]
  }
}