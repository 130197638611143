import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import QuickModal, {
  QuickModalProps,
} from "../../components/modals/QuickModal";
import Input from "../../components/Form/Input";
import {
  CodeCreated,
  CodeHasPending,
  postLendingSurvey,
  POSTLendingSurveyError,
} from "../../lib/lendingSurveysService";
import { useLendingSurveys } from "./surveys.query";

export const modalTitle = "Request New Requirements & Objectives";

const useStyles = makeStyles({
  main: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    marginRight: "1em",
    alignSelf: "center",
  },
  fg2: {
    flexGrow: 2,
  },
  addModal: {
    display: "flex",
    padding: "2em 1em",
  },
});

export type FormValues = {
  name: string;
};

export type AddLendingSurveyModalProps = {
  isVisible: boolean;
  userId: string;
  onClose?: () => void;
};

const AddLendingSurveyModal = ({
  isVisible,
  userId,
  onClose,
}: AddLendingSurveyModalProps) => {
  const styles = useStyles();

  const { refetch } = useLendingSurveys(
    {
      clientId: userId,
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const cancelTokenSource = React.useMemo(() => Axios.CancelToken.source(), []);

  const [isCreated, setIsCreated] = React.useState(false);

  const [hasPending, setHasPending] = React.useState(false);

  const [hasError, setHasError] = React.useState(false);

  const { register, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      name: "",
    },
  });

  const handleOnSubmit = React.useCallback(
    (data: FormValues) => {
      postLendingSurvey(data.name, userId, {
        cancelToken: cancelTokenSource.token,
      })
        .then((res) => {
          if (res.data.code === CodeCreated) {
            refetch();
            setIsCreated(true);

            if (onClose) onClose();
          } else {
            setHasError(true);
          }
        })
        .catch((error: POSTLendingSurveyError) => {
          if (!(error.isAxiosError && error.response?.data.code)) {
            setHasError(true);
            return;
          }

          switch (error.response?.data.code) {
            case CodeHasPending:
              setHasPending(true);

              break;

            default:
              setHasError(true);
          }
        });
    },
    [cancelTokenSource.token, onClose, refetch, userId]
  );

  const actions: QuickModalProps["actions"] = React.useMemo(
    () => [
      {
        name: "Submit",
        onClick: handleSubmit(handleOnSubmit),
      },
      {
        name: "Cancel",
        onClick: onClose,
      },
    ],
    [handleOnSubmit, handleSubmit, onClose]
  );

  const handleOnCloseIsCreatedModal = React.useCallback(() => {
    setIsCreated(false);
  }, []);

  const handleOnClosePendingModal = React.useCallback(() => {
    setHasPending(false);
  }, []);

  const handleOnCloseErrorModal = React.useCallback(() => {
    setHasError(false);
  }, []);

  React.useEffect(() => {
    return () => {
      cancelTokenSource.cancel();
    };
  }, [cancelTokenSource]);

  React.useEffect(() => {
    reset();
  }, [isVisible, reset]);

  return (
    <>
      <QuickModal
        isVisible={isVisible}
        onClose={onClose}
        title={modalTitle}
        actions={actions}
        className={styles.addModal}
      >
        <div className={styles.main}>
          <Typography variant="body1">Name</Typography>
        </div>

        <div className={styles.fg2}>
          <Input
            placeholder="Type request name here..."
            {...register("name", { required: true })}
          />
        </div>
      </QuickModal>

      <QuickModal
        isVisible={isCreated}
        onClose={handleOnCloseIsCreatedModal}
        title={modalTitle}
      >
        Requested successfully.
      </QuickModal>

      <QuickModal
        isVisible={hasPending}
        onClose={handleOnClosePendingModal}
        title={modalTitle}
      >
        The client still has pending request.
      </QuickModal>

      <QuickModal
        isVisible={hasError}
        onClose={handleOnCloseErrorModal}
        title={modalTitle}
      >
        We encountered an error. Please, try again later.
      </QuickModal>
    </>
  );
};

export default AddLendingSurveyModal;
