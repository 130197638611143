import React, { useState, useContext } from "react"
import ClientChangesContext from "../../../../../../components/Context/ClientChangesContext"
import ShowChangesTable from "../ShowChangesTable"
import { formatCurrency } from "../../../../../../lib/utils"
import _ from "lodash"

const UnsecuredLiabilitiesUpdates = () => {
  const { unsecuredUpdates, ACStates, setACStates } = useContext(
    ClientChangesContext
  )

  const indexOnBalance: boolean[] = []
  const indexOnOwner: boolean[] = []
  const indexOnPeriod: boolean[] = []
  const indexOnRepayment: boolean[] = []
  const indexOnType: boolean[] = []

  unsecuredUpdates &&
    unsecuredUpdates.typeChanges.forEach((asset: any) =>
      indexOnType.push(false)
    )

  unsecuredUpdates &&
    unsecuredUpdates.ownerChanges.forEach((asset: any) =>
      indexOnOwner.push(false)
    )

  unsecuredUpdates &&
    unsecuredUpdates.periodChanges.forEach((asset: any) =>
      indexOnPeriod.push(false)
    )

  unsecuredUpdates &&
    unsecuredUpdates.repaymentChanges.forEach((asset: any) =>
      indexOnRepayment.push(false)
    )

  unsecuredUpdates &&
    unsecuredUpdates.balanceChanges.forEach((asset: any) =>
      indexOnBalance.push(false)
    )

  const [switchIndexBalance, setSwitchIndexBalance] = useState(indexOnBalance)
  const [switchIndexType, setSwitchIndexType] = useState(indexOnType)
  const [switchIndexOwner, setSwitchIndexOwner] = useState(indexOnOwner)
  const [switchIndexPeriod, setSwitchIndexPeriod] = useState(indexOnPeriod)
  const [switchIndexRepayment, setSwitchIndexRepayment] = useState(
    indexOnRepayment
  )

  const changePropertyHandler = (
    objProp: string,
    currValue: any,
    prevValue: any,
    summaryId: string,
    newSwitch: boolean[],
    index: number
  ) => {
    return ACStates?.unsecuredLiabilities.map((liability: any) => {
      if (liability.summaryId === summaryId) {
        if (newSwitch !== undefined && newSwitch[index]) {
          if (objProp === "owner") {
            _.mapValues(liability[objProp], function(o) {
              if (o.fullName === currValue) {
                o.selected = true
              } else if (currValue == "Both") {
                if (_.has(o, "selected")) o.selected = true
              } else if (currValue == "Other") {
                if (_.has(o, "selected")) o.selected = false
              } else {
                if (_.has(o, "selected")) o.selected = false
              }
            })
          } else {
            liability[objProp] = currValue
          }
        } else {
          liability[objProp] = prevValue
        }
      }
      return liability
    })
  }

  const unsecuredLiabilitiesHandler = (
    summaryId: string,
    index: number,
    currValue: any,
    prevValue: any,
    toChange: string
  ) => {
    let newSwitch: boolean[]
    let changeValue
    switch (toChange) {
      case "type":
        newSwitch = switchIndexType && [...switchIndexType]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexType(newSwitch)
        break
      case "balance":
        newSwitch = switchIndexBalance && [...switchIndexBalance]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexBalance(newSwitch)
        break
      case "owner":
        newSwitch = switchIndexOwner && [...switchIndexOwner]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexOwner(newSwitch)
        break
      case "period":
        newSwitch = switchIndexPeriod && [...switchIndexPeriod]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexPeriod(newSwitch)
        break
      case "repayment":
        newSwitch = switchIndexRepayment && [...switchIndexRepayment]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexRepayment(newSwitch)
        break
      default:
        newSwitch = []
    }

    if (ACStates !== undefined) {
      setACStates &&
        setACStates({ ...ACStates, unsecuredLiabilities: changeValue })
    }
  }

  return (
    <>
      {/* start type changes */}
      {unsecuredUpdates?.typeChanges.length !== 0 ? (
        unsecuredUpdates?.typeChanges.map(
          (
            liabilities: {
              prevValType: string
              currValType: string
              summaryId: string
            },
            index: number
          ) => {
            const { prevValType, currValType, summaryId } = liabilities
            return (
              <ShowChangesTable
                prevVal={prevValType}
                currVal={currValType}
                index={index}
                switchIndex={switchIndexType}
                updatesCaller={() => {
                  unsecuredLiabilitiesHandler(
                    summaryId,
                    index,
                    currValType,
                    prevValType,
                    "type"
                  )
                }}
                type="unsecuredType"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end type changes */}
      {/* start balance changes */}
      {unsecuredUpdates?.balanceChanges.length !== 0 ? (
        unsecuredUpdates?.balanceChanges.map(
          (
            liabilities: {
              currValBalance: number
              prevValBalance: number
              summaryId: string
            },
            index: number
          ) => {
            const { prevValBalance, currValBalance, summaryId } = liabilities
            return (
              <ShowChangesTable
                prevVal={prevValBalance}
                currVal={currValBalance}
                index={index}
                switchIndex={switchIndexBalance}
                updatesCaller={() => {
                  unsecuredLiabilitiesHandler(
                    summaryId,
                    index,
                    currValBalance,
                    prevValBalance,
                    "balance"
                  )
                }}
                type="unsecuredBalance"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end balance changes */}
      {/* start period changes */}
      {unsecuredUpdates?.periodChanges.length !== 0 ? (
        unsecuredUpdates?.periodChanges.map(
          (
            liabilities: {
              prevPeriod: string
              currPeriod: string
              summaryId: string
            },
            index: number
          ) => {
            const { prevPeriod, currPeriod, summaryId } = liabilities
            return (
              <ShowChangesTable
                prevVal={prevPeriod}
                currVal={currPeriod}
                index={index}
                switchIndex={switchIndexPeriod}
                updatesCaller={() => {
                  unsecuredLiabilitiesHandler(
                    summaryId,
                    index,
                    currPeriod,
                    prevPeriod,
                    "period"
                  )
                }}
                type="unsecuredPeriod"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end period changes */}
      {/* start repayment changes */}
      {unsecuredUpdates?.repaymentChanges.length !== 0 ? (
        unsecuredUpdates?.repaymentChanges.map(
          (
            liabilities: {
              currRepayment: number
              prevRepayment: number
              summaryId: string
            },
            index: number
          ) => {
            const { prevRepayment, currRepayment, summaryId } = liabilities
            return (
              <ShowChangesTable
                prevVal={prevRepayment}
                currVal={currRepayment}
                index={index}
                switchIndex={switchIndexRepayment}
                updatesCaller={() => {
                  unsecuredLiabilitiesHandler(
                    summaryId,
                    index,
                    currRepayment,
                    prevRepayment,
                    "repayment"
                  )
                }}
                type="unsecuredRepayment"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end repayment changes */}
      {/* start owner changes */}
      {unsecuredUpdates?.ownerChanges.length !== 0 ? (
        unsecuredUpdates?.ownerChanges.map(
          (
            liabilities: {
              currValOwner: string
              prevValOwner: string
              summaryId: string
            },
            index: number
          ) => {
            const { prevValOwner, currValOwner, summaryId } = liabilities
            return (
              <ShowChangesTable
                prevVal={prevValOwner}
                currVal={currValOwner}
                index={index}
                switchIndex={switchIndexOwner}
                updatesCaller={() => {
                  unsecuredLiabilitiesHandler(
                    summaryId,
                    index,
                    currValOwner,
                    prevValOwner,
                    "owner"
                  )
                }}
                type="unsecuredOwner"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end owner changes */}
    </>
  )
}

export default UnsecuredLiabilitiesUpdates
