import { i_Assessment } from "../../../../components/AssessmentItem/Assessment.interface"
import AssessmentItem from "../../../../components/AssessmentItem/AssessmentItem"
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined"
import IconButton from "@material-ui/core/IconButton"
import React, { useState, useEffect, useContext } from "react"
import { _fetch } from "../../../../lib/utils"
import Button from "@material-ui/core/Button"
import { FadeLoader } from "react-spinners"
import styles from "./styles.scss"
import _ from "lodash"
import VersionAssessmentContext from "../../../../components/Context/VersionAssessmentContext"

const VersionControl = (props: any) => {
  const [VCStates, setVCStates] = useState<i_VCStates | undefined>(undefined)
  const [showArchive, setShowArchive] = useState<any>(false)
  const [isLoading, setIsLoading] = useState(false)
  const { setIsCalcCompleted } = useContext(VersionAssessmentContext)

  const getVCLists = async () => {
    setIsLoading(true)
    let Assessments: any = await _fetch(
      "client/getVersionControl/" + props.uid,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
    Assessments = await Assessments.json()
    return new Promise(function(resolve, reject) {
      if (Assessments.found) {
        setVCStates({
          owner: Assessments.data.owner,
          Assessments: Assessments.data.versionControls,
        })
      }
      setTimeout(function() {
        setIsLoading(false)
      }, 500)
      resolve(true)
    })
  }
  const addVC = async (args: any) => {
    setIsLoading(true)
    await getVCLists()
    setTimeout(function() {
      setIsLoading(false)
    }, 500)
  }
  const archiveVC = async (args: any, isArchive: boolean = true) => {
    await _fetch(
      isArchive
        ? "client/archiveVersionControl"
        : "client/unarchiveVersionControl",
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(args),
      }
    )

    getVCLists()
  }

  useEffect(() => {
    getVCLists()
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = (args: i_clickables) => {
    switch (args.type) {
      case "addIcon":
        props.goto("AssessmentCalculator")
        props.navigatePage("AssessmentCalculator")
        break
      case "edit":
        props.goto("AssessmentCalculator", args.id)
        props.navigatePage("AssessmentCalculator")
        setIsCalcCompleted && setIsCalcCompleted(args.status)
        break
      case "duplicate": {
        const tmpVCStates = _.cloneDeep(VCStates)
        const newAssessment = _.clone(
          tmpVCStates?.Assessments.find(a => a._id === args.id)
        )
        addVC({
          ...newAssessment,
          clientId: props.uid,
          status: "In Progress",
          title: [newAssessment?.title, "DUPLICATE"].join(" - "),
          isDuplicate: true,
        })
        break
      }
      case "archive":
        archiveVC({
          clientId: props.uid,
          versionControlId: args.id,
        })
        break
      case "unarchive":
        archiveVC(
          {
            clientId: props.uid,
            versionControlId: args.id,
          },
          false
        )
        break
    }
  }
  return (
    <div className={styles.versionControlContainer}>
      {isLoading && (
        <div className={[styles.spinner, "loading-spinner"].join(" ")}>
          <FadeLoader
            height={20}
            width={5}
            radius={10}
            margin={10}
            color={"#999"}
            loading={isLoading}
          />
        </div>
      )}
      <div className={styles.titleContainer}>
        <h2>Assessment Work Bench</h2>

        <IconButton onClick={() => handleClick({ type: "addIcon" })}>
          <AddCircleOutlinedIcon className={styles.addIcon} />
        </IconButton>
      </div>
      <div className={styles.buttonGroup}>
        <Button
          onClick={() => setShowArchive(true)}
          variant={showArchive ? "contained" : "text"}
        >
          Archived
        </Button>
        <Button
          onClick={() => setShowArchive(false)}
          variant={!showArchive ? "contained" : "text"}
        >
          Active
        </Button>
      </div>
      {(!VCStates?.Assessments ||
        VCStates?.Assessments.filter((i: any) => i.isArchive === showArchive)
          .length === 0) && (
        <p>
          There are no current{" "}
          <strong>{showArchive ? "Archive" : "Active"}</strong> assessments for
          this client.{" "}
          {!showArchive && "Click the (+) button to start adding now."}
        </p>
      )}

      {VCStates?.Assessments.filter((i: any) => i.isArchive === showArchive)
        .sort((a, b) => (a.createdOn < b.createdOn ? 1 : -1))
        .map(item => (
          <AssessmentItem
            key={item._id}
            onEdit={() =>
              handleClick({ type: "edit", id: item._id, status: item.status })
            }
            onDuplicate={(id: any) => handleClick({ type: "duplicate", id })}
            onArchive={() => handleClick({ type: "archive", id: item._id })}
            onUnarchive={() => handleClick({ type: "unarchive", id: item._id })}
            {...item}
          />
        ))}
    </div>
  )
}
interface i_clickables {
  type: String
  id?: any
  status?: string
}
interface i_VCStates {
  owner: any
  Assessments: i_Assessment[]
}
export default VersionControl
