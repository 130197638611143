import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal as MaterialUIModal,
  ModalProps as MuiModalProps,
  Fade,
  Backdrop,
} from "@material-ui/core";

export interface ModalProps
  extends Omit<Omit<MuiModalProps, "children">, "open"> {
  showModal: boolean;
  paperClassName?: string;
  children: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Modal = ({
  showModal,
  children,
  paperClassName,
  ...props
}: ModalProps) => {
  const classes = useStyles();

  return (
    <MaterialUIModal
      className={classes.modal}
      open={showModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      {...props}
    >
      <Fade in={showModal}>
        <div className={`${classes.paper} ${paperClassName || ""}`}>
          {children}
        </div>
      </Fade>
    </MaterialUIModal>
  );
};

export default Modal;
