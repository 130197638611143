import React, { useContext, useState } from "react"
import ClientChangesContext from "../../../../../../components/Context/ClientChangesContext"
import FlexExpenseChangesHeader from "./FlexExpenseChangesHeader"
import FlexExpenseChangesRow from "./FlexExpenseChangesRow"

const FlexExpenseUpdatesEducation = () => {
  const { flexExpenseUpdates, ACStates, setACStates } = useContext(
    ClientChangesContext
  )

  const educationExpenseChanges = flexExpenseUpdates?.educationChanges
  const numberOfLoops = educationExpenseChanges?.educationChangesArr.length ?? 0
  const indexOnChanges: boolean[] = []
  for (let i = 0; i < numberOfLoops; i++) {
    indexOnChanges.push(false)
  }
  const [switchIndex, setSwitchIndex] = useState(indexOnChanges)

  const educationChangesHandler = (
    index: number,
    summaryId: string,
    prevValue: any,
    currValue: any
  ) => {
    let newSwitch = [...switchIndex]
    newSwitch[index] = !newSwitch[index]

    let changeValue = ACStates?.expenses.map((expense: any) => {
      if (expense.flexType === "Education") {
        expense.items.map((item: any) => {
          if (item.summaryId === summaryId) {
            if (newSwitch !== undefined && newSwitch[index]) {
              item.monthlyAmount = currValue
            } else {
              item.monthlyAmount = prevValue
            }
          }
        })
        const newTotalAmount = expense.items
          .map((item: { monthlyAmount: number }) => item.monthlyAmount)
          .reduce(
            (prevAmount: number, currAmount: number) => prevAmount + currAmount,
            0
          )
        expense.monthlyAmount = newTotalAmount
      }
      return expense
    })

    if (ACStates !== undefined) {
      setACStates && setACStates({ ...ACStates, expenses: changeValue })
    }

    setSwitchIndex(newSwitch)
  }
  return (
    <>
      {numberOfLoops !== 0 ? (
        <FlexExpenseChangesHeader
          prevMonthly={educationExpenseChanges?.prevMonthlyTotalAmount ?? 0}
          currMonthly={educationExpenseChanges?.monthlyTotalAmount ?? 0}
          description={"Education"}
        />
      ) : null}
      {educationExpenseChanges?.educationChangesArr.length !== 0
        ? educationExpenseChanges?.educationChangesArr.map(
            (rExpense, index: number) => {
              const { prevAmount, currAmount, desc, summaryId } = rExpense
              const prevEdit = prevAmount ?? 0
              const currEdit = currAmount ?? 0
              return (
                <FlexExpenseChangesRow
                  desc={desc}
                  prevEdit={prevEdit}
                  currEdit={currEdit}
                  index={index}
                  switchIndex={switchIndex}
                  updatesCaller={() =>
                    educationChangesHandler(
                      index,
                      summaryId,
                      prevEdit,
                      currEdit
                    )
                  }
                />
              )
            }
          )
        : null}
    </>
  )
}

export default FlexExpenseUpdatesEducation
