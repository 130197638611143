import { Box, Button, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import classNames from 'classnames'
import _ from "lodash"
import React from 'react'
import { setAddHocSequence } from '../../lib/services';
import { UserProps } from '../../scenes/DocumentCollection/AdminRequest/AdminRequest.interface';
import Modal from '../Modal';
import styles from './SendOneOffReminderModal.module.scss'

type SendOneOffReminderModal = {
	showModal: boolean
	onClose: () => void
	rowData: any
	clientId: string
	refreshPage: Function
}

const SendOneOffReminderModal: React.FC<SendOneOffReminderModal> = props => {

	const { showModal, onClose, rowData, clientId, refreshPage } = props
	const generalInformation: any = {
		clientInfo: '',
		requesterName: '',
		requestName: '',
		requesterEmail: ''
	}
	if (rowData?.name && rowData.name !== '') {
		generalInformation.requestName = rowData?.name;
	}

	// Requester Name
	if ((rowData?.staff?.fname && rowData.staff.fname !== '' && rowData?.staff?.lname && rowData.staff.lname !== '')) {
		generalInformation.requesterName = `${rowData.staff.fname} ${rowData.staff.lname}`
	}

	// Client Info
	if (rowData?.receivers && rowData.receivers.length > 0) {
		rowData.receivers.forEach((receiver: UserProps) => {
			generalInformation.clientInfo = generalInformation.clientInfo && generalInformation.clientInfo !== '' ? generalInformation.clientInfo.concat(" ", `and ${receiver.fname}`) : receiver.fname;
		})
	}
	// Requester email
	if (rowData?.sender && rowData.sender !== '') {
		generalInformation.requesterEmail = rowData.sender
	}
	const onCloseModal = () => {
		if (onClose) onClose();
	}
	const handleSubmit = async () => {
		const result = await setAddHocSequence(rowData?._id)
		if (result.status === "success") {
			if (onClose) onClose();

			setTimeout(() => {
				if (refreshPage) refreshPage();
			}, 5000); // Wait for addHocSequence status update
		}
	}
	return (
		<Modal showModal={showModal} paperClassName={styles.modalPapertext} onClose={(_, reason) => {
			if (reason === "backdropClick") {
				onClose();
			}
		}}>
			<Box className={styles.headerRow}>
				<Box className={styles.headerTitle}>Send One Off Reminder Email</Box>
				<IconButton onClick={onCloseModal}>
					<Close classes={{ root: styles.closeIcon }} />
				</IconButton>
			</Box>

			<Box className={styles.contentRow}>
				<Box className={styles.upperText}>Are you sure you want to send a one-off reminder email? This will be the same as the initial notification email that is sent to the client:
				</Box>


				<Box className={styles.lowerText}>
					<p>Hi {generalInformation?.clientInfo},</p>
					<p>{generalInformation.requesterName ? <b
						className={styles.addColor}>{generalInformation.requesterName}</b> : "Your advisor"} from Empower Wealth has requested documents from you.
					</p>
					<p>
						You can action the request <b
							className={styles.addColor}>{generalInformation?.requestName}</b> via our encrypted secure platform by clicking the link below.</p>
					<p className={styles.buttonBox}>
						<a href={`${window.location.origin}/documentcollection/${clientId}`} target="_blank" className={styles.goToRequest} style={{ textDecoration: "none" }}
						>GO TO REQUEST
						</a>
					</p>
					<p>
						These documents enable us to carry out our professional duties on your behalf and are critical to the work being performed, so please action this quickly.</p>
					<p>
						If you have any questions about your request, please reach out to <b
							className={styles.addColor}>{generalInformation?.requesterEmail}</b> directly.</p>
				</Box>

			</Box>

			<Box className={styles.footerRow}>
				<Button
					className={classNames(styles.actionButtons, styles.cancelButton)}
					onClick={onCloseModal}>
					Cancel
				</Button>
				<Button
					className={classNames(styles.actionButtons, styles.submitButton)} onClick={handleSubmit}>
					Yes
				</Button>
			</Box>
		</Modal >
	)
}

export default SendOneOffReminderModal