import { Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import GoToPortalIcon from "../../assets/go-to-portal.svg";
import EditIcon from "../../assets/pencil-outline.svg";
import CancelIcon from "../../assets/stop-red-solid.svg";
import DuplicateRequestIcon from "../../assets/duplicate-request.svg";
import SendOneOffReminderIcon from "../../assets/send-one-off-reminder.svg";
import EditFollowUpSequenceIcon from "../../assets/edit-follow-up-sequence.svg";
import CancelEmailFollowUpsIcon from "../../assets/cancel-email-follow-ups.svg";
import { RequestHistoryProps } from "../../scenes/DocumentCollection/RequestHistory/RequestHistory.interfaces";
import styles from "./ButtonStatus.module.scss";

const GO_TO_PORTAL = "Go To Portal"
const EDIT_REQUEST = "Edit Request"
const CANCEL_REQUEST = "Cancel Request"
const DUPLICATE_REQUEST = "Duplicate Request"
const SEND_ONE_OFF_REMINDER = "Send One-off Reminder"
const EDIT_FOLLOW_UP_SEQUENCE = "Edit Follow-up Sequence"
const CANCEL_EMAIL_FOLLOW_UPS = "Cancel Email Follow-ups"
const OPTIONSFORINPROGRESS = Object.freeze([GO_TO_PORTAL, EDIT_REQUEST, CANCEL_REQUEST, DUPLICATE_REQUEST, SEND_ONE_OFF_REMINDER, EDIT_FOLLOW_UP_SEQUENCE, CANCEL_EMAIL_FOLLOW_UPS]);
const OPTIONSFORDRAFT = Object.freeze([GO_TO_PORTAL, EDIT_REQUEST, CANCEL_REQUEST]);

const StatusIncomplete = "In Progress"
const StatusComplete = "Completed"
const StatusCanceled = "Cancelled"
const StatusDraft = "Draft"

const ButtonStatus = ({
  row,
  setrowData,
  onMenuItemClick
}: {
  row: RequestHistoryProps,
  setrowData: any,
  onMenuItemClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number, data: RequestHistoryProps) => void
}) => {
  const [btnColor, setBtnColor] = useState<string>('');
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget)
  }

  const handleMenuClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();
    setMenuAnchorEl(null)
  }
  setrowData(row)
  useEffect(() => {
    if (row?.workflowStatus) {
      switch (row.workflowStatus) {
        case StatusComplete:
          setBtnColor('#66E84B')
          break;
        case StatusDraft:
          setBtnColor('#F57C14')
          break;
        case StatusCanceled:
          setBtnColor('#828282')
          break;
        case StatusIncomplete:
          setBtnColor('#EB5757')
          break;
        default:
          setBtnColor('#000')
          break;
      }
    }
  }, [row])


  const getIcons = (index: Number) => {
    switch (index) {
      case 0: return <img
        src={GoToPortalIcon}
        className={styles.optionIcons}
        alt="Go-To-Portal-icon"
      />
      case 1: return <img
        src={EditIcon}
        className={styles.optionIcons}
        alt="Edit-Request-Icon"
      />
      case 2: return <img
        src={CancelIcon}
        className={styles.optionIcons}
        alt="Cancel-Icon"
      />

      case 3: return <img
        src={DuplicateRequestIcon}
        className={styles.optionIcons}
        alt="Duplicate-Request-Icon"
      />
      case 4: return <img
        src={SendOneOffReminderIcon}
        className={styles.optionIcons}
        alt="Send-One-Off-Reminder-Icon"
      />
      case 5: return <img
        src={EditFollowUpSequenceIcon}
        className={styles.optionIcons}
        alt="Edit-Follow-Up-Sequence-Icon"
      />
      case 6: return <img
        src={CancelEmailFollowUpsIcon}
        className={styles.optionIcons}
        alt="Cancel-Email-Follow-Ups-Icon"
      />
      default:
        break;
    }
  }
  return <Box display="flex" flex={1} justifyContent="center" style={{ position: 'relative' }}>
    <Box flex={1} display="flex" alignItems="center" justifyContent="center">
      <div className={styles.statusButton} style={{ color: btnColor, borderColor: btnColor }}>{row.workflowStatus}</div>
      {
        row?.status && ['submitByAdmin', 'savedByAdmin'].includes(row.status) ?
          <>
            <IconButton size="small" onClick={handleMenuClick} classes={{ root: styles.moreButton }}>
              <MoreVert fontSize="large" htmlColor="#541868" />
            </IconButton>
            <Menu
              id={`long-menu-${_.uniqueId()}`}
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
              classes={{ paper: styles.moreListWrapper }}
            >
              {OPTIONSFORINPROGRESS.map((option: any, index: number) => {
                if (index === 6) {
                  let firstStatus: any
                  let secondStatus: any
                  row?.emailTrackingsStatus?.forEach((tracking, ind) => {
                    const currentSequence = tracking?.currentSequence
                    if (ind === 0) firstStatus = tracking?.reminderSequence[currentSequence]?.status
                    else if (ind === 1) secondStatus = tracking?.reminderSequence[currentSequence]?.status
                  })
                  if (row?.emailTrackingsStatus?.length === 2) {
                    if (firstStatus && firstStatus?.trim().toLocaleLowerCase() !== "cancelled" && secondStatus && secondStatus?.trim().toLocaleLowerCase() !== "cancelled") {
                      return <MenuItem
                        className={styles.moreList}
                        key={option}
                        onClick={(event) => {
                          onMenuItemClick(event, index, row)
                          setMenuAnchorEl(null)
                        }}
                      >
                        {
                          getIcons(index)
                        }
                        {option}
                      </MenuItem>
                    } else {
                      return;
                    }
                  } else {
                    if (firstStatus && firstStatus?.trim().toLocaleLowerCase() !== "cancelled") {
                      return <MenuItem
                        className={styles.moreList}
                        key={option}
                        onClick={(event) => {
                          onMenuItemClick(event, index, row)
                          setMenuAnchorEl(null)
                        }}
                      >
                        {
                          getIcons(index)
                        }
                        {option}
                      </MenuItem>
                    } else {
                      return;
                    }
                  }
                } else {
                  return <MenuItem
                    className={styles.moreList}
                    key={option}
                    onClick={(event) => {
                      onMenuItemClick(event, index, row)
                      setMenuAnchorEl(null)
                    }}
                  >
                    {
                      getIcons(index)
                    }
                    {option}
                  </MenuItem>
                }

              })}
            </Menu>
          </>
          : row?.status && ['cancelled', 'submitByClient'].includes(row.status) ?
            <>
              <IconButton size="small" onClick={handleMenuClick} classes={{ root: styles.moreButton }}>
                <MoreVert fontSize="large" htmlColor="#541868" />
              </IconButton>
              <Menu
                id={`long-menu-${_.uniqueId()}`}
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
                classes={{ paper: styles.moreListWrapper }}
              >

                <MenuItem
                  className={styles.moreList}
                  onClick={(event) => {
                    onMenuItemClick(event, 3, row)
                    setMenuAnchorEl(null)
                  }}
                >
                  {
                    getIcons(3)
                  }
                  {DUPLICATE_REQUEST}
                </MenuItem>
              </Menu>
            </>
            :
            <>
              <IconButton size="small" onClick={handleMenuClick} classes={{ root: styles.moreButton }}>
                <MoreVert fontSize="large" htmlColor="#541868" />
              </IconButton>
              <Menu
                id={`long-menu-${_.uniqueId()}`}
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
                classes={{ paper: styles.moreListWrapper }}
              >
                {OPTIONSFORDRAFT.map((option: any, index: number) => {
                  return <MenuItem
                    className={styles.moreList}
                    key={option}
                    onClick={(event) => {
                      onMenuItemClick(event, index, row)
                      setMenuAnchorEl(null)
                    }}
                  >
                    {
                      getIcons(index)
                    }
                    {option}
                  </MenuItem>
                })}
              </Menu>
            </>
      }
    </Box>
  </Box>
}

export default ButtonStatus