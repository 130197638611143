import React, { useContext, useState } from "react"
import ShowChangesTable from "../ShowChangesTable"
import {
  findBySummaryId,
  compareItems,
  formatCurrency,
  selectedOwner,
} from "../../../../../../lib/utils"
import _ from "lodash"
import ClientChangesContext from "../../../../../../components/Context/ClientChangesContext"

const CreditCardAddition = () => {
  const { creditAddition, ACStates, setACStates } = useContext(
    ClientChangesContext
  )
  const indexOnLender: boolean[] = []
  const indexOnLimit: boolean[] = []
  const indexOnProposed: boolean[] = []
  const indexOnOwner: boolean[] = []

  creditAddition &&
    creditAddition.lenderChanges.forEach((credit: any) =>
      indexOnLender.push(false)
    )
  creditAddition &&
    creditAddition.limitChanges.forEach((credit: any) =>
      indexOnLimit.push(false)
    )
  creditAddition &&
    creditAddition.proposedLimitChanges.forEach((credit: any) =>
      indexOnProposed.push(false)
    )
  creditAddition &&
    creditAddition.ownerChanges.forEach((credit: any) =>
      indexOnOwner.push(false)
    )
  const [switchIndexLender, setSwitchIndexLender] = useState<boolean[]>(
    indexOnLender
  )
  const [switchIndexLimit, setSwitchIndexLimit] = useState<boolean[]>(
    indexOnLimit
  )
  const [switchIndexProposed, setSwitchIndexProposed] = useState<boolean[]>(
    indexOnProposed
  )
  const [switchIndexOwner, setSwitchIndexOwner] = useState<boolean[]>(
    indexOnOwner
  )

  const changePropertyHandler = (
    objProp: string,
    currValue: any,
    prevValue: any,
    summaryId: string,
    newSwitch: boolean[],
    index: number
  ) => {
    const updateValues = ACStates?.creditCard.map((credit: any) => {
      if (credit.summaryId === summaryId) {
        if (newSwitch !== undefined && newSwitch[index]) {
          credit[objProp] = currValue
        } else {
          credit[objProp] = prevValue
        }
      }
      return credit
    })

    const shouldAddValue = updateValues.find(
      (item: { summaryId: string }) => item.summaryId === summaryId
    )

    if (shouldAddValue === undefined) {
      let newItem: any = {
        lender: "",
        limit: 0,
        owner: "",
        proposedLimit: 0,
        summaryId,
      }

      if (newSwitch !== undefined && newSwitch[index]) {
        newItem[objProp] = currValue
      } else {
        newItem[objProp] = prevValue
      }

      updateValues.push(newItem)
    } else {
      const checkAddedLender = findBySummaryId(
        creditAddition?.lenderChanges,
        summaryId
      )
      const checkAddedLimit = findBySummaryId(
        creditAddition?.limitChanges,
        summaryId
      )
      const checkAddedOwner = findBySummaryId(
        creditAddition?.ownerChanges,
        summaryId
      )
      const checkAddedProposed = findBySummaryId(
        creditAddition?.proposedLimitChanges,
        summaryId
      )

      const checkUpdatedValues = findBySummaryId(
        ACStates?.creditCard,
        summaryId
      )

      if (
        compareItems(checkAddedLender.prevLender, checkUpdatedValues.lender) &&
        compareItems(checkAddedLimit.prevLimit, checkUpdatedValues.limit) &&
        compareItems(checkAddedOwner.prevOwner, checkUpdatedValues.owner) &&
        compareItems(
          checkAddedProposed.prevProposedLimit,
          checkUpdatedValues.proposedLimit
        )
      ) {
        const position = updateValues.findIndex(
          (uValues: { summaryId: string }) => uValues.summaryId === summaryId
        )
        if (position !== -1) updateValues.splice(position, 1)
      }
    }
    return updateValues
  }

  const creditAdditionHandler = (
    summaryId: string,
    index: number,
    currValue: any,
    prevValue: any,
    toChange: string
  ) => {
    let newSwitch: boolean[]
    let changeValue

    switch (toChange) {
      case "lender":
        newSwitch = switchIndexLender && [...switchIndexLender]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexLender(newSwitch)
        break
      case "limit":
        newSwitch = switchIndexLimit && [...switchIndexLimit]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexLimit(newSwitch)
        break
      case "proposedLimit":
        newSwitch = switchIndexProposed && [...switchIndexProposed]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexProposed(newSwitch)
        break
      case "owner":
        newSwitch = switchIndexOwner && [...switchIndexOwner]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexOwner(newSwitch)
        break
      default:
        newSwitch = []
    }

    if (ACStates !== undefined) {
      setACStates && setACStates({ ...ACStates, creditCard: changeValue })
    }
  }

  return (
    <>
      {/* start of credit lender changes */}
      {creditAddition?.lenderChanges.length !== 0 ? (
        creditAddition?.lenderChanges.map(
          (
            credit: {
              prevLender: string
              currLender: string
              summaryId: string
            },
            index: number
          ) => {
            const { prevLender, currLender, summaryId } = credit
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={currLender}
                index={index}
                switchIndex={switchIndexLender}
                updatesCaller={() => {
                  creditAdditionHandler(
                    summaryId,
                    index,
                    currLender,
                    prevLender,
                    "lender"
                  )
                }}
                type="creditLender"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of lender changes */}
      {/* start of limit changes */}
      {creditAddition?.limitChanges.length !== 0 ? (
        creditAddition?.limitChanges.map(
          (
            credit: {
              prevLimit: number
              currLimit: number
              summaryId: string
            },
            index: number
          ) => {
            const { prevLimit, currLimit, summaryId } = credit
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={formatCurrency(currLimit)}
                index={index}
                switchIndex={switchIndexLimit}
                updatesCaller={() => {
                  creditAdditionHandler(
                    summaryId,
                    index,
                    currLimit,
                    prevLimit,
                    "limit"
                  )
                }}
                type="creditLimit"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of limit changes */}
      {/* start of proposed limit changes */}
      {creditAddition?.proposedLimitChanges.length !== 0 ? (
        creditAddition?.proposedLimitChanges.map(
          (
            asset: {
              prevProposedLimit: number
              currProposedLimit: number
              summaryId: string
            },
            index: number
          ) => {
            const { prevProposedLimit, currProposedLimit, summaryId } = asset
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={formatCurrency(currProposedLimit)}
                index={index}
                switchIndex={switchIndexProposed}
                updatesCaller={() => {
                  creditAdditionHandler(
                    summaryId,
                    index,
                    currProposedLimit,
                    prevProposedLimit,
                    "proposedLimit"
                  )
                }}
                type="creditProposed"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of propsed limti changes */}
      {/* start of owner changes */}
      {creditAddition?.ownerChanges.length !== 0 ? (
        creditAddition?.ownerChanges.map(
          (
            asset: {
              prevOwner: any
              currOwner: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevOwner, currOwner, summaryId } = asset
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={selectedOwner(currOwner)}
                index={index}
                switchIndex={switchIndexOwner}
                updatesCaller={() => {
                  creditAdditionHandler(
                    summaryId,
                    index,
                    currOwner,
                    prevOwner,
                    "owner"
                  )
                }}
                type="creditOwner"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of owner changes */}
    </>
  )
}

export default CreditCardAddition
