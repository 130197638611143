import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React from 'react';
import { FilterConst } from '../../constants/requestHistoryOptions';
import styles from "./StatusFilter.module.scss";
const { REQUEST_STATUS_TYPE } = FilterConst;

type Props = {
    onChange?: (value: string) => void,
    defaultSelStatus?: string
}

const StatusFilter = (props: Props) => {
    const handleChangeFilterByStatus = (_event: React.MouseEvent<HTMLElement>, selectStatus: string | null) => {
        if (selectStatus) {
            if (props.onChange) props.onChange(selectStatus);
        }
    };

    return (
        <div className={styles.filterByStatus}>
            <ToggleButtonGroup
                exclusive
                classes={{ root: styles.toggleButtonGroup }}
                value={props.defaultSelStatus}
                onChange={handleChangeFilterByStatus}
            >
                <ToggleButton classes={{ root: styles.toggleButtons, selected: styles.toggleButtonSelected }} value={REQUEST_STATUS_TYPE.All_Status['key']}>{REQUEST_STATUS_TYPE.All_Status['value']}</ToggleButton>
                <ToggleButton classes={{ root: styles.toggleButtons, selected: styles.toggleButtonSelected }} value={REQUEST_STATUS_TYPE.Current_Status['key']}>{REQUEST_STATUS_TYPE.Current_Status['value']}</ToggleButton>
                <ToggleButton classes={{ root: styles.toggleButtons, selected: styles.toggleButtonSelected }} value={REQUEST_STATUS_TYPE.Past_Status['key']}>{REQUEST_STATUS_TYPE.Past_Status['value']}</ToggleButton>
            </ToggleButtonGroup>
        </div>
    )
}

export default StatusFilter