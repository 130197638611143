import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useCallback, useMemo, useState } from "react";
import { mapTextSlug } from "../../lib/textMap";
import { UseMemoFormResult } from "../../lib/useMemoForm";

const useStyles = makeStyles({
  item: {
    margin: "1em 2em",
    display: "flex",
    gap: "1em",
    alignItems: "center",
  },
  label: {
    cursor: "pointer",
  },
});

export type RankItemProps = {
  label: string;
  name: string;
  ranks: number;
  form: UseMemoFormResult;
  isReadonly?: boolean;
  initialValue?: string;
};

const RankInputItem = ({
  label,
  name,
  ranks,
  form: { register },
  isReadonly,
  initialValue,
}: RankItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const styles = useStyles();
  const open = useCallback(() => !isReadonly && setIsOpen(true), [isReadonly]);
  const close = useCallback(() => setIsOpen(false), []);

  const inputUseFormProps = useMemo(() => register(name), [name, register]);

  return (
    <div className={styles.item}>
      <FormControl variant="outlined">
        <Select
          open={isOpen}
          onOpen={open}
          onClose={close}
          defaultValue={initialValue || ""}
          inputProps={inputUseFormProps}
          disabled={isReadonly}
        >
          <MenuItem value="">---</MenuItem>

          {Array(ranks)
            .fill(0)
            .map((_, index) => {
              const rank = `${index + 1}`;

              return <MenuItem value={rank}>{rank}</MenuItem>;
            })}
        </Select>
      </FormControl>

      <Typography className={styles.label} onClick={open} color="primary">
        {mapTextSlug(label)}
      </Typography>
    </div>
  );
};

export default RankInputItem;
