import { createContext } from "react"
import {
  i_ACStates,
  i_flexItemsUpdates,
} from "../../scenes/MBWorkbench/PreliminaryAssessment/AssessmentCalculator/interface"

type ContextProps = {
  rentalUpdates: {
    addressChanges: any[]
    ownership1Changes: any[]
    ownership2Changes: any[]
    propertyValueChanges: any[]
    rentChanges: any[]
  }
  rentalAddition: {
    addressChanges: any[]
    ownership1Changes: any[]
    ownership2Changes: any[]
    propertyValueChanges: any[]
    rentChanges: any[]
  }
  financialAssetUpdates: {
    typeChanges: any[]
    balanceChanges: any[]
    ownerChanges: any[]
  }
  financialAssetAddition: {
    typeChanges: any[]
    balanceChanges: any[]
    ownerChanges: any[]
  }
  nonFinancialAssetUpdates: {
    typeChanges: any[]
    balanceChanges: any[]
    ownerChanges: any[]
  }
  nonFinancialAssetAddition: {
    typeChanges: any[]
    balanceChanges: any[]
    ownerChanges: any[]
  }
  income1Updates: {
    portalChanges: any[]
    typeChanges: any[]
  }
  income1Addition: {
    portalChanges: any[]
    typeChanges: any[]
  }
  income2Updates: {
    portalChanges: any[]
    typeChanges: any[]
  }
  income2Addition: {
    portalChanges: any[]
    typeChanges: any[]
  }
  unsecuredUpdates: {
    balanceChanges: any[]
    ownerChanges: any[]
    periodChanges: any[]
    repaymentChanges: any[]
    typeChanges: any[]
  }
  unsecuredAdditional: {
    balanceChanges: any[]
    ownerChanges: any[]
    periodChanges: any[]
    repaymentChanges: any[]
    typeChanges: any[]
  }
  homeLoansUpdates: {
    lenderChanges: any[]
    loanTypeChanges: any[]
    primaryPurposeChanges: any[]
    borrowerChanges: any[]
    repaymentTypeChanges: any[]
    repaymentChanges: any[]
    periodChanges: any[]
    loanExpiryChanges: any[]
    balanceChanges: any[]
    accountNumberChanges: any[]
    ioExpChanges: any[]
    fixedIOChanges: any[]
    addressChanges: any[]
    baseRateChanges: any[]
    discountRateChanges: any[]
    interestRateChanges: any[]
  }
  homeLoansAddition: {
    lenderChanges: any[]
    loanTypeChanges: any[]
    primaryPurposeChanges: any[]
    borrowerChanges: any[]
    repaymentTypeChanges: any[]
    repaymentChanges: any[]
    periodChanges: any[]
    loanExpiryChanges: any[]
    balanceChanges: any[]
    accountNumberChanges: any[]
    ioExpChanges: any[]
    fixedIOChanges: any[]
    addressChanges: any[]
    baseRateChanges: any[]
    discountRateChanges: any[]
    interestRateChanges: any[]
  }
  creditUpdates: {
    lenderChanges: any[]
    limitChanges: any[]
    ownerChanges: any[]
    proposedLimitChanges: any[]
  }
  creditAddition: {
    lenderChanges: any[]
    limitChanges: any[]
    ownerChanges: any[]
    proposedLimitChanges: any[]
  }
  showFlexExpenseTitle: boolean
  flexExpenseUpdates: i_flexItemsUpdates
  ACStates: i_ACStates
  setACStates: Function
}

const ClientChangesContext = createContext<Partial<ContextProps>>({})

export default ClientChangesContext
