import { Box, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, ListItem, makeStyles } from '@material-ui/core'
import { CheckSharp, ErrorOutline, KeyboardArrowDown } from '@material-ui/icons'
import _ from "lodash"
import React from 'react'
import FileItem from '../../../components/FileItem/FileItem'
import FileUploader from '../../../components/FileUploader'
import { i_Document } from '../../../lib/services'
import { i_FileStatementPeriod } from '../Documents/Documents.interfaces'
import styles from "./ClientResponse.module.scss"

const useStyles = makeStyles({
    docLabelRow: {
      backgroundColor: '#F2F2F2',
      padding: '15px 40px',
      borderRadius: '4px'
    },
    fileRow: {
      marginLeft: '85px',
      '& .MuiPaper-root': {
        border: '1px solid #CCC',
        padding: '10px'
      }
    },
    container: {
      width: '100%',
      padding: '5px 0'
    },
    expansionPanelRoot: {
        margin: '0 !important',
        boxShadow: 'none !important',
        width: '100%',
        '&:first-child': {
            marginTop: '1px !important'
        },
        '&:not(:first-child)': {
            marginTop: '5px !important'
        }
    },
    listDocumentType: {
        background: '#F2F2F2 !important',
        color:' black !important',
        fontFamily: "'Poppins', 'sans-serif', -apple-system, monospace !important",
        minHeight: '48px !important',
        flexDirection: 'row-reverse',
        boxShadow: 'none !important',
        padding: '0 0 0 15px',
        border: '1px solid #C4C4C4',
        borderRadius: '2px',
        "& .MuiExpansionPanelSummary-content": {
          margin: '5px 0'
        },
        "& .MuiExpansionPanelSummary-expandIcon": {
          padding: '0 12px',
          margin: '0'
        }
    },
    expansionPanelDetails: {
        padding: '0px',
        marginBottom: '5px',
        border: '1px solid #C4C4C4',
        borderRadius: '4px',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
    }
});

const ClientResponseItem = ({ 
    clientId, 
    documents,
    onFileUploaderModalOpen,
    setDroppedFile,
    requestId,
    refreshRequestedDocuments,
    setRequestItemId
}: {
    clientId: any,
    documents: ReadonlyArray<i_Document>,
    onFileUploaderModalOpen: (
        dcid: string,
        isEdit: boolean,
        defaultFileType: string,
        fileLabel?: string,
        fileId?: string,
        notes?: string,
        requestId?: String,
        tags?: string[],
        staPeriod?: i_FileStatementPeriod,
        referNo?: string
    ) => Function,
    setDroppedFile: (file: File | undefined) => void,
    requestId: String,
    refreshRequestedDocuments: () => void,
    setRequestItemId?: (id: String) => void
}) => {
    const classes = useStyles();

    const groupedDocuments = _.chain(documents)
    .groupBy("_id")
    .map(function(value, key) {
      const filterLabel = _.map(value, function(o) { return o._id === key ? o.documentLabel : ''});
      const filterNotes = _.map(value, function(o) { return o._id === key ? o.notes : ''});
        
      return {
        documentLabel: filterLabel[0],
        documentNotes: filterNotes[0],
        docs: value
      }
    })
    .value();

    return <>{
        groupedDocuments.map(({ docs, documentLabel, documentNotes }, index) => {
            return <>
                {
                    docs.map((item, idx) => {
                        const docType = (item.dcid?.documentType && item.dcid.documentType !== undefined) ? item.dcid.documentType : '';

                        return <React.Fragment key={idx}>
                            <ExpansionPanel defaultExpanded className={classes.expansionPanelRoot}>
                                <ExpansionPanelSummary expandIcon={<KeyboardArrowDown fontSize="large" className={styles.textOrange} />} 
                                    aria-controls={`panel${index}-content`}
                                    id={`panel${index}-header`} 
                                    className={classes.listDocumentType}
                                >
                                    <Grid item xs={4} style={{ display: 'flex' }}>
                                        <Box display="flex" alignItems="center">
                                            <span className={styles.docLabel}>{documentLabel}</span>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <span className={styles.docNotes}>{item?.notes && item.notes !== "" && `Notes: ${item.notes}`}</span></Grid>
                                    <Grid item xs={1}>
                                        <Box display="flex" alignItems="center">
                                            { item?.files && item.files.length > 0 ? <CheckSharp htmlColor="#27AE60" /> : <ErrorOutline htmlColor="#EB5757"/> }
                                            &nbsp;
                                            <span className={styles.docFiles}>{ item?.files && item.files.length > 0 ? item.files.length : 0 }&nbsp;files uploaded</span>
                                        </Box>
                                    </Grid>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                                    <ListItem className={styles.fileItemDetailContainer} style={{ flexDirection: 'column'}}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12} md={5} lg={5}>
                                                <FileUploader
                                                    type="modal-details"
                                                    openModalUpload={onFileUploaderModalOpen(item._id, false, docType, '', '', documentNotes, requestId, [], undefined, "")}
                                                    droppedFile={(file: File) => { file && setDroppedFile(file) }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={7} lg={7}>
                                                {
                                                    item?.files && item.files.length > 0 &&
                                                    <>
                                                        <p className={styles.txtUploadFile}>Uploaded Files</p>
                                                        <FileItem
                                                            item={item}
                                                            display={{
                                                                view: true,
                                                                download: true,
                                                                isShowIconEdit: true,
                                                                isShowIconDelete: true,
                                                                isShowNote: false,
                                                                isShowCheckbox: false,
                                                                isShowStatus: false,
                                                                isShowDateCreated: true,
                                                                isShowTag: true,
                                                                isMultipleRequest: true,
                                                                isFromClient: true
                                                            }}
                                                            onFileUploaderModalOpen={onFileUploaderModalOpen}
                                                            refreshRequestedDocuments={refreshRequestedDocuments}
                                                            clientId={clientId}
                                                            requestId={requestId}
                                                            setRequestItemId={setRequestItemId}
                                                            isFromDocument={false}
                                                            folder="Mortgages"
                                                        />
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </React.Fragment>
                    })
                }
            </>
        })
    }</>
}

export default ClientResponseItem