import {
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
} from "react-hook-form";
import { SurveyValues } from "../../lib/lendingSurveysService";
import { UseMemoFormResult } from "../../lib/useMemoForm";
import {
  SurveyItemDependencies,
  SurveyItemInput,
  SurveyItemValue,
} from "./surveys.types";

export const getVisibilityMap = (
  dependencies: SurveyItemDependencies,
  name: string,
  value?: string | { [option: string]: string },
  prev?: { [key: string]: boolean | undefined }
) => {
  const valueMap = dependencies[name];

  if (!(valueMap && !Array.isArray(valueMap))) return {};

  const valueMapEntries = Object.entries(valueMap);

  const newVisibilityMap: {
    [key: string]: boolean | undefined;
  } = { ...prev };

  valueMapEntries.forEach(([expectedValue, ids]) => {
    ids?.forEach((itemId) => {
      const oldValue = newVisibilityMap[itemId];

      newVisibilityMap[itemId] = oldValue || expectedValue === value;
    });
  });

  return newVisibilityMap;
};

export const getVisibilityMapOfValues = (
  values:
    | undefined
    | {
        [inputName: string]:
          | string
          | {
              [option: string]: string;
            };
      },
  dependencies: SurveyItemDependencies
) => {
  const inputNames = Object.keys(dependencies);

  return inputNames.reduce(
    (prev, inputName) => {
      return getVisibilityMap(
        dependencies,
        inputName,
        values?.[inputName],
        prev
      );
    },
    {} as {
      [textIdOrInputName: string]: boolean | undefined;
    }
  );
};

export const getRequiredInputName = <
  Values extends Record<string, any> = Record<string, any>
>(
  values: Values,
  schema: {
    items: SurveyItemValue[];
    dependencies: SurveyItemDependencies;
  }
) => {
  const newItems = cleanUpForm(schema, values);

  return newItems.reduce((prev, item) => {
    if (prev) return prev;

    if ("textId" in item) return prev;

    if (item.inputType === "rank") {
      const { inputName } = item;

      const itemValues = values[inputName];

      if (!(itemValues && typeof itemValues === "object")) return prev;

      const entries = Object.entries(itemValues);

      const count = entries.reduce(
        (current, [, value]) => (value ? current + 1 : current),
        0
      );

      const { required } = item;

      if (required && required > count) {
        return `${inputName}.${entries[0][0]}`;
      }
    }

    const value = values[item.inputName];

    if (!value && item.required) return item.inputName;

    return prev;
  }, null as null | string);
};

export const createUseFormHandleSubmit = (
  onValid: SubmitHandler<FieldValues>,
  schema: {
    items: SurveyItemValue[];
    dependencies: SurveyItemDependencies;
  },
  useFormResult: UseMemoFormResult,
  onInvalid?: SubmitErrorHandler<FieldValues>
): ReturnType<UseMemoFormResult["handleSubmit"]> => {
  return async () => {
    const { getValues, setFocus, handleSubmit } = useFormResult;

    const rankInputName = getRequiredInputName(getValues(), schema);

    if (rankInputName) {
      setFocus(rankInputName);

      if (onInvalid) onInvalid({});

      return undefined;
    }

    return handleSubmit((...value) => {
      return onValid(...value);
    }, onInvalid)();
  };
};

export const cleanUpForm = (
  form: {
    items: SurveyItemValue[];
    dependencies: SurveyItemDependencies;
  },
  values: SurveyValues
) => {
  const { dependencies, items } = form;
  const keysToExcludeSet = new Set<string>();

  Object.entries(dependencies).forEach(
    ([dependencyKey, dependentKeysOrValueMap]) => {
      if (Array.isArray(dependentKeysOrValueMap)) {
        const dependentKeys = dependentKeysOrValueMap;

        if (!values[dependencyKey]) {
          dependentKeys.forEach((key) => keysToExcludeSet.add(key));
        }

        return;
      }

      const valueMap = dependentKeysOrValueMap;

      Object.entries(valueMap).forEach(([value, dependentKeys]) => {
        if (values[dependencyKey] !== value) {
          dependentKeys.forEach((key) => keysToExcludeSet.add(key));
        }
      });
    }
  );

  const newItems = items.filter((item) => {
    return item.inputName && !keysToExcludeSet.has(item.inputName);
  }) as SurveyItemInput[];

  return newItems;
};
