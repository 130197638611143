import React, { useContext, useState } from "react"
import ClientChangesContext from "../../../../../../components/Context/ClientChangesContext"
import ShowChangesTable from "../ShowChangesTable"
import { formatCurrency } from "../../../../../../lib/utils"

const RentalChangesTable = () => {
  const { rentalUpdates, ACStates, setACStates } = useContext(
    ClientChangesContext
  )

  const indexOnAddress: boolean[] = []
  const indexOnOwnership1: boolean[] = []
  const indexOnPropertyValue: boolean[] = []
  const indexOnOwnership2: boolean[] = []
  const indexOnRental: boolean[] = []

  rentalUpdates &&
    rentalUpdates.addressChanges.forEach((rental: any) =>
      indexOnAddress.push(false)
    )

  rentalUpdates &&
    rentalUpdates.ownership1Changes.forEach((rental: any) =>
      indexOnOwnership1.push(false)
    )

  rentalUpdates &&
    rentalUpdates.propertyValueChanges.forEach((rental: any) =>
      indexOnPropertyValue.push(false)
    )

  rentalUpdates &&
    rentalUpdates.ownership2Changes.forEach((rental: any) =>
      indexOnOwnership2.push(false)
    )

  rentalUpdates &&
    rentalUpdates.rentChanges.forEach((rental: any) => {
      indexOnRental.push(false)
    })

  const [switchIndexAddress, setSwitchIndexAddress] = useState<boolean[]>(
    indexOnAddress
  )
  const [switchIndexOwnership1, setSwitchIndexOwnership1] = useState<boolean[]>(
    indexOnOwnership1
  )
  const [switchIndexPropertyValue, setSwitchIndexPropertyValue] = useState<
    boolean[]
  >(indexOnPropertyValue)
  const [switchIndexOwnership2, setSwitchIndexOwnership2] = useState<boolean[]>(
    indexOnOwnership2
  )
  const [switchIndexRental, setSwitchIndexRental] = useState<boolean[]>(
    indexOnRental
  )
  const changePropertyHandler = (
    objProp: string,
    currValue: any,
    prevValue: any,
    summaryId: string,
    newSwitch: boolean[],
    index: number
  ) => {
    return ACStates?.rentalIncome.map((rental: any) => {
      if (rental.summaryId === summaryId) {
        if (newSwitch !== undefined && newSwitch[index]) {
          rental[objProp] = currValue
        } else {
          rental[objProp] = prevValue
        }
      }
      return rental
    })
  }

  const rentalUpdatesHandler = (
    summaryId: string,
    index: number,
    currValue: any,
    prevValue: any,
    toChange: string
  ) => {
    let newSwitch: boolean[]
    let changeValue

    switch (toChange) {
      case "address":
        newSwitch = switchIndexAddress && [...switchIndexAddress]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexAddress(newSwitch)
        break
      case "ownershipPercentageClient1":
        newSwitch = switchIndexOwnership1 && [...switchIndexOwnership1]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexOwnership1(newSwitch)
        break
      case "ownershipPercentageClient2":
        newSwitch = switchIndexOwnership2 && [...switchIndexOwnership2]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexOwnership2(newSwitch)
        break
      case "propertyValue":
        newSwitch = switchIndexPropertyValue && [...switchIndexPropertyValue]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexPropertyValue(newSwitch)
        break
      case "rent":
        newSwitch = switchIndexRental && [...switchIndexRental]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexRental(newSwitch)
        break
      default:
        newSwitch = []
    }

    if (ACStates !== undefined) {
      setACStates && setACStates({ ...ACStates, rentalIncome: changeValue })
    }
  }

  return (
    <>
      {/* start of rental address changes */}
      {rentalUpdates?.addressChanges.length !== 0 ? (
        rentalUpdates?.addressChanges.map(
          (
            rental: {
              prevValAddress: any
              currValAddress: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevValAddress, currValAddress, summaryId } = rental
            return (
              <ShowChangesTable
                prevVal={prevValAddress}
                currVal={currValAddress}
                index={index}
                switchIndex={switchIndexAddress}
                updatesCaller={() => {
                  rentalUpdatesHandler(
                    summaryId,
                    index,
                    currValAddress,
                    prevValAddress,
                    "address"
                  )
                }}
                type="address"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental ownership1 changes */}
      {rentalUpdates?.ownership1Changes.length !== 0 ? (
        rentalUpdates?.ownership1Changes.map(
          (
            rental: {
              prevOwnership1: any
              currOwnership1: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevOwnership1, currOwnership1, summaryId } = rental
            return (
              <ShowChangesTable
                prevVal={prevOwnership1}
                currVal={currOwnership1}
                index={index}
                switchIndex={switchIndexOwnership1}
                updatesCaller={() => {
                  rentalUpdatesHandler(
                    summaryId,
                    index,
                    currOwnership1,
                    prevOwnership1,
                    "ownershipPercentageClient1"
                  )
                }}
                type="ownership1"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental ownership1 changes */}
      {/* start of rental ownership2 changes */}
      {rentalUpdates?.ownership2Changes.length !== 0 ? (
        rentalUpdates?.ownership2Changes.map(
          (
            rental: {
              prevOwnership2: any
              currOwnership2: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevOwnership2, currOwnership2, summaryId } = rental
            return (
              <ShowChangesTable
                prevVal={prevOwnership2}
                currVal={currOwnership2}
                index={index}
                switchIndex={switchIndexOwnership2}
                updatesCaller={() => {
                  rentalUpdatesHandler(
                    summaryId,
                    index,
                    currOwnership2,
                    prevOwnership2,
                    "ownershipPercentageClient2"
                  )
                }}
                type="ownership2"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental ownership2 changes */}
      {/* start of rental property value changes */}
      {rentalUpdates?.propertyValueChanges.length !== 0 ? (
        rentalUpdates?.propertyValueChanges.map(
          (
            rental: {
              prevPropertyValue: any
              currPropertyValue: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevPropertyValue, currPropertyValue, summaryId } = rental
            return (
              <ShowChangesTable
                prevVal={formatCurrency(prevPropertyValue)}
                currVal={formatCurrency(currPropertyValue)}
                index={index}
                switchIndex={switchIndexPropertyValue}
                updatesCaller={() => {
                  rentalUpdatesHandler(
                    summaryId,
                    index,
                    currPropertyValue,
                    prevPropertyValue,
                    "propertyValue"
                  )
                }}
                type="propertyValueChanges"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental property value changes */}
      {/* start of rental property value changes */}
      {rentalUpdates?.rentChanges.length !== 0 ? (
        rentalUpdates?.rentChanges.map(
          (
            rental: {
              prevRent: any
              currRent: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevRent, currRent, summaryId } = rental
            return (
              <ShowChangesTable
                prevVal={formatCurrency(prevRent)}
                currVal={formatCurrency(currRent)}
                index={index}
                switchIndex={switchIndexRental}
                updatesCaller={() => {
                  rentalUpdatesHandler(
                    summaryId,
                    index,
                    currRent,
                    prevRent,
                    "rent"
                  )
                }}
                type="rentChanges"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental property value changes */}
    </>
  )
}

export default RentalChangesTable
