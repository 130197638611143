import * as React from "react";
import { isObject } from "../../lib/checkers";
import { mapTextSlug } from "../../lib/textMap";
import { ItemNode } from "./surveys.types";

export type FormTextProps = {
  data: ItemNode["data"];
};

const FormText = ({ data }: { data: ItemNode["data"] }) => {
  if (Array.isArray(data)) {
    return (
      <>
        {data.map((item) => (
          <FormText data={item} />
        ))}
      </>
    );
  }

  if (data === "br") {
    return <br />;
  }

  const content = isObject(data)
    ? React.createElement(data.type, null, mapTextSlug(data.text))
    : mapTextSlug(data);

  return <>{content}</>;
};

export default FormText;
