import React, { useEffect, useState, useRef } from "react"
import { DocumentsInputProps } from "../../scenes/DocumentCollection/AdminRequest/AdminRequest.interface"
import styles from "./RequestItem.module.scss"

const RequestItem: React.FC<DocumentsInputProps> = ({
  isDuplicate,
  isEditable,
  isReadOnly,
  onEdit,
  notes,
}) => {
  const [editable, setEditable] = useState(isEditable || false)
  const [value, setValue] = useState(notes || "")
  const textareaRef = useRef() as React.MutableRefObject<HTMLTextAreaElement>
  useEffect(() => {
    setValue(notes || "")
  }, [notes])

  const nltoBr = (val: string) => {
    const newLinePattern = / [ \r\n]+/gm
    return val.replace(newLinePattern, "\n")
  }
  const onInputChange = (e: any) => {
    const { value } = e.target
    setValue(value)
    onEdit && onEdit(value)
  }
  const onInputBlur = (e: any) => {
    setEditable(false)
  }

  const textAreaAdjust = (e: any) => {
    e.target.style.height = "1px"
    e.target.style.height = 15 + e.target.scrollHeight + "px"
  }
  useEffect(() => {
    if (!isReadOnly) {
      textareaRef.current.focus()
    }
  }, [editable])
  return (
    <div
      className={`${styles.inputWrapper} ${
        isDuplicate && value === "" ? styles.duplicateDoc : ""
      }`}
    >
      {(isReadOnly && (
        <p className={[styles.inputField, styles.show].join(" ")}>
          {nltoBr(value)}
        </p>
      )) || (
        <>
          <textarea
            ref={textareaRef}
            className={[
              styles.txtAreaField,
              editable ? styles.show : styles.hide,
            ].join(" ")}
            autoFocus={true}
            onBlur={onInputBlur}
            onChange={onInputChange}
            onKeyUp={textAreaAdjust}
            value={value || ""}
          ></textarea>
          <div>
            <pre
              className={[
                styles.inputField,
                !editable ? styles.show : styles.hide,
              ].join(" ")}
              onDoubleClick={() => {
                setEditable(true)
              }}
            >
              {isDuplicate && value === ""
                ? "Please specify clear notes"
                : nltoBr(value)}
            </pre>
          </div>
        </>
      )}
    </div>
  )
}

export default RequestItem
