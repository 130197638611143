import React, { useState, useEffect } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Divider from "@material-ui/core/Divider"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import IconButton from "@material-ui/core/IconButton"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import Edit from "@material-ui/icons/Edit"
import ClearIcon from "@material-ui/icons/Clear"
import CheckIcon from "@material-ui/icons/Check"
import TextField from "@material-ui/core/TextField"
import moment from "moment"
import styles from "./ClientNotes.scss"
import { i_ClientNotes } from "./ClientNotes.interface"
import { _fetch } from "./../../lib/utils"
import _ from "lodash"

export interface i_Comments {
  comments: i_ClientNotes[]
  category: String
  mortgageCalcId: String
  setComments: Function
}

export interface i_Note {
  key: any
  value: string
}

const ClientNotes = (props: i_Comments) => {
  const [comments, setComments] = useState<any>(
    !_.isNil(props.comments) ? props.comments : []
  )
  const [input, setInput] = useState("")
  const [note, setNote] = useState<i_Note[]>([])

  const handleEdit = (key: any, event: React.MouseEvent) => {
    event.stopPropagation()
    const copy = [...comments]
    copy.map((item, index) => {
      if (index === key) {
        return (item.editMode = !item.editMode)
      }
    })
    setComments(copy)
  }

  const handleInput = (key: any, value: any, event: any) => {
    event.stopPropagation()
    setInput(value)
  }

  const saveClientNotes = async (clientCategoryNotes: any) => {
    const res = await _fetch("client/addMortgageCalcComments", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(clientCategoryNotes),
    })
    const result = await res.json()
    if (result.success) {
      console.log("Client notes saved!")
    }
  }

  const handleCheck = (key: any, event: React.MouseEvent) => {
    event.stopPropagation()
    const copy = [...comments]
    copy.map((item, index) => {
      if (index === key) {
        const text = input === "" ? item.comment : input
        return [(item.comment = text), (item.editMode = !item.editMode)]
      }
    })
    setComments(copy)
    const toSave = {
      comments: comments,
      mortgageCalcId: props.mortgageCalcId,
      category: props.category,
    }
    saveClientNotes(toSave)
  }

  const handleDelete = (
    val: i_ClientNotes,
    key: any,
    event: React.MouseEvent
  ) => {
    event.stopPropagation()
    if (val.editMode) {
      setInput("")
      val.editMode = false
    } else {
      const copy = [...comments]
      copy.splice(key, 1)
      setComments(copy)
      const toSave = {
        comments: copy,
        mortgageCalcId: props.mortgageCalcId,
        category: props.category,
      }
      saveClientNotes(toSave)
    }
  }

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  const handleAdd = (event: React.MouseEvent) => {
    event.stopPropagation()
    const copy = [...comments]
    copy.push({
      dateCreated: moment(new Date()).format("DD-MM-YYYY HH:MM"),
      comment: "",
      editMode: true,
    })
    setComments(copy)
  }

  useEffect(() => {
    props.setComments(comments)
  }, [comments])

  return (
    <List className={styles.root} onClick={e => handleClick(e)}>
      <ListItem alignItems="flex-start">
        <ListItemText primary={"Notes"} className={styles.text} />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="comments"
            style={{ color: "green" }}
            onClick={e => handleAdd(e)}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
      <Divider />
      {comments?.map((val: any, key: any) => (
        <ListItem alignItems="flex-start" divider key={key}>
          {val.editMode ? (
            <TextField
              aria-label="input"
              multiline
              rowsMax={4}
              variant="outlined"
              margin="dense"
              className={styles.textField}
              defaultValue={val.comment}
              onChange={(e: React.ChangeEvent<{ value: any }>) =>
                handleInput(key, e.target.value, e)
              }
            />
          ) : (
            <ListItemText
              primary={val.comment}
              className={styles.listItem}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    className={styles.inline}
                    color="textPrimary"
                  ></Typography>
                  {moment().format("DD-MM-YYYY HH:MM")}
                </React.Fragment>
              }
            />
          )}
          <ListItemSecondaryAction key={key}>
            {val.editMode ? (
              <IconButton
                edge="end"
                aria-label="save"
                style={{ color: "green" }}
                onClick={e => handleCheck(key, e)}
              >
                <CheckIcon />
              </IconButton>
            ) : (
              <IconButton
                edge="end"
                aria-label="edit"
                style={{ color: "green" }}
                onClick={e => handleEdit(key, e)}
              >
                <Edit fontSize="small" />
              </IconButton>
            )}
            <IconButton
              edge="end"
              aria-label="clear"
              style={{ color: "green" }}
              onClick={event => handleDelete(val, key, event)}
            >
              <ClearIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  )
}
export default ClientNotes
