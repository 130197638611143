import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import Modal from "../../components/Modal";
import { useQuickModalStyles } from "../../components/modals/QuickModal";

export type ConfirmSubmitSurveyModalProps = {
  open: boolean;
  onClose?: () => void;
  onSubmit?: (
    // eslint-disable-next-line no-unused-vars
    event: React.MouseEvent<HTMLButtonElement | MouseEvent>
  ) => void;
};

const ConfirmSubmitSurveyModal = ({
  open,
  onClose,
  onSubmit,
}: ConfirmSubmitSurveyModalProps) => {
  const styles = useQuickModalStyles();

  return (
    <Modal
      showModal={open}
      onClose={onClose}
      paperClassName={styles.modalPaper}
    >
      <div className={styles.item}>
        <Typography variant="h6">
          Are you happy with your Lending Requirements & Objectives?
        </Typography>
      </div>

      <div className={classNames(styles.item)}>
        <Typography variant="body1">
          If you need more time or would like to add more detail, click NOT YET,
          if you are happy please proceed and click YES.
        </Typography>
      </div>

      <div className={styles.actions}>
        <Button color="primary" variant="contained" onClick={onClose}>
          NOT YET
        </Button>

        <div style={{ flex: 1 }} />

        <Button
          color="primary"
          variant="contained"
          onClick={onSubmit}
          style={{
            backgroundColor: "#DE4F36",
          }}
        >
          YES
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmSubmitSurveyModal;
