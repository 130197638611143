import React, { useState } from "react"
import styles from "./FileUploader.scss"

const imgDropFile = (
  <svg
    width="33"
    height="27"
    viewBox="0 0 33 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.1766 7.87486H25.2898C26.1808 7.87501 27.063 8.05463 27.8859 8.40344C28.7088 8.75224 29.4562 9.26338 30.0853 9.90755C31.3576 11.2094 32.0722 12.9733 32.0722 14.8124C32.0722 16.6514 31.3576 18.4153 30.0853 19.7172C29.4562 20.3613 28.7088 20.8725 27.8859 21.2213C27.063 21.5701 26.1808 21.7497 25.2898 21.7499H20.7707V19.4374H25.2898C26.4563 19.3841 27.5577 18.8735 28.3647 18.012C29.1717 17.1505 29.6221 16.0044 29.6221 14.8124C29.6221 13.6203 29.1717 12.4742 28.3647 11.6127C27.5577 10.7512 26.4563 10.2406 25.2898 10.1874H23.2194L22.9408 8.20555C22.7708 6.96391 22.2085 5.81284 21.3393 4.92717C20.4701 4.0415 19.3415 3.46963 18.1249 3.29842C16.9076 3.12867 15.6694 3.37131 14.6002 3.98913C13.531 4.60694 12.6899 5.5658 12.2059 6.71861L11.4516 8.48074L9.61899 8.04367C9.20011 7.9376 8.77068 7.88096 8.33914 7.87486C6.83956 7.87486 5.40115 8.48305 4.34329 9.56761C3.55492 10.3769 3.0185 11.406 2.80147 12.5256C2.58444 13.6452 2.69649 14.8053 3.12353 15.8601C3.55057 16.9149 4.27354 17.8173 5.20154 18.4538C6.12954 19.0903 7.22116 19.4325 8.33914 19.4374H13.9886V21.7499H8.33914C7.21715 21.7602 6.10588 21.5262 5.07939 21.0637C4.0529 20.6011 3.13476 19.9206 2.38613 19.0674C1.25422 17.7805 0.55559 16.1575 0.392544 14.436C0.229498 12.7145 0.610598 10.9849 1.48004 9.50055C2.0559 8.5171 2.8299 7.6698 3.75074 7.01478C4.67159 6.35976 5.71827 5.91198 6.82144 5.70111C7.92234 5.49299 9.05722 5.52305 10.1468 5.79592C10.8334 4.19108 12.0131 2.85812 13.5077 1.99864C15.0023 1.13915 16.7305 0.799896 18.4307 1.03217C20.1317 1.26715 21.711 2.06263 22.9286 3.29772C24.1462 4.5328 24.9355 6.13994 25.1766 7.87486ZM21.4095 16.7849L18.433 13.7486V26.3194H16.1813V13.8319L13.2864 16.7872L11.6849 15.15L16.546 10.1874H18.1498L23.011 15.15L21.4095 16.7849Z" fill="#541868"/>
  </svg>
)

interface i_FileUploader {
  type: String
  openModalUpload: Function
  droppedFile?: Function
}

const FileUploader = ({
  type,
  openModalUpload,
  droppedFile,
}: i_FileUploader) => {
  const [dragover, setDragover] = useState(false)

  const handleDragEnter = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragLeave = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragover(false)
  }
  const handleDragOver = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragover(true)
  }
  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    let files = [...e.dataTransfer.files]
    droppedFile && droppedFile(files)
    openModalUpload()
    setDragover(false)
  }
  const renderDrop = () => (
    <div
      onDrop={e => handleDrop(e)}
      onDragOver={e => handleDragOver(e)}
      onDragEnter={e => handleDragEnter(e)}
      onDragLeave={e => handleDragLeave(e)}
      className={[styles.fileUploaderDrop, dragover && styles.ondropmode].join(
        " "
      )}
      onClick={() => openModalUpload()}
    >
      {imgDropFile}
      <br />
      <p>
        Drop files here to upload or <br /><span onClick={() => openModalUpload()}>Browse</span>{" "}
        for File
      </p>
    </div>
  )

  return <div className={styles.fileUploader}>{renderDrop()}</div>
}
export default FileUploader
