import moment from "moment"
import { pv } from "../../../../lib/finance"
import {
  incomeTaxRef,
  lowIncomeTaxRef,
  lowIncomeTaxRef2,
  medicalLevyRef,
} from "./config"

const monthsInAYear = 12,
  quarterInAYear = 4,
  biMonthlyInAYear = 24,
  weeksInAYear = 52,
  daysInAWeek = 7,
  fortnightInAYear = 26

export const formatMoney = (
  amount: any,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? "-" : ""

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    )
    const j = i.toString().length > 3 ? i.toString().length % 3 : 0

    return (
      negativeSign +
      (j ? i.toString().substr(0, j) + thousands : "") +
      i
        .toString()
        .substr(j)
        .replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    )
  } catch (e) {
    console.log(e)
  }
}

export const getBorrowingPower = (props: any) => {
  const shavedBy = 3.8 / 100
  const {
    creditCardLimit,
    client1Income,
    client2Income,
    livingExpense,
    interestRate,
    stressBuffer,
    termsInYear,
  } = props
  const reductionToMonthlyTotalIncome = creditCardLimit * shavedBy
  const totalMonthlyIncome =
    deductedEstimatedTaxPaid(client1Income) / 12 +
    deductedEstimatedTaxPaid(client2Income) / 12 -
    livingExpense -
    reductionToMonthlyTotalIncome
  const rate = (interestRate + stressBuffer) / 100 / monthsInAYear
  const nper = monthsInAYear * termsInYear
  const pmt = totalMonthlyIncome
  const ret = pv(rate, nper, pmt)
  return formatMoney(ret)
}

export const getDTI = (props: any) => {
  return formatMoney((props.totalDebt / props.totalIncome).toFixed(2))
}

export const getPer = (input: any, period: string) => {
  input = parseFloat(input?.toString().replace(/,/g, ""))
  let annualVal
  switch (period) {
    case "Weekly":
      annualVal = input * weeksInAYear
      break
    case "Fortnightly":
      annualVal = input * fortnightInAYear
      break
    case "Monthly":
      annualVal = input * monthsInAYear
      break
    case "Bi-Monthly":
      annualVal = input * biMonthlyInAYear
      break
    case "Quarterly":
      annualVal = input * quarterInAYear
      break
    case "Yearly":
      annualVal = input
      break
  }
  return {
    fortnight: formatMoney(annualVal / fortnightInAYear),
    week: formatMoney(annualVal / weeksInAYear),
    month: formatMoney(annualVal / monthsInAYear),
    year: formatMoney(annualVal),
  }
}

// get difference of two dates per (day/week). Used in YTD
export const getNumOf: any = (date1: any, date2: any) => {
  date1 = moment(date1)
  date2 = moment(date2)
  return {
    days: date1.diff(date2, "days").toFixed(),
    weeks: date1.diff(date2, "weeks").toFixed(),
  }
}

export const getAvePer = (props: any) => {
  const income = parseFloat(props.income?.toString().replace(/,/g, ""))
  const weekly_income =
    income / (getNumOf(props.date1, props.date2).days / daysInAWeek)
  const yearly_income = weekly_income * weeksInAYear

  return {
    week: formatMoney(Math.ceil(weekly_income)),
    anum: formatMoney(Math.ceil(yearly_income)),
  }
}

export const getIncomeByPercent = (annualIncome: any, percentage: any) => {
  const _annualIncome = parseFloat(annualIncome?.toString().replace(/,/g, ""))
  const _percentage = parseFloat(percentage?.toString().replace(/,/g, ""))

  return formatMoney((_annualIncome * _percentage) / 100)
}

// used in rental income
export const getYield = (props: any) => {
  const value = parseFloat(props.value?.toString().replace(/,/g, ""))
  const annualRent = parseFloat(props.annualRent?.toString().replace(/,/g, ""))
  return formatMoney(((annualRent / value) * 100).toFixed(2))
}

export const getRedraw = (props: any) => {
  const balance = parseFloat(props.balance?.toString().replace(/,/g, ""))
  return formatMoney(props.limit - balance)
}

export const getTotal = (...arg: any) => {
  return formatMoney(
    arg
      .reduce((_total: any, _cur: any) => {
        const total = parseFloat(_total.toString().replace(/,/g, ""))
        const cur = parseFloat(_cur.toString().replace(/,/g, ""))
        return total + cur
      })
      .toFixed(2)
  )
}

const invokeTaxValueFromIndex = (subject: any, ref: any, INDEX: number) => {
  return (subject - ref[INDEX].min) * ref[INDEX].excessRate + ref[INDEX].tax
}
export const deductedEstimatedTaxPaid = (totalTaxableIncome: number) => {
  return totalTaxableIncome - computeEstimatedTaxPaid(totalTaxableIncome)
}

export const computeEstimatedTaxPaid = (totalTaxableIncome: number) => {
  let incomeTaxComputation = 0
  let lowIncomeTaxComputation = 0
  let lowIncomeTaxComputation2 = 0
  let medicalLevyComputation = 0

  // Income Tax
  if (totalTaxableIncome > incomeTaxRef[4].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[4].min) * incomeTaxRef[4].excessRate +
      incomeTaxRef[4].tax
  } else if (totalTaxableIncome > incomeTaxRef[3].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[3].min) * incomeTaxRef[3].excessRate +
      incomeTaxRef[3].tax
  } else if (totalTaxableIncome > incomeTaxRef[2].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[2].min) * incomeTaxRef[2].excessRate +
      incomeTaxRef[2].tax
  } else if (totalTaxableIncome > incomeTaxRef[1].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[1].min) * incomeTaxRef[1].excessRate +
      incomeTaxRef[1].tax
  } else if (totalTaxableIncome > incomeTaxRef[0].min) {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[0].min) * incomeTaxRef[0].excessRate +
      incomeTaxRef[0].tax
  } else {
    incomeTaxComputation =
      (totalTaxableIncome - incomeTaxRef[0].min) * incomeTaxRef[0].excessRate +
      incomeTaxRef[0].tax
  }

  /** LOW INCOME */
  if (totalTaxableIncome > lowIncomeTaxRef[4].max) {
    lowIncomeTaxComputation = 0
  } else if (
    totalTaxableIncome > lowIncomeTaxRef[3].min &&
    totalTaxableIncome < lowIncomeTaxRef[3].max
  ) {
    lowIncomeTaxComputation =
      lowIncomeTaxRef[3].tax +
      (totalTaxableIncome - lowIncomeTaxRef[3].min) *
        lowIncomeTaxRef[3].excessRate
  } else if (
    totalTaxableIncome > lowIncomeTaxRef[2].min &&
    totalTaxableIncome < lowIncomeTaxRef[2].max
  ) {
    lowIncomeTaxComputation =
      lowIncomeTaxRef[2].tax +
      (totalTaxableIncome - lowIncomeTaxRef[2].min) *
        lowIncomeTaxRef[2].excessRate
  } else if (
    totalTaxableIncome > lowIncomeTaxRef[1].min &&
    totalTaxableIncome < lowIncomeTaxRef[1].max
  ) {
    lowIncomeTaxComputation =
      lowIncomeTaxRef[1].tax +
      (totalTaxableIncome - lowIncomeTaxRef[1].min) *
        lowIncomeTaxRef[1].excessRate
  } else if (
    totalTaxableIncome < lowIncomeTaxRef[0].max &&
    totalTaxableIncome > 0
  ) {
    lowIncomeTaxComputation =
      lowIncomeTaxRef[0].tax +
      (totalTaxableIncome - lowIncomeTaxRef[0].max) *
        lowIncomeTaxRef[0].excessRate
  }

  /** LOW INCOME 2 */
  if (totalTaxableIncome > lowIncomeTaxRef2[3].max) {
    lowIncomeTaxComputation2 =
      lowIncomeTaxRef2[3].tax +
      (totalTaxableIncome - lowIncomeTaxRef2[2].max) *
        lowIncomeTaxRef2[3].excessRate
  } else if (
    totalTaxableIncome > lowIncomeTaxRef2[2].min &&
    totalTaxableIncome < lowIncomeTaxRef2[2].max
  ) {
    lowIncomeTaxComputation2 =
      lowIncomeTaxRef2[2].tax +
      (totalTaxableIncome - lowIncomeTaxRef2[2].min) *
        lowIncomeTaxRef2[2].excessRate
  } else if (
    totalTaxableIncome > lowIncomeTaxRef2[1].min &&
    totalTaxableIncome < lowIncomeTaxRef2[1].max
  ) {
    lowIncomeTaxComputation2 =
      lowIncomeTaxRef2[1].tax +
      (totalTaxableIncome - lowIncomeTaxRef2[1].min) *
        lowIncomeTaxRef2[1].excessRate
  } else if (
    totalTaxableIncome > lowIncomeTaxRef2[0].min &&
    totalTaxableIncome < lowIncomeTaxRef2[0].max
  ) {
    lowIncomeTaxComputation2 = lowIncomeTaxRef2[0].tax
  }

  let allLowIncomeTax = -lowIncomeTaxComputation - lowIncomeTaxComputation2

  /** MEDICAL LEVY */
  if (totalTaxableIncome > medicalLevyRef[1].max) {
    medicalLevyComputation =
      medicalLevyRef[2].tax +
      (totalTaxableIncome - medicalLevyRef[1].max) *
        medicalLevyRef[2].excessRate
  } else if (
    totalTaxableIncome > medicalLevyRef[0].max &&
    totalTaxableIncome < medicalLevyRef[1].max
  ) {
    medicalLevyComputation =
      medicalLevyRef[0].tax +
      (totalTaxableIncome - medicalLevyRef[0].max) *
        medicalLevyRef[1].excessRate
  } else {
    medicalLevyComputation =
      (totalTaxableIncome - medicalLevyRef[0].max) *
        medicalLevyRef[0].excessRate +
      medicalLevyRef[0].tax
  }

  let estimatedTaxPaid =
    incomeTaxComputation + allLowIncomeTax + medicalLevyComputation
  if (estimatedTaxPaid < 0) estimatedTaxPaid = 0

  estimatedTaxPaid = Math.round(estimatedTaxPaid)
  return estimatedTaxPaid
}
