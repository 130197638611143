import {makeStyles} from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";

export const useInputStyles = makeStyles({
  input: {
    font: "inherit",
    color: "inherit",
    border: "1px solid #ddd",
    borderRadius: "0.25em",
    padding: "0.5em",
    minWidth: "80%",
  },
});

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({className: classNameProp, ...props}, ref) => {
    const styles = useInputStyles();
    const className = classNames(styles.input, classNameProp);

    return <input ref={ref} className={className} {...props} />;
  }
);

export default Input;
