import React, { useEffect, useState } from "react"
import _ from "lodash"
import AddRequestModal from "./../../../components/AddRequestModal/AddRequestModal"
import RequestItem from "../../../components/RequestItem/RequestItem"
import { i_AdminRequest, UserProps } from "./AdminRequest.interface"
import styles from "./AdminRequest.module.scss"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined"
import FadeLoader from "react-spinners/FadeLoader"
import AddDocumentItemsModal from "../../../components/AddDocumentItemsModal"

import {
  fetchGeneralDocumentList,
  groupDocumentsByDocumentType,
  groupRequestedDocumentsByDocumentType,
  i_Document,
  getDocumentType,
  addGeneralDocument,
  getCurrentUser,
  getRequestById,
  getLastestRequestsByStatus,
  i_resultAPIs,
} from "../../../lib/services"
import {
  AppBar,
  Button,
  Typography,
  List,
  ListItem,
  Toolbar,
  IconButton,
} from "@material-ui/core"
import { EditOutlined } from "@material-ui/icons"
import { RequestHistoryProps, StaffEmailsProps } from "../RequestHistory/RequestHistory.interfaces"
import { AddEditMode } from "../../../components/AddMultiRequestModal"
import moment from "moment"
import RequestReadyModal from "../../../components/RequestReadyModal"
import NoticeModal from "../../../components/NoticeModal"

const plusIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z" />
  </svg>
)

const LockIcon = (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.8125 9.625C4.63016 9.625 4.4553 9.69743 4.32636 9.82636C4.19743 9.9553 4.125 10.1302 4.125 10.3125V18.5625C4.125 18.7448 4.19743 18.9197 4.32636 19.0486C4.4553 19.1776 4.63016 19.25 4.8125 19.25H17.1875C17.3698 19.25 17.5447 19.1776 17.6736 19.0486C17.8026 18.9197 17.875 18.7448 17.875 18.5625V10.3125C17.875 10.1302 17.8026 9.9553 17.6736 9.82636C17.5447 9.69743 17.3698 9.625 17.1875 9.625H4.8125ZM4.8125 8.25H17.1875C17.7345 8.25 18.2591 8.4673 18.6459 8.85409C19.0327 9.24089 19.25 9.76549 19.25 10.3125V18.5625C19.25 19.1095 19.0327 19.6341 18.6459 20.0209C18.2591 20.4077 17.7345 20.625 17.1875 20.625H4.8125C4.26549 20.625 3.74089 20.4077 3.35409 20.0209C2.9673 19.6341 2.75 19.1095 2.75 18.5625V10.3125C2.75 9.76549 2.9673 9.24089 3.35409 8.85409C3.74089 8.4673 4.26549 8.25 4.8125 8.25Z" fill="black" />
    <path d="M11 11.6875C11.1823 11.6875 11.3572 11.7599 11.4861 11.8889C11.6151 12.0178 11.6875 12.1927 11.6875 12.375V16.5C11.6875 16.6823 11.6151 16.8572 11.4861 16.9861C11.3572 17.1151 11.1823 17.1875 11 17.1875C10.8177 17.1875 10.6428 17.1151 10.5139 16.9861C10.3849 16.8572 10.3125 16.6823 10.3125 16.5V12.375C10.3125 12.1927 10.3849 12.0178 10.5139 11.8889C10.6428 11.7599 10.8177 11.6875 11 11.6875ZM15.125 8.25V6.875C15.125 5.78098 14.6904 4.73177 13.9168 3.95818C13.1432 3.1846 12.094 2.75 11 2.75C9.90598 2.75 8.85677 3.1846 8.08318 3.95818C7.3096 4.73177 6.875 5.78098 6.875 6.875V8.25H15.125ZM11 1.375C12.4587 1.375 13.8576 1.95446 14.8891 2.98591C15.9205 4.01736 16.5 5.41631 16.5 6.875V9.625H5.5V6.875C5.5 5.41631 6.07946 4.01736 7.11091 2.98591C8.14236 1.95446 9.54131 1.375 11 1.375Z" fill="black" />
  </svg>
)

const AdminRequest: React.FC<i_AdminRequest> = ({
  setContainerToHaveChanges,
  user_info,
  onSubmit,
  clientId,
  triggerSave,
  triggerDiscard,
  toStateProceed,
  stateToChange,
  onEditRequest,
  requestInfo,
  newRequestHaveChanges,
  clientInfo,
  setMode,
  mode,
  isAdmin
}) => {
  // =================================[ State ]====================================

  const [requestedDocuments, setRequestedDocuments] = useState<i_Document[]>([])
  const [isModalRequested, setIsModalRequested] = useState<boolean>(false)
  const [hasRequested, setHasRequested] = useState<boolean>(false)
  const [documentType, setDocumentType] = useState<string[]>([])
  const [documents, setDocuments] = useState<i_Document[]>([])
  const [showModal, setShowModal] = React.useState(false)
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [fetchedDocuments, setFetchedDocuments] = useState<i_Document[]>([])
  const [selectedDocuments, setSelectedDocuments] = useState<i_Document[]>([])
  const [requestTitle, setRequestTitle] = useState<string>('');
  const [requestId, setRequestId] = useState<String>('');
  const [requestEmails, setRequestEmails] = useState<StaffEmailsProps[]>([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const [isLoadingRequestedDocs, setIsLoadingRequestedDocs] = useState<boolean>(false);
  const [showNoticeModal, setShowNoticeModal] = useState(false)
  const [noticeTextModal, setNoticeTextModal] = useState<string>('')
  const [showDocumentItemsModal, setShowDocumentItemsModal] = useState<boolean>(false)
  const [document, setDocument] = useState({})

  // =================================[ Functions ]==========================

  const documentsList = groupDocumentsByDocumentType(
    documents.filter((d: any) => !d.uid).filter((d: any) => d._id)
  )

  const addDocToList = (document: i_Document) => {
    setRequestedDocuments([...requestedDocuments, document])
    setSelectedDocuments([...selectedDocuments, document])
  }

  const addToList = (e: React.MouseEvent, document: i_Document) => {
    addDocToList({ ...document, notes: "" })
  }

  const removeFromList = (e: React.MouseEvent, document: i_Document) => {
    const reqDocumentsList = requestedDocuments.filter(
      d => d.key !== document.key
    )
    setRequestedDocuments(reqDocumentsList)

    const selectReqDocumentsList = selectedDocuments.filter(
      d => d.key !== document.key
    )
    setSelectedDocuments(selectReqDocumentsList)
  }

  const onNotesChange = (key: number) => (value: string) => {
    const item = _.cloneDeep(requestedDocuments.find(d => d.key === key))
    if (!item) return
    item.notes = value
    const itemIndex = requestedDocuments.findIndex(d => d.key === key)
    const newRequestedDocuments = [
      ...requestedDocuments.slice(0, itemIndex),
      item,
      ...requestedDocuments.slice(itemIndex + 1),
    ]
    setRequestedDocuments(newRequestedDocuments)

    const itemS = _.cloneDeep(selectedDocuments.find(d => d.key === key))
    if (!itemS) return
    itemS.notes = value
    const itemIndexS = selectedDocuments.findIndex(d => d.key === key)
    const newSelectedDocuments = [
      ...selectedDocuments.slice(0, itemIndexS),
      item,
      ...selectedDocuments.slice(itemIndexS + 1),
    ]
    setSelectedDocuments(newSelectedDocuments)
  }

  const onModalSubmit = (documentType: string, fileName: string) => {
    const uid = isModalRequested ? clientId : undefined
    addGeneralDocument(documentType, fileName, uid).then((response: i_resultAPIs) => {
      if (response.status === 'success') {
        fetchGeneralDocumentList().then(res => {
          setDocuments(
            res.filter((doc: i_Document) => !doc.uid && doc.status === "active")
          )
          const document = res.find((doc: i_Document) => doc.label === fileName)
          if (document) {
            addDocToList(document)
          }
        })
        setShowModal(false)
      } else if (response.status === 400) {
        setShowNoticeModal(true);
        setNoticeTextModal(response.message);
      }
    })
  }

  const onModalClose = () => setShowModal(false)

  const onModalOpen = (isRequestedDoc: boolean) => () => {
    setIsModalRequested(isRequestedDoc)
    setShowModal(true)
  }

  const onRequestSubmit = (sender: UserProps, receivers: UserProps[], isFollowUp?: boolean, sequences?: string[]) => {
    onSubmit(requestId, requestTitle, requestEmails, selectedDocuments, sender, receivers, "submitByAdmin", isFollowUp, sequences).then(() => {
      setRequestedDocuments([])
      setSelectedDocuments([])
      setShowEmailModal(false)
      init();
      if (newRequestHaveChanges) newRequestHaveChanges(false);
    })
  }

  const saveDocuments = () => {
    if (selectedDocuments.length > 0) {
      const statusToSave = hasRequested ? 'submitByAdmin' : 'draft';

      onSubmit(requestId, requestTitle, requestEmails, selectedDocuments, null, null, statusToSave, false).then(() => {
        setSelectedDocuments([])
        setRequestedDocuments([])
        init();
        if (newRequestHaveChanges) newRequestHaveChanges(false);

        if (stateToChange) {
          setContainerToHaveChanges(false)
          toStateProceed()
        }
      })
      setShowNoticeModal(false)
      setNoticeTextModal('')
    } else {
      setShowNoticeModal(true)
      setNoticeTextModal('No documents have been selected')
    }
  }

  const discardDocuments = () => {
    requestInfo ? init(requestInfo) : init()
    if (newRequestHaveChanges) newRequestHaveChanges(false)
  }

  const init = async (request?: RequestHistoryProps) => {
    setIsLoadingRequestedDocs(true)
    const docs = await fetchGeneralDocumentList();
    const lastRequests = await getLastestRequestsByStatus(clientId);

    let docList: i_Document[] = [];
    docList = docs.filter((doc: i_Document) => doc.status === "active").map((item: i_Document) => {
      const infoLastest: any = {};
      lastRequests.map((result: RequestHistoryProps) => {
        const found = result.requested_documents.find((itm: any) => itm.dcid === item._id);

        if (found) {
          infoLastest.requestDate = result.createdAt ?? '';
          infoLastest.requestEmail = result?.staff?.email ?? '';
          infoLastest.requestName = result?.name ?? '';
        }

      })

      return {
        ...item,
        infoLastest
      }
    })

    setDocuments(docList)
    setDocumentType(getDocumentType(docs))

    if (request) {
      const result = await getRequestById(request?._id)

      if (result?.status === "submitByAdmin") {
        setHasRequested(true);
      } else {
        setHasRequested(false);
      }

      if (result?.status && ['draft', 'submitByAdmin', 'saveByAdmin'].includes(result.status)) {
        const saved = result?.requested_documents.map((document: i_Document) => {
          const infoLastest: any = {};

          lastRequests.map((result: RequestHistoryProps) => {
            const found = result.requested_documents.find((itm: any) => document?.dcid && (document.dcid._id === itm.dcid));

            if (found) {
              infoLastest.requestDate = result.createdAt ?? '';
              infoLastest.requestEmail = result?.staff?.email ?? '';
              infoLastest.requestName = result?.name ?? '';
            }
          })

          // NEW : no linked dcid from settings
          if (!document.dcid) {
            return {
              _id: document._id,
              documentType: document.documentType,
              label: document.documentLabel,
              notes: document.notes,
              files: document?.files ?? [],
              isRestrict: document.isRestrict,
              roles: document.roles && [...document.roles],
              infoLastest
            }
          } else {
            // LEGACY : label and type is fetched from settings
            return {
              _id: document._id,
              dcid: { ...document.dcid },
              ...document.dcid,
              documentType: document.documentType || document.dcid.documentType,
              label: document.documentLabel || document.dcid.label,
              notes: document.notes,
              files: document?.files ?? [],
              isRestrict: document.isRestrict !== undefined ? document.isRestrict : document.dcid.isRestrict,
              roles: document.roles ? [...document.roles] : document.dcid.roles && [...document.dcid.roles],
              infoLastest
            }
          }
        })

        setRequestedDocuments(saved)
        setFetchedDocuments(saved)
        setSelectedDocuments(saved)
        setIsLoadingRequestedDocs(false)
      } else {
        setRequestedDocuments([])
        setFetchedDocuments([])
        setSelectedDocuments([])
        setIsLoadingRequestedDocs(false)
      }
    } else {
      setRequestedDocuments([])
      setFetchedDocuments([])
      setSelectedDocuments([])
      setIsLoadingRequestedDocs(false)
    }
  }

  /**
   * Function for showing restriction model
   * @param _ 
   * @param document 
   */
  const showAccessUserRoles = (_: React.MouseEvent, document: i_Document) => {
    setShowDocumentItemsModal(true)
    setMode("show access roles")
    setDocument(document)
  }
  // =================================[ Hooks ]==========================
  useEffect(() => {
    // init();
    getCurrentUser("users/me").then(userInfo => {
      if (userInfo && userInfo.role === 'super_admin') {
        setIsSuperAdmin(true)
      }
    });
  }, [])

  useEffect(() => {
    // console.log({ triggerSave, triggerDiscard })
    if (triggerSave) {
      saveDocuments()
    }
    if (triggerDiscard) {
      discardDocuments()
    }
  }, [triggerSave, triggerDiscard])

  useEffect(() => {
    if (requestInfo) {
      setRequestTitle(requestInfo.name ?? "");
      setRequestEmails(requestInfo.staffEmails ?? []);
      setRequestId(requestInfo._id ?? "");
    }
    init(requestInfo);
  }, [requestInfo])

  useEffect(() => {
    if (fetchedDocuments && requestedDocuments) {
      if (_.isEqual(fetchedDocuments, requestedDocuments)) {
        if (newRequestHaveChanges) newRequestHaveChanges(false)
      } else {
        if (newRequestHaveChanges) newRequestHaveChanges(true)
      }
    } else {
      if (newRequestHaveChanges) newRequestHaveChanges(true)
    }
  }, [fetchedDocuments, requestedDocuments])

  // =================================[ Renders ]==========================
  const renderDocumentType = (isRequested: boolean) => (
    documentsOnDocumentType: i_Document[],
    documentType: string
  ) => {
    const userRole = isAdmin && isAdmin['input'];
    return (
      <React.Fragment>
        <ListItem
          key={`list-item-documentType-${documentType}`}
          component="a"
          className={styles.listDocumentType}
        >
          {documentType}
        </ListItem>
        {documentsOnDocumentType.map((document, i) => {
          return document.label && (document.roles === undefined || document.roles !== undefined && document.roles.includes(userRole)) && <ListItem key={`list-item-${i}`}>
            <div className={styles.onDocumentTypeGroup}>
              <div className={styles.onlockIconAndLabel}>
                {document.isRestrict &&
                  <IconButton
                    classes={{ root: styles.iconButtons }}
                    onClick={e => showAccessUserRoles(e, document)}
                  >
                    {LockIcon}
                  </IconButton>
                }
                <span>{document.label}</span>
              </div>
              {
                document?.infoLastest && !_.isEmpty(document.infoLastest)
                && <span className={styles.onDocumentTypeNote}>
                  {`Last requested: ${moment(document.infoLastest.requestDate).format("DD MMM YYYY")} by ${document.infoLastest.requestEmail} - ${document.infoLastest.requestName.toUpperCase()}`}
                </span>
              }
            </div>
            <div>
              {isRequested && (
                <RequestItem
                  onEdit={onNotesChange(document.key)}
                  notes={document.notes || null}
                  isDuplicate={document.isDuplicate}
                />
              )}
              {isRequested ? (
                <IconButton
                  key={`document-${document._id}`}
                  onClick={e => removeFromList(e, document)}
                  classes={{ root: styles.iconButtons }}
                >
                  <RemoveCircleOutlineOutlinedIcon
                    style={{ color: '#4e185b', fontSize: 30 }}
                  />
                </IconButton>
              ) : (
                <IconButton
                  key={`document-${document._id}`}
                  onClick={e => addToList(e, document)}
                  classes={{ root: styles.iconButtons }}
                >
                  <AddCircleOutlineIcon
                    style={{ color: '#4e185b', fontSize: 30 }}
                  />
                </IconButton>
              )}
            </div>
          </ListItem>
        })}
      </React.Fragment>
    )
  }
  const isSame = (fetched: any, r: any) => {
    if (fetched && r) {
      if (_.isEqual(fetched, r)) {
        setContainerToHaveChanges(false)
        return true
      }
      setContainerToHaveChanges(true)
      return false
    } else {
      setContainerToHaveChanges(false)
      return true
    }
  }

  const onOpenReadyModal = () => {
    if (requestedDocuments.length > 0) {
      setShowEmailModal(true);
      setShowNoticeModal(false);
      setNoticeTextModal('')
    } else {
      setShowEmailModal(false);
      setShowNoticeModal(true);
      setNoticeTextModal('No documents have been selected')

    }
  }

  const requestedDocumentsList = groupRequestedDocumentsByDocumentType(
    requestedDocuments
  )

  return (
    <>
      <div className={styles.root}>
        <div className={styles.headerWrapper}>
          <div className={styles.documentsName}>
            <span>{requestTitle}</span>
            <IconButton classes={{ root: styles.editRequestIconWrapper }} onClick={() => { if (onEditRequest) onEditRequest(AddEditMode.EDIT, null, true) }}>
              <EditOutlined classes={{ root: styles.editRequestIcon }} htmlColor="#541868" />
            </IconButton>
          </div>
          <Button
            variant="contained"
            className={styles.sendButton}
            classes={{ disabled: styles.disabledButton }}
            onClick={onOpenReadyModal}
            disabled={hasRequested}
          >
            Send Request to Client
          </Button>
        </div>
        <div className={styles.documentsList}>
          <div style={{ flex: "2" }}>
            <AppBar position="static" className={styles.appbar}>
              <Toolbar className={styles.toolbar}>
                <Typography variant="h4">Documents List</Typography>
                {
                  isSuperAdmin &&
                  <a
                    href="#"
                    className={styles.addButton}
                    onClick={onModalOpen(false)}
                  >
                    {" "}
                    {/* eslint-disable-line */}
                    {plusIcon}
                  </a>
                }
                {" "}
              </Toolbar>
            </AppBar>
            <List className={styles.listWrapper}>
              {Object.keys(documentsList).length > 0 ? (
                Object.keys(documentsList)
                  .sort()
                  .map(key =>
                    renderDocumentType(false)(documentsList[key], key)
                  )
              ) : (
                <li>Loading...</li>
              )}
            </List>
          </div>
          <div style={{ flex: "3" }}>
            <AppBar position="static" className={styles.appbar}>
              <Toolbar className={styles.toolbar}>
                <Typography variant="h4">Requested Documents</Typography>
                <a
                  href="#"
                  className={styles.addButton}
                  onClick={onModalOpen(true)}
                >
                  {" "}
                  {/* eslint-disable-line */}
                  {plusIcon}
                </a>{" "}
              </Toolbar>
            </AppBar>

            {hasRequested && (
              <p className={styles.hasRequestNote}>
                <strong>NOTE:</strong> There is currently a request outstanding
                with this client that has not yet been fulfilled. Check the
                outstanding request <strong>BEFORE</strong> making a new
                request.
              </p>
            )}

            <List className={styles.listWrapper}>
              {
                isLoadingRequestedDocs ? <div className={[styles.spinner, "loading-spinner", styles.noFixed].join(" ")}>
                  <FadeLoader height={15} width={5} radius={10} margin={10} color="#4e185b" />
                </div> : <>
                  {Object.keys(requestedDocumentsList).length > 0
                    ? Object.keys(requestedDocumentsList)
                      .sort()
                      .map(key =>
                        renderDocumentType(true)(requestedDocumentsList[key], key)
                      )
                    : !hasRequested && <li>No data yet.</li>}
                </>
              }
            </List>

            {!isSame(fetchedDocuments, requestedDocuments) && (
              <div className={styles.bottomButton}>
                <Button
                  variant="contained"
                  className={styles.saveDiscardButton}
                  onClick={saveDocuments}
                >
                  Save
                </Button>

                <Button
                  variant="contained"
                  className={styles.saveDiscardButton}
                  onClick={discardDocuments}
                >
                  Discard
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <AddRequestModal
        documentType={documentType}
        showModal={showModal}
        onClose={onModalClose}
        onSubmit={onModalSubmit}
      />
      {
        requestedDocuments && (
          <RequestReadyModal
            user_info={user_info}
            clientInfo={clientInfo}
            showModal={showEmailModal}
            onClose={() => setShowEmailModal(false)}
            onSubmit={onRequestSubmit}
          />
        )
      }
      <NoticeModal
        showModal={showNoticeModal}
        onClose={() => setShowNoticeModal(false)}
        infoText={noticeTextModal}
      />
      <AddDocumentItemsModal
        showModal={showDocumentItemsModal}
        onClose={() => setShowDocumentItemsModal(false)}
        clientId={clientId}
        setMode={setMode}
        mode={mode}
        document={document}
      />
    </>
  )
}

export default AdminRequest
