import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { TextareaAutosize } from "@material-ui/core";
import { useMemo, useCallback } from "react";
import { mapTextSlug } from "../../lib/textMap";
import Input, { useInputStyles } from "../../components/Form/Input";
import { ColorPrimary } from "../../lib/theme";
import { SurveyItemTextInput } from "./surveys.types";
import { UseMemoFormResult } from "../../lib/useMemoForm";

const cssFocusProps = {
  outline: `${ColorPrimary} auto 1px`,
};

const useStyles = makeStyles({
  main: {
    display: "flex",
    marginTop: "1em",
    marginBottom: "1em",
    alignItems: "center",
  },
  labelWrap: {
    flexGrow: 1,
    flexBasis: 0,
    textAlign: "right",
    marginRight: "2em",
  },
  label: {
    color: ColorPrimary,
    fontWeight: 400,
  },
  inputWrap: {
    flexGrow: 2,
    flexBasis: 0,
  },
  input: {
    width: "100%",
    "&:focus-visible": cssFocusProps,
  },
  textarea: {
    padding: "0.5em !important",
    "&:focus-visible": cssFocusProps,
  },
});

export type SurveyTextInputProps = {
  data: SurveyItemTextInput;
  form: UseMemoFormResult;
  // eslint-disable-next-line no-unused-vars
  onChange?: (name: string, value: string) => void;
  isReadonly?: boolean;
  initialValue?: string;
};

const SurveyTextInput = ({
  data: { inputType, label: inputLabel, inputName, required, initialRows },
  form: { register, setValue },
  onChange,
  isReadonly,
  initialValue,
}: SurveyTextInputProps) => {
  const styles = useStyles();
  const inputStyles = useInputStyles();

  const inputRegisterProps = useMemo(
    () => register(inputName, { required }),
    [inputName, register, required]
  );

  const handleOnChange = useCallback(
    (event: any) => {
      inputRegisterProps.onChange(event);
      if (onChange) onChange(inputName, event.currentTarget.value);
    },
    [inputName, inputRegisterProps, onChange]
  );

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setValue(inputName, event.currentTarget.value.trim());
      inputRegisterProps.onBlur(event);
    },
    [inputName, inputRegisterProps, setValue]
  );

  return (
    <div className={styles.main}>
      {inputLabel ? (
        <div className={styles.labelWrap}>
          <label className={styles.label} htmlFor={inputName}>
            {mapTextSlug(inputLabel)}
          </label>
        </div>
      ) : null}

      <div className={styles.inputWrap}>
        {inputType === "textarea" ? (
          <TextareaAutosize
            {...inputRegisterProps}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            rowsMin={initialRows}
            rowsMax={10}
            className={classNames(inputStyles.input, styles.textarea)}
            disabled={isReadonly}
            defaultValue={initialValue}
          />
        ) : (
          <Input
            {...inputRegisterProps}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            className={classNames(styles.input)}
            disabled={isReadonly}
            defaultValue={initialValue}
          />
        )}
      </div>
    </div>
  );
};

export default SurveyTextInput;
