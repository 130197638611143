import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import * as React from "react";
import { useThemeStyles } from "../../lib/theme";

export type AddIconButtonProps = {
  onClick?: () => void;
};

const AddIconButton = ({ onClick }: AddIconButtonProps) => {
  const styles = useThemeStyles();

  return (
    <IconButton
      className={styles.iconContainedPrimary}
      aria-label="add"
      color="primary"
      size="small"
      onClick={onClick}
    >
      <AddIcon fontSize="large" />
    </IconButton>
  );
};

export default React.memo(AddIconButton);
