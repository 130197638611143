import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, {
  useState,
  useMemo,
  useCallback,
  BaseSyntheticEvent,
  useRef,
} from "react";
import { dequal } from "dequal/lite";
import { delayFunction } from "../../lib/function";
import FormText from "./FormText";
import SurveyItem from "./SurveyItem";
import {
  GETSurveyData,
  SurveyItemDependencies,
  SurveyItemValue,
  VisibilityMap,
} from "./surveys.types";
import {
  createUseFormHandleSubmit,
  getVisibilityMapOfValues,
} from "./surveys.utils";
import useMemoForm from "../../lib/useMemoForm";
import Asterisk from "../../components/Form/Asterisk";
import QuickModal from "../../components/modals/QuickModal";
import { t } from "../../lib/textMap";
import { putLendingSurvey } from "../../lib/lendingSurveysService";
import ConfirmSubmitSurveyModal from "./ConfirmSubmitSurveyModal";

const useStyles = makeStyles({
  actions: {
    paddingTop: "1em",
    display: "flex",
    justifyContent: "center",
  },
});

export type SurveyFormProps = {
  surveyId: string;
  initialValues?: GETSurveyData["doc"]["values"];
  data: {
    items: SurveyItemValue[];
    dependencies: SurveyItemDependencies;
  };
  isReadonly?: boolean;
  onSave?: () => void;
};

const SurveyForm = ({
  surveyId,
  initialValues,
  data,
  isReadonly,
  onSave,
}: SurveyFormProps) => {
  const styles = useStyles();

  // const result = useQuery('/api/mbworkbench/lending-surveys?status=pending', async () => [{}])

  const [visibilityMap, setVisibilityMap] = useState<VisibilityMap>(() =>
    getVisibilityMapOfValues(initialValues, data.dependencies)
  );

  const [incomplete, setIncomplete] = useState(false);

  const [isError, setIsError] = useState(false);

  const [isConfirm, setIsConfirm] = useState(false);

  const closeIncompleteAlert = useCallback(() => setIncomplete(false), []);
  const closeErrorAlert = useCallback(() => setIsError(false), []);

  const form = useMemoForm();

  const { getValues } = form;

  const canSubmitRef = useRef(true);

  const delayOnChange = useMemo(
    () =>
      delayFunction(() => {
        const newVisibilityMap = getVisibilityMapOfValues(
          getValues(),
          data.dependencies
        );

        if (!dequal(visibilityMap, newVisibilityMap)) {
          setVisibilityMap(newVisibilityMap);
        }

        canSubmitRef.current = true;
      }, 1000 / 3),
    [data.dependencies, getValues, visibilityMap]
  );

  const handleOnChange = useCallback(() => {
    canSubmitRef.current = false;

    delayOnChange();
  }, [delayOnChange]);

  const handleSubmit = useMemo(() => {
    const handleSubmitTemp = createUseFormHandleSubmit(
      () => {
        setIsConfirm(true);
      },
      data,
      form,
      () => {
        setIncomplete(true);
      }
    );

    return (ev: BaseSyntheticEvent) => {
      if (!canSubmitRef.current) return;

      handleSubmitTemp(ev);
    };
  }, [data, form]);

  return (
    <>
      {data.items.map((surveyItem) => {
        if ("textId" in surveyItem) {
          if (visibilityMap[surveyItem.textId] === false) return <></>;

          return (
            <p>
              <FormText key={surveyItem.textId} data={surveyItem.data} />{" "}
              <Asterisk isVisible={surveyItem.hasAsterisk} />
            </p>
          );
        }

        return (
          <SurveyItem
            key={surveyItem.inputName}
            data={surveyItem}
            form={form}
            onChange={handleOnChange}
            isVisible={visibilityMap[surveyItem.inputName]}
            isReadonly={isReadonly}
            initialValues={initialValues}
          />
        );
      })}

      {isReadonly ? null : (
        <>
          <div className={styles.actions}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSubmit}
            >
              {t("acceptAndSubmit")}
            </Button>
          </div>

          <ConfirmSubmitSurveyModal
            open={isConfirm}
            onClose={() => {
              setIsConfirm(false);
            }}
            onSubmit={() => {
              putLendingSurvey(surveyId, getValues())
                .then(() => {
                  if (onSave) onSave();
                })
                .catch(() => {
                  setIsError(true);
                });
            }}
          />

          <QuickModal
            isVisible={incomplete}
            onClose={closeIncompleteAlert}
            title={t("lendingRAndO")}
          >
            <Typography>
              {t("missingAndCompleteFields")} ({t("markedWithAsterisk")}{" "}
              <Asterisk isVisible />)
            </Typography>
          </QuickModal>

          <QuickModal
            isVisible={isError}
            onClose={closeErrorAlert}
            title={t("lendingRAndO")}
          >
            <Typography>{t("submitError")}</Typography>
          </QuickModal>
        </>
      )}
    </>
  );
};

export default React.memo(SurveyForm);
