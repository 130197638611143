import React, { useEffect, useRef, useState } from "react";
import EditIcon from "../../assets/pencil-outline-violet.svg";
import { adminTemplateConstants } from "../../lib/constants";
import styles from "./InlineEdit.module.scss";

const InlineEdit: React.FC<i_InlineEdit> = ({ value, setValue }) => {
    const [editingValue, setEditingValue] = useState<string>(adminTemplateConstants.DEFAULT_NAME);
    const inputRef = useRef<HTMLInputElement>(null);
    
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setEditingValue(event.target.value);
    
    const onKeyDown = (event: any) => {
        if (event.key === "Enter" || event.key === "Escape") {
            event.target.blur();
        }
    }
  
    const onBlur = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event.target.value.trim() === "") {
            setEditingValue(value.trim());
        } else {
            setValue(event.target.value.trim())
        }
    }

    const handleClick = () => {
        inputRef.current && inputRef.current.focus();
    };

    useEffect(() => {
        setEditingValue(value);
    }, [value])

    return <div className={styles.documentsName}>
        <input
            type="text"
            aria-label="Field name"
            ref={inputRef}
            value={editingValue}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            style={{ width: ((editingValue.length + 1) * 14) + 'px' }}
        />
        <img 
            src={EditIcon} 
            className={styles.editTemplatetIcon} 
            onClick={handleClick}
            alt="Edit name icon"
        />
    </div>
};

interface i_InlineEdit {
    value: any
    setValue: Function
}

export default InlineEdit