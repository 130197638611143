const textMap: {
  [textSlug: string]: string | undefined;
} = {
  howComfortable:
    "To get an understanding of how comfortable you are with lending please select one of the following",
  needHelpToUnderstand:
    "I need a lot of help to understand how my loan works and my financial situation",
  understandBasics:
    "I understand the basics of how a loan works, but don’t know what specifics features do",
  veryGoodUnderstanding:
    "I have a very good understanding of how loans work and the associated features",
  describeLendingNeeds:
    "Please describe in your own words the following requirements & objectives for your lending needs:",
  purposeOfLoan: "Purpose of loan sought",
  amountCreditSought: "Amount of credit sought",
  loanTerm: "Loan term required",
  repaymentTypes: "Repayment types required and why",
  rankProductFeatures:
    "Please rank at least three of your top preferences in product features",
  fixedRate: "Fixed Rate",
  varRate: "Variable Rate",
  fixedVarSplit: "Fixed & Variable Rate Loan Splits",
  principleInterest: "Principle & Interest",
  interestOnly: "Interest Only",
  offset: "Offset/redraw",
  creditCard: "Credit Card",
  packageProducts: "Package Products",
  addComment: "Please add in any comments on the above",
  rankRequirements:
    "Please rank at least three of your top preferences for other requirements & considerations",
  financeClause: "Subject to finance clause",
  settlementDeadline: "Settlement deadline",
  preApproveReq: "Pre-approval Requirement",
  specLender: "Specific lender",
  lmiReq: "LMI requirements",
  branchAccess: "Branch access",
  creditLine: "Line of credit",
  eBank: "Electronic banking",
  contructionLoan: "Construction loan",
  addPayments: "Ability to make additional payments",
  lenderOffers: "Lender cashback/special offers",
  changesInTheFuture1:
    "Are there any significant changes in the future which will affect your ability to meet your financial commitments?",
  changesInTheFuture2:
    "Ie: Employment Stability? Future Children? Financial Milestones?",
  yes: "Yes",
  no: "No",
  explainFuture: "Please explain your future changes",
  createdOn: "Created On",
  updatedOn: "Modified On",
  openState: "In Progress",
  doneState: "Completed",
  acceptAndSubmit: "Accept & Submit",
  missingAndCompleteFields:
    "We are missing some answers. Please complete all required fields.",
  lendingRAndO: "Lending Requirements & Objectives",
  markedWithAsterisk: "They are marked with an asterisk",
  submitError:
    "There was an error while submitting. Please try again later or refresh the page.",
};

export default textMap;

export function mapTextSlug(textSlug: string) {
  return textMap[textSlug] || textSlug;
}

export const t = mapTextSlug;
