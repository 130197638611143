import React, { useContext, useState } from "react"
import ShowChangesTable from "../ShowChangesTable"
import {
  findBySummaryId,
  compareItems,
  formatCurrency,
  selectedOwner,
} from "../../../../../../lib/utils"
import ClientChangesContext from "../../../../../../components/Context/ClientChangesContext"

const FinancialAssetChangesAddition = () => {
  const { financialAssetAddition, ACStates, setACStates } = useContext(
    ClientChangesContext
  )

  const indexOnType: boolean[] = []
  const indexOnBalance: boolean[] = []
  const indexOnOwner: boolean[] = []

  financialAssetAddition &&
    financialAssetAddition.typeChanges.forEach((asset: any) =>
      indexOnType.push(false)
    )

  financialAssetAddition &&
    financialAssetAddition.balanceChanges.forEach((asset: any) =>
      indexOnBalance.push(false)
    )

  financialAssetAddition &&
    financialAssetAddition.ownerChanges.forEach((asset: any) =>
      indexOnOwner.push(false)
    )

  const [switchIndexType, setSwitchIndexType] = useState<boolean[]>(indexOnType)
  const [switchIndexBalance, setSwitchIndexBalance] = useState<boolean[]>(
    indexOnBalance
  )
  const [switchIndexOwner, setSwitchIndexOwner] = useState<boolean[]>(
    indexOnOwner
  )

  const changePropertyHandler = (
    objProp: string,
    currValue: any,
    prevValue: any,
    summaryId: string,
    newSwitch: boolean[],
    index: number
  ) => {
    const updateValues = ACStates?.financialAssets.map((asset: any) => {
      if (asset.summaryId === summaryId) {
        if (newSwitch !== undefined && newSwitch[index]) {
          asset[objProp] = currValue
        } else {
          asset[objProp] = prevValue
        }
      }
      return asset
    })

    const shouldAddValue = updateValues.find(
      (item: { summaryId: string }) => item.summaryId === summaryId
    )

    if (shouldAddValue === undefined) {
      let newItem: any = {
        type: "",
        balance: 0,
        owner: "",
        summaryId,
      }

      if (newSwitch !== undefined && newSwitch[index]) {
        newItem[objProp] = currValue
      } else {
        newItem[objProp] = prevValue
      }

      updateValues.push(newItem)
    } else {
      const checkAddedType = findBySummaryId(
        financialAssetAddition?.typeChanges,
        summaryId
      )
      const checkAddedBalance = findBySummaryId(
        financialAssetAddition?.balanceChanges,
        summaryId
      )
      const checkAddedOwner = findBySummaryId(
        financialAssetAddition?.ownerChanges,
        summaryId
      )

      const checkUpdatedValues = findBySummaryId(
        ACStates?.financialAssets,
        summaryId
      )

      if (
        compareItems(checkAddedType.prevValType, checkUpdatedValues.type) &&
        compareItems(
          checkAddedBalance.prevBalance,
          checkUpdatedValues.balance
        ) &&
        compareItems(checkAddedOwner.prevValOwner, checkUpdatedValues.owner)
      ) {
        const position = updateValues.findIndex(
          (uValues: { summaryId: string }) => uValues.summaryId === summaryId
        )
        if (position !== -1) updateValues.splice(position, 1)
      }
    }
    return updateValues
  }

  const financialAssetAdditionHandler = (
    summaryId: string,
    index: number,
    currValue: any,
    prevValue: any,
    toChange: string
  ) => {
    let newSwitch: boolean[]
    let changeValue
    switch (toChange) {
      case "type":
        newSwitch = switchIndexType && [...switchIndexType]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexType(newSwitch)
        break
      case "balance":
        newSwitch = switchIndexBalance && [...switchIndexBalance]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexBalance(newSwitch)
        break
      case "owner":
        newSwitch = switchIndexOwner && [...switchIndexOwner]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexOwner(newSwitch)
        break
      default:
        newSwitch = []
    }

    if (ACStates !== undefined) {
      setACStates && setACStates({ ...ACStates, financialAssets: changeValue })
    }
  }

  return (
    <>
      {financialAssetAddition !== undefined &&
      financialAssetAddition.typeChanges.length !== 0 ? (
        financialAssetAddition.typeChanges.map(
          (
            rental: {
              prevValType: any
              currValType: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevValType, currValType, summaryId } = rental
            return (
              <ShowChangesTable
                prevVal={prevValType}
                currVal={currValType}
                index={index}
                switchIndex={switchIndexType}
                updatesCaller={() => {
                  financialAssetAdditionHandler(
                    summaryId,
                    index,
                    currValType,
                    prevValType,
                    "type"
                  )
                }}
                type="faTypeChanges"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {financialAssetAddition !== undefined &&
      financialAssetAddition.balanceChanges.length !== 0 ? (
        financialAssetAddition.balanceChanges.map(
          (
            rental: {
              prevBalance: any
              currBalance: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevBalance, currBalance, summaryId } = rental
            return (
              <ShowChangesTable
                prevVal={formatCurrency(prevBalance)}
                currVal={formatCurrency(currBalance)}
                index={index}
                switchIndex={switchIndexBalance}
                updatesCaller={() => {
                  financialAssetAdditionHandler(
                    summaryId,
                    index,
                    currBalance,
                    prevBalance,
                    "balance"
                  )
                }}
                type="faBalanceChanges"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {financialAssetAddition !== undefined &&
      financialAssetAddition.ownerChanges.length !== 0 ? (
        financialAssetAddition.ownerChanges.map(
          (
            rental: {
              prevValOwner: any
              currValOwner: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevValOwner, currValOwner, summaryId } = rental
            return (
              <ShowChangesTable
                prevVal={prevValOwner}
                currVal={selectedOwner(currValOwner)}
                index={index}
                switchIndex={switchIndexOwner}
                updatesCaller={() => {
                  financialAssetAdditionHandler(
                    summaryId,
                    index,
                    currValOwner,
                    prevValOwner,
                    "owner"
                  )
                }}
                type="faOwnerChanges"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
    </>
  )
}

export default FinancialAssetChangesAddition
