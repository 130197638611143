import React, { useContext, useState } from "react"
import ShowChangesTable from "../ShowChangesTable"
import ClientChangesContext from "../../../../../../components/Context/ClientChangesContext"
import {
  findBySummaryId,
  compareItems,
  formatCurrency,
} from "../../../../../../lib/utils"

const RentalChangesAddition = () => {
  const { rentalAddition, ACStates, setACStates } = useContext(
    ClientChangesContext
  )

  const indexOnAddress: boolean[] = []
  const indexOnOwnership1: boolean[] = []
  const indexOnPropertyValue: boolean[] = []
  const indexOnOwnership2: boolean[] = []
  const indexOnRental: boolean[] = []

  rentalAddition &&
    rentalAddition.addressChanges.forEach((rental: any) =>
      indexOnAddress.push(false)
    )

  rentalAddition &&
    rentalAddition.ownership1Changes.forEach((rental: any) =>
      indexOnOwnership1.push(false)
    )

  rentalAddition &&
    rentalAddition.propertyValueChanges.forEach((rental: any) =>
      indexOnPropertyValue.push(false)
    )

  rentalAddition &&
    rentalAddition.ownership2Changes.forEach((rental: any) =>
      indexOnOwnership2.push(false)
    )

  rentalAddition &&
    rentalAddition.rentChanges.forEach((rental: any) =>
      indexOnRental.push(false)
    )

  const [switchIndexAddress, setSwitchIndexAddress] = useState<boolean[]>(
    indexOnAddress
  )
  const [switchIndexOwnership1, setSwitchIndexOwnership1] = useState<boolean[]>(
    indexOnOwnership1
  )
  const [switchIndexPropertyValue, setSwitchIndexPropertyValue] = useState<
    boolean[]
  >(indexOnPropertyValue)
  const [switchIndexOwnership2, setSwitchIndexOwnership2] = useState<boolean[]>(
    indexOnOwnership2
  )
  const [switchIndexRental, setSwitchIndexRental] = useState<boolean[]>(
    indexOnRental
  )

  const changePropertyHandler = (
    objProp: string,
    currValue: string | boolean | number,
    prevValue: string | boolean | number,
    summaryId: string,
    newSwitch: boolean[],
    index: number
  ) => {
    const updateValues = ACStates?.rentalIncome.map((rental: any) => {
      if (rental.summaryId === summaryId) {
        if (newSwitch !== undefined && newSwitch[index]) {
          rental[objProp] = currValue
        } else {
          rental[objProp] = prevValue
        }
      }
      return rental
    })

    const shouldAddValue = findBySummaryId(updateValues, summaryId)

    if (shouldAddValue === undefined) {
      let newItem: any = {
        address: "",
        isCustom: false,
        ownershipPercentageClient1: 0,
        ownershipPercentageClient2: 0,
        period: "Monthly",
        propertyValue: 0,
        rent: 0,
        summaryId,
      }

      if (newSwitch !== undefined && newSwitch[index]) {
        newItem[objProp] = currValue
      }

      updateValues.push(newItem)
    } else {
      const checkAddedAddress = findBySummaryId(
        rentalAddition?.addressChanges,
        summaryId
      )
      const checkAddedOwnership1 = findBySummaryId(
        rentalAddition?.ownership1Changes,
        summaryId
      )
      const checkAddedOwnership2 = findBySummaryId(
        rentalAddition?.ownership2Changes,
        summaryId
      )
      const checkAddedPropertyValue = findBySummaryId(
        rentalAddition?.propertyValueChanges,
        summaryId
      )

      const checkAddedRentalIncome = findBySummaryId(
        rentalAddition?.rentChanges,
        summaryId
      )

      const checkUpdatedValues = findBySummaryId(
        ACStates?.rentalIncome,
        summaryId
      )

      if (
        compareItems(
          checkAddedAddress.prevValAddress,
          checkUpdatedValues.address
        ) &&
        compareItems(
          checkAddedOwnership1.prevOwnership1,
          checkUpdatedValues.ownershipPercentageClient1
        ) &&
        compareItems(
          checkAddedOwnership2.prevOwnership2,
          checkUpdatedValues.ownershipPercentageClient2
        ) &&
        compareItems(
          checkAddedRentalIncome.prevRent,
          checkUpdatedValues.rent
        ) &&
        compareItems(
          checkAddedPropertyValue.prevPropertyValue,
          checkUpdatedValues.propertyValue
        )
      ) {
        const position = updateValues.findIndex(
          (uValues: { summaryId: string }) => uValues.summaryId === summaryId
        )
        if (position !== -1) updateValues.splice(position, 1)
      }
    }
    return updateValues
  }

  const rentalAdditionHandler = (
    summaryId: string,
    index: number,
    currValue: any,
    prevValue: any,
    toChange: string
  ) => {
    let newSwitch: boolean[]
    let changeValue
    switch (toChange) {
      case "address":
        newSwitch = switchIndexAddress && [...switchIndexAddress]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexAddress(newSwitch)
        break
      case "ownershipPercentageClient1":
        newSwitch = switchIndexOwnership1 && [...switchIndexOwnership1]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexOwnership1(newSwitch)
        break
      case "ownershipPercentageClient2":
        newSwitch = switchIndexOwnership2 && [...switchIndexOwnership2]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexOwnership2(newSwitch)
        break
      case "propertyValue":
        newSwitch = switchIndexPropertyValue && [...switchIndexPropertyValue]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexPropertyValue(newSwitch)
        break
      case "rent":
        newSwitch = switchIndexRental && [...switchIndexRental]
        if (newSwitch?.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexRental(newSwitch)
      default:
        newSwitch = []
    }

    if (ACStates !== undefined) {
      setACStates && setACStates({ ...ACStates, rentalIncome: changeValue })
    }
  }

  return (
    <>
      {/* start of rental address changes */}
      {rentalAddition?.addressChanges.length !== 0 ? (
        rentalAddition?.addressChanges.map(
          (
            rental: {
              prevValAddress: any
              currValAddress: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevValAddress, currValAddress, summaryId } = rental
            return (
              <ShowChangesTable
                prevVal=""
                currVal={currValAddress}
                index={index}
                switchIndex={switchIndexAddress}
                updatesCaller={() => {
                  rentalAdditionHandler(
                    summaryId,
                    index,
                    currValAddress,
                    prevValAddress,
                    "address"
                  )
                }}
                type="address"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental address changes */}
      {/* start of rental ownership1Changes changes */}
      {rentalAddition?.ownership1Changes.length !== 0 ? (
        rentalAddition?.ownership1Changes.map(
          (
            rental: {
              prevOwnership1: any
              currOwnership1: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevOwnership1, currOwnership1, summaryId } = rental
            return (
              <ShowChangesTable
                prevVal=""
                currVal={currOwnership1}
                index={index}
                switchIndex={switchIndexOwnership1}
                updatesCaller={() => {
                  rentalAdditionHandler(
                    summaryId,
                    index,
                    currOwnership1,
                    prevOwnership1,
                    "ownershipPercentageClient1"
                  )
                }}
                type="ownership1"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental ownership1Changes changes */}
      {/* start of rental ownership2Changes changes */}
      {rentalAddition?.ownership2Changes.length !== 0 ? (
        rentalAddition?.ownership2Changes.map(
          (
            rental: {
              prevOwnership2: any
              currOwnership2: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevOwnership2, currOwnership2, summaryId } = rental
            return (
              <ShowChangesTable
                prevVal=""
                currVal={currOwnership2}
                index={index}
                switchIndex={switchIndexOwnership2}
                updatesCaller={() => {
                  rentalAdditionHandler(
                    summaryId,
                    index,
                    currOwnership2,
                    prevOwnership2,
                    "ownershipPercentageClient2"
                  )
                }}
                type="ownership2"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental ownership2Changes changes */}
      {/* start of rental property value changes */}
      {rentalAddition?.propertyValueChanges.length !== 0 ? (
        rentalAddition?.propertyValueChanges.map(
          (
            rental: {
              prevPropertyValue: any
              currPropertyValue: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevPropertyValue, currPropertyValue, summaryId } = rental
            return (
              <ShowChangesTable
                prevVal=""
                currVal={formatCurrency(currPropertyValue)}
                index={index}
                switchIndex={switchIndexPropertyValue}
                updatesCaller={() => {
                  rentalAdditionHandler(
                    summaryId,
                    index,
                    currPropertyValue,
                    prevPropertyValue,
                    "propertyValue"
                  )
                }}
                type="propertyValueChanges"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental property value changes */}
      {/* start of rental income value changes */}
      {rentalAddition?.rentChanges.length !== 0 ? (
        rentalAddition?.rentChanges.map(
          (
            rental: {
              prevRent: any
              currRent: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevRent, currRent, summaryId } = rental
            return (
              <ShowChangesTable
                prevVal=""
                currVal={formatCurrency(currRent)}
                index={index}
                switchIndex={switchIndexRental}
                updatesCaller={() => {
                  rentalAdditionHandler(
                    summaryId,
                    index,
                    currRent,
                    prevRent,
                    "rent"
                  )
                }}
                type="rentChanges"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of rental income value changes */}
    </>
  )
}

export default RentalChangesAddition
