import React, { useState, useEffect } from "react"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Link from "@material-ui/core/Link"
import Drawer from "@material-ui/core/Drawer"
import { i_Accordion } from "./Accordion.interface"
import styles from "./Accordion.style.scss"
import ClientNotes from "../ClientNotes/ClientNotes"
import { _fetch } from "../../lib/utils"
import {
  i_ClientNotes,
  i_SaveNotes,
} from "../ClientNotes/ClientNotes.interface"
import _ from "lodash"

const Accordion: React.FunctionComponent<i_Accordion> = props => {
  const [open, setOpen] = useState(false)
  const [comments, setComments] = useState<i_ClientNotes[]>([])
  const [notes, setNotes] = useState<i_SaveNotes | undefined>(undefined)

  const getComments = async (event: MouseEvent) => {
    event.stopPropagation()
    const response = await _fetch(
      "client/getMortgageCalcComments/" +
        props.uid +
        "/" +
        props.versionControlId +
        "/" +
        props.category,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
    const json = await response.json()
    setComments(json.data)
    setOpen(true)
  }

  useEffect(() => {
    setComments(comments)
    toggleDrawer(open)
  }, [comments, open])

  const saveNotes = async () => {
    const res = await _fetch("client/addMortgageCalcComments", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        notes,
      }),
    })
    const result = await res.json()
  }

  const toggleDrawer = (open: boolean) => () => {
    setOpen(open)
  }

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  useEffect(() => {
    notes && saveNotes()
  }, [notes])

  return (
    <ExpansionPanel className={styles.accordion}>
      <ExpansionPanelSummary
        aria-label="Expand"
        className={styles.expansionSummaryContainer}
        classes={{
          content: styles.expansionSummary,
          expandIcon: styles.expandIcon,
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
      >
        <Typography variant="h6" className={styles.heading}>
          {props.headerTitle}{" "}
          {props.versionControlId && (
            <Link
              aria-label="Comments"
              component="button"
              variant="body2"
              color="textSecondary"
              onClick={(e: any) => getComments(e)}
              classes={{ root: styles.commentToggle }}
            >
              Click here to add notes
            </Link>
          )}
        </Typography>
        <Drawer
          anchor={"right"}
          open={open}
          onClose={toggleDrawer(false)}
          onClick={e => handleClick(e)}
        >
          <ClientNotes
            comments={comments}
            category={props.category}
            mortgageCalcId={props.versionControlId}
            setComments={setComments}
          ></ClientNotes>
        </Drawer>
        <Typography variant="h6" className={styles.accordionTotal}>
          {props.headerTotalTitle}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={styles.expansionPanelDetails}>
        <>
          {React.Children &&
            React.Children.map(
              props.children,
              (element: any, elementIndex: number) =>
                React.cloneElement(element)
            )}
        </>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
export default Accordion
