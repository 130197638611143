exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2OnyFmp3oCvM5dPQBqwP5R {\n  width: 100%;\n  min-width: 400px;\n  max-width: 400px;\n  overflow-wrap: break-word;\n  background-color: white;\n  padding-top: 55px !important; }\n  ._2OnyFmp3oCvM5dPQBqwP5R * {\n    font-size: 16px !important; }\n\n._5nY-kazcGWNZ5v7EcsJ5e {\n  display: inline; }\n\n._1Cw-M-KnLotfKd8rDkZdEw {\n  width: 95%; }\n\n._2MtmQtiKyA6ty2_7FQFBTB {\n  width: 95%;\n  padding-right: 20px !important; }\n\n._3XljLf7Yyybskbmd941Usw {\n  color: green; }\n", ""]);
// Exports
exports.locals = {
	"root": "_2OnyFmp3oCvM5dPQBqwP5R",
	"inline": "_5nY-kazcGWNZ5v7EcsJ5e",
	"textField": "_1Cw-M-KnLotfKd8rDkZdEw",
	"listItem": "_2MtmQtiKyA6ty2_7FQFBTB",
	"text": "_3XljLf7Yyybskbmd941Usw"
};