import React, { useState, KeyboardEvent, useEffect } from "react"
import TextField from "@material-ui/core/TextField"
import Edit from "@material-ui/icons/Edit"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import styles from "./EditableText.module.scss"
import { i_ACStates } from "../../scenes/MBWorkbench/PreliminaryAssessment/AssessmentCalculator/interface"

interface i_EditableText {
  title: String | undefined
  ACStates: i_ACStates | undefined
  setACStates: Function
}

const EditableText = (props: i_EditableText) => {
  const [editMode, setEditMode] = useState(false)
  const [mouseOver, setMouseOver] = useState(false)

  useEffect(() => {
    const updateState = { ...props.ACStates } as i_ACStates
    updateState.title = props.title
    props.setACStates(updateState)
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updateState = { ...props.ACStates } as i_ACStates
    updateState.title = event.target.value
    props.setACStates(updateState)
  }

  const handleMouseOver = () => {
    if (!mouseOver) {
      setMouseOver(true)
      setEditMode(false)
    }
  }

  const handleMouseOut = () => {
    if (mouseOver) {
      setMouseOver(false)
      setEditMode(false)
    }
  }

  const handleonKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 13) {
      setEditMode(false)
    }
  }

  const handleClick = () => {
    setEditMode(true)
    setMouseOver(false)
  }
  return (
    <TextField
      className={styles.field}
      defaultValue={props.title}
      margin="normal"
      disabled={!editMode}
      onChange={handleChange}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      onKeyDown={handleonKeyDown}
      InputProps={{
        classes: {
          disabled: styles.disabled,
          input: styles.textField,
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton className={styles.editIcon} onClick={handleClick}>
              <Edit />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default EditableText
