import { i_Assessment } from "./Assessment.interface"
import React from "react"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import EditIcon from "@material-ui/icons/Edit"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import ArchiveIcon from "@material-ui/icons/Archive"
import UnarchiveIcon from "@material-ui/icons/Unarchive"
import moment from "moment"
import _ from "lodash"

import styles from "./AssessmentItem.module.scss"

const AssessmentItem: React.FC<i_Assessment> = props => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div key={props._id} className={styles.container}>
      <div className={styles.itemContainer}>
        <div
          className={styles.item}
          style={{ flex: 12, textTransform: "uppercase" }}
        >
          {props?.title}
        </div>
        <div className={styles.itemDate}>
          <span>Created on</span>
          <div>{moment.utc(props.createdOn).format("DD-MM-YYYY")}</div>
        </div>
        <div className={styles.itemDate}>
          <span>Modified on</span>
          <div>{moment.utc(props.updatedOn).format("DD-MM-YYYY") || "-"}</div>
        </div>
        {/* <div className={styles.item}>{props.owner}</div> */}
        <div className={styles.item}>
          <span className={styles.status}>{props.status}</span>
        </div>
        <div className={styles.item} style={{ flex: 1 }}>
          <IconButton
            aria-label="menu"
            className={styles.menuIcon}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      </div>
      <Menu
        className={styles.optionsMenu}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!props.isArchive && (
          <div>
            <MenuItem
              className={styles.icontext}
              onClick={() => {
                props.onEdit && props.onEdit()
              }}
            >
              <ListItemIcon classes={{ root: styles.icon }}>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Edit" />
            </MenuItem>

            <MenuItem
              className={styles.icontext}
              onClick={() => {
                props.onDuplicate && props.onDuplicate(props._id)
                handleClose()
              }}
            >
              <ListItemIcon classes={{ root: styles.icon }}>
                <FileCopyIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Duplicate" />
            </MenuItem>

            <MenuItem
              className={styles.icontext}
              onClick={() => {
                props.onArchive && props.onArchive()
              }}
            >
              <ListItemIcon classes={{ root: styles.icon }}>
                <ArchiveIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Archive" />
            </MenuItem>
          </div>
        )}
        {props.isArchive && (
          <MenuItem
            className={styles.icontext}
            onClick={() => {
              props.onUnarchive && props.onUnarchive()
            }}
          >
            <ListItemIcon classes={{ root: styles.icon }}>
              <UnarchiveIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Unarchive" />
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}

export default AssessmentItem
