import React from "react"
import Switch from "react-switch"
import infoIcon from "../../../../../assets/info-circle-solid.svg"
import styles from "./ClientChangesModal.scss"

interface i_showChangesProps {
  prevVal: any
  currVal: any
  updatesCaller: () => void
  switchIndex: boolean[]
  index: number
  type: string
  action: string
}

const ShowChangesTable = (props: i_showChangesProps) => {
  const {
    prevVal,
    currVal,
    updatesCaller,
    switchIndex,
    index,
    type,
    action,
  } = props
  let typeTitle = ""
  let tooltiptext = ""
  const itemNumber = index + 1
  switch (type) {
    case "address":
      typeTitle = `Rental Income Address`
      tooltiptext = `Rental Income - ${itemNumber}`
      break
    case "ownership1":
      typeTitle = `Rental Income Primary Ownership Percentage`
      tooltiptext = `Rental Income - ${itemNumber}`
      break
    case "ownership2":
      typeTitle = `Rental Income Secondary Ownership Percentage`
      tooltiptext = `Rental Income - ${itemNumber}`
      break
    case "propertyValueChanges":
      typeTitle = `Rental Income Property Value`
      tooltiptext = `Rental Income - ${itemNumber}`
      break
    case "rentChanges":
      typeTitle = `Rental Annual Income`
      tooltiptext = `Rental Income - ${itemNumber}`
      break
    case "faTypeChanges":
      typeTitle = `Financial Asset Type`
      tooltiptext = `Financial Asset - ${itemNumber}`
      break
    case "faBalanceChanges":
      typeTitle = `Financial Asset Balance`
      tooltiptext = `Financial Asset - ${itemNumber}`
      break
    case "faOwnerChanges":
      typeTitle = `Financial Asset Owner`
      tooltiptext = `Financial Asset - ${itemNumber}`
      break
    case "nfaTypeChanges":
      typeTitle = `Non-Financial Asset Type`
      tooltiptext = `Non-Financial Asset - ${itemNumber}`
      break
    case "nfaBalanceChanges":
      typeTitle = `Non-Financial Balance`
      tooltiptext = `Non-Financial Asset - ${itemNumber}`
      break
    case "nfaOwnerChanges":
      typeTitle = `Non-Financial Asset Owner`
      tooltiptext = `Non-Financial Asset - ${itemNumber}`
      break
    case "portal":
      typeTitle = `Primary Annual Income`
      tooltiptext = `Primary Annual Income - ${itemNumber}`
      break
    case "incomeType":
      typeTitle = `Primary Income Type`
      tooltiptext = `Primary Income Type - ${itemNumber}`
      break
    case "secondaryIncomeType":
      typeTitle = `Secondary Income Type`
      tooltiptext = `Secondary Income Type - ${itemNumber}`
      break
    case "secondaryPortal":
      typeTitle = `Secondary Annual Income`
      tooltiptext = `Secondary Annual Income - ${itemNumber}`
      break
    case "unsecuredType":
      typeTitle = `Unsecured Liabilities Type`
      tooltiptext = `Unsecured Liabilities Type - ${itemNumber}`
      break
    case "unsecuredBalance":
      typeTitle = `Unsecured Liabilities Balance`
      tooltiptext = `Unsecured Liabilities Balance - ${itemNumber}`
      break
    case "unsecuredPeriod":
      typeTitle = `Unsecured Liabilities Period`
      tooltiptext = `Unsecured Liabilities Period - ${itemNumber}`
      break
    case "unsecuredOwner":
      typeTitle = `Unsecured Liabilities Owner`
      tooltiptext = `Unsecured Liabilities Owner - ${itemNumber}`
      break
    case "unsecuredRepayment":
      typeTitle = `Unsecured Liabilities Repayment`
      tooltiptext = `Unsecured Liabilities Repayment - ${itemNumber}`
      break
    case "lenderHL":
      typeTitle = `Home Loans Lender`
      tooltiptext = `Home Loans Lender - ${itemNumber}`
      break
    case "loanType":
      typeTitle = `Home Loans Type`
      tooltiptext = `Home Loans Type - ${itemNumber}`
      break
    case "primaryPurpose":
      typeTitle = `Home Loans Primary Purpose`
      tooltiptext = `Home Loans Primary Purpose - ${itemNumber}`
      break
    case "borrower":
      typeTitle = `Home Loans Borrower`
      tooltiptext = `Home Loans Borrower - ${itemNumber}`
      break
    case "repaymentType":
      typeTitle = `Home Loans Repayment Type`
      tooltiptext = `Home Loans Repayment Type - ${itemNumber}`
      break
    case "repayment":
      typeTitle = `Home Loans Repayment`
      tooltiptext = `Home Loans Repayment - ${itemNumber}`
      break
    case "period":
      typeTitle = `Home Loans Period`
      tooltiptext = `Home Loans Period - ${itemNumber}`
      break
    case "loanExpiry":
      typeTitle = `Home Loans Loan Expiry`
      tooltiptext = `Home Loans Loan Expiry - ${itemNumber}`
      break
    case "balance":
      typeTitle = `Home Loans Balance`
      tooltiptext = `Home Loans Balance - ${itemNumber}`
      break
    case "accountNumber":
      typeTitle = `Home Loans Account Number`
      tooltiptext = `Home Loans Account Number - ${itemNumber}`
      break
    case "ioExp":
      typeTitle = `Home Loans IO Exp`
      tooltiptext = `Home Loans IO Exp - ${itemNumber}`
      break
    case "fixedIO":
      typeTitle = `Home Loans Fixed IO`
      tooltiptext = `Home Loans Fixed IO - ${itemNumber}`
      break
    case "addressHL":
      typeTitle = `Home Loans Address`
      tooltiptext = `Home Loans Address - ${itemNumber}`
      break
    case "baseRate":
      typeTitle = `Home Loans Base Rate`
      tooltiptext = `Home Loans Base Rate - ${itemNumber}`
      break
    case "discountRate":
      typeTitle = `Home Loans Discount Rate`
      tooltiptext = `Home Loans Discount Rate - ${itemNumber}`
      break
    case "interestRate":
      typeTitle = `Home Loans Interest Rate`
      tooltiptext = `Home Loans Interest Rate - ${itemNumber}`
      break
    case "creditLender":
      typeTitle = `Credit Card Lender`
      tooltiptext = `Credit Card Lender - ${itemNumber}`
      break
    case "creditLimit":
      typeTitle = `Credit Card Limit`
      tooltiptext = `Credit Card Limit - ${itemNumber}`
      break
    case "creditProposed":
      typeTitle = `Credit Card Proposed`
      tooltiptext = `Credit Card Proposed - ${itemNumber}`
      break
    case "creditOwner":
      typeTitle = `Credit Card Ownership`
      tooltiptext = `Credit Card Ownership - ${itemNumber}`
      break
    default:
      typeTitle = ""
      tooltiptext = ""
  }

  return (
    <tr>
      <td>{typeTitle}</td>
      <td>
        <div className={styles.tooltip}>
          <img
            alt="plus"
            title="Add Month"
            src={infoIcon}
            className={styles.infoIcon}
          />
          <span className={styles.tooltiptext}>{tooltiptext}</span>
        </div>
      </td>
      <td>
        <p className={action === "Add" ? styles.addBtn : styles.updateBtn}>
          {action}
        </p>
      </td>
      <td>{prevVal}</td>
      <td>{currVal}</td>
      <td>
        <Switch
          onChange={updatesCaller}
          checked={switchIndex && switchIndex[index]}
        />
      </td>
    </tr>
  )
}

export default ShowChangesTable
