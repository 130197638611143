import React from "react"
import { Grid } from "@material-ui/core"
import Modal from "../Modal"
import styles from "./NoticeModal.module.scss"

interface NoticeModalProps {
  showModal: boolean
  onClose: () => void,
  infoText: string
}

const NoticeModal: React.FC<NoticeModalProps> = props => {
  return (
    <Modal showModal={props.showModal} onClose={props.onClose}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} >
        <h3 className={styles.contentWrapper}>
          <div dangerouslySetInnerHTML={{ __html: props.infoText }} />
        </h3>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default NoticeModal
