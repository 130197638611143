exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3EGifRp9JWPWHgOM-zM2_c {\n  width: 35em;\n  background: #eee; }\n  ._3EGifRp9JWPWHgOM-zM2_c.y77dPuM0_BtzR0CiwzFEY {\n    border: 1px solid red; }\n  ._3EGifRp9JWPWHgOM-zM2_c > div {\n    background: #d5d5d5;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 2px !important; }\n    ._3EGifRp9JWPWHgOM-zM2_c > div > button {\n      padding: 0; }\n    ._3EGifRp9JWPWHgOM-zM2_c > div > input {\n      padding: 2px !important;\n      font-size: 14px; }\n    ._3EGifRp9JWPWHgOM-zM2_c > div > textarea {\n      padding: 2px !important;\n      font-size: 14px;\n      line-height: initial; }\n\n._3qpfaD3uUmbxOOlwNpZXvl {\n  width: 100%;\n  min-height: 20px;\n  overflow-wrap: break-word;\n  margin: 6px 8px;\n  cursor: pointer;\n  font-family: 'Poppins';\n  background: none;\n  border: none;\n  padding: 2px !important;\n  font-size: 14px;\n  line-height: initial; }\n  ._3qpfaD3uUmbxOOlwNpZXvl:hover {\n    background-color: lightblue; }\n\n._2yMgaQHZQ1NmNOUwKmApfI {\n  outline: none !important;\n  background: none;\n  border: none;\n  width: 100%;\n  min-height: 22px;\n  font-size: 14px;\n  line-height: initial;\n  padding: 3px 7px 5px;\n  font-family: 'Poppins';\n  overflow: hidden;\n  margin-bottom: 0; }\n\npre.dqImB-c6mYcRzsUobuvHo:hover {\n  background: #c3dff8; }\n\n._3-jNI1uasU8sFI7ApppsgQ {\n  outline: none !important;\n  background: none;\n  border: none;\n  width: 100%;\n  height: 35px;\n  font-size: 14px;\n  line-height: initial;\n  padding: 7px 10px;\n  font-family: 'Poppins';\n  overflow: hidden; }\n\n.UZRuYgoqVtrqvxPwAJkGV {\n  display: block; }\n\n._3XW1eByCx2E8DAbjJ9aO8S {\n  display: none; }\n", ""]);
// Exports
exports.locals = {
	"inputWrapper": "_3EGifRp9JWPWHgOM-zM2_c",
	"duplicateDoc": "y77dPuM0_BtzR0CiwzFEY",
	"documentInput": "_3qpfaD3uUmbxOOlwNpZXvl",
	"inputField": "_2yMgaQHZQ1NmNOUwKmApfI",
	"inputFIeld": "dqImB-c6mYcRzsUobuvHo",
	"txtAreaField": "_3-jNI1uasU8sFI7ApppsgQ",
	"show": "UZRuYgoqVtrqvxPwAJkGV",
	"hide": "_3XW1eByCx2E8DAbjJ9aO8S"
};