import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, ListItem, makeStyles } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { ExpanderComponentProps } from "react-data-table-component";
import { i_FileStatementPeriod } from "../Documents/Documents.interfaces";
import { RequestHistoryProps } from "../RequestHistory/RequestHistory.interfaces";
import styles from "./ClientResponse.module.scss";
import ClientResponseItem from "./ClientResponseItem";

interface Props extends ExpanderComponentProps<RequestHistoryProps> {
  clientId?: any,
  setDroppedFile?: (file: File | undefined) => void,
  onFileUploaderModalOpen?: (
    dcid: string,
    isEdit: boolean,
    defaultFileType: string,
    fileLabel?: string,
    fileId?: string,
    notes?: string,
    requestId?: String,
    tags?: string[],
    staPeriod?: i_FileStatementPeriod,
    referNo?: string
  ) => Function,
  refreshRequestedDocuments?: () => void,
  setRequestItemId?: (id: String) => void
}

const useStyles = makeStyles({
    requestItemWrapper: {
      border: '1px solid #CCCCCC',
      marginLeft: '10px',
      "& .MuiListItem-root.MuiListItem-gutters": {
        paddingLeft: '40px',
        paddingRight: '40px',
      }
    },
    noDataWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      "& > p": {
        color: '#CCC',
        margin: 0
      }
    },
    expansionPanelRoot: {
      margin: '0 0 10px 20px !important',
      boxShadow: 'none !important'
    },
    listDocumentType: {
      background: '#C4C4C4 !important',
      color:' black !important',
      fontFamily: "'Poppins', 'sans-serif', -apple-system, monospace !important",
      minHeight: '42px !important',
      flexDirection: 'row-reverse',
      boxShadow: 'none !important',
      padding: '0px',
      borderRadius: '2px',
      "& .MuiExpansionPanelSummary-content": {
        margin: '5px 0'
      },
      "& .MuiExpansionPanelSummary-expandIcon": {
        padding: '0 12px',
        margin: '0'
      }
    },
    expansionPanelDetails: {
      padding: '0px'
    }
});

const ClientResponseGroup: React.FC<Props> = ({ 
    data,
    clientId,
    onFileUploaderModalOpen,
    setDroppedFile,
    refreshRequestedDocuments,
    setRequestItemId
}) => {
    const classes = useStyles();
    const [groupedRequestedDocuments, setGroupedRequestedDocuments] = useState<any[]>([]);
    const [requestId, setRequestId] = useState<String>('');

    useEffect(() => {
      if (data && data?.requested_documents) {
        const groupedRequestedDocs = _.chain(data.requested_documents)
          .groupBy("dcid.documentType")
          .map((value, key) => ({ documentType: key, documents: value }))
          .value();
        setGroupedRequestedDocuments(groupedRequestedDocs);
        setRequestId(data._id);
      }
    }, [data])

    return <div>
        {
            groupedRequestedDocuments.map(
                ({ documentType, documents }, index) => {
                  return (
                    <>
                      <ExpansionPanel defaultExpanded className={classes.expansionPanelRoot}>
                          <ExpansionPanelSummary expandIcon={
                            <KeyboardArrowDown fontSize="large" className={styles.textOrange} />
                          } aria-controls={`panel${index}-content`}id={`panel${index}-header`} className={classes.listDocumentType}>
                            <span className={styles.docType}>{documentType}</span>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                            <ListItem className={styles.fileItemContainer} style={{ flexDirection: 'column'}}>
                              {
                                onFileUploaderModalOpen && 
                                setDroppedFile &&
                                refreshRequestedDocuments &&
                                setRequestItemId &&
                                <ClientResponseItem 
                                  clientId={clientId} 
                                  documents={documents}
                                  requestId={requestId}
                                  onFileUploaderModalOpen={onFileUploaderModalOpen} 
                                  setDroppedFile={setDroppedFile}
                                  refreshRequestedDocuments={refreshRequestedDocuments}
                                  setRequestItemId={setRequestItemId}
                                />
                              }
                            </ListItem>
                          </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </>
                  )
                }
              )
        }
    </div>
}

export default ClientResponseGroup