import React, { useContext, useState } from "react"
import ClientChangesContext from "../../../../../../components/Context/ClientChangesContext"
import FlexExpenseChangesHeader from "./FlexExpenseChangesHeader"
import FlexExpenseChangesRow from "./FlexExpenseChangesRow"

const FlexUpdatesInvestmentUtilities = () => {
  const { flexExpenseUpdates, ACStates, setACStates } = useContext(
    ClientChangesContext
  )

  const utilitiesINVChanges = flexExpenseUpdates?.utilitiesInvestmentChanges
  const numberOfLoops =
    utilitiesINVChanges?.utilitiesInvestmentChangesArr.length ?? 0
  const indexOnChanges: boolean[] = []
  for (let i = 0; i < numberOfLoops; i++) {
    indexOnChanges.push(false)
  }
  const [switchIndex, setSwitchIndex] = useState(indexOnChanges)
  const investmentUtilitiesHandler = (
    index: number,
    desc: string,
    prevValue: any,
    currValue: any
  ) => {
    let newSwitch = [...switchIndex]
    newSwitch[index] = !newSwitch[index]

    let changeValue = ACStates?.expenses.map((expense: any) => {
      if (expense.flexType === "Utilities & Rates - INV") {
        expense.items.map((item: any) => {
          if (item.desc === desc) {
            if (newSwitch !== undefined && newSwitch[index]) {
              item.monthlyAmount = currValue
            } else {
              item.monthlyAmount = prevValue
            }
          }
        })
        const newTotalAmount = expense.items
          .map((item: { monthlyAmount: number }) => item.monthlyAmount)
          .reduce(
            (prevAmount: number, currAmount: number) => prevAmount + currAmount,
            0
          )
        expense.monthlyAmount = newTotalAmount
      }
      return expense
    })

    if (ACStates !== undefined) {
      setACStates && setACStates({ ...ACStates, expenses: changeValue })
    }

    setSwitchIndex(newSwitch)
  }

  return (
    <>
      {numberOfLoops !== 0 ? (
        <FlexExpenseChangesHeader
          prevMonthly={utilitiesINVChanges?.prevMonthlyTotalAmount ?? 0}
          currMonthly={utilitiesINVChanges?.monthlyTotalAmount ?? 0}
          description={"Utilities & Rates - INV"}
        />
      ) : null}
      {utilitiesINVChanges?.utilitiesInvestmentChangesArr.length !== 0
        ? utilitiesINVChanges?.utilitiesInvestmentChangesArr.map(
            (uExpense, index: number) => {
              const { prevAmount, currAmount, desc, summaryId } = uExpense
              const prevEdit = prevAmount ?? 0
              const currEdit = currAmount ?? 0
              return (
                <>
                  <FlexExpenseChangesRow
                    desc={desc}
                    prevEdit={prevEdit}
                    currEdit={currEdit}
                    index={index}
                    switchIndex={switchIndex}
                    updatesCaller={() =>
                      investmentUtilitiesHandler(
                        index,
                        desc,
                        prevEdit,
                        currEdit
                      )
                    }
                  />
                </>
              )
            }
          )
        : null}
    </>
  )
}

export default FlexUpdatesInvestmentUtilities
