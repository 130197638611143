import React, { useContext, useState } from "react"
import ShowChangesTable from "../ShowChangesTable"
import ClientChangesContext from "../../../../../../components/Context/ClientChangesContext"
import {
  formatCurrency,
  findBySummaryId,
  compareItems,
  selectedOwner,
  isObjectEmpty,
} from "../../../../../../lib/utils"

const UnsecuredLiabilitiesAddition = () => {
  const { unsecuredAdditional, ACStates, setACStates } = useContext(
    ClientChangesContext
  )

  const indexOnBalance: boolean[] = []
  const indexOnOwner: boolean[] = []
  const indexOnPeriod: boolean[] = []
  const indexOnRepayment: boolean[] = []
  const indexOnType: boolean[] = []

  unsecuredAdditional &&
    unsecuredAdditional.typeChanges.forEach((asset: any) =>
      indexOnType.push(false)
    )

  unsecuredAdditional &&
    unsecuredAdditional.ownerChanges.forEach((asset: any) =>
      indexOnOwner.push(false)
    )

  unsecuredAdditional &&
    unsecuredAdditional.periodChanges.forEach((asset: any) =>
      indexOnPeriod.push(false)
    )

  unsecuredAdditional &&
    unsecuredAdditional.repaymentChanges.forEach((asset: any) =>
      indexOnRepayment.push(false)
    )

  unsecuredAdditional &&
    unsecuredAdditional.balanceChanges.forEach((asset: any) =>
      indexOnBalance.push(false)
    )

  const [switchIndexBalance, setSwitchIndexBalance] = useState(indexOnBalance)
  const [switchIndexType, setSwitchIndexType] = useState(indexOnType)
  const [switchIndexOwner, setSwitchIndexOwner] = useState(indexOnOwner)
  const [switchIndexPeriod, setSwitchIndexPeriod] = useState(indexOnPeriod)
  const [switchIndexRepayment, setSwitchIndexRepayment] = useState(
    indexOnRepayment
  )

  const changePropertyHandler = (
    objProp: string,
    currValue: any,
    prevValue: any,
    summaryId: string,
    newSwitch: boolean[],
    index: number
  ) => {
    const updateValues = ACStates?.unsecuredLiabilities.map(
      (liability: any) => {
        if (liability.summaryId === summaryId) {
          if (newSwitch !== undefined && newSwitch[index]) {
            liability[objProp] = currValue
          } else {
            liability[objProp] = prevValue
          }
        }
        return liability
      }
    )

    const shouldAddValue = updateValues.find(
      (item: { summaryId: string }) => item.summaryId === summaryId
    )

    if (shouldAddValue === undefined) {
      let newItem: any = {
        type: "",
        balance: 0,
        owner: {},
        summaryId,
        repayment: 0,
        period: "",
        rate: 0,
      }

      if (newSwitch !== undefined && newSwitch[index]) {
        newItem[objProp] = currValue
      } else {
        newItem[objProp] = prevValue
      }

      updateValues.push(newItem)
    } else {
      const checkAddedType = findBySummaryId(
        unsecuredAdditional?.typeChanges,
        summaryId
      )
      const checkAddedBalance = findBySummaryId(
        unsecuredAdditional?.balanceChanges,
        summaryId
      )
      const checkAddedOwner = findBySummaryId(
        unsecuredAdditional?.ownerChanges,
        summaryId
      )
      const checkAddedRepayment = findBySummaryId(
        unsecuredAdditional?.repaymentChanges,
        summaryId
      )
      const checkAddedPeriod = findBySummaryId(
        unsecuredAdditional?.periodChanges,
        summaryId
      )
      const checkUpdatedValues = findBySummaryId(
        ACStates?.unsecuredLiabilities,
        summaryId
      )

      const isAddedOwnerEmpty = isObjectEmpty(checkAddedOwner.prevValOwner)
      const isUpdatedOwnerEmpty = isObjectEmpty(checkUpdatedValues.owner)

      if (
        compareItems(checkAddedType.prevValType, checkUpdatedValues.type) &&
        compareItems(
          checkAddedBalance.prevBalance,
          checkUpdatedValues.balance
        ) &&
        isAddedOwnerEmpty &&
        isUpdatedOwnerEmpty &&
        compareItems(
          checkAddedRepayment.prevRepayment,
          checkUpdatedValues.repayment
        ) &&
        compareItems(checkAddedPeriod.prevPeriod, checkUpdatedValues.period)
      ) {
        const position = updateValues.findIndex(
          (uValues: { summaryId: string }) => uValues.summaryId === summaryId
        )
        if (position !== -1) updateValues.splice(position, 1)
      }
    }
    return updateValues
  }

  const unsecuredLiabilitiesHandler = (
    summaryId: string,
    index: number,
    currValue: any,
    prevValue: any,
    toChange: string
  ) => {
    let newSwitch: boolean[]
    let changeValue
    switch (toChange) {
      case "type":
        newSwitch = switchIndexType && [...switchIndexType]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexType(newSwitch)
        break
      case "balance":
        newSwitch = switchIndexBalance && [...switchIndexBalance]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexBalance(newSwitch)
        break
      case "owner":
        newSwitch = switchIndexOwner && [...switchIndexOwner]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexOwner(newSwitch)
        break
      case "period":
        newSwitch = switchIndexPeriod && [...switchIndexPeriod]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexPeriod(newSwitch)
        break
      case "repayment":
        newSwitch = switchIndexRepayment && [...switchIndexRepayment]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexRepayment(newSwitch)
        break
      default:
        newSwitch = []
    }

    if (ACStates !== undefined) {
      setACStates &&
        setACStates({ ...ACStates, unsecuredLiabilities: changeValue })
    }
  }

  return (
    <>
      {unsecuredAdditional?.typeChanges.length !== 0 ? (
        unsecuredAdditional?.typeChanges.map(
          (
            liability: {
              prevValType: any
              currValType: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevValType, currValType, summaryId } = liability
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={currValType}
                index={index}
                switchIndex={switchIndexType}
                updatesCaller={() => {
                  unsecuredLiabilitiesHandler(
                    summaryId,
                    index,
                    currValType,
                    prevValType,
                    "type"
                  )
                }}
                type="unsecuredType"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {unsecuredAdditional?.balanceChanges.length !== 0 ? (
        unsecuredAdditional?.balanceChanges.map(
          (
            liability: {
              prevBalance: number
              currBalance: number
              summaryId: string
            },
            index: number
          ) => {
            const { prevBalance, currBalance, summaryId } = liability
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={formatCurrency(currBalance)}
                index={index}
                switchIndex={switchIndexBalance}
                updatesCaller={() => {
                  unsecuredLiabilitiesHandler(
                    summaryId,
                    index,
                    currBalance,
                    prevBalance,
                    "balance"
                  )
                }}
                type="unsecuredBalance"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {unsecuredAdditional?.periodChanges.length !== 0 ? (
        unsecuredAdditional?.periodChanges.map(
          (
            liability: {
              prevPeriod: string
              currPeriod: string
              summaryId: string
            },
            index: number
          ) => {
            const { prevPeriod, currPeriod, summaryId } = liability
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={currPeriod}
                index={index}
                switchIndex={switchIndexPeriod}
                updatesCaller={() => {
                  unsecuredLiabilitiesHandler(
                    summaryId,
                    index,
                    currPeriod,
                    prevPeriod,
                    "period"
                  )
                }}
                type="unsecuredPeriod"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {unsecuredAdditional?.repaymentChanges.length !== 0 ? (
        unsecuredAdditional?.repaymentChanges.map(
          (
            liability: {
              prevRepayment: number
              currRepayment: number
              summaryId: string
            },
            index: number
          ) => {
            const { prevRepayment, currRepayment, summaryId } = liability
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={formatCurrency(currRepayment)}
                index={index}
                switchIndex={switchIndexRepayment}
                updatesCaller={() => {
                  unsecuredLiabilitiesHandler(
                    summaryId,
                    index,
                    currRepayment,
                    prevRepayment,
                    "repayment"
                  )
                }}
                type="unsecuredRepayment"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {unsecuredAdditional?.ownerChanges.length !== 0 ? (
        unsecuredAdditional?.ownerChanges.map(
          (
            liability: {
              prevValOwner: any
              currValOwner: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevValOwner, currValOwner, summaryId } = liability
            return (
              <ShowChangesTable
                prevVal={""}
                currVal={selectedOwner(currValOwner)}
                index={index}
                switchIndex={switchIndexOwner}
                updatesCaller={() => {
                  unsecuredLiabilitiesHandler(
                    summaryId,
                    index,
                    currValOwner,
                    prevValOwner,
                    "owner"
                  )
                }}
                type="unsecuredOwner"
                action="Add"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
    </>
  )
}

export default UnsecuredLiabilitiesAddition
