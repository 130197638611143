import React, { useContext, useState } from "react"
import _ from "lodash"
import ClientChangesContext from "../../../../../../components/Context/ClientChangesContext"
import ShowChangesTable from "../ShowChangesTable"
import { formatCurrency } from "../../../../../../lib/utils"

const FinancialAssetChangesTable = () => {
  const { financialAssetUpdates, ACStates, setACStates } = useContext(
    ClientChangesContext
  )

  const indexOnType: boolean[] = []
  const indexOnBalance: boolean[] = []
  const indexOnOwner: boolean[] = []

  financialAssetUpdates &&
    financialAssetUpdates.typeChanges.forEach((asset: any) =>
      indexOnType.push(false)
    )

  financialAssetUpdates &&
    financialAssetUpdates.balanceChanges.forEach((asset: any) =>
      indexOnBalance.push(false)
    )

  financialAssetUpdates &&
    financialAssetUpdates.ownerChanges.forEach((asset: any) =>
      indexOnOwner.push(false)
    )

  const [switchIndexType, setSwitchIndexType] = useState<boolean[]>(indexOnType)

  const [switchIndexBalance, setSwitchIndexBalance] = useState<boolean[]>(
    indexOnBalance
  )

  const [switchIndexOwner, setSwitchIndexOwner] = useState<boolean[]>(
    indexOnOwner
  )

  const changePropertyHandler = (
    objProp: string,
    currValue: any,
    prevValue: any,
    summaryId: string,
    newSwitch: boolean[],
    index: number
  ) => {
    return ACStates?.financialAssets.map((asset: any) => {
      if (asset.summaryId === summaryId) {
        if (newSwitch !== undefined && newSwitch[index]) {
          if (objProp === "owner") {
            _.mapValues(asset[objProp], function(o) {
              if (o.fullName === currValue) {
                o.selected = true
              } else if (currValue == "Both") {
                if (_.has(o, "selected")) o.selected = true
              } else if (currValue == "Other") {
                if (_.has(o, "selected")) o.selected = false
              } else {
                if (_.has(o, "selected")) o.selected = false
              }
            })
          } else {
            asset[objProp] = currValue
          }
        } else {
          asset[objProp] = prevValue
        }
      }
      return asset
    })
  }

  const financialAssetUpdatesHandler = (
    summaryId: string,
    index: number,
    currValue: any,
    prevValue: any,
    toChange: string
  ) => {
    let newSwitch: boolean[]
    let changeValue

    switch (toChange) {
      case "type":
        newSwitch = switchIndexType && [...switchIndexType]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexType(newSwitch)
        break
      case "balance":
        newSwitch = switchIndexBalance && [...switchIndexBalance]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexBalance(newSwitch)
        break
      case "owner":
        newSwitch = switchIndexOwner && [...switchIndexOwner]
        if (newSwitch !== undefined && newSwitch.length !== 0) {
          newSwitch[index] = !newSwitch[index]
        }
        changeValue = changePropertyHandler(
          toChange,
          currValue,
          prevValue,
          summaryId,
          newSwitch,
          index
        )
        setSwitchIndexOwner(newSwitch)
        break
      default:
        newSwitch = []
    }

    if (ACStates !== undefined) {
      setACStates && setACStates({ ...ACStates, financialAssets: changeValue })
    }
  }

  return (
    <>
      {/* start of asset type changes */}
      {financialAssetUpdates !== undefined &&
      financialAssetUpdates.typeChanges.length !== 0 ? (
        financialAssetUpdates.typeChanges.map(
          (
            asset: {
              prevValType: any
              currValType: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevValType, currValType, summaryId } = asset
            return (
              <ShowChangesTable
                prevVal={prevValType}
                currVal={currValType}
                index={index}
                switchIndex={switchIndexType}
                updatesCaller={() => {
                  financialAssetUpdatesHandler(
                    summaryId,
                    index,
                    currValType,
                    prevValType,
                    "type"
                  )
                }}
                type="faTypeChanges"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of asset type changes */}
      {/* start of asset balance changes */}
      {financialAssetUpdates !== undefined &&
      financialAssetUpdates.balanceChanges.length !== 0 ? (
        financialAssetUpdates.balanceChanges.map(
          (
            asset: {
              prevValBalance: any
              currValBalance: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevValBalance, currValBalance, summaryId } = asset
            return (
              <ShowChangesTable
                prevVal={formatCurrency(prevValBalance)}
                currVal={formatCurrency(currValBalance)}
                index={index}
                switchIndex={switchIndexBalance}
                updatesCaller={() => {
                  financialAssetUpdatesHandler(
                    summaryId,
                    index,
                    currValBalance,
                    prevValBalance,
                    "balance"
                  )
                }}
                type="faBalanceChanges"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of asset balance changes */}
      {/* start of asset owner changes */}
      {financialAssetUpdates !== undefined &&
      financialAssetUpdates.ownerChanges.length !== 0 ? (
        financialAssetUpdates.ownerChanges.map(
          (
            asset: {
              prevValOwner: any
              currValOwner: any
              summaryId: any
            },
            index: number
          ) => {
            const { prevValOwner, currValOwner, summaryId } = asset
            return (
              <ShowChangesTable
                prevVal={prevValOwner}
                currVal={currValOwner}
                index={index}
                switchIndex={switchIndexOwner}
                updatesCaller={() => {
                  financialAssetUpdatesHandler(
                    summaryId,
                    index,
                    currValOwner,
                    prevValOwner,
                    "owner"
                  )
                }}
                type="faOwnerChanges"
                action="Update"
              />
            )
          }
        )
      ) : (
        <tr></tr>
      )}
      {/* end of asset owner changes */}
    </>
  )
}

export default FinancialAssetChangesTable
