import * as React from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {useLendingSurveys} from "./surveys.query";
import SurveysListItem from "./SurveysListItem";

const useStyles = makeStyles({
  emptyWrap: {
    padding: "2em 1em",
    textAlign: "center",
  },
});

export type LendingSurveysListProps = {
  userId: string;
};

const SurveysList = ({userId}: LendingSurveysListProps) => {
  const styles = useStyles();

  const {data} = useLendingSurveys({
    clientId: userId,
  });

  if (!data) {
    return <></>;
  }

  if (data.docs.length === 0) {
    return (
      <div className={styles.emptyWrap}>
        <Typography variant="h5">No records found.</Typography>
      </div>
    );
  }

  return (
    <>
      {data.docs.map((survey) => {
        const {_id} = survey;

        return <SurveysListItem key={_id} data={survey} />;
      })}
    </>
  );
};

export default SurveysList;
