import { Box, Button, IconButton, Input, InputLabel, MenuItem, Select } from '@material-ui/core'
import { AddCircleOutline, Close, RemoveCircleOutlineRounded } from "@material-ui/icons"
import classNames from 'classnames'
import _ from "lodash"
import React, { useEffect, useState } from 'react'
import { getAllTemplates, getTemplateById, i_Document } from '../../lib/services'
import { validateEmail } from '../../lib/utils'
import { TemplateProps } from '../../scenes/DocumentCollection/AdminTemplate/AdminTemplate.interface'
import { RequestHistoryProps, RequestHistoryStaff, StaffEmailsProps } from '../../scenes/DocumentCollection/RequestHistory/RequestHistory.interfaces'
import Modal from '../Modal'
import styles from "./AddMultiRequestModal.module.scss"

export enum AddEditMode {
	ADD = "add",
	EDIT = "edit",
}

type AddMultiRequestModalProps = {
	showModal: boolean
	userInfo: RequestHistoryStaff | null
	mode: AddEditMode,
	onSubmit: (reqName: string, documents: i_Document[], emails: StaffEmailsProps[], status: string, mode?: AddEditMode, templateId?: string, reqId?: String) => void
	onClose: () => void,
	defaultValues?: RequestHistoryProps
}

type TemplateListProps = {
	_id: string,
	name: string
}

const requestNamePattern = "Document collection request from XXXXX";

const AddMultiRequestModal: React.FC<AddMultiRequestModalProps> = props => {
	
	const { showModal, userInfo, onSubmit, onClose, mode, defaultValues } = props
	const [requestName, setRequestName] = useState<string>('');
	const [requestId, setRequestId] = useState<String>('');
	const [template, setTemplate] = useState<string>("");
	const [requestedDocuments, setRequestedDocuments] = useState<any>([])
	const [staffEmails, setStaffEmails] = useState<StaffEmailsProps[]>([{ email: '', addable: true, removeable: true, error: false, errMsg: '' }]);
	const [requestNameError, setRequestNameError] = useState<string>('');
	const [templateList, setTemplateList] = useState<TemplateListProps[]>([]);
	const [disableTemplates, setDisableTemplates] = useState<boolean>(false);

	useEffect(() => {
		if (userInfo) {
			loadTemplates();
			if (mode === AddEditMode.ADD) { // ADD MODE
				let userName = '';
				if (userInfo?.fname && userInfo.fname !== '' && userInfo?.lname && userInfo.lname !== '') {
					userName = `${userInfo.fname} ${userInfo.lname}`;
				} else if (userInfo?.email !== '') {
					userName = userInfo.email ? userInfo.email.split('@')[0] : '';
				}
				setRequestName(requestNamePattern.replace('XXXXX', userName));
				
				const newStaffEmails: StaffEmailsProps[] = [];
				newStaffEmails.push({ email: userInfo?.email || '' , addable: true, removeable: true, error: false, errMsg: '' });
				setStaffEmails(newStaffEmails);
			} else if (mode === AddEditMode.EDIT && defaultValues && !_.isEmpty(defaultValues)) { // EDIT MODE
				setRequestName(defaultValues.name || '');
				setRequestId(defaultValues._id || '');
				setRequestedDocuments(defaultValues.requested_documents ?? []);
				if (defaultValues.name === '') {
					setRequestNameError('Please input request name');
				}

				if (defaultValues.staffEmails && defaultValues.staffEmails.length > 0) {
					const newStaffEmails: StaffEmailsProps[] = [...defaultValues.staffEmails];
					newStaffEmails.map((item, index, currentList) => {
						if (index + 1 === currentList.length) { 	// Last one
							item.addable = true;
							item.removeable = true;
							item.error = false;
							item.errMsg = '';
						} else { 									// Not last one.
							item.addable = false;
							item.removeable = false;
							item.error = false;
							item.errMsg = '';
						}
					})
					setStaffEmails(newStaffEmails);
				}

				
				if (defaultValues.template) {
					setTemplate(defaultValues.template._id);
				} else {
					setTemplate("");
				}
			}
		}
	}, [userInfo, mode, defaultValues])

	useEffect(() => {
		if (staffEmails.length === 0) {
			staffEmails.push({ email: '', addable: true, removeable: true, error: false, errMsg: '' });
		}
	}, [staffEmails])

	useEffect(() => {
		if (mode === AddEditMode.ADD) {
			if (template === "") return setRequestedDocuments([]);
			getTemplateById(template).then(({ requested_documents }) => {
				setRequestedDocuments(requested_documents);
			});
		}
	}, [template])

	useEffect(() => {
		if (showModal && mode === AddEditMode.EDIT) {
			setDisableTemplates(true);
		} else {
			setDisableTemplates(false);
			setTemplate("");
		}
	}, [showModal, mode])

	const loadTemplates = async () => {
		const result = await getAllTemplates();
		const currentTemplates = [];

		currentTemplates.push({ _id: "", name: "Blank Template" });
		if (result.status === "success") {
			const { templates } = result.data
			templates.forEach((tmplt: TemplateProps) => currentTemplates.push({ _id: tmplt._id, name: tmplt.name }));
			setTemplateList(currentTemplates);
		}
	}

	const handleChangeRequestName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setRequestName(e.target.value)
		if (e.target.value === '') {
			setRequestNameError('Please input request name');
		} else {
			setRequestNameError('')
		}
	}

	const resetModal = () => {
		setRequestName('');
		setRequestId('');
		setRequestNameError('');
		setStaffEmails([]);
		setTemplate("");
		setDisableTemplates(false);
	}
	
	const handleChangeTemplate = (
		e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
		_child: React.ReactNode
		): void => {
			setTemplate(e.target.value as string)
	}

	const handleChangeEmailAtItem = (value: any, index: number) => {
		const newStaffEmails: StaffEmailsProps[] = [...staffEmails];
		newStaffEmails[index].error = !validateEmail(value);
		newStaffEmails[index].errMsg = !validateEmail(value) ? 'Please input valid email' : '';
		newStaffEmails[index].email = value;
		setStaffEmails(newStaffEmails);
	}

	const handleAddItem = (index: number): void => {
		const newStaffEmails: StaffEmailsProps[] = [...staffEmails];
		newStaffEmails[index].addable = false;
		newStaffEmails[index].removeable = false;
		newStaffEmails.push({ email: '', addable: true, removeable: true, error: false, errMsg: '' })
		setStaffEmails(newStaffEmails);	
	}

	const handleRemoveItem = (index: number) => {
		const newStaffEmails: StaffEmailsProps[] = [...staffEmails];
		if (index > -1) {
			newStaffEmails.splice(index, 1);
			newStaffEmails[newStaffEmails.length - 1].addable = true;
			newStaffEmails[newStaffEmails.length - 1].removeable = true;
		}
		setStaffEmails(newStaffEmails);
	};

	const handleSubmit = () => {
		
		const newStaffEmails: StaffEmailsProps[] = [...staffEmails];
		
		if (!newStaffEmails.some(item => item?.errMsg && item.errMsg !== '') && requestNameError === '') {

			// Remove duplicate emails
			const emails = newStaffEmails.filter((item: StaffEmailsProps, index: number) => {
				const _item = JSON.stringify(item.email);
				return index === newStaffEmails.findIndex((obj: StaffEmailsProps) => {
					return JSON.stringify(obj.email) === _item;
				});
			}).map((item: StaffEmailsProps) => ({ email: item?.email || '' }));

			// const params = 
			const templateId = template !== '' ? template : undefined;
			if (mode === AddEditMode.ADD) {
				onSubmit && onSubmit(requestName, requestedDocuments, emails, 'draft', mode, templateId);
			} else if (mode === AddEditMode.EDIT && requestId !== '') {
				onSubmit && onSubmit(requestName, requestedDocuments, emails, 'draft', mode, templateId, requestId);
			}

			resetModal();
		}

	}

	const onCloseModal = () => {
		if (onClose) onClose();
		resetModal();
	}

	return (
		<Modal showModal={showModal} paperClassName={styles.modalPaper}>
			<Box className={styles.headerRow}>
				<Box className={styles.headerTitle}>Create a new request</Box>
				<IconButton onClick={onCloseModal}>
					<Close classes={{ root: styles.closeIcon }} />
				</IconButton>
			</Box>
			
			<Box className={styles.contentRow}>
				<Box className={styles.inputGroup}>
					<InputLabel>Name your Request</InputLabel>
					<Input
						placeholder={requestNamePattern}
						className={classNames(styles.inputControls, styles.txtName)}
						value={requestName} 
						onChange={handleChangeRequestName}/>
					<div 
						className={styles.errorMsgEmail} 
						style={{ 
							display: requestNameError !== '' ? 'block' : 'none',
							marginTop: requestNameError !== '' ? '8px' : '0px'
						}}
					>
						{requestNameError}
					</div>
				</Box>
				<Box className={styles.inputGroup}>
					<InputLabel>Select from template</InputLabel>
					<Select
						value={template}
						displayEmpty
						disabled={disableTemplates}
						onChange={handleChangeTemplate}
						className={classNames(styles.inputControls, styles.selectTemplate)}
						classes={{ 
							root: styles.selectMenu,
							icon: styles.selectMenuIcon
						}}
						MenuProps={{
							anchorOrigin: {
							  vertical: "bottom",
							  horizontal: "left"
							},
							transformOrigin: {
							  vertical: "top",
							  horizontal: "left"
							},
							getContentAnchorEl: null,
							classes: { paper: styles.selectMenuList }
						}}
					>	
						{ templateList.map((template: TemplateListProps) => <MenuItem key={`template-${template._id}`} value={template._id}><span className={styles.txtTemplate}>{template.name}</span></MenuItem>) }
					</Select>
				</Box>

				<Box className={classNames(styles.inputGroup, styles.staffsGroup)}>
					<InputLabel>Which <b>staff member(s)</b> do you want to be notified once the Request is completed?</InputLabel>
					{
						staffEmails.map((item: StaffEmailsProps, index: number) => {
							return <>
								<Box display="flex" key={index} className={styles.emailItem}>
									<Input
										placeholder='Enter email here'
										className={classNames(styles.inputControls, styles.txtStaffsEmail)}
										type="email"
										value={item.email}
										onChange={e => handleChangeEmailAtItem(e.target.value, index)}
									/>
									{
										item.addable &&
										<div className={classNames(styles.staffButtonGroup, styles.addStaffGroup)}>
											<IconButton classes={{ root: styles.iconButtons }} onClick={() => handleAddItem(index)}>
												<AddCircleOutline classes={{ root: styles.staffIcon }} htmlColor="#541868" />
											</IconButton>
										</div>
									}
									{
										index > 0 && item.removeable &&
										<div className={classNames(styles.staffButtonGroup, styles.removeStaffGroup)}>
											<IconButton classes={{ root: styles.iconButtons }} onClick={() => handleRemoveItem(index)}>
												<RemoveCircleOutlineRounded classes={{ root: styles.staffIcon }} htmlColor="#541868" />
											</IconButton>
										</div>
									}
								</Box>
								<div 
									className={styles.errorMsgEmail} 
									style={{ 
										display: item.error ? 'block' : 'none',
										marginBottom: item.error ? '8px' : '0px'
									}}>
									{item.errMsg}
								</div>
							</>
						})
					}
				</Box>
			</Box>

			<Box className={styles.footerRow}>
				<Button 
					className={classNames(styles.actionButtons, styles.cancelButton)}
					onClick={onCloseModal}>
					Cancel
				</Button>
				<Button 
					className={classNames(styles.actionButtons, styles.yesButton)} 
					onClick={handleSubmit}>
					Yes
				</Button>
			</Box>
		</Modal>
	)
}

export default AddMultiRequestModal