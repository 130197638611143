import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {ColorLighterGrey} from "../../lib/theme";

const useStyles = makeStyles({
  item: {
    padding: "1em 2em",
    display: "flex",
    backgroundColor: ColorLighterGrey,
    marginBottom: "0.5em",
    borderRadius: "0.25em",
    alignItems: "center",
  },
});

export type ListItemProps = {
  children?: React.ReactNode;
};

const ListItem = ({children}: ListItemProps) => {
  const styles = useStyles();

  return <div className={styles.item}>{children}</div>;
};

export default ListItem;
