import { Box, Grid, IconButton } from "@material-ui/core"
import { AddCircleOutlined, ArrowDropDownSharp, KeyboardArrowDown, KeyboardArrowUp, MailOutline } from "@material-ui/icons"
import _ from "lodash"
import moment from "moment"
import React, { ReactElement, useEffect, useMemo, useRef, useState } from "react"
import DataTable, { TableColumn } from "react-data-table-component"
import FadeLoader from "react-spinners/FadeLoader"
import { AddEditMode } from "../../../components/AddMultiRequestModal"
import ButtonStatus from "../../../components/ButtonStatus"
import DuplicateRequestModal from "../../../components/DuplicateRequestModal/DuplicateRequestModal"
import Modal from "../../../components/Modal"
import MonthRangeFilter, { DateFilterProps } from "../../../components/MonthRangeFilter"
import MultiDownloadButton from "../../../components/MultiDownloadButton/MultiDownloadButton"
import NoticeModal from "../../../components/NoticeModal"
import StatusFilter from "../../../components/StatusFilter"
import TrackingHistoryModal from "../../../components/TrackingHistoryModal"
import { FilterConst } from '../../../constants/requestHistoryOptions'
import { getClientNames, getTrackingsByTranscations, getTrackingsByRequestId, i_resultAPIs, getTrackingsHistory } from "../../../lib/services"
import { sortByCompletedAt, sortByName, sortByRequested, sortByRequester } from "../../../lib/sortingDataTable"
import { objectToQueryString, _fetch } from "../../../lib/utils"
import { addFileRecords } from "../Documents/Documents.controller"
import { i_clientName } from "../Documents/Documents.interfaces"
import { Document, GeneralInformationProps, HistoryDataProps, ReminderSequenceProps, RequestHistoryProps, TrackingProps } from "./RequestHistory.interfaces"
import styles from "./RequestHistory.module.scss"
import RequestHistoryGroup from "./RequestHistoryGroup"
import { emailSent1, emailSent2, emailSent3, emailSent4, received1, received2, received3, received4, opened1, opened2, opened3, opened4, bounced1, bounced2, bounced3, bounced4, reminderGray, reminderBlue, reminderGreen, reminderRed, cancelEmailFollowups } from "./emailTrackingIcons"
import { UserProps } from "../AdminRequest/AdminRequest.interface"
import SendOneOffReminderModal from "../../../components/SendOneOffReminderModal"
import CancelEmailFollowupsModal from "../../../components/CancelEmailFollowupsModal"
const GO_TO_PORTAL = "Go To Portal"
const EDIT_REQUEST = "Edit Request"
const CANCEL_REQUEST = "Cancel Request"
const DUPLICATE_REQUEST = "Duplicate Request"
const SEND_ONE_OFF_REMINDER = "Send One-off Reminder"
const EDIT_FOLLOW_UP_SEQUENCE = "Edit Follow-up Sequence"
const CANCEL_EMAIL_FOLLOW_UPS = "Cancel Email Follow-ups"
const OPTIONS = Object.freeze([GO_TO_PORTAL, EDIT_REQUEST, CANCEL_REQUEST, DUPLICATE_REQUEST, SEND_ONE_OFF_REMINDER, EDIT_FOLLOW_UP_SEQUENCE, CANCEL_EMAIL_FOLLOW_UPS]);

const DATE_FORMAT = "DD/MM/YYYY"
const TIME_FORMAT = "hh:mma"
const DATETIME_FORMAT = "DD/MM/YYYY hh:mma"
const { REQUEST_STATUS_TYPE } = FilterConst;

//  Internally, customStyles will deep merges your customStyles with the default styling.
export const customStyles = {
  tableWrapper: {
    style: {
      fontFamily: '"Poppins", sans-serif',
    },
  },
  header: {
    style: {
      fontSize: '20px',
      color: '#541868',
      lineHeight: '28px',
      fontWeight: 400
    },
  },
  head: {
    style: {
      fontSize: '12px',
      color: '#541868',
      lineHeight: '17px',
      fontWeight: 300,
    },
  },
  headRow: {
    style: {
      minHeight: 'unset',
      height: '35px',

      '& > div:first-child': {
        display: 'none'
      },
      '& > div:nth-child(2)': {
        paddingRight: '60px'
      }
    },
  },
  rows: {
    style: {
      backgroundColor: '#FAFAFA',
      borderRadius: '4px',

      '&:not(:first-child)': {
        marginBottom: '5px',
        marginTop: '5px',
      },
      '&:first-child': {
        marginTop: '23px',
        marginBottom: '5px',
      },

      '& > div:first-child': {

        '& svg.MuiSvgIcon-root': {
          fontSize: '30px',
          color: '#541868',
        }
      },

      '& .rdt_TableCell': {
        marginBottom: '9px',
        marginTop: '9px',

        'div[data-tag=allowRowEvents]': {
          fontSize: '14px',
          lineHeight: '20px',
          color: '#541868',
          whiteSpace: 'break-spaces',
          textAlign: 'center'
        },

        '&:not(:first-child):not(:nth-child(2))': {
          'div[data-tag=allowRowEvents]': {
            color: '#000000',
          },
        },
        '&:nth-child(3), &:nth-child(7)': {
          'div[data-tag=allowRowEvents]': {
            fontSize: '12px',
          },
        },
        '&:nth-child(2) > div[data-tag=allowRowEvents]': {
          textAlign: 'left'
        }
      },

      'div[data-column-id=emailStatus]': {
        maxWidth: "70px",
        minWidth: "70px"
      },

      'div[data-column-id=name]': {
        padding: '0 32px 0 0'
      }
    },
  },
  headCells: {
    style: {
      '&[data-column-id=emailStatus]': {
        maxWidth: "70px",
        minWidth: "70px",
        padding: 0,

        '& .rdt_TableCol_Sortable': {
          justifyContent: 'center !important'
        }
      },

      '&:not(:nth-child(2))': {
        '& > .rdt_TableCol_Sortable': {
          justifyContent: 'center'
        }
      },

      '& .rdt_TableCol_Sortable': {

        '& span.__rdt_custom_sort_icon__': {
          display: 'flex',

          '& svg': {
            height: '20px',
            width: '20px',
          }
        },

        '&.fFBlx span.__rdt_custom_sort_icon__ svg': {
          opacity: '0.3',
        }
      },
    },
  },
};

const RequestHistory = (props: any): ReactElement => {
  const [requestData, setRequestData] = useState<any>([])
  const [checkedDownload, setCheckedDownload] = useState<any[]>([])
  const [showSubmitSuccessModal, setShowSubmitSuccessModal] = useState(false)
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState("")
  const [clientName, setClientName] = useState<i_clientName | undefined | void>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [resetItemComponent, setResetItemComponent] = useState(false)
  const [tableTitle, setTableTitle] = useState("All Request");
  const statusFilterRef = useRef<string>(REQUEST_STATUS_TYPE.All_Status['key']);
  const [apiParams, setApiParams] = useState<object>(() => ({
    startDate: '1990-01-01',
    endDate: '2100-12-31',
    status: REQUEST_STATUS_TYPE.All_Status['key']
  }));
  const [selectedRow, setSelectedRow] = useState<String | null>();

  const [showNoticeModal, setShowNoticeModal] = useState(false)
  const [noticeTextModal, setNoticeTextModal] = useState<string>('')
  const [showDuplicateRequestModal, setShowDuplicateRequestModal] = useState(false)
  const [rowData, setrowData] = useState({})
  const [historyData, setHistoryData] = useState<HistoryDataProps>();
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [isHistoryLoading, setIsHistoryLoading] = useState<boolean>(false);
  const [showSendOneOffReminder, setShowSendOneOffReminder] = useState<boolean>(false);
  const [showCancelEmailFollowUpModal, setShowCancelEmailFollowUpModal] = useState<boolean>(false);

  const columns: TableColumn<RequestHistoryProps>[] = useMemo(() => [
    {
      id: "name",
      name: "Name",
      selector: row => row?.name ? row.name.toUpperCase() : '',
      sortable: true,
      grow: 6,
      sortFunction: sortByName
    },
    {
      id: "completedAt",
      name: "Completed",
      selector: row => row.completedAt ? moment(row.completedAt).valueOf() : moment('1990-01-01').valueOf(),
      cell: row => moment(row.completedAt, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid() ? <>{moment(row.completedAt).format(DATE_FORMAT)}<br />{moment(row.completedAt).format(TIME_FORMAT)}</> : '',
      style: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center'
      },
      sortable: true,
      grow: 1,
      sortFunction: sortByCompletedAt
    },
    {
      id: "emailStatus",
      name: <MailOutline fontSize="large" style={{ color: '#541868' }} />,
      sortable: false,
      grow: 1,
      style: {
        borderLeft: '1px solid #C4C4C4',
        justifyContent: 'center'
      },
      cell: (row) => row && createEmailBadges(row),
    },
    {
      id: "division",
      name: "Division",
      selector: row => row?.staff?.division ? row.staff.division.toUpperCase() : '',
      sortable: true,
      grow: 2,
      style: {
        borderLeft: '1px solid #C4C4C4',
        justifyContent: 'center'
      }
    },
    {
      id: "requestedBy",
      name: "Requested By",
      selector: row => (row?.staff?.fname && row.staff.fname !== '' && row?.staff?.lname && row.staff.lname !== '') ? `${row.staff.fname} ${row.staff.lname}` : row?.staff?.email ? row.staff.email.split('@')[0] : '',
      sortable: true,
      grow: 1,
      style: {
        borderLeft: '1px solid #C4C4C4',
        justifyContent: 'center'
      },
      sortFunction: sortByRequester
    },
    {
      id: "requested",
      name: "Requested",
      selector: row => row.createdAt ? moment(row.createdAt).valueOf() : moment().valueOf(),
      cell: row => moment(row.createdAt, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid() ? <>{moment(row.createdAt).format(DATE_FORMAT)}<br />{moment(row.createdAt).format(TIME_FORMAT)}</> : '',
      sortable: true,
      grow: 1,
      style: {
        borderLeft: '1px solid #C4C4C4',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center'
      },
      sortFunction: sortByRequested
    },
    {
      id: "status",
      name: "Status",
      sortable: true,
      selector: row => row?.workflowStatus ? row.workflowStatus.toUpperCase() : '',
      grow: 2,
      style: {
        borderLeft: '1px solid #C4C4C4',
        justifyContent: 'center'
      },
      cell: (row) => <ButtonStatus row={row} setrowData={setrowData} onMenuItemClick={handleonMenuItemClick} />,
    }
  ], []);
  const refreshPage = () => {
    getData(apiParams);
    getClientNames("client/getClientNames").then(clientName => { setClientName(clientName) });
  }

  useEffect(() => {
    getData(apiParams);
    getClientNames("client/getClientNames").then(clientName => { setClientName(clientName) });
  }, []);
  const handleClickOnSendOneOffReminder = () => {
    setShowSendOneOffReminder(true)
  }
  const handleClickOnCancelEmailFollowUpModal = () => {
    setShowCancelEmailFollowUpModal(true)
  }
  const handleonMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number, data: RequestHistoryProps) => {
    event.stopPropagation();
    switch (OPTIONS[index]) {
      case GO_TO_PORTAL:
        window.location.href = `${window.location.origin}/dashboard/${props.clientId}`;
        break;
      case EDIT_REQUEST:
        if (props.onAddRequest) props.onAddRequest(AddEditMode.EDIT, data, false)
        break;
      case CANCEL_REQUEST:
        // Add document collection
        setIsLoading(true);
        if (data.status === "draft") {
          cancelDraftRequest(data._id);
          setIsLoading(false);
        } else if (data.status === "submitByAdmin") {
          if (data.requested_documents.length > 0) {
            addFileRecords({
              requestedDocuments: data.requested_documents,
              clientId: props.clientId,
              isSingle: false,
              requestId: data._id
            })
              .then(res => {
                if (res) {
                  cancelRequest(data._id)
                }
                setIsLoading(false);
              })
              .catch(() => {
                alert("Something`s wrong! Please contact the website administrator.")
                setIsLoading(false);
              })
          } else {
            setIsLoading(false);
            setShowNoticeModal(true);
            setNoticeTextModal('No documents have been selected')
          }
        }
        break;
      case DUPLICATE_REQUEST:
        setShowDuplicateRequestModal(true)
        break;
      case SEND_ONE_OFF_REMINDER:
        // For send one of reminder
        handleClickOnSendOneOffReminder()
        break;
      case EDIT_FOLLOW_UP_SEQUENCE:
        // edit follow up sequence
        break;
      case CANCEL_EMAIL_FOLLOW_UPS:
        // cancel email follow ups
        handleClickOnCancelEmailFollowUpModal()
        break;

      default:
        break;
    }
  }

  const cancelRequest = (requestId: String) => {
    _fetch(["mbworkbench/setCancelRequest"].join("/"), {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ request_id: requestId }),
    })
      .then((res: any) => res.json())
      .then((_res: any) => {
        getData({ ...apiParams, status: statusFilterRef.current })
        setApiParams({ ...apiParams, status: statusFilterRef.current });
      })
  }

  const cancelDraftRequest = (requestId: String) => {
    _fetch(["mbworkbench/setCancelDraftRequest"].join("/"), {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ request_id: requestId }),
    })
      .then((res: any) => res.json())
      .then((_res: any) => {
        getData({ ...apiParams, status: statusFilterRef.current })
        setApiParams({ ...apiParams, status: statusFilterRef.current });
      })
  }

  const createEmailBadges = (row: RequestHistoryProps) => {
    const createStatusIcons = (sequence: Number, status: any) => {
      switch (sequence) {
        case 0:
          if (status?.trim().toLocaleLowerCase() === "send") {
            return emailSent1
          } else if (status?.trim().toLocaleLowerCase() === "deliverydelay") {
            return emailSent1
          } else if (status?.trim().toLocaleLowerCase() === "ready") {
            return emailSent1
          } else if (status?.trim().toLocaleLowerCase() === "pending") {
            return emailSent1
          } else if (status?.trim().toLocaleLowerCase() === "delivery") {
            return received1
          } else if (status?.trim().toLocaleLowerCase() === "click" || status?.trim().toLocaleLowerCase() === "open") {
            return opened1
          } else if (status?.trim().toLocaleLowerCase() === "completed") {
            return opened1
          } else if (status?.trim().toLocaleLowerCase() === "bounce") {
            return bounced1
          } else if (status?.trim().toLocaleLowerCase() === "rendering failure") {
            return bounced1
          } else if (status?.trim().toLocaleLowerCase() === "cancelled") {
            return cancelEmailFollowups
          } else {
            return "draft"
          }
        case 1:
          if (status?.trim().toLocaleLowerCase() === "send") {
            return emailSent2
          } else if (status?.trim().toLocaleLowerCase() === "deliverydelay") {
            return emailSent2
          } else if (status?.trim().toLocaleLowerCase() === "pending") {
            return emailSent2
          } else if (status?.trim().toLocaleLowerCase() === "ready") {
            return emailSent2
          } else if (status?.trim().toLocaleLowerCase() === "delivery") {
            return received2
          } else if (status?.trim().toLocaleLowerCase() === "click" || status?.trim().toLocaleLowerCase() === "open") {
            return opened2
          } else if (status?.trim().toLocaleLowerCase() === "completed") {
            return opened2
          } else if (status?.trim().toLocaleLowerCase() === "bounce") {
            return bounced2
          } else if (status?.trim().toLocaleLowerCase() === "rendering failure") {
            return bounced2
          } else if (status?.trim().toLocaleLowerCase() === "cancelled") {
            return cancelEmailFollowups
          } else {
            return "draft"
          }
        case 2:
          if (status?.trim().toLocaleLowerCase() === "send") {
            return emailSent3
          } else if (status?.trim().toLocaleLowerCase() === "deliverydelay") {
            return emailSent3
          } else if (status?.trim().toLocaleLowerCase() === "ready") {
            return emailSent3
          } else if (status?.trim().toLocaleLowerCase() === "pending") {
            return emailSent3
          } else if (status?.trim().toLocaleLowerCase() === "delivery") {
            return received3
          } else if (status?.trim().toLocaleLowerCase() === "click" || status?.trim().toLocaleLowerCase() === "open") {
            return opened3
          } else if (status?.trim().toLocaleLowerCase() === "completed") {
            return opened3
          } else if (status?.trim().toLocaleLowerCase() === "bounce") {
            return bounced3
          } else if (status?.trim().toLocaleLowerCase() === "rendering failure") {
            return bounced3
          } else if (status?.trim().toLocaleLowerCase() === "cancelled") {
            return cancelEmailFollowups
          } else {
            return "draft"
          }
        case 3:
          if (status?.trim().toLocaleLowerCase() === "send") {
            return emailSent4
          } else if (status?.trim().toLocaleLowerCase() === "deliverydelay") {
            return emailSent4
          } else if (status?.trim().toLocaleLowerCase() === "ready") {
            return emailSent4
          } else if (status?.trim().toLocaleLowerCase() === "pending") {
            return emailSent4
          } else if (status?.trim().toLocaleLowerCase() === "delivery") {
            return received4
          } else if (status?.trim().toLocaleLowerCase() === "click" || status?.trim().toLocaleLowerCase() === "open") {
            return opened4
          } else if (status?.trim().toLocaleLowerCase() === "completed") {
            return opened4
          } else if (status?.trim().toLocaleLowerCase() === "bounce") {
            return bounced4
          } else if (status?.trim().toLocaleLowerCase() === "rendering failure") {
            return bounced4
          } else if (status?.trim().toLocaleLowerCase() === "cancelled") {
            return cancelEmailFollowups
          } else {
            return "draft"
          }
        case 4:
          if (status?.trim().toLocaleLowerCase() === "send") {
            return reminderGray
          } else if (status?.trim().toLocaleLowerCase() === "deliverydelay") {
            return reminderGray
          } else if (status?.trim().toLocaleLowerCase() === "ready") {
            return reminderGray
          } else if (status?.trim().toLocaleLowerCase() === "pending") {
            return reminderGray
          } else if (status?.trim().toLocaleLowerCase() === "delivery") {
            return reminderBlue
          } else if (status?.trim().toLocaleLowerCase() === "click" || status?.trim().toLocaleLowerCase() === "open") {
            return reminderGreen
          } else if (status?.trim().toLocaleLowerCase() === "completed") {
            return reminderGreen
          } else if (status?.trim().toLocaleLowerCase() === "bounce") {
            return reminderRed
          } else if (status?.trim().toLocaleLowerCase() === "rendering failure") {
            return reminderRed
          } else if (status?.trim().toLocaleLowerCase() === "cancelled") {
            return cancelEmailFollowups
          } else {
            return "draft"
          }
      }
    }
    let firstPersonStatus
    let secondPersonStatus
    row?.emailTrackingsStatus && row?.emailTrackingsStatus.length > 0 && row.emailTrackingsStatus.map((tracking, index) => {
      switch (tracking?.currentSequence) {
        case 0:
          if (tracking?.reminderSequence[0]?.status?.trim().toLocaleLowerCase() === "send") {
            if (index === 0) firstPersonStatus = emailSent1
            else secondPersonStatus = emailSent1
          } else if (tracking?.reminderSequence[0]?.status?.trim().toLocaleLowerCase() === "deliverydelay") {
            if (index === 0) firstPersonStatus = emailSent1
            else secondPersonStatus = emailSent1
          } else if (tracking?.reminderSequence[0]?.status?.trim().toLocaleLowerCase() === "pending") {
            if (index === 0) firstPersonStatus = emailSent1
            else secondPersonStatus = emailSent1
          } else if (tracking?.reminderSequence[0]?.status?.trim().toLocaleLowerCase() === "ready") {
            if (index === 0) firstPersonStatus = emailSent1
            else secondPersonStatus = emailSent1
          } else if (tracking?.reminderSequence[0]?.status?.trim().toLocaleLowerCase() === "delivery") {
            if (index === 0) firstPersonStatus = received1
            else secondPersonStatus = received1
          } else if (tracking?.reminderSequence[0]?.status?.trim().toLocaleLowerCase() === "click" || tracking?.reminderSequence[0]?.status?.trim().toLocaleLowerCase() === "open") {
            if (index === 0) firstPersonStatus = opened1
            else secondPersonStatus = opened1
          } else if (tracking?.reminderSequence[0]?.status?.trim().toLocaleLowerCase() === "completed") {
            if (index === 0) firstPersonStatus = opened1
            else secondPersonStatus = opened1
          } else if (tracking?.reminderSequence[0]?.status?.trim().toLocaleLowerCase() === "bounce") {
            if (index === 0) firstPersonStatus = bounced1
            else secondPersonStatus = bounced1
          } else if (tracking?.reminderSequence[0]?.status?.trim().toLocaleLowerCase() === "rendering failure") {
            if (index === 0) firstPersonStatus = bounced1
            else secondPersonStatus = bounced1
          } else if (tracking?.reminderSequence[0]?.status?.trim().toLocaleLowerCase() === "cancelled") {
            if (index === 0) firstPersonStatus = cancelEmailFollowups
            else secondPersonStatus = cancelEmailFollowups
          } else {
            const sequences = tracking?.addHocSequence?.filter(sequence => sequence.setSequence === tracking?.currentSequence)
            const latestSequence = sequences[sequences.length - 1];
            if (latestSequence?.status?.trim().toLocaleLowerCase() === "draft" || !latestSequence) {

              if (index === 0) firstPersonStatus = emailSent1
              else secondPersonStatus = emailSent1
            } else {
              if (index === 0) firstPersonStatus = createStatusIcons(4, latestSequence?.status)
              else secondPersonStatus = createStatusIcons(4, latestSequence?.status)
            }
          }
          break;

        case 1:
          if (tracking?.reminderSequence[1]?.status?.trim().toLocaleLowerCase() === "send") {
            if (index === 0) firstPersonStatus = emailSent2
            else secondPersonStatus = emailSent2
          } else if (tracking?.reminderSequence[1]?.status?.trim().toLocaleLowerCase() === "deliverydelay") {
            if (index === 0) firstPersonStatus = emailSent2
            else secondPersonStatus = emailSent2
          } else if (tracking?.reminderSequence[1]?.status?.trim().toLocaleLowerCase() === "pending") {
            if (index === 0) firstPersonStatus = emailSent2
            else secondPersonStatus = emailSent2
          } else if (tracking?.reminderSequence[1]?.status?.trim().toLocaleLowerCase() === "ready") {
            if (index === 0) firstPersonStatus = emailSent2
            else secondPersonStatus = emailSent2
          } else if (tracking?.reminderSequence[1]?.status?.trim().toLocaleLowerCase() === "delivery") {
            if (index === 0) firstPersonStatus = received2
            else secondPersonStatus = received2
          } else if (tracking?.reminderSequence[1]?.status?.trim().toLocaleLowerCase() === "click" || tracking?.reminderSequence[1]?.status?.trim().toLocaleLowerCase() === "open") {
            if (index === 0) firstPersonStatus = opened2
            else secondPersonStatus = opened2
          } else if (tracking?.reminderSequence[1]?.status?.trim().toLocaleLowerCase() === "completed") {
            if (index === 0) firstPersonStatus = opened2
            else secondPersonStatus = opened2
          } else if (tracking?.reminderSequence[1]?.status?.trim().toLocaleLowerCase() === "bounce") {
            if (index === 0) firstPersonStatus = bounced2
            else secondPersonStatus = bounced2
          } else if (tracking?.reminderSequence[1]?.status?.trim().toLocaleLowerCase() === "rendering failure") {
            if (index === 0) firstPersonStatus = bounced2
            else secondPersonStatus = bounced2
          } else if (tracking?.reminderSequence[1]?.status?.trim().toLocaleLowerCase() === "cancelled") {
            if (index === 0) firstPersonStatus = cancelEmailFollowups
            else secondPersonStatus = cancelEmailFollowups
          } else {
            const sequences = tracking.addHocSequence.filter((sequence) => sequence.setSequence === tracking?.currentSequence)
            const latestSequence = sequences[sequences.length - 1];
            if (latestSequence?.status?.trim().toLocaleLowerCase() === "draft" || !latestSequence) {
              if (index === 0) firstPersonStatus = createStatusIcons(0, tracking?.reminderSequence[0]?.status)
              else secondPersonStatus = createStatusIcons(0, tracking?.reminderSequence[0]?.status)
            } else {
              if (index === 0) firstPersonStatus = createStatusIcons(4, latestSequence?.status)
              else secondPersonStatus = createStatusIcons(4, latestSequence?.status)
            }
          }
          break;

        case 2:
          if (tracking?.reminderSequence[2]?.status?.trim().toLocaleLowerCase() === "send") {
            if (index === 0) firstPersonStatus = emailSent3
            else secondPersonStatus = emailSent3
          } else if (tracking?.reminderSequence[2]?.status?.trim().toLocaleLowerCase() === "deliverydelay") {
            if (index === 0) firstPersonStatus = emailSent3
            else secondPersonStatus = emailSent3
          } else if (tracking?.reminderSequence[2]?.status?.trim().toLocaleLowerCase() === "pending") {
            if (index === 0) firstPersonStatus = emailSent3
            else secondPersonStatus = emailSent3
          } else if (tracking?.reminderSequence[2]?.status?.trim().toLocaleLowerCase() === "ready") {
            if (index === 0) firstPersonStatus = emailSent3
            else secondPersonStatus = emailSent3
          } else if (tracking?.reminderSequence[2]?.status?.trim().toLocaleLowerCase() === "delivery") {
            if (index === 0) firstPersonStatus = received3
            else secondPersonStatus = received3
          } else if (tracking?.reminderSequence[2]?.status?.trim().toLocaleLowerCase() === "click" || tracking?.reminderSequence[2]?.status?.trim().toLocaleLowerCase() === "open") {
            if (index === 0) firstPersonStatus = opened3
            else secondPersonStatus = opened3
          } else if (tracking?.reminderSequence[2]?.status?.trim().toLocaleLowerCase() === "completed") {
            if (index === 0) firstPersonStatus = opened3
            else secondPersonStatus = opened3
          } else if (tracking?.reminderSequence[2]?.status?.trim().toLocaleLowerCase() === "bounce") {
            if (index === 0) firstPersonStatus = bounced3
            else secondPersonStatus = bounced3
          } else if (tracking?.reminderSequence[2]?.status?.trim().toLocaleLowerCase() === "rendering failure") {
            if (index === 0) firstPersonStatus = bounced3
            else secondPersonStatus = bounced3
          } else if (tracking?.reminderSequence[2]?.status?.trim().toLocaleLowerCase() === "cancelled") {
            if (index === 0) firstPersonStatus = cancelEmailFollowups
            else secondPersonStatus = cancelEmailFollowups
          } else {

            const sequences = tracking.addHocSequence.filter((sequence) => sequence.setSequence === tracking?.currentSequence)
            const latestSequence = sequences[sequences.length - 1];
            if (latestSequence?.status?.trim().toLocaleLowerCase() === "draft" || !latestSequence) {
              if (index === 0) firstPersonStatus = createStatusIcons(1, tracking?.reminderSequence[1]?.status)
              else secondPersonStatus = createStatusIcons(1, tracking?.reminderSequence[1]?.status)
            } else {
              if (index === 0) firstPersonStatus = createStatusIcons(4, latestSequence?.status)
              else secondPersonStatus = createStatusIcons(4, latestSequence?.status)
            }
          }

          break;
        case 3:
          if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "send") {
            if (index === 0) firstPersonStatus = emailSent4
            else secondPersonStatus = emailSent4
          } else if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "deliverydelay") {
            if (index === 0) firstPersonStatus = emailSent4
            else secondPersonStatus = emailSent4
          } else if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "pending") {
            if (index === 0) firstPersonStatus = emailSent4
            else secondPersonStatus = emailSent4
          } else if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "ready") {
            if (index === 0) firstPersonStatus = emailSent4
            else secondPersonStatus = emailSent4
          } else if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "delivery") {
            if (index === 0) firstPersonStatus = received4
            else secondPersonStatus = received4
          } else if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "click" || tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "open") {
            if (index === 0) firstPersonStatus = opened4
            else secondPersonStatus = opened4
          } else if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "completed") {
            if (index === 0) firstPersonStatus = opened4
            else secondPersonStatus = opened4
          } else if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "bounce") {
            if (index === 0) firstPersonStatus = bounced4
            else secondPersonStatus = bounced4
          } else if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "rendering failure") {
            if (index === 0) firstPersonStatus = bounced4
            else secondPersonStatus = bounced4
          } else if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "cancelled") {
            if (index === 0) firstPersonStatus = cancelEmailFollowups
            else secondPersonStatus = cancelEmailFollowups
          } else {
            const sequences = tracking.addHocSequence.filter((sequence) => sequence.setSequence === tracking?.currentSequence)
            const latestSequence = sequences[sequences.length - 1];
            if (latestSequence?.status?.trim().toLocaleLowerCase() === "draft" || !latestSequence) {
              if (index === 0) firstPersonStatus = createStatusIcons(2, tracking?.reminderSequence[2]?.status)
              else secondPersonStatus = createStatusIcons(2, tracking?.reminderSequence[2]?.status)
            } else {
              if (index === 0) firstPersonStatus = createStatusIcons(4, latestSequence?.status)
              else secondPersonStatus = createStatusIcons(4, latestSequence?.status)
            }

          }
          break;
        case 4:
          if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "send") {
            if (index === 0) firstPersonStatus = emailSent4
            else secondPersonStatus = emailSent4
          } else if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "deliverydelay") {
            if (index === 0) firstPersonStatus = emailSent4
            else secondPersonStatus = emailSent4
          } else if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "ready") {
            if (index === 0) firstPersonStatus = emailSent4
            else secondPersonStatus = emailSent4
          } else if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "pending") {
            if (index === 0) firstPersonStatus = emailSent4
            else secondPersonStatus = emailSent4
          } else if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "delivery") {
            if (index === 0) firstPersonStatus = received4
            else secondPersonStatus = received4
          } else if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "click" || tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "open") {
            if (index === 0) firstPersonStatus = opened4
            else secondPersonStatus = opened4
          } else if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "completed") {
            if (index === 0) firstPersonStatus = opened4
            else secondPersonStatus = opened4
          } else if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "bounce") {
            if (index === 0) firstPersonStatus = bounced4
            else secondPersonStatus = bounced4
          } else if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "rendering failure") {
            if (index === 0) firstPersonStatus = bounced4
            else secondPersonStatus = bounced4
          } else if (tracking?.reminderSequence[3]?.status?.trim().toLocaleLowerCase() === "cancelled") {
            if (index === 0) firstPersonStatus = cancelEmailFollowups
            else secondPersonStatus = cancelEmailFollowups
          } else {

            const sequences = tracking.addHocSequence.filter((sequence) => sequence.setSequence === tracking?.currentSequence)
            const latestSequence = sequences[sequences.length - 1];
            if (latestSequence?.status?.trim().toLocaleLowerCase() === "draft" || !latestSequence) {
              if (index === 0) firstPersonStatus = createStatusIcons(3, tracking?.reminderSequence[3]?.status)
              else secondPersonStatus = createStatusIcons(3, tracking?.reminderSequence[3]?.status)
            } else {
              if (index === 0) firstPersonStatus = createStatusIcons(4, latestSequence?.status)
              else secondPersonStatus = createStatusIcons(4, latestSequence?.status)
            }
          }
          break;
      }
    })
    if (row.emailTrackingsStatus && row.emailTrackingsStatus.length === 2 && firstPersonStatus && secondPersonStatus) {
      return <div className={styles.statusIcons}>
        {firstPersonStatus !== "draft" && <img src={firstPersonStatus} alt="Person1 status" width={"22px"} height={"22px"} onClick={() => handleClickOnEmailIcon(row)} />}
        {secondPersonStatus !== "draft" && <img src={secondPersonStatus} alt="Person2 status" width={"22px"} height={"22px"} onClick={() => handleClickOnEmailIcon(row)} />}
      </div>
    } else if (row.emailTrackingsStatus && row.emailTrackingsStatus.length === 1 && firstPersonStatus) {
      return <div className={styles.statusIcons}>
        {firstPersonStatus !== "draft" && <img src={firstPersonStatus} alt="Person1 status" width={"22px"} height={"22px"} onClick={() => handleClickOnEmailIcon(row)} />}
      </div>
    } else {
      return <div></div>
    }
  }

  const getData = async (params: object) => {
    setIsLoading(true)
    const data = await _fetch(
      ["mbworkbench/getRequestsHistory", `${props.clientId || ""}`, objectToQueryString(params)].join("/"),
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    ).then(res => res.json())
    let allTransactionsIds: String[] = []
    data && data.map((_data: RequestHistoryProps) => {
      allTransactionsIds.push(_data._id)
    })
    const result = await getTrackingsByTranscations(allTransactionsIds)
    const trackings = result && result?.data?.tracking
    data && setRequestData(data.map((_data: RequestHistoryProps) => {
      const emailTrackingsStatus = trackings.filter((item: TrackingProps) => item.transaction.transactionId === _data._id)
      return {
        emailTrackingsStatus,
        ..._data,
        requested_documents: _data.requested_documents.map(
          (d: Document) => {
            // NEW : no linked dcid from settings
            if (!d.dcid) {
              return {
                ...d,
                dcid: {
                  documentType: d.documentType,
                  label: d.documentLabel,
                },
              }
            } else {
              // LEGACY : label and type is fetched from settings
              return d
            }
          }
        ),
      }
    }),
    )
    setIsLoading(false);
  }

  const removeChecked = () => {
    setResetItemComponent(true)
    setCheckedDownload([])
    getData(apiParams);
  }

  const renderSubmitSuccessModal = () => (
    <Modal
      showModal={showSubmitSuccessModal}
      onClose={() => setShowSubmitSuccessModal(false)}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h3>{submitSuccessMessage}</h3>
        </Grid>
      </Grid>
    </Modal>
  )

  const checkDownloadHandler = (fileItem: any) => {
    let newFiles: any = _.clone(checkedDownload)
    // This loops the multi files and decides whether to delete or add into the new set of files
    // which will be passed to the multiple download button.
    fileItem.forEach((element: any, _index: number) => {
      if (newFiles.find((i: any) => i._id === element._id)) {
        if (element.checked === false) {
          newFiles = newFiles.filter((i: any) => {
            return i._id !== element._id
          })
        } else {
          // do nothing, don't add, don't delete
        }
      } else {
        newFiles.push(element)
      }
    })

    setCheckedDownload(newFiles)
  }

  const handleRowExpand = (expanded: boolean, row: RequestHistoryProps) => {
    if (expanded) {
      setSelectedRow(row._id)
    }
  }

  const handleChangeMonthFilter = (value: DateFilterProps) => {
    if (value.startDate && value.endDate) {
      let currentApiParams = {
        ...apiParams,
        startDate: value.startDate,
        endDate: value.endDate
      };

      getData(currentApiParams)
      setApiParams(currentApiParams);
    }
  };

  const handleChangeStatus = (status: string) => {
    if (status !== '') {
      statusFilterRef.current = status;
      let currentApiParams = { ...apiParams, status };
      getData(currentApiParams)
      setApiParams(currentApiParams);

      switch (status) {
        case REQUEST_STATUS_TYPE.All_Status['key']:
          setTableTitle("All Requests")
          break;
        case REQUEST_STATUS_TYPE.Current_Status['key']:
          setTableTitle("Current Requests in Progress")
          break;
        case REQUEST_STATUS_TYPE.Past_Status['key']:
          setTableTitle("Past Requests")
          break;

        default:
          setTableTitle("All Requests")
          break;
      }
    }
  };

  const handleClickOnEmailIcon = async (request: RequestHistoryProps) => {
    setIsHistoryLoading(true);
    setShowHistory(true);
    const requestId = request._id;

    const generalInformation: GeneralInformationProps = {
      clientInfo: '',
      requestedBy: '',
      requestedDate: '',
      completedDate: 'Not completed',
      requestName: ''
    }

    // Requested By
    if (request?.name && request.name !== '') {
      generalInformation.requestName = request.name;
    }

    // Requested By
    if ((request?.staff?.fname && request.staff.fname !== '' && request?.staff?.lname && request.staff.lname !== '')) {
      generalInformation.requestedBy = `${request.staff.fname} ${request.staff.lname}`
    } else if (request?.staff?.email) {
      generalInformation.requestedBy = request.staff.email.split('@')[0]
    }

    // Request Date
    if (request?.createdAt) {
      generalInformation.requestedDate = moment(request.createdAt).format(DATETIME_FORMAT);
    } else if (request?.emailedAt) {
      generalInformation.requestedDate = moment(request.emailedAt).format(DATETIME_FORMAT);
    }

    // Complete Date
    if (request?.completedAt) {
      generalInformation.completedDate = moment(request.completedAt).format(DATETIME_FORMAT);
    }

    // Client Info
    if (request?.receivers && request.receivers.length > 0) {
      request.receivers.forEach((receiver: UserProps) => {
        generalInformation.clientInfo = generalInformation.clientInfo && generalInformation.clientInfo !== '' ? generalInformation.clientInfo.concat(" ", `and ${receiver.fname}`) : receiver.fname;
      })
    }

    const result: i_resultAPIs = await getTrackingsByRequestId(requestId);

    if (result.status === "success") {
      const { data: { trackings } } = result;
      if (trackings.length > 0) {
        const trackingHistory: any[] = [];
        await Promise.all(trackings.map(async (track: TrackingProps, trackIndex: number) => {
          let response: i_resultAPIs = await getTrackingsHistory(track._id);

          if (response.status === 'success') {
            const { data: { trackingHistoryObj } } = response;

            let recipient = trackingHistoryObj.recipients?.email[0];
            let sender = trackingHistoryObj.sender?.email || '';

            if (trackingHistoryObj.reminderSequence.length > 0) {
              trackingHistoryObj.reminderSequence.sort((a: ReminderSequenceProps, b: ReminderSequenceProps) => {
                const valueA = a?.emailTemplate ? a.emailTemplate.slice(-1) : 0;
                const valueB = b?.emailTemplate ? b.emailTemplate.slice(-1) : 0;
                return Number(valueB) - Number(valueA);
              }).forEach((item: ReminderSequenceProps) => {
                const { status, sentDate, _id, nextReminder, emailSubject, emailHtml, emailTemplate } = item;
                const sequence = emailTemplate ? Number(emailTemplate.slice(-1)) - 1 : 0;
                if (status?.toLocaleLowerCase() !== 'draft') {
                  trackingHistory.push({ _id, sequence, nextReminder, status, recipient, sender, sentDate, emailSubject, emailHtml, orderBy: trackIndex })
                }
              })
            }

          }
        }))
          .catch((error) => console.log(`ERROR: ${error.message}`));

        setIsHistoryLoading(false);
        setHistoryData({ generalInformation, trackingHistory });
      }
    }
  }

  return (
    <>
      <div className={styles.root}>
        <Grid
          container
          spacing={3}
          classes={{
            root: styles.filterGridContainer
          }}
        >
          <Grid item xs={6} className={styles.titleGroup}>
            <div className={styles.tableTitle}>{tableTitle}</div>
          </Grid>
          <Grid item xs className={styles.actionGroup}>
            <Box display="flex">
              <div className={styles.checkDownload}>
                {checkedDownload.length > 0 && (
                  <MultiDownloadButton
                    checkedDownload={checkedDownload}
                    clientId={props.clientId}
                    setShowSubmitSuccessModal={setShowSubmitSuccessModal}
                    setSubmitSuccessMessage={setSubmitSuccessMessage}
                    removeChecked={removeChecked}
                    clientName={clientName}
                    setCheckedDownload={setCheckedDownload}
                  />
                )}
              </div>
              <MonthRangeFilter onChange={handleChangeMonthFilter} />
              <StatusFilter defaultSelStatus={statusFilterRef.current} onChange={handleChangeStatus} />

              <div className={styles.addRequestGroup}>
                <IconButton classes={{ root: styles.iconButtons }} onClick={() => props.onAddRequest(AddEditMode.ADD, null, false)}>
                  <AddCircleOutlined classes={{ root: styles.addRequestIcon }} htmlColor="#541868" />
                </IconButton>
              </div>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          classes={{
            root: styles.listGridContainer
          }}
        >
          <DataTable
            columns={columns}
            noDataComponent={<Box className={styles.noDataBox}>There is currently no data associated in this section.</Box>}
            data={requestData}
            progressPending={isLoading}
            progressComponent={<div className={[styles.spinner, "loading-spinner", styles.noFixed].join(" ")}>
              <FadeLoader height={20} width={5} radius={10} margin={10} color="#4e185b" />
            </div>}
            defaultSortFieldId="name"
            defaultSortAsc
            sortIcon={<ArrowDropDownSharp />}
            fixedHeader
            persistTableHead
            onRowExpandToggled={handleRowExpand}
            expandableRowExpanded={(row: RequestHistoryProps) => row._id === selectedRow}
            expandableRows
            expandOnRowClicked
            expandableIcon={{
              collapsed: <KeyboardArrowDown />,
              expanded: <KeyboardArrowUp />
            }}
            expandableRowsComponent={RequestHistoryGroup}
            expandableRowsComponentProps={{ clientId: props.clientId, checkDownloadHandler: checkDownloadHandler, resetItemComponent: resetItemComponent, isAdmin: props.isAdmin }}
            customStyles={customStyles}
            pagination
            paginationPerPage={Number(process.env.REACT_APP_ITEM_PER_PAGE) || 5}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
            onChangeRowsPerPage={() => setSelectedRow(null)}
            onChangePage={() => setSelectedRow(null)}
            onSort={() => setSelectedRow(null)}
          />
        </Grid>
      </div>
      {renderSubmitSuccessModal()}
      <NoticeModal
        showModal={showNoticeModal}
        onClose={() => setShowNoticeModal(false)}
        infoText={noticeTextModal}
      />
      <DuplicateRequestModal
        showModal={showDuplicateRequestModal}
        rowData={rowData}
        onClose={() => setShowDuplicateRequestModal(false)}
        onSubmit={props.onDuplicateRequest}
        setShowDuplicateRequestModal={setShowDuplicateRequestModal}
      />
      <TrackingHistoryModal
        isOpen={showHistory}
        onClose={() => setShowHistory(false)}
        historyData={historyData}
        isLoading={isHistoryLoading}
      />
      <SendOneOffReminderModal
        showModal={showSendOneOffReminder}
        onClose={() => setShowSendOneOffReminder(false)}
        rowData={rowData}
        clientId={props.clientId}
        refreshPage={refreshPage}
      />
      <CancelEmailFollowupsModal
        showModal={showCancelEmailFollowUpModal}
        onClose={() => setShowCancelEmailFollowUpModal(false)}
        rowData={rowData}
        refreshPage={refreshPage}
      />
    </>
  )
}

export default RequestHistory
