import { Box, IconButton, LinearProgress } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { ArrowDropDownSharp, CheckSharp, Close, ErrorOutline, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons"
import classNames from "classnames"
import _ from "lodash"
import moment from "moment"
import React, { useEffect, useMemo, useState } from "react"
import DataTable, { TableColumn } from "react-data-table-component"
import { FadeLoader } from "react-spinners"
import Modal from "../../../components/Modal"
import {
  getAllRequestsByStatus, getRequestById, i_Document, i_RequestedDocument, i_RequestedDocumentFile,
  updateDocumentCollection, uploadDocument, validateAndAddTags
} from "../../../lib/services"
import { sortByCompletion, sortByName, sortByRequested, sortByRequester } from "../../../lib/sortingDataTable"
import { customStyles } from "../RequestHistory/RequestHistory"
import { Document, RequestHistoryProps } from "../RequestHistory/RequestHistory.interfaces"
import { addFileRecords } from "./../Documents/Documents.controller"
import { i_ClientResponse } from "./ClientReponse.interface"
import styles from "./ClientResponse.module.scss"
import ClientResponseGroup from "./ClientResponseGroup"
import NoticeModal from "../../../components/NoticeModal"
import MultipleRequestUploader from "../../../components/MultipleRequestUploader"
import { i_FileStatementPeriod } from "../Documents/Documents.interfaces"


const DATE_FORMAT = "DD/MM/YYYY"
const TIME_FORMAT = "hh:mma"

const ClientResponse: React.FC<i_ClientResponse> = ({
  clientId,
  client_email,
  setNewRequestLength
}) => {
  // =================================[ State ]==========================================

  const [uploadedFile, setUploadedFile] = useState<
    i_RequestedDocumentFile | undefined
  >()
  const [requestedDocuments, setRequestedDocuments] = useState<i_Document[]>([])
  const [requestData, setRequestData] = useState<RequestHistoryProps[]>([])
  const [modalError, setModalError] = useState<string | undefined | null>()
  const [showFileUploaderModal, setShowFileUploaderModal] = useState(false)
  const [droppedFile, setDroppedFile] = useState<File | undefined>()
  const [isModalSubmitting, setIsModalSubmitting] = useState(false)
  const [currentFileEdited, setCurrentFileEdited] = useState("")
  const [showSubmitModal, setShowSubmitModal] = useState(false)
  const [showSubmitSuccessModal, setShowSubmitSuccessModal] = useState(false)
  const [modalDocumentId, setModalDocumentId] = useState<string>("")
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState("")
  const [modalIsEdit, setModalIsEdit] = useState<boolean>(false)
  const [fileLabel, setFileLabel] = useState("")
  const [fileType, setFileType] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [requestItemId, setRequestItemId] = useState<String | null>();
  const [tags, setTags] = useState<string[]>([]);
  const [requestedDateItem, setRequestedDateItem] = useState<any>();

  const [statementStartDate, setStatementStartDate] = useState<any>(null);
  const [statementEndDate, setStatementEndDate] = useState<any>(null);
  const [defReferNo, setDefReferNo] = useState<string>("");

  // =================================[ Functions ]==========================

  const refreshRequestedDocuments = () => {
    setIsLoading(true);
    getAllRequestsByStatus(clientId, 'submitByAdmin').then(response => {
      if (typeof response !== "undefined" && response !== null) {
        setRequestData(response.map((_data: RequestHistoryProps) => {
          return {
            ..._data,
            requested_documents: _data.requested_documents.map(
            (d: Document) => {
              // NEW : no linked dcid from settings
              if (!d.dcid) {
                return {
                  ...d,
                  dcid: {
                    _id: d._id,
                    documentType: d.documentType,
                    label: d.documentLabel,
                  },
                }
              } else {
                // LEGACY : label and type is fetched from settings
                return d
              }
            }
            ),
          }
        }))

        setNewRequestLength && setNewRequestLength(response.length);
      } else {
        setRequestData([]);
      }
      setIsLoading(false);
    })
  }
  const updateUploadFile = (dcid: string, newItem: i_Document) => {
    const itemIndex = requestedDocuments.findIndex(d => d._id === dcid)
    const newRequestedDocuments = [
      ...requestedDocuments.slice(0, itemIndex),
      newItem,
      ...requestedDocuments.slice(itemIndex + 1),
    ]
    setRequestedDocuments(newRequestedDocuments)
    setShowFileUploaderModal(false)
  }
  const onFileUploaderModalSubmit = (
    dcid: string,
    fileName: string,
    label: string,
    fileType: string,
    fileTags: string[],
    file?: File | undefined,
    staPeriod?: i_FileStatementPeriod,
    referNo?: string
  ) => {
    if (requestItemId !== undefined && requestItemId !== null) {
      const requestItem = requestData.find((item: RequestHistoryProps) => item._id === requestItemId);
      const item = requestItem?.requested_documents && requestItem?.requested_documents.find(d => d._id === dcid) as i_Document;
      if (fileTags.length > 0 && clientId) {
        validateAndAddTags(clientId, fileTags);
      }

      if (modalIsEdit && item && item.files) {
        const { files } = item
        let file = files.find((f: any) => f._id === currentFileEdited)
        const fileIndex = files.findIndex((f: any) => f._id === currentFileEdited)
  
        if (file) {
          item.files[fileIndex] = {
            ...item.files[fileIndex],
            label: label,
            file_type: fileType,
            updatedAt: moment().toISOString(),
            tags: fileTags,
            referenceNumber: referNo ? referNo : undefined,
            statementPeriod: staPeriod ? staPeriod : undefined
          }
  
          updateDocumentCollection({
            uid: clientId,
            status: "submitByAdmin",
            request_id: requestItemId,
            dcdata: requestItem.requested_documents.map(d => ({
              ...d,
              dcid: d.dcid,
              files: d.files,
            })),
          })
            .then(function() {
              setFileLabel("")
              refreshRequestedDocuments()
              setShowFileUploaderModal(false)
            })
            .catch(() => {
              setIsModalSubmitting(false)
              setModalError("Unable to modify details")
            })
        }
      } else if (item && file) {
        setIsModalSubmitting(true)
        setModalError("")
        uploadDocument(file, fileType, clientId, client_email)
          .then(({ data }) => {
            item.files = [
              ...(item.files || []),
              {
                file_name: fileName,
                ETag: data.ETag,
                label,
                createdAt: moment().toISOString(),
                updatedAt: moment().toISOString(),
                tags: fileTags,
                referenceNumber: referNo ? referNo : undefined,
                statementPeriod: staPeriod ? staPeriod : undefined
              },
            ]
            if (!data.error) {
              updateUploadFile(dcid, item)
              updateDocumentCollection({
                uid: clientId,
                status: "submitByAdmin",
                request_id: requestItemId,
                dcdata: requestItem.requested_documents.map(document => ({
                  ...document,
                  dcid: document.dcid,
                  files: document.files,
                })),
              }).then(function() {
                refreshRequestedDocuments();
                setIsModalSubmitting(false)
              })
            } else {
              setModalError("Unable to connect with AWS S3. " + data.error.code)
            }
          })
          .catch(err => {
            setIsModalSubmitting(false)
            setModalError("Unable to upload document. " + err.error.code)
          })
      } else setShowFileUploaderModal(false)

    }
  }
  const onCompleteRequestClick = (item: RequestHistoryProps) => {
    setRequestedDateItem(item.createdAt)
    handleSetRequestedDocuments(item._id);
    setShowSubmitModal(true)
  }

  const onFileUploaderModalOpen = (
    dcid: string,
    isEdit: boolean,
    defaultFileType: string,
    fileLabel?: string,
    fileId?: string,
    notes?: string,
    requestId?: String,
    fileTags?: string[],
    staPeriod?: i_FileStatementPeriod,
    referNo?: string
  ) => () => {
    setModalDocumentId(dcid)
    setModalIsEdit(isEdit)
    requestId && setRequestItemId(requestId);
    
    setTags(fileTags && fileTags.length > 0 ? fileTags : []);
    
    if (fileLabel) {
      const document = requestedDocuments.find(d => d._id === dcid && d.notes === notes)
      
      if (document && document.files) {
        const file = document.files.find((f: any) => f.label === fileLabel)
        file && setUploadedFile(file as i_RequestedDocumentFile)
      }
      setFileLabel(fileLabel)
    }

    fileId && setCurrentFileEdited(fileId)
    setFileType(defaultFileType)
    setStatementStartDate(staPeriod && staPeriod?.start ? staPeriod.start : null);
    setStatementEndDate(staPeriod && staPeriod?.end ? staPeriod.end : null);

    referNo ? setDefReferNo(referNo) : setDefReferNo("");
    setShowFileUploaderModal(true)
  }

  const completeRequestYesHandler = () => {
    setIsLoading(true);
    // Add document collection
    if (requestItemId) {
      addFileRecords({
        requestedDocuments: requestedDocuments,
        clientId: clientId,
        isSingle: false,
        requestId: requestItemId,
        requestAt: requestedDateItem
      }).then(res => {
        if (!_.isNil(res)) {
          const message = res.message ? res.message : "Request completed"
          setSubmitSuccessMessage(message)
          setShowSubmitSuccessModal(true)
          refreshRequestedDocuments()
          setShowSubmitModal(false)
        } else {
          setSubmitSuccessMessage(
            "Something is wrong on the uploading process. Please try again."
          )
          setShowSubmitSuccessModal(true)
          setShowSubmitModal(false)
          refreshRequestedDocuments()
        }
      })
    }
    
  }

  // =================================[ Hooks ]==========================

  useEffect(() => {
    if (requestItemId !== undefined && requestItemId !== null) {
      handleSetRequestedDocuments(requestItemId);
    }
  }, [requestItemId])

  const handleSetRequestedDocuments = (requestId: any) => {
    getRequestById(requestId).then(request => {
      if (!_.isEmpty(request)) {
        const { requested_documents } = request
        const requested = requested_documents.map((document: i_RequestedDocument) => {
          // NEW : no linked dcid from settings
          if (!document.dcid)
            return {
              ...document,
              dcid: {
                documentType: document.documentType,
                label: document.documentLabel,
              },
              notes: document.notes,
              files: document.files || [],
            }
          // LEGACY : label and type is fetched from settings
          return {
            ...document,
            notes: document.notes,
            files: document.files || [],
          }
        })
        setRequestedDocuments(requested)
      }
    })
  }
  
  useEffect(() => {
    refreshRequestedDocuments()
  }, [clientId])

  const columns: TableColumn<RequestHistoryProps>[] = useMemo(() => [
    {
      id: "name",
      name: "Name",
      selector: row => row?.name ? row.name.toUpperCase() : '',
      sortable: true,
      grow: 7/2,
      sortFunction: sortByName
    },
    {
      id: "completion",
      name: "Completion",
      selector: row => row?.completion ? row.completion.uploadedFiles : 0,
      cell: (row) => renderCompletionColumn(row),
      style: {
        borderLeft: '1px solid #C4C4C4',
        justifyContent: 'center',
        wordBreak: 'keep-all'
      },
      sortable: true,
      grow: 1,
      sortFunction: sortByCompletion
    },
    {
      id: "division",
      name: "Division",
      selector: row => row?.staff?.division ? row.staff.division.toUpperCase() : '',
      sortable: true,
      grow: 2,
      style: {
        borderLeft: '1px solid #C4C4C4',
        justifyContent: 'center'
      }
    },
    {
      id: "requestedBy",
      name: "Requested By",
      selector: row => (row?.staff?.fname && row.staff.fname !== '' && row?.staff?.lname && row.staff.lname !== '') ? `${row.staff.fname} ${row.staff.lname}` : row?.staff?.email ? row.staff.email.split('@')[0] : '',
      sortable: true,
      grow: 1,
      style: {
        borderLeft: '1px solid #C4C4C4',
        justifyContent: 'center'
      },
      sortFunction: sortByRequester
    },
    {
      id: "requested",
      name: "Requested",
      selector: row => row.createdAt ? moment(row.createdAt).valueOf() : moment().valueOf(),
      cell: row => moment(row.createdAt, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid() ? <>{moment(row.createdAt).format(DATE_FORMAT)}<br/>{moment(row.createdAt).format(TIME_FORMAT)}</> : '',
      sortable: true,
      grow: 1,
      style: {
        borderLeft: '1px solid #C4C4C4',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center'
      },
      sortFunction: sortByRequested
    },
    {
      id: "",
      name: "",
      grow: 3/2,
      sortable: false,
      style: {
        borderLeft: '1px solid #C4C4C4',
        justifyContent: 'center',
        paddingLeft: '10px',
        paddingRight: '10px'
      },
      cell: (row) => <Button variant="contained" className={styles.completeRequestButton} onClick={() => onCompleteRequestClick(row)}>Complete Request</Button>,
    }
  ], []);

  // =================================[ Renders ]==========================

  const renderCompletionColumn = (row: RequestHistoryProps) => {
    let iconCompletion = <ErrorOutline htmlColor="#EB5757"/>;
    let requestedFiles = 0;
    let uploadedFiles = 0;

    if (row?.completion) {
      iconCompletion = row.completion.isComplete ? <CheckSharp htmlColor="#27AE60" /> : <ErrorOutline htmlColor="#EB5757"/>;
      requestedFiles = row.completion.requestedFiles ?? 0;
      uploadedFiles = row.completion.uploadedFiles ?? 0;
    }

    return <>{iconCompletion}&nbsp;&nbsp;{uploadedFiles}/{requestedFiles}&nbsp;Completed</>
  }

  const renderSubmitModal = () => (
    <Modal
      showModal={showSubmitModal}
      onClose={() => setShowSubmitModal(false)}
      paperClassName={styles.modalWrapper}
    >
      <Box className={styles.headerRow}>
        <Box className={styles.headerTitle}>Are you sure you have uploaded all the documents?</Box>
        <IconButton onClick={() => setShowSubmitModal(false)} disabled={isLoading}>
          <Close classes={{ root: styles.closeIcon }} />
        </IconButton>
      </Box>
      {isLoading && <LinearProgress />}

      <Box className={styles.contentRow}>
        {
          <>
            <p className={styles.txtContent}>
              You can view the requested documents by clicking on the<br/> request to expand the list.
            </p>
            <p className={styles.txtContent}>
              Only click ‘yes’ if you are sure you have uploaded all the<br /> requested documents.
            </p>
          </>
        }
      </Box>

      <Box className={styles.footerRow}>
        <Button 
          className={classNames(styles.actionButtons, styles.cancelButton)}
          disabled={isLoading}
          onClick={() => setShowSubmitModal(false)}>
          Not Yet
        </Button>
        <Button 
          className={classNames(styles.actionButtons, styles.yesButton)}
          disabled={isLoading}
          onClick={completeRequestYesHandler}>
          Yes, I’m Sure
        </Button>
      </Box>
    </Modal>
  )

  return (
    <>
      <div className={styles.root}>
        <DataTable
          columns={columns}
          data={requestData}
          customStyles={customStyles}
          progressPending={isLoading}
          progressComponent={<div className={[styles.spinner, "loading-spinner", styles.noFixed].join(" ")}>
              <FadeLoader height={20} width={5} radius={10} margin={10} color="#4e185b"/>
          </div>}
          noDataComponent={<Box className={styles.noDataBox}>No requests found.</Box>}
          defaultSortFieldId="name"
          defaultSortAsc
          sortIcon={<ArrowDropDownSharp />}
          fixedHeader
          persistTableHead
          expandableRows
          expandOnRowClicked
          expandableIcon={{
            collapsed: <KeyboardArrowDown />,
            expanded:  <KeyboardArrowUp />
          }}
          expandableRowsComponent={ClientResponseGroup}
          expandableRowsComponentProps={{ 
            clientId, 
            onFileUploaderModalOpen,
            setDroppedFile,
            refreshRequestedDocuments,
            setRequestItemId
          }}
          expandableRowExpanded={(row: RequestHistoryProps) => row._id === requestItemId}
          pagination
          paginationPerPage={Number(process.env.REACT_APP_ITEM_PER_PAGE) || 5}
          paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          onChangeRowsPerPage={() => setRequestItemId(null)}
          onChangePage={() => setRequestItemId(null)}
          onSort={() => setRequestItemId(null)}
        />
      </div>
      <MultipleRequestUploader
        showModal={showFileUploaderModal}
        defaultFileType={fileType}
        defaultFileLabel={fileLabel}
        defaultTags={tags}
        defaultStatementStart={statementStartDate}
        defaultStatementEnd={statementEndDate}
        defaultReferNo={defReferNo}
        onClose={() => {
          setShowFileUploaderModal(false)
          setFileLabel("")
        }}
        onSubmit={onFileUploaderModalSubmit}
        dcid={modalDocumentId}
        droppedFile={droppedFile}
        uploadedFile={uploadedFile}
        isEdit={modalIsEdit}
        unsetDroppedFile={(toUnset: Boolean) => {
          if (toUnset) setDroppedFile(undefined)
        }}
        submitting={isModalSubmitting}
        otherDocumentsChoices={[]}
        error={modalError}
      />
      {renderSubmitModal()}
      
      <NoticeModal
        showModal={showSubmitSuccessModal}
        onClose={() => setShowSubmitSuccessModal(false)}
        infoText={submitSuccessMessage}
      />
    </>
  )
}

export default ClientResponse
