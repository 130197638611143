type DelayFunctionCallback<T extends any[]> = (
  // eslint-disable-next-line no-unused-vars
  ...ev: T
) => void;

export const delayFunction = <T extends any[]>(
  cb: DelayFunctionCallback<T>,
  milliseconds?: number
): DelayFunctionCallback<T> => {
  // eslint-disable-next-line no-undef
  let timeout: NodeJS.Timeout;

  return (...ev: T) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      cb(...ev);
    }, milliseconds || 1000 / 3);
  };
};
