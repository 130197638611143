exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);", ""]);
// Module
exports.push([module.id, "html, body {\n  margin: 0px !important;\n  padding: 0px !important; }\n\n._2HLuZ6BSg9u3dJmJcLD54M {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  background: rgba(255, 255, 255, 0.8);\n  top: 0;\n  left: 0;\n  z-index: 900;\n  overflow: hidden; }\n\n.CqlLyx3m5QaYmUYRlHm82 {\n  display: flex; }\n\n._3k9uDEeu6TbRO76aAQKQqb {\n  margin: 0 8px !important;\n  min-width: 297px !important; }\n\n._1qCl5sksPLooZxBbPU84Oj {\n  min-width: 95px !important;\n  height: 32px !important;\n  margin: 0 6px !important;\n  border-radius: 20px !important;\n  border: none !important;\n  padding: 0 !important; }\n  ._1qCl5sksPLooZxBbPU84Oj span.MuiToggleButton-label {\n    font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n    font-weight: 400 !important;\n    font-size: 14px !important;\n    line-height: 20px !important;\n    color: #828282;\n    text-transform: none; }\n\n._3X3BXna1rgQCgWaedUI6V2 {\n  background-color: #7F528E !important; }\n  ._3X3BXna1rgQCgWaedUI6V2 span.MuiToggleButton-label {\n    color: #FFF !important; }\n", ""]);
// Exports
exports.locals = {
	"spinner": "_2HLuZ6BSg9u3dJmJcLD54M",
	"filterByStatus": "CqlLyx3m5QaYmUYRlHm82",
	"toggleButtonGroup": "_3k9uDEeu6TbRO76aAQKQqb",
	"toggleButtons": "_1qCl5sksPLooZxBbPU84Oj",
	"toggleButtonSelected": "_3X3BXna1rgQCgWaedUI6V2"
};