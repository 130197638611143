import React from "react"
import Grid from "@material-ui/core/Grid"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import styles from "./NavBar.module.scss"
import { Badge, createStyles, makeStyles, Theme } from "@material-ui/core"
import adminManageBtn from "../../assets/admin-setting-icon.svg";
interface i_NavBar {
  currentPage: String
  setCurrentPage: Function
  pages: Array<any>
  openEditTemplateModal?: Function
  isAdmin: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badges: {
      color: '#FFF',
      right: '25px',
      top: '20px',
      fontSize: '12px',
      lineHeight: '17px',
      fontWeight: 700,
      backgroundColor: '#66E84B',
      zIndex: 0
    },
    insideIcon: {
      color: (props: any) => props.color,
    },
  })
);

const NavBar = ({ currentPage, setCurrentPage, pages, openEditTemplateModal, isAdmin }: i_NavBar) => {
  const classes = useStyles();
  const acceptedRolesForAdminManageButton = ["super_admin"]

  return (
    <div className={styles.navbarContainer}>
      <div className={styles.root1}>
        <Grid container>
          <Grid item xs={9} sm={9} md={11} lg={11}>
            <h3 className={styles.title}>&nbsp;&nbsp;&nbsp; My Documents</h3>
          </Grid>
        </Grid>
      </div>
      <div className={styles.root2}>
        <Grid container>
          <Grid item xs={11}>
            <ul>
              {pages.map(page =>
                page.show ? (
                  <Badge classes={{ badge: classes.badges }} badgeContent={page.name === 'ClientResponse' && page.bagdes > 0 ? page.bagdes : 0} >
                    <li
                      className={currentPage === page.name ? styles.active : ""}
                      onClick={() => setCurrentPage(page.name)}
                    >
                      {page.label}
                    </li>
                  </Badge>
                ) : null
              )}
            </ul>
          </Grid>
          {isAdmin &&  acceptedRolesForAdminManageButton.includes(isAdmin["input"]) && (
            < Grid item xs={1}>
              <span className={styles.adminManageBtn} onClick={() => openEditTemplateModal && openEditTemplateModal(true)}>
                <img  src={adminManageBtn} alt="Adminmanage" />
              </span>
            </Grid>
          )
          }

        </Grid>
      </div>
    </div >
  )
}

export default NavBar
