exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1mVZzIkU3LSqF128TX_DVR {\n  opacity: 1 !important;\n  border-bottom: 0 !important;\n  border-bottom-color: green !important; }\n\n.qzceOPf-EhmCmGPZsS49- {\n  width: 85% !important;\n  color: black !important;\n  font-size: 20px !important; }\n  .qzceOPf-EhmCmGPZsS49-:before {\n    border-bottom: 0 !important; }\n  .qzceOPf-EhmCmGPZsS49-:focus {\n    border-color: green !important; }\n  .qzceOPf-EhmCmGPZsS49-:hover {\n    border-color: green !important; }\n\n._378SkH0ODWqh-bHldhp_yI {\n  color: grey !important;\n  border-bottom: 0 !important;\n  font-size: 20px !important; }\n  ._378SkH0ODWqh-bHldhp_yI:before {\n    border-bottom: 0 !important; }\n\n._2oAdxlYbUezXd465RfjBCA {\n  color: green !important;\n  font-size: 35px !important; }\n\n._2mqvgLliUyniv2ZqVw7jos {\n  margin-right: 5px !important; }\n", ""]);
// Exports
exports.locals = {
	"field": "_1mVZzIkU3LSqF128TX_DVR",
	"textField": "qzceOPf-EhmCmGPZsS49-",
	"disabled": "_378SkH0ODWqh-bHldhp_yI",
	"editIcon": "_2oAdxlYbUezXd465RfjBCA",
	"edit": "_2mqvgLliUyniv2ZqVw7jos"
};