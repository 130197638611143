import { Box, Button, IconButton, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';
import { Close, AddCircleOutline } from '@material-ui/icons';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import classNames from 'classnames'
import _, { result } from "lodash"
import React, { useEffect, useState } from 'react'
import { createNewEmpltyTemplate, getAllTemplates } from '../../lib/services';
import Modal from '../Modal';
import styles from "./AddEditTemplateModal.module.scss";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

type AddEditTemplateModalProps = {
	showModal: boolean
	onClose: () => void,
	setShowAdminTemlate: Function,
	setCurrentPage: Function,
	setTemplate: Function,
	isAdmin: any,
	openConfirmDeleteModal: Function,
	triggerTemplate: any,
	showEditTemplateModal: boolean
	setMode: Function
	setShowNoticeModal:Function
	setNoticeTextModal:Function
}
const EditTemplateModal: React.FC<AddEditTemplateModalProps> = props => {

	const { showModal, onClose, setShowAdminTemlate, setCurrentPage, setTemplate, isAdmin, openConfirmDeleteModal, showEditTemplateModal, setMode ,setShowNoticeModal,setNoticeTextModal} = props
	const [selectedTemplate, setSelectedTemplate] = useState<any>("");
	const [templates, setTemplates] = useState<any>([])
	const [ListOpen, setlistOpen] = useState(false)
	const onCloseModal = () => {
		if (onClose) onClose();
	}
	const getArrowIcon = () => {
		if (ListOpen) {
			return <ExpandLessIcon className={styles.dropDownIcon} />
		} else {
			return <ExpandMoreIcon className={styles.dropDownIcon} />
		}
	}
	const resetModal = () => {
		setSelectedTemplate("")
	}
	const handleChangeTemplate = (
		e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
		_child: React.ReactNode
	): void => {
		setSelectedTemplate(e.target.value as string);
	}
	const deleteTemplate = (template: any) => {
		openConfirmDeleteModal();
		setTemplate(template)
		setMode("delete template")
		resetModal();

	}
	const addNewTemplate = async () => {
		createNewEmpltyTemplate().then(response => {
			if(response.status === "success"){
			setTemplate(response?.data.template);
			resetModal();
			setCurrentPage({ page: "AdminTemplate" })
			setShowAdminTemlate(true);
			onCloseModal();
			}else{
				console.log(`ERROR: ${response.message}`)
				setShowNoticeModal(true)
				setNoticeTextModal(response.message)
			}
		})
	}
	const handleSubmit = () => {
		if (selectedTemplate) {
             
			setShowAdminTemlate(true);
			setCurrentPage({ page: "AdminTemplate" })
			setTemplate(selectedTemplate)
			onCloseModal();
		}
	}
	const getTemplateHandler = () => {
		if (showEditTemplateModal) {
			getAllTemplates().then(result => {
				setTemplates(result.data.templates)
				setSelectedTemplate("")
			})
		}
	}

	useEffect(() => {
		getTemplateHandler();
	}, [props.triggerTemplate, showEditTemplateModal])
	interface valueObject {
		name: string
	}

	return (
		<Modal showModal={showModal} paperClassName={styles.modalPapertext} onClose={(_, reason) => {
			if (reason === "backdropClick") {
				onCloseModal();
				resetModal();
			}
		}}>
			<Box className={styles.headerRow}>
				<Box className={styles.headerTitle}>Edit template</Box>
				<IconButton onClick={onCloseModal}>
					<Close classes={{ root: styles.closeIcon }} />
				</IconButton>
			</Box>
			<Box className={styles.contentRow}>
				<Box className={styles.inputGroup}>
					<InputLabel className={styles.inputGroup1}>Select from template</InputLabel>
					<Select
						displayEmpty
						value={selectedTemplate}
						onChange={handleChangeTemplate}
						className={classNames(styles.inputControls, styles.selectTemplate)}
						classes={{
							root: styles.selectMenu,
							icon: styles.selectMenuIcon
						}}
						MenuProps={{
							anchorOrigin: {
								vertical: "bottom",
								horizontal: "left"
							},
							transformOrigin: {
								vertical: "top",
								horizontal: "left"
							},
							getContentAnchorEl: null,
							classes: { paper: styles.selectMenuList }
						}}
						onOpen={() => setlistOpen(true)}
						onClose={() => setlistOpen(false)}
						IconComponent={() => getArrowIcon()}
						renderValue={selectedTemplate !== "" ? (value: any) => {
							return <>{value?.name}</>
						} : () => "Select Template"}
					>
						{templates.map((template: any) => <MenuItem key={`template-${template._id}`} value={template} className={styles.inputGroupListItem}>
							<Grid container>
								<Grid item xs={11}   style={{ display: "flex", alignItems: "center", color: "gray" }}>
									 <Box className={styles.textMaxLength} > {template.name}</Box> 
								</Grid>
								{isAdmin && isAdmin['input'] === "super_admin" && (
									<Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
										<div className={classNames(styles.staffButtonGroup, styles.addStaffGroup)}>
											<IconButton onClick={() => deleteTemplate(template)} classes={{ root: styles.iconButtons }} >
												<RemoveCircleOutlineIcon classes={{ root: styles.staffIcon }} />
											</IconButton>
										</div>
									</Grid>
								)}
							</Grid>

						</MenuItem>)}
						{isAdmin && isAdmin['input'] === "super_admin" && (

							<MenuItem value="" className={styles.inputGroupListItem}>
								<Grid container onClick={addNewTemplate} >
									<Grid item xs={11} style={{ display: "flex", alignItems: "center", color: "gray" }}>
										Add new Template
									</Grid>
									<Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
										<div className={classNames(styles.staffButtonGroup, styles.addStaffGroup)}>
											<IconButton classes={{ root: styles.iconButtons }} >
												<AddCircleOutline classes={{ root: styles.staffIcon }} htmlColor="#541868" />
											</IconButton>
										</div>
									</Grid>
								</Grid>
							</MenuItem>
						)}

					</Select>
				</Box>
			</Box>
			<Box className={styles.footerRow}>
				<Button
					className={classNames(styles.actionButtons, styles.cancelButton)}
					onClick={onCloseModal}>
					Cancel
				</Button>
				<Button
					className={classNames(styles.actionButtons, styles.submitButton)}
					onClick={handleSubmit}
					disabled={!selectedTemplate}
				>
					Submit
				</Button>
			</Box>
		</Modal>
	)
}

export default EditTemplateModal