import React from "react"
import { formatCurrency } from "../../../../../../lib/utils"
import styles from "../ClientChangesModal.scss"

type i_headerProps = {
  prevMonthly: number
  currMonthly: number
  description: string
}

const FlexExpenseChangesHeader = ({
  prevMonthly,
  currMonthly,
  description,
}: i_headerProps) => {
  return (
    <>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <strong>MBAC Total</strong>
        </td>
        <td>
          <strong>Portal Total</strong>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>
          <strong>{description}</strong>
        </td>
        <td>
          <div className={styles.filler}></div>
        </td>
        <td>
          <p className={styles.updateBtn}>Update</p>
        </td>
        <td>{formatCurrency(prevMonthly)}</td>
        <td>{formatCurrency(currMonthly)}</td>
        <td></td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <strong>Expense</strong>
        </td>
        <td>
          <strong>Expense</strong>
        </td>
      </tr>
    </>
  )
}

export default FlexExpenseChangesHeader
