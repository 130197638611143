import { AppBar, Box, Button, IconButton, List, ListItem, Toolbar, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FadeLoader } from "react-spinners";
import AddDocumentItemsModal from "../../../components/AddDocumentItemsModal";
import InlineEdit from "../../../components/InlineEdit";
import MessageModal from "../../../components/NoticeModal";
import RequestItem from "../../../components/RequestItem/RequestItem";
import { adminTemplateActionMessages, adminTemplateConstants, ModalModes } from "../../../lib/constants";
import { fetchGeneralDocumentList, getTemplateById, groupDocumentsByDocumentType, groupRequestedDocumentsByDocumentType, i_Document, i_DocumentType } from "../../../lib/services";
import { i_AdminTemplate, TemplateProps } from "./AdminTemplate.interface";
import styles from "./AdminTemplate.module.scss";
const LockIcon = (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.8125 9.625C4.63016 9.625 4.4553 9.69743 4.32636 9.82636C4.19743 9.9553 4.125 10.1302 4.125 10.3125V18.5625C4.125 18.7448 4.19743 18.9197 4.32636 19.0486C4.4553 19.1776 4.63016 19.25 4.8125 19.25H17.1875C17.3698 19.25 17.5447 19.1776 17.6736 19.0486C17.8026 18.9197 17.875 18.7448 17.875 18.5625V10.3125C17.875 10.1302 17.8026 9.9553 17.6736 9.82636C17.5447 9.69743 17.3698 9.625 17.1875 9.625H4.8125ZM4.8125 8.25H17.1875C17.7345 8.25 18.2591 8.4673 18.6459 8.85409C19.0327 9.24089 19.25 9.76549 19.25 10.3125V18.5625C19.25 19.1095 19.0327 19.6341 18.6459 20.0209C18.2591 20.4077 17.7345 20.625 17.1875 20.625H4.8125C4.26549 20.625 3.74089 20.4077 3.35409 20.0209C2.9673 19.6341 2.75 19.1095 2.75 18.5625V10.3125C2.75 9.76549 2.9673 9.24089 3.35409 8.85409C3.74089 8.4673 4.26549 8.25 4.8125 8.25Z" fill="black" />
        <path d="M11 11.6875C11.1823 11.6875 11.3572 11.7599 11.4861 11.8889C11.6151 12.0178 11.6875 12.1927 11.6875 12.375V16.5C11.6875 16.6823 11.6151 16.8572 11.4861 16.9861C11.3572 17.1151 11.1823 17.1875 11 17.1875C10.8177 17.1875 10.6428 17.1151 10.5139 16.9861C10.3849 16.8572 10.3125 16.6823 10.3125 16.5V12.375C10.3125 12.1927 10.3849 12.0178 10.5139 11.8889C10.6428 11.7599 10.8177 11.6875 11 11.6875ZM15.125 8.25V6.875C15.125 5.78098 14.6904 4.73177 13.9168 3.95818C13.1432 3.1846 12.094 2.75 11 2.75C9.90598 2.75 8.85677 3.1846 8.08318 3.95818C7.3096 4.73177 6.875 5.78098 6.875 6.875V8.25H15.125ZM11 1.375C12.4587 1.375 13.8576 1.95446 14.8891 2.98591C15.9205 4.01736 16.5 5.41631 16.5 6.875V9.625H5.5V6.875C5.5 5.41631 6.07946 4.01736 7.11091 2.98591C8.14236 1.95446 9.54131 1.375 11 1.375Z" fill="black" />
    </svg>
)

const plusIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24">
        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z" />
    </svg>
)

const AdminTemplate: React.FC<i_AdminTemplate> = ({ 
    template, 
    isAdmin,
    onSubmit, 
    setTemplateNameError, 
    setDeleteDocumentItem, 
    clientId, 
    openConfirmDeleteModal, 
    setDeleteDocumentType, 
    setMode, 
    deleteDocumentType, 
    mode, 
    isReset, 
    setIsReset,
    setIsTemplateHaveChanges
}) => {
    // =================================[ State ]====================================
    const [documents, setDocuments] = useState<i_Document[]>([]);
    const [loadingTemplateDocs, setLoadingTemplateDocs] = useState<boolean>(false);
    const [requestedDocuments, setRequestedDocuments] = useState<i_Document[]>([]);
    const [fetchedDocuments, setFetchedDocuments] = useState<i_Document[]>([])
    const [isModalRequested, setIsModalRequested] = useState<boolean>(false)
    const [templateName, setTemplateName] = useState<string>('');
    const [showMessageModal, setShowMessageModal] = useState<boolean>(false)
    const [showDocumentItemsModal, setShowDocumentItemsModal] = useState<boolean>(false)
    const [txtMessage, setTxtMessage] = useState<string>('')
    const [requestedDocumentsList, setRequestedDocumentsList] = useState<i_DocumentType>({})
    const [document, setDocument] = useState({})
    const [isAddRequestDocument, setIsAddRequestDocument] = useState(false)
    const [documentId, setDocumentId] = useState("")
    const [editFromRightSide, setEditFromRightSide] = useState(false)
    const [indexForUpdatedDocument, setIndexForUpdatedDocument] = useState(0)
    const [showAddCategory,setShowAddCategory] = useState(false)
    // =================================[ Functions ]====================================
    const init = async () => {
        setLoadingTemplateDocs(true)
        const docs = await fetchGeneralDocumentList();
        setDocuments(docs.filter((doc: i_Document) => !doc.uid && doc.status === "active"))
        if (template) {
            handlePopulateRequestedDocs(template._id);
        } else {
            setRequestedDocuments([]);
        }
        setLoadingTemplateDocs(false)
    }

    const addDocuments = async () => {
        setLoadingTemplateDocs(true)
        const docs = await fetchGeneralDocumentList();
        setDocuments(docs.filter((doc: i_Document) => !doc.uid && doc.status === "active"))
        setLoadingTemplateDocs(false)
    }

    const documentsList = groupDocumentsByDocumentType(
        documents.filter((d: any) => !d.uid).filter((d: any) => d._id)
    )

    const addDocToList = (document: i_Document) => {
        if (mode === ModalModes.EDIT && !editFromRightSide) {
            const newRequestedDocuments: i_Document[] = requestedDocuments.map((doc) => doc._id === document._id ? document : doc);
            setRequestedDocuments(newRequestedDocuments)
        } else if (mode === ModalModes.EDIT && editFromRightSide) {
            const item = _.cloneDeep(requestedDocuments.find(d => d.key === indexForUpdatedDocument))
            if (!item) return
            item.documentType = document.documentType
            item.isDuplicate = document.isDuplicate
            item.isRestrict = document.isRestrict
            item.key = document.key
            item.label = document.label
            item.roles = document.roles
            item.uid = document.uid 
            item.notes = document.notes 
            const itemIndex = requestedDocuments.findIndex(d => d.key === indexForUpdatedDocument)
            const newRequestedDocuments = [...requestedDocuments];
            newRequestedDocuments[itemIndex] = item;
            setRequestedDocuments([...newRequestedDocuments])
        } else {
            setRequestedDocuments([...requestedDocuments, document])
        }
        setEditFromRightSide(false)
    }

    const addToList = (e: React.MouseEvent, document: i_Document) => {
        addDocToList({ ...document, notes: "" })
    }

    const removeFromList = (_: React.MouseEvent, document: i_Document) => {
        const reqDocumentsList = requestedDocuments.filter(d => d.key !== document.key);
        setRequestedDocuments(reqDocumentsList)
    }

    const deleteDocumentFromList = (docs: any) => {
        openConfirmDeleteModal()
        setMode("delete documentitem")
        setDeleteDocumentItem(docs)
        setDocumentId(docs._id)
    }

    const showAccessUserRoles = (_: React.MouseEvent, document: i_Document) => {
        setShowDocumentItemsModal(true)
        setMode("show access roles")
        setDocument(document)
    }

    const onNotesChange = (key: number) => (value: string) => {
        const item = _.cloneDeep(requestedDocuments.find(d => d.key === key))
        if (!item) return
        item.notes = value
        const itemIndex = requestedDocuments.findIndex(d => d.key === key)
        const newRequestedDocuments = [
            ...requestedDocuments.slice(0, itemIndex),
            item,
            ...requestedDocuments.slice(itemIndex + 1),
        ]
        setRequestedDocuments(newRequestedDocuments)
    }

    const handleSaveTemplate = () => {
        if (templateName.trim().toUpperCase() === adminTemplateConstants.DEFAULT_NAME.toUpperCase()) {
            setTxtMessage(adminTemplateActionMessages.TEMPLATE_NAME_WARNING)
            setShowMessageModal(true);
            setTemplateNameError && setTemplateNameError(true)
        } else {
            if (!template) return;
            setTemplateNameError && setTemplateNameError(false);
            setIsTemplateHaveChanges && setIsTemplateHaveChanges(false);
            onSubmit(template._id, templateName, requestedDocuments)
        }
    }

    const handleDiscardTemplate = () => {
        init();
        setIsTemplateHaveChanges && setIsTemplateHaveChanges(false);
    }

    const onModalOpen = (isRequested: boolean) => {
        setShowAddCategory(!isRequested)
        setIsModalRequested(isRequested)
        setShowDocumentItemsModal(true)
    }

    const onEditDocumentItem = (document: i_Document, editType: boolean, index: number) => {
        setShowAddCategory(!editType)
        setEditFromRightSide(editType)
        setIndexForUpdatedDocument(index)
        setShowDocumentItemsModal(true);
        setMode(ModalModes.EDIT);
        setDocument(document);
        setDocumentId(document._id)
    }

    const handlePopulateRequestedDocs = (templateId: string) => {
        getTemplateById(templateId).then((tmp: TemplateProps) => {
            if (!_.isEmpty(tmp)) {
                setTemplateName(tmp.name);
                const saved = tmp.requested_documents.map((document: i_Document) => {
                    // NEW : no linked dcid from settings
                    if (!document.dcid) {
                        return {
                            _id: document._id,
                            documentType: document.documentType,
                            label: document.documentLabel,
                            isRestrict: document.isRestrict,
                            roles: document.roles && [...document.roles],
                            notes: document.notes
                        }
                    } else {
                        // LEGACY : label and type is fetched from settings
                        return {
                            _id: document._id,
                            dcid: { ...document.dcid },
                            ...document.dcid,
                            documentType: document.documentType || document.dcid.documentType,
                            label: document.documentLabel || document.dcid.label,
                            isRestrict: document.isRestrict !== undefined ? document.isRestrict : document.dcid.isRestrict,
                            roles: document.roles ? [...document.roles] : document.dcid.roles && [...document.dcid.roles],
                            notes: document.notes,
                        }
                    }
                });

                setFetchedDocuments(saved);
                let newRequestDocuments: i_Document[] = []
                if (documentId) {
                    if (mode !== "delete documentitem") {
                        //     newRequestDocuments = requestedDocuments.filter((doc) => doc._id !== documentId)
                        //     setRequestedDocuments([...newRequestDocuments]);
                        // } else {
                        newRequestDocuments = requestedDocuments.filter((doc) => doc._id !== documentId)
                        setRequestedDocuments([...newRequestDocuments]);
                    }
                    setDocumentId("")
                } else if (mode === "delete document type") {
                    setRequestedDocuments(requestedDocuments.filter(doc => doc.documentType !== deleteDocumentType));
                } else {
                    setRequestedDocuments(saved);
                }
            }
        });
    }

    const isSame = (fetchedDocs: i_Document[], currentDocs: i_Document[]) => {
        return (_.isEqual(fetchedDocs, currentDocs)) ? true : false;
    }

    // =================================[ Renders ]====================================
    const renderDocumentType = (isRequested: boolean) => (
        documentsOnDocumentType: i_Document[],
        documentType: string
    ) => {
        return (
            <React.Fragment>
                <ListItem
                    key={`list-item-documentType-${documentType}`}
                    component="a"
                    className={styles.listDocumentType}
                >
                    <Box className={styles.textMaxLength}>{documentType}</Box>
                </ListItem>
                {documentsOnDocumentType.map((document, i) => {
                    return document.label && <ListItem key={`list-item-${i}`}>
                        <div className={styles.onDocumentTypeGroup}>
                            {document.isRestrict &&
                                <IconButton
                                    classes={{ root: styles.iconButtons }}
                                    onClick={e => showAccessUserRoles(e, document)}
                                >
                                    {LockIcon}
                                </IconButton>
                            }
                            {isRequested ? <span onClick={() => onEditDocumentItem(document, true, document.key)}>{document.label}</span> : <span onClick={() => onEditDocumentItem(document, false, document.key)}>{document.label}</span>}
                        </div>
                        <div>
                            {isRequested && (
                                <RequestItem
                                    onEdit={onNotesChange(document.key)}
                                    notes={document.notes || null}
                                    isDuplicate={document.isDuplicate}
                                />
                            )}
                            {isRequested ? (
                                <IconButton
                                    key={`document-${document._id}`}
                                    onClick={e => removeFromList(e, document)}
                                    classes={{ root: styles.iconButtons }}
                                >
                                    <RemoveCircleOutlineOutlinedIcon
                                        style={{ color: '#4e185b', fontSize: 30 }}
                                    />
                                </IconButton>
                            ) : (<>
                                <IconButton
                                    key={`document-${i}`}
                                    onClick={e => addToList(e, document)}
                                    classes={{ root: styles.iconButtons }}
                                >
                                    <AddCircleOutlineIcon
                                        style={{ color: '#4e185b', fontSize: 30 }}
                                    />
                                </IconButton>

                                <IconButton
                                    key={`document-${i}`}
                                    classes={{ root: styles.iconButtons }}
                                    onClick={() => deleteDocumentFromList(document)}
                                >
                                    <RemoveCircleOutlineOutlinedIcon
                                        style={{ color: '#4e185b', fontSize: 30 }}
                                    />
                                </IconButton>
                            </>

                            )}
                        </div>
                    </ListItem>
                })}
            </React.Fragment>
        )
    }

    // =================================[ Hooks ]====================================
    useEffect(() => {
        init();
    }, [template]);

    useEffect(() => {
        if (isReset) init();
        if (setIsReset) setIsReset(false)
    }, [isReset]);

    useEffect(() => {
        if (isAddRequestDocument) addDocuments();
        setIsAddRequestDocument(false)
    }, [isAddRequestDocument]);

    useEffect(() => {
        setRequestedDocumentsList(groupRequestedDocumentsByDocumentType(requestedDocuments));
    }, [requestedDocuments]);

    useEffect(() => {
        if (templateName.trim().toUpperCase() === adminTemplateConstants.DEFAULT_NAME.toUpperCase()) {
            setTemplateNameError && setTemplateNameError(true)
        } else {
            setTemplateNameError && setTemplateNameError(false);
        }
    }, [templateName]);

    useEffect(() => {
        if (setIsTemplateHaveChanges) {
            if (_.isEqual(fetchedDocuments, requestedDocuments)) {
                setIsTemplateHaveChanges(false)
            } else {
                setIsTemplateHaveChanges(true)
            }
        }
    }, [fetchedDocuments, requestedDocuments])

    return <>
        <div className={styles.root}>
            <div className={styles.headerWrapper}>
                <InlineEdit value={templateName} setValue={setTemplateName} />
                <Button
                    variant="contained"
                    className={styles.sendButton}
                    classes={{ disabled: styles.disabledButton }}
                    onClick={handleSaveTemplate}
                >
                    Save Template
                </Button>
            </div>
            <div className={styles.documentsList}>
                <div style={{ flex: "2" }}>
                    <AppBar position="static" className={styles.appbar}>
                        <Toolbar className={styles.toolbar}>
                            <Typography variant="h4">Documents List</Typography>
                            {
                                isAdmin && isAdmin['input'] === "super_admin" &&
                                <a
                                    href="#"
                                    className={styles.addButton}
                                    onClick={() => onModalOpen(false)}>
                                    {plusIcon}
                                </a>
                            }
                        </Toolbar>
                    </AppBar>
                    <List className={styles.listWrapper}>
                        {Object.keys(documentsList).length > 0 ? (
                            Object.keys(documentsList)
                                .sort()
                                .map(key =>
                                    renderDocumentType(false)(documentsList[key], key)
                                )
                        ) : (
                            <li>No data yet.</li>
                        )}
                    </List>
                </div>
                <div style={{ flex: "3" }}>
                    <AppBar position="static" className={styles.appbar}>
                        <Toolbar className={styles.toolbar}>
                            <Typography variant="h4">Requested Documents</Typography>
                            <a
                                href="#"
                                className={styles.addButton}
                                onClick={() => onModalOpen(true)}>
                                {plusIcon}
                            </a>
                        </Toolbar>
                    </AppBar>
                    <List className={styles.listWrapper}>
                        {
                            loadingTemplateDocs ? <div className={[styles.spinner, "loading-spinner", styles.noFixed].join(" ")}>
                                <FadeLoader height={15} width={5} radius={10} margin={10} color="#4e185b" />
                            </div> :
                                <>
                                    {Object.keys(requestedDocumentsList).length > 0
                                        ? Object.keys(requestedDocumentsList)
                                            .sort()
                                            .map(key => renderDocumentType(true)(requestedDocumentsList[key], key))
                                        : <li>No data yet.</li>}
                                </>
                        }
                    </List>

                    {!isSame(fetchedDocuments, requestedDocuments) && (
                        <div className={styles.bottomButton}>
                            <Button
                                variant="contained"
                                className={styles.saveDiscardButton}
                                onClick={handleSaveTemplate}
                            >
                                Save
                            </Button>

                            <Button
                                variant="contained"
                                className={styles.saveDiscardButton}
                                onClick={handleDiscardTemplate}
                            >
                                Discard
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
        <MessageModal
            showModal={showMessageModal}
            onClose={() => setShowMessageModal(false)}
            infoText={txtMessage}
        />
        <AddDocumentItemsModal
            showModal={showDocumentItemsModal}
            onClose={() => setShowDocumentItemsModal(false)}
            clientId={clientId}
            openConfirmDeleteModal={openConfirmDeleteModal}
            setDeleteDocumentType={setDeleteDocumentType}
            setMode={setMode}
            triggerDeleteDocumentType={deleteDocumentType}
            refreshPage={init}
            mode={mode}
            document={document}
            addDocToListMethod={addDocToList}
            setIsAddRequestDocument={setIsAddRequestDocument}
            isModalRequested={isModalRequested}
            setShowMessageModal={setShowMessageModal}
            setTxtMessage={setTxtMessage}
            editFromRightSide={editFromRightSide}
            showAddCategory={showAddCategory}
        />
    </>
}

export default AdminTemplate
