import {
  Box,
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  withStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { reminderEmailStatusesGroup } from "../../lib/constants";
import {
  bounced1,
  bounced2,
  bounced3,
  bounced4,
  emailSent1,
  emailSent2,
  emailSent3,
  emailSent4,
  opened1,
  opened2,
  opened3,
  opened4,
  received1,
  received2,
  received3,
  received4,
} from "../../scenes/DocumentCollection/RequestHistory/emailTrackingIcons";
import {
  HistoryDataProps,
  TrackingHistoryProps,
} from "../../scenes/DocumentCollection/RequestHistory/RequestHistory.interfaces";
import Modal from "../Modal";
import styles from "./TrackingHistoryModal.module.scss";

const { SEND_GROUP, RECEIVE_GROUP, BOUNCE_GROUP, OPEN_GROUP, COMPLETE_GROUP } =
  reminderEmailStatusesGroup;

interface TrackingHistoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  historyData?: HistoryDataProps;
}

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      color: theme.palette.common.black,
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "25px",
      backgroundColor: theme.palette.common.white
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const TrackingHistoryModal: React.FC<TrackingHistoryModalProps> = ({
  isOpen,
  onClose,
  historyData,
  isLoading,
}) => {
  const [generalData, setGeneralData] = useState<any>();
  const [trackingData, setTrackingData] = useState<any[]>([]);

  useEffect(() => {
    if (historyData?.generalInformation) {
      setGeneralData(historyData.generalInformation);
    }

    if (
      historyData?.trackingHistory &&
      historyData.trackingHistory.length > 0
    ) {
      const { trackingHistory } = historyData;

      setTrackingData(
        trackingHistory.sort(
          (a: TrackingHistoryProps, b: TrackingHistoryProps) => {
            const valueA = a?.orderBy ? a.orderBy : 0;
            const valueB = b?.orderBy ? b.orderBy : 0;
            return valueA - valueB;
          }
        )
      );
    }
  }, [historyData]);

  const renderStatus = (status: string) => {
    if (SEND_GROUP.Statuses.includes(status.toLocaleLowerCase())) {
      return <span style={{ color: SEND_GROUP.Color }}>{SEND_GROUP.Name}</span>;
    } else if (RECEIVE_GROUP.Statuses.includes(status.toLocaleLowerCase())) {
      return (
        <span style={{ color: RECEIVE_GROUP.Color }}>{RECEIVE_GROUP.Name}</span>
      );
    } else if (OPEN_GROUP.Statuses.includes(status.toLocaleLowerCase())) {
      return <span style={{ color: OPEN_GROUP.Color }}>{OPEN_GROUP.Name}</span>;
    } else if (BOUNCE_GROUP.Statuses.includes(status.toLocaleLowerCase())) {
      return (
        <span style={{ color: BOUNCE_GROUP.Color }}>{BOUNCE_GROUP.Name}</span>
      );
    } else if (COMPLETE_GROUP.Statuses.includes(status.toLocaleLowerCase())) {
      return (
        <span style={{ color: COMPLETE_GROUP.Color }}>
          {COMPLETE_GROUP.Name}
        </span>
      );
    }
  };

  const renderEmailIcons = (status: string, sequence?: number) => {
    let iconType;

    switch (sequence) {
      case 0:
        if (SEND_GROUP.Statuses.includes(status.toLocaleLowerCase())) {
          iconType = emailSent1;
        } else if (
          RECEIVE_GROUP.Statuses.includes(status.toLocaleLowerCase())
        ) {
          iconType = received1;
        } else if (
          OPEN_GROUP.Statuses.includes(status.toLocaleLowerCase()) ||
          COMPLETE_GROUP.Statuses.includes(status.toLocaleLowerCase())
        ) {
          iconType = opened1;
        } else if (BOUNCE_GROUP.Statuses.includes(status.toLocaleLowerCase())) {
          iconType = bounced1;
        } else {
          iconType = undefined;
        }
        break;
      case 1:
        if (SEND_GROUP.Statuses.includes(status.toLocaleLowerCase())) {
          iconType = emailSent2;
        } else if (
          RECEIVE_GROUP.Statuses.includes(status.toLocaleLowerCase())
        ) {
          iconType = received2;
        } else if (
          OPEN_GROUP.Statuses.includes(status.toLocaleLowerCase()) ||
          COMPLETE_GROUP.Statuses.includes(status.toLocaleLowerCase())
        ) {
          iconType = opened2;
        } else if (BOUNCE_GROUP.Statuses.includes(status.toLocaleLowerCase())) {
          iconType = bounced2;
        } else {
          iconType = undefined;
        }
        break;
      case 2:
        if (SEND_GROUP.Statuses.includes(status.toLocaleLowerCase())) {
          iconType = emailSent3;
        } else if (
          RECEIVE_GROUP.Statuses.includes(status.toLocaleLowerCase())
        ) {
          iconType = received3;
        } else if (
          OPEN_GROUP.Statuses.includes(status.toLocaleLowerCase()) ||
          COMPLETE_GROUP.Statuses.includes(status.toLocaleLowerCase())
        ) {
          iconType = opened3;
        } else if (BOUNCE_GROUP.Statuses.includes(status.toLocaleLowerCase())) {
          iconType = bounced3;
        } else {
          iconType = undefined;
        }
        break;
      case 3:
        if (SEND_GROUP.Statuses.includes(status.toLocaleLowerCase())) {
          iconType = emailSent4;
        } else if (
          RECEIVE_GROUP.Statuses.includes(status.toLocaleLowerCase())
        ) {
          iconType = received4;
        } else if (
          OPEN_GROUP.Statuses.includes(status.toLocaleLowerCase()) ||
          COMPLETE_GROUP.Statuses.includes(status.toLocaleLowerCase())
        ) {
          iconType = opened4;
        } else if (BOUNCE_GROUP.Statuses.includes(status.toLocaleLowerCase())) {
          iconType = bounced4;
        } else {
          iconType = undefined;
        }
        break;
      default:
        iconType = undefined;
        break;
    }

    return (
      <div className={styles.statusIcons}>
        {iconType && (
          <img
            src={iconType}
            alt="Person1 status"
            width={"22px"}
            height={"22px"}
          />
        )}
      </div>
    );
  };

  const handleClickView = async (event: any, emailHtml?: string) => {
    event.preventDefault();
    if (emailHtml) {
      const newWindow = window.open();
      newWindow?.document.write(emailHtml);
    }
  };

  return (
    <React.Fragment>
      <Modal showModal={isOpen} onClose={onClose} paperClassName={styles.root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box className={styles.headerRow}>
              <Box className={styles.headerTitle}>Email Sequence History</Box>
              <IconButton onClick={onClose}>
                <Close classes={{ root: styles.closeIcon }} />
              </IconButton>
            </Box>
          </Grid>
          {isLoading ? (
            <Grid item xs={12} className={styles.progressWrapper}>
              <CircularProgress className={styles.circularProgress} />
            </Grid>
          ) : (
            <React.Fragment>
              {generalData && (
                <React.Fragment>
                  <Grid item xs={4}>
                    <Box className={styles.paper}>
                      <Box flex={1}>
                        <span className={styles.txtSpan}>Client</span>
                      </Box>
                      <Box flex={3}>
                        <span>{generalData.clientInfo}</span>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={4}>
                    <Box className={styles.paper}>
                      <Box flex={1}>
                        <span className={styles.txtSpan}>Requested Date</span>
                      </Box>
                      <Box flex={2}>
                        <span>{generalData.requestedDate}</span>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={4}>
                    <Box className={styles.paper}>
                      <Box flex={1}>
                        <span className={styles.txtSpan}>
                          Request Completed
                        </span>
                      </Box>
                      <Box flex={2}>
                        <span>{generalData.completedDate}</span>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={4}>
                    <Box className={styles.paper}>
                      <Box flex={1}>
                        <span className={styles.txtSpan}>Request Name</span>
                      </Box>
                      <Box flex={3}>
                        <span>{generalData.requestName}</span>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={4}>
                    <Box className={styles.paper}>
                      <Box flex={1}>
                        <span className={styles.txtSpan}>Requested By</span>
                      </Box>
                      <Box flex={2}>
                        <span>{generalData.requestedBy}</span>
                      </Box>
                    </Box>
                  </Grid>
                </React.Fragment>
              )}

              <TableContainer className={styles.tableContainer}>
                <Table stickyHeader aria-label="Tracking history table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Sequence</StyledTableCell>
                      <StyledTableCell align="center">Email</StyledTableCell>
                      <StyledTableCell>Subject</StyledTableCell>
                      <StyledTableCell>Sender</StyledTableCell>
                      <StyledTableCell>Recipient(s)</StyledTableCell>
                      <StyledTableCell align="center">
                        Date Sent
                      </StyledTableCell>
                      <StyledTableCell align="center">Status</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {trackingData.length > 0 ? (
                      trackingData.map((tracking: TrackingHistoryProps) => {
                        return (
                          <StyledTableRow key={tracking._id}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {tracking?.status
                                ? renderEmailIcons(
                                    tracking.status,
                                    tracking.sequence
                                  )
                                : null}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {tracking?.emailHtml && (
                                <a
                                  href="#"
                                  target="_blank"
                                  style={{ color: "#541868" }}
                                  onClick={(event) =>
                                    handleClickView(event, tracking.emailHtml)
                                  }
                                >
                                  [View]
                                </a>
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              {tracking?.emailSubject
                                ? tracking.emailSubject
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell>{tracking.sender}</StyledTableCell>
                            <StyledTableCell>
                              {tracking.recipient}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {tracking?.sentDate
                                ? moment(tracking.sentDate).format(
                                    "DD/MM/YYYY hh:mma"
                                  )
                                : null}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {tracking?.status
                                ? renderStatus(tracking.status)
                                : null}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell colSpan={7} align="center">
                          No data
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          )}
        </Grid>
      </Modal>
    </React.Fragment>
  );
};

export default TrackingHistoryModal;
