import * as React from "react";
import AddIconButton from "./AddIconButton";
import AddLendingSurveyModal from "./AddLendingSurveyModal";

export type AddSurveyIconButtonProps = {
  userId: string;
};

const AddSurveyIconButton = ({userId}: AddSurveyIconButtonProps) => {
  const [isAdd, setIsAdd] = React.useState(false);

  const handleOnAdd = React.useCallback(() => {
    setIsAdd(true);
  }, []);

  const handOnClose = React.useCallback(() => {
    setIsAdd(false);
  }, []);

  return (
    <>
      <AddIconButton onClick={handleOnAdd} />

      <AddLendingSurveyModal
        userId={userId}
        isVisible={isAdd}
        onClose={handOnClose}
      />
    </>
  );
};

export default AddSurveyIconButton;
