import Cookies from "js-cookie"
import _ from "lodash"
import formatNumber from "format-number"

export const getOwnershipFromBorrowers = (ownerId, borrowers) => {
  switch (ownerId) {
    case "Joint": {
      const owners = borrowers
        .filter(x => x.name !== "Joint" && x.name !== "Other")
        .reduce((owners, curr) => {
          const owner = {
            name: curr.name,
            owner: curr._id,
            // Give the first owner 100% ownership
            percentage: owners.length === 0 ? 100 : 0,
          }
          return [...owners, owner]
        }, [])

      const ownership = {
        ownershipDesc: "",
        ownershipType: "Joint",
        owners,
      }
      return ownership
    }

    case "Other": {
      const ownership = {
        ownershipDesc: "",
        ownershipType: "Other",
        owners: [
          { name: borrowers[0].name, owner: borrowers[0]._id, percentage: 100 },
        ],
      }
      return ownership
    }

    default: {
      const owner = borrowers.find(x => x._id === ownerId)

      const ownership = {
        ownershipDesc: "",
        ownershipType: "Sole",
        owners: [{ name: owner.name, owner: owner._id, percentage: 100 }],
      }
      return ownership
    }
  }
}

export const _fetch = (path, init = {}) => {
  const token = Cookies.get("token")
  const api_endpoint = `https://${window.location.hostname}/api/`
  return fetch(api_endpoint + path, {
    ...init,
    referrer: window.location.href,
    headers: {
      ...init.headers,
      originreferer: window.location.href,
      Authorization: `Bearer ${token}`,
    },
  })
}

export const clientChangesRentalIncomeUpdate = (
  rentalIncome,
  fetchedRentalIncome
) => {
  let addressChanges = []
  let ownership1Changes = []
  let ownership2Changes = []
  let propertyValueChanges = []
  let rentChanges = []

  rentalIncome.map(rental => {
    const findSameInFetch = fetchedRentalIncome.find(
      fRental => fRental.summaryId === rental.summaryId
    )
    if (
      findSameInFetch !== undefined ||
      typeof findSameInFetch !== "undefined"
    ) {
      const { summaryId } = findSameInFetch
      let currValAddress = null
      let prevValAddress = null
      let currOwnership1 = null
      let prevOwnership1 = null
      let currOwnership2 = null
      let prevOwnership2 = null
      let currPropertyValue = null
      let prevPropertyValue = null
      let currRent = null
      let prevRent = null

      if (findSameInFetch.address !== rental.address) {
        prevValAddress = findSameInFetch.address
        currValAddress = rental.address
        addressChanges.push({ prevValAddress, currValAddress, summaryId })
      }
      if (
        findSameInFetch.ownershipPercentageClient1 !==
        rental.ownershipPercentageClient1
      ) {
        prevOwnership1 = findSameInFetch.ownershipPercentageClient1
        currOwnership1 = rental.ownershipPercentageClient1
        ownership1Changes.push({ prevOwnership1, currOwnership1, summaryId })
      }
      if (
        findSameInFetch.ownershipPercentageClient2 !==
        rental.ownershipPercentageClient2
      ) {
        prevOwnership2 = findSameInFetch.ownershipPercentageClient2
        currOwnership2 = rental.ownershipPercentageClient2
        ownership2Changes.push({ prevOwnership2, currOwnership2, summaryId })
      }
      if (findSameInFetch.propertyValue !== rental.propertyValue) {
        prevPropertyValue = findSameInFetch.propertyValue
        currPropertyValue = rental.propertyValue
        propertyValueChanges.push({
          prevPropertyValue,
          currPropertyValue,
          summaryId,
        })
      }
      if (findSameInFetch.rent !== rental.rent) {
        prevRent = findSameInFetch.rent
        currRent = rental.rent
        rentChanges.push({ prevRent, currRent, summaryId })
      }
    }
  })

  const allRentalChanges = {
    addressChanges,
    ownership1Changes,
    ownership2Changes,
    propertyValueChanges,
    rentChanges,
  }

  return allRentalChanges
}

export const isNullOrUndefined = item => {
  return item === null || item === undefined
}

export const clientChangesRentalAdditions = (
  rentalIncome,
  fetchedRentalIncome
) => {
  const fetchedRenntalId = fetchedRentalIncome.map(rental => rental.summaryId)
  let addressChanges = []
  let ownership1Changes = []
  let ownership2Changes = []
  let propertyValueChanges = []
  let rentChanges = []
  rentalIncome
    .filter(rental => !fetchedRenntalId.includes(rental.summaryId))
    .map(rental => {
      const {
        address,
        ownershipPercentageClient1,
        ownershipPercentageClient2,
        propertyValue,
        rent,
        summaryId,
      } = rental

      const addressToAdd = isNullOrUndefined(address) ? "" : address
      const ownership1ToAdd = isNullOrUndefined(ownershipPercentageClient1)
        ? 0
        : ownershipPercentageClient1
      const ownership2ToAdd = isNullOrUndefined(ownershipPercentageClient2)
        ? 0
        : ownershipPercentageClient2
      const propertyValueToAdd = isNullOrUndefined(propertyValue)
        ? 0
        : propertyValue
      const rentToAdd = isNullOrUndefined(rent) ? 0 : rent

      addressChanges.push({
        prevValAddress: "",
        currValAddress: addressToAdd,
        summaryId,
      })
      ownership1Changes.push({
        prevOwnership1: 0,
        currOwnership1: ownership1ToAdd,
        summaryId,
      })
      ownership2Changes.push({
        prevOwnership2: 0,
        currOwnership2: ownership2ToAdd,
        summaryId,
      })
      propertyValueChanges.push({
        prevPropertyValue: 0,
        currPropertyValue: propertyValueToAdd,
        summaryId,
      })
      rentChanges.push({ prevRent: 0, currRent: rentToAdd, summaryId })
    })

  return {
    addressChanges,
    ownership1Changes,
    ownership2Changes,
    propertyValueChanges,
    rentChanges,
  }
}

export const clientChangesAssetsUpdate = (assets, fetchedAssets) => {
  let typeChanges = []
  let balanceChanges = []
  let ownerChanges = []

  assets.map(asset => {
    const findSameInFetch = fetchedAssets.find(
      findAssets => findAssets.summaryId === asset.summaryId
    )
    if (
      findSameInFetch !== undefined ||
      typeof findSameInFetch !== "undefined"
    ) {
      const { summaryId } = findSameInFetch
      let currValType = null
      let prevValType = null
      let currValBalance = null
      let prevValBalance = null
      let currValOwner = null
      let prevValOwner = null

      if (findSameInFetch.type !== asset.type) {
        prevValType = findSameInFetch.type
        currValType = asset.type
        typeChanges.push({ prevValType, currValType, summaryId })
      }
      if (findSameInFetch.balance !== asset.balance) {
        prevValBalance = findSameInFetch.balance
        currValBalance = asset.balance
        balanceChanges.push({
          prevValBalance,
          currValBalance,
          summaryId,
        })
      }
      if (selectedOwner(findSameInFetch.owner) !== selectedOwner(asset.owner)) {
        prevValOwner = selectedOwner(findSameInFetch.owner)
        currValOwner = selectedOwner(asset.owner)
        ownerChanges.push({ prevValOwner, currValOwner, summaryId })
      }
    }
  })

  const allAssetChanges = {
    typeChanges,
    balanceChanges,
    ownerChanges,
  }

  return allAssetChanges
}

export const selectedOwner = owner => {
  const clients = _.pickBy(owner, function(value, key) {
    return key === "client1" || key === "client2"
  })
  const selected = _.filter(clients, "selected")
  if (_.size(selected) == 2) return "Both"
  else if (_.size(selected) == 0) return "Other"
  else return _.toString(_.map(selected, "fullName"))
}

export const clientChangesAssetsAdditions = (assets, fetchedAssets) => {
  const fetchedFinancialAssetId = fetchedAssets.map(asset => asset.summaryId)
  let typeChanges = []
  let balanceChanges = []
  let ownerChanges = []
  assets
    .filter(asset => !fetchedFinancialAssetId.includes(asset.summaryId))
    .map(asset => {
      const { type, balance, owner, summaryId } = asset

      const typeToAdd = isNullOrUndefined(type) ? "" : type

      typeChanges.push({
        prevValType: "",
        currValType: typeToAdd,
        summaryId,
      })
      balanceChanges.push({ prevBalance: "", currBalance: balance, summaryId })
      ownerChanges.push({
        prevValOwner: "",
        currValOwner: owner,
        summaryId,
      })
    })

  return {
    typeChanges,
    balanceChanges,
    ownerChanges,
  }
}

export const clientChangesCreditUpdates = (creditCard, fetchedCreditCard) => {
  const lenderChanges = []
  const limitChanges = []
  const ownerChanges = []
  const proposedLimitChanges = []

  creditCard.map(credit => {
    const findSameInFetch = fetchedCreditCard.find(
      item => item.summaryId === credit.summaryId
    )

    if (
      findSameInFetch !== undefined ||
      typeof findSameInFetch !== "undefined"
    ) {
      const { summaryId } = findSameInFetch
      let currLender = null
      let prevLender = null
      let currLimit = null
      let prevLimit = null
      let currOwner = null
      let prevOwner = null
      let prevProposedLimit = null
      let currProposedLimit = null

      if (findSameInFetch.lender !== credit.lender) {
        prevLender = findSameInFetch.lender
        currLender = credit.lender
        lenderChanges.push({ prevLender, currLender, summaryId })
      }

      if (findSameInFetch.limit !== credit.limit) {
        prevLimit = findSameInFetch.limit
        currLimit = credit.limit
        limitChanges.push({ prevLimit, currLimit, summaryId })
      }

      if (findSameInFetch.proposedLimit !== credit.proposedLimit) {
        prevProposedLimit = findSameInFetch.proposedLimit
        currProposedLimit = credit.proposedLimit
        proposedLimitChanges.push({
          prevProposedLimit,
          currProposedLimit,
          summaryId,
        })
      }

      if (
        selectedOwner(findSameInFetch.owner) !== selectedOwner(credit.owner)
      ) {
        prevOwner = selectedOwner(findSameInFetch.owner)
        currOwner = selectedOwner(credit.owner)
        ownerChanges.push({ prevOwner, currOwner, summaryId })
      }
    }
  })

  const allCreditCardChanges = {
    lenderChanges,
    limitChanges,
    proposedLimitChanges,
    ownerChanges,
  }

  return allCreditCardChanges
}

export const clientChangesCreditAddition = (creditCard, fetchedCreditCard) => {
  const fetchedCreditById = fetchedCreditCard.map(item => item.summaryId)
  const lenderChanges = []
  const limitChanges = []
  const ownerChanges = []
  const proposedLimitChanges = []

  creditCard
    .filter(credit => !fetchedCreditById.includes(credit.summaryId))
    .map(credit => {
      const { lender, limit, owner, summaryId, proposedLimit } = credit

      const limitToAdd = limit ?? 0
      const proposedLimitToAdd = proposedLimit ?? 0
      const lenderToAdd = lender ?? ""

      limitChanges.push({
        prevLimit: 0,
        currLimit: limitToAdd,
        summaryId,
      })

      lenderChanges.push({
        prevLender: "",
        currLender: lenderToAdd,
        summaryId,
      })

      ownerChanges.push({
        prevOwner: "",
        currOwner: owner,
        summaryId,
      })

      proposedLimitChanges.push({
        prevProposedLimit: 0,
        currProposedLimit: proposedLimitToAdd,
        summaryId,
      })
    })

  return {
    lenderChanges,
    limitChanges,
    proposedLimitChanges,
    ownerChanges,
  }
}

export const clientChangesUnsecuredUpdates = (
  unsecuredLiabilities,
  fetchUnsecured
) => {
  const balanceChanges = []
  const typeChanges = []
  const ownerChanges = []
  const repaymentChanges = []
  const periodChanges = []

  unsecuredLiabilities.map(liabilities => {
    const findSameInFetch = fetchUnsecured.find(
      item => item.summaryId === liabilities.summaryId
    )
    if (
      findSameInFetch !== undefined ||
      typeof findSameInFetch !== "undefined"
    ) {
      const { summaryId } = findSameInFetch
      let currValType = null
      let prevValType = null
      let currValBalance = null
      let prevValBalance = null
      let currValOwner = null
      let prevValOwner = null
      let prevRepayment = null
      let currRepayment = null
      let prevPeriod = null
      let currPeriod = null

      if (findSameInFetch.type !== liabilities.type) {
        prevValType = findSameInFetch.type
        currValType = liabilities.type
        typeChanges.push({ prevValType, currValType, summaryId })
      }
      if (findSameInFetch.balance !== liabilities.balance) {
        prevValBalance = findSameInFetch.balance
        currValBalance = liabilities.balance
        balanceChanges.push({
          prevValBalance,
          currValBalance,
          summaryId,
        })
      }
      if (findSameInFetch.repayment !== liabilities.repayment) {
        prevRepayment = findSameInFetch.repayment
        currRepayment = liabilities.repayment
        repaymentChanges.push({ prevRepayment, currRepayment, summaryId })
      }
      if (findSameInFetch.period !== liabilities.period) {
        prevPeriod = findSameInFetch.period
        currPeriod = liabilities.period
        periodChanges.push({ prevPeriod, currPeriod, summaryId })
      }
      if (
        selectedOwner(findSameInFetch.owner) !==
        selectedOwner(liabilities.owner)
      ) {
        prevValOwner = selectedOwner(findSameInFetch.owner)
        currValOwner = selectedOwner(liabilities.owner)
        ownerChanges.push({ prevValOwner, currValOwner, summaryId })
      }
    }
  })
  const allUnsecuredUpdates = {
    typeChanges,
    balanceChanges,
    ownerChanges,
    repaymentChanges,
    periodChanges,
  }
  return allUnsecuredUpdates
}

export const clientChangesUnsecuredAddition = (
  unsecuredLiabilities,
  fetchUnsecured
) => {
  const fetchedUnsecuredById = fetchUnsecured.map(item => item.summaryId)
  const typeChanges = []
  const balanceChanges = []
  const ownerChanges = []
  const repaymentChanges = []
  const periodChanges = []

  unsecuredLiabilities
    .filter(item => !fetchedUnsecuredById.includes(item.summaryId))
    .map(item => {
      const { type, balance, owner, summaryId, period, repayment } = item

      const typeToAdd = isNullOrUndefined(type) ? "" : type
      const repaymentToAdd = isNullOrUndefined(repayment) ? 0 : repayment
      const balanceToAdd = isNullOrUndefined(balance) ? 0 : balance
      const periodToAdd = isNullOrUndefined(period) ? "" : period

      typeChanges.push({
        prevValType: "",
        currValType: typeToAdd,
        summaryId,
      })
      balanceChanges.push({
        prevBalance: 0,
        currBalance: balanceToAdd,
        summaryId,
      })
      repaymentChanges.push({
        prevRepayment: 0,
        currRepayment: repaymentToAdd,
        summaryId,
      })
      periodChanges.push({
        prevPeriod: "",
        currPeriod: periodToAdd,
        summaryId,
      })
      ownerChanges.push({
        prevValOwner: {},
        currValOwner: owner,
        summaryId,
      })
    })

  return {
    typeChanges,
    balanceChanges,
    ownerChanges,
    periodChanges,
    repaymentChanges,
  }
}

export const checkObjectPropsLength = objArr => {
  const keys = Object.keys(objArr)
  const withLength = keys.map(key => {
    let check
    if (objArr[key].length > 0) {
      check = true
    } else {
      check = false
    }
    return check
  })
  let hasUpdates
  if (withLength.includes(true)) {
    hasUpdates = true
  } else {
    hasUpdates = false
  }
  return hasUpdates
}

export const clientChangesIncomeUpdate = (incomeBreakdown, fetchedIncome) => {
  const portalChanges = []
  const typeChanges = []

  incomeBreakdown.map(income => {
    const findSameInFetch = fetchedIncome.find(
      fincome => fincome.summaryId === income.summaryId
    )

    if (
      findSameInFetch !== undefined ||
      typeof findSameInFetch !== "undefined"
    ) {
      const { summaryId } = findSameInFetch
      let currPortal = null
      let prevPortal = null
      let currType = null
      let prevType = null

      if (findSameInFetch.portal !== income.portal) {
        prevPortal = findSameInFetch.portal
        currPortal = income.portal
        portalChanges.push({ prevPortal, currPortal, summaryId })
      }
      if (findSameInFetch.type !== income.type) {
        prevType = findSameInFetch.type
        currType = income.type
        typeChanges.push({ prevType, currType, summaryId })
      }
    }
  })

  const allIncomeChanges = {
    portalChanges,
    typeChanges,
  }

  return allIncomeChanges
}

export const clientChangesIncomeAdditional = (
  incomeBreakdown,
  fetchedIncome
) => {
  const fetchedIncomeId = fetchedIncome.map(income => income.summaryId)
  const portalChanges = []
  const typeChanges = []

  incomeBreakdown
    .filter(income => income.hasOwnProperty("summaryId"))
    .filter(income => !fetchedIncomeId.includes(income.summaryId))
    .map(income => {
      const { portal, type, summaryId } = income

      const portalToAdd = isNullOrUndefined(portal) ? 0 : portal
      const typeToAdd = isNullOrUndefined(type) ? "" : type

      portalChanges.push({
        prevPortal: 0,
        currPortal: portalToAdd,
        summaryId,
      })

      typeChanges.push({
        prevType: "",
        currType: typeToAdd,
        summaryId,
      })
    })
  return {
    portalChanges,
    typeChanges,
  }
}

export const clientHomeLoansAdditions = (homeLoans, fetchedHomeLoans) => {
  const fetchedHomeLoansId = fetchedHomeLoans.map(home => home.summaryId)
  let lenderChanges = []
  let loanTypeChanges = []
  let primaryPurposeChanges = []
  let borrowerChanges = []
  let repaymentTypeChanges = []
  let repaymentChanges = []
  let periodChanges = []
  let loanExpiryChanges = []
  let balanceChanges = []
  let accountNumberChanges = []
  let ioExpChanges = []
  let fixedIOChanges = []
  let addressChanges = []
  let baseRateChanges = []
  let discountRateChanges = []
  let interestRateChanges = []
  homeLoans
    .filter(home => !fetchedHomeLoansId.includes(home.summaryId))
    .map(home => {
      const {
        lender,
        loanType,
        primaryPurpose,
        borrower,
        repaymentType,
        repayment,
        period,
        loanExpiry,
        balance,
        baseRate,
        discountRate,
        interestRate,
        accountNumber,
        ioExp,
        fixedIO,
        address,
        summaryId,
      } = home

      const baseRateToAdd = baseRate ?? 0
      const discountRateToAdd = discountRate ?? 0
      const interestRateToAdd = interestRate ?? 0
      const lenderToAdd = isNullOrUndefined(lender) ? "" : lender
      const loanTypeToAdd = isNullOrUndefined(loanType) ? "" : loanType
      const primaryPurposeToAdd = isNullOrUndefined(primaryPurpose)
        ? ""
        : primaryPurpose
      const borrowerToAdd = isNullOrUndefined(borrower) ? "" : borrower
      const repaymentTypeToAdd = isNullOrUndefined(repaymentType)
        ? ""
        : repaymentType
      const repaymentToAdd = isNullOrUndefined(repayment) ? 0 : repayment
      const periodToAdd = isNullOrUndefined(period) ? "" : period
      const loanExpiryToAdd = isNullOrUndefined(loanExpiry) ? "" : loanExpiry
      const balanceToAdd = isNullOrUndefined(balance) ? 0 : balance
      const accountNumberToAdd = isNullOrUndefined(accountNumber)
        ? ""
        : accountNumber
      const ioExpToAdd = isNullOrUndefined(ioExp) ? "" : ioExp
      const fixedIOToAdd = isNullOrUndefined(fixedIO) ? "" : fixedIO
      const addressToAdd = address.length === 0 ? "" : address[0]
      baseRateChanges.push({
        prevBaseRate: 0,
        currBaseRate: baseRateToAdd,
        summaryId,
      })
      discountRateChanges.push({
        prevDiscountRate: 0,
        currDiscountRate: discountRateToAdd,
        summaryId,
      })
      interestRateChanges.push({
        prevInterestRate: 0,
        currInterestRate: interestRateToAdd,
        summaryId,
      })
      lenderChanges.push({ prevLender: "", currLender: lenderToAdd, summaryId })
      loanTypeChanges.push({
        prevLoanType: "",
        currLoanType: loanTypeToAdd,
        summaryId,
      })
      primaryPurposeChanges.push({
        prevPrimaryPurpose: "",
        currPrimaryPurpose: primaryPurposeToAdd,
        summaryId,
      })
      borrowerChanges.push({
        prevBorrower: "",
        currBorrower: borrowerToAdd,
        summaryId,
      })
      repaymentTypeChanges.push({
        prevRepaymentType: "",
        currRepaymentType: repaymentTypeToAdd,
        summaryId,
      })
      repaymentChanges.push({
        prevRepayment: 0,
        currRepayment: repaymentToAdd,
        summaryId,
      })
      periodChanges.push({
        prevPeriod: "",
        currPeriod: periodToAdd,
        summaryId,
      })
      loanExpiryChanges.push({
        prevLoanExpiry: "",
        currLoanExpiry: loanExpiryToAdd,
        summaryId,
      })
      balanceChanges.push({
        prevBalance: 0,
        currBalance: balanceToAdd,
        summaryId,
      })
      accountNumberChanges.push({
        prevAccountNumber: "",
        currAccountNumber: accountNumberToAdd,
        summaryId,
      })
      ioExpChanges.push({
        prevIoExp: "",
        currIoExp: ioExpToAdd,
        summaryId,
      })
      fixedIOChanges.push({
        prevFixedIO: "",
        currFixedIO: fixedIOToAdd,
        summaryId,
      })
      addressChanges.push({
        prevAddress: "",
        currAddress: addressToAdd,
        summaryId,
      })
    })

  return {
    lenderChanges,
    loanTypeChanges,
    primaryPurposeChanges,
    borrowerChanges,
    repaymentTypeChanges,
    repaymentChanges,
    periodChanges,
    loanExpiryChanges,
    balanceChanges,
    accountNumberChanges,
    ioExpChanges,
    fixedIOChanges,
    addressChanges,
    baseRateChanges,
    discountRateChanges,
    interestRateChanges,
  }
}

export const clientChangesHomeLoansUpdate = (homeLoans, fetchedHomeLoans) => {
  let lenderChanges = []
  let loanTypeChanges = []
  let primaryPurposeChanges = []
  let borrowerChanges = []
  let repaymentTypeChanges = []
  let loanExpiryChanges = []
  let balanceChanges = []
  let repaymentChanges = []
  let periodChanges = []
  let accountNumberChanges = []
  let ioExpChanges = []
  let fixedIOChanges = []
  let addressChanges = []
  let baseRateChanges = []
  let discountRateChanges = []
  let interestRateChanges = []

  homeLoans.map(hl => {
    const findSameInFetch = fetchedHomeLoans.find(
      fHomeLoans => fHomeLoans.summaryId === hl.summaryId
    )
    if (
      findSameInFetch !== undefined ||
      typeof findSameInFetch !== "undefined"
    ) {
      const { summaryId } = findSameInFetch
      let currLender = null
      let prevLender = null
      let currLoanType = null
      let prevLoanType = null
      let currPrimaryPurpose = null
      let prevPrimaryPurpose = null
      let currBorrower = null
      let prevBorrower = null
      let currRepaymentType = null
      let prevRepaymentType = null
      let currRepayment = null
      let prevRepayment = null
      let currPeriod = null
      let prevPeriod = null
      let currLoanExpiry = null
      let prevLoanExpiry = null
      let currBalance = null
      let prevBalance = null
      let currAccountNumber = null
      let prevAccountNumber = null
      let currIoExp = null
      let prevIoExp = null
      let currFixedIO = null
      let prevFixedIO = null
      let currAddress = null
      let prevAddress = null
      let currBaseRate = null
      let prevBaseRate = null
      let currDiscountRate = null
      let prevDiscountRate = null
      let currInterestRate = null
      let prevInterestRate = null

      if (findSameInFetch.lender !== hl.lender) {
        prevLender = findSameInFetch.lender
        currLender = hl.lender
        lenderChanges.push({ prevLender, currLender, summaryId })
      }
      if (findSameInFetch.loanType !== hl.loanType) {
        prevLoanType = findSameInFetch.loanType
        currLoanType = hl.loanType
        loanTypeChanges.push({
          prevLoanType,
          currLoanType,
          summaryId,
        })
      }
      if (findSameInFetch.primaryPurpose !== hl.primaryPurpose) {
        prevPrimaryPurpose = findSameInFetch.primaryPurpose
        currPrimaryPurpose = hl.primaryPurpose
        primaryPurposeChanges.push({
          prevPrimaryPurpose,
          currPrimaryPurpose,
          summaryId,
        })
      }
      if (findSameInFetch.borrower !== hl.borrower) {
        prevBorrower = findSameInFetch.borrower
        currBorrower = hl.borrower
        borrowerChanges.push({
          prevBorrower,
          currBorrower,
          summaryId,
        })
      }
      if (findSameInFetch.repaymentType !== hl.repaymentType) {
        prevRepaymentType = findSameInFetch.repaymentType
        currRepaymentType = hl.repaymentType
        repaymentTypeChanges.push({
          prevRepaymentType,
          currRepaymentType,
          summaryId,
        })
      }
      if (findSameInFetch.repayment !== hl.repayment) {
        prevRepayment = findSameInFetch.repayment
        currRepayment = hl.repayment
        repaymentChanges.push({
          prevRepayment,
          currRepayment,
          summaryId,
        })
      }
      if (findSameInFetch.period !== hl.period) {
        prevPeriod = findSameInFetch.period
        currPeriod = hl.period
        periodChanges.push({
          prevPeriod,
          currPeriod,
          summaryId,
        })
      }
      if (findSameInFetch.loanExpiry !== hl.loanExpiry) {
        prevLoanExpiry = findSameInFetch.loanExpiry
        currLoanExpiry = hl.loanExpiry
        loanExpiryChanges.push({
          prevLoanExpiry,
          currLoanExpiry,
          summaryId,
        })
      }
      if (findSameInFetch.balance !== hl.balance) {
        prevBalance = findSameInFetch.balance
        currBalance = hl.balance
        balanceChanges.push({
          prevBalance,
          currBalance,
          summaryId,
        })
      }
      if (findSameInFetch.baseRate !== hl.baseRate) {
        prevBaseRate = findSameInFetch.baseRate
        currBaseRate = hl.baseRate
        baseRateChanges.push({
          prevBaseRate,
          currBaseRate,
          summaryId,
        })
      }
      if (findSameInFetch.discountRate !== hl.discountRate) {
        prevDiscountRate = findSameInFetch.discountRate
        currDiscountRate = hl.discountRate
        discountRateChanges.push({
          prevDiscountRate,
          currDiscountRate,
          summaryId,
        })
      }
      if (findSameInFetch.interestRate !== hl.interestRate) {
        prevInterestRate = findSameInFetch.interestRate
        currInterestRate = hl.interestRate
        interestRateChanges.push({
          prevInterestRate,
          currInterestRate,
          summaryId,
        })
      }
      if (findSameInFetch.accountNumber !== hl.accountNumber) {
        prevAccountNumber = findSameInFetch.accountNumber
        currAccountNumber = hl.accountNumber
        accountNumberChanges.push({
          prevAccountNumber,
          currAccountNumber,
          summaryId,
        })
      }
      if (findSameInFetch.ioExp !== hl.ioExp) {
        prevIoExp = findSameInFetch.ioExp
        currIoExp = hl.ioExp
        ioExpChanges.push({
          prevIoExp,
          currIoExp,
          summaryId,
        })
      }
      if (findSameInFetch.fixedIO !== hl.fixedIO) {
        prevFixedIO = findSameInFetch.fixedIO
        currFixedIO = hl.fixedIO
        fixedIOChanges.push({
          prevFixedIO,
          currFixedIO,
          summaryId,
        })
      }
      if (
        JSON.stringify(findSameInFetch.address) !== JSON.stringify(hl.address)
      ) {
        prevAddress = findSameInFetch.address
        currAddress = hl.address
        addressChanges.push({
          prevAddress,
          currAddress,
          summaryId,
        })
      }
    }
  })

  const allHomeLoansChanges = {
    lenderChanges,
    loanTypeChanges,
    primaryPurposeChanges,
    borrowerChanges,
    repaymentTypeChanges,
    repaymentChanges,
    periodChanges,
    loanExpiryChanges,
    balanceChanges,
    baseRateChanges,
    discountRateChanges,
    interestRateChanges,
    accountNumberChanges,
    ioExpChanges,
    fixedIOChanges,
    addressChanges,
  }

  return allHomeLoansChanges
}

export const clientChangesFlexExpenseUpdates = (
  fetchedExpenses,
  passedExpenses
) => {
  let rentChangesMonthly = 0
  let prevMonthlyRentAmount = 0

  let rentChanges = {
    flexType: "Rental Expense",
    rentChangesArr: [],
    monthlyTotalAmount: 0,
    prevMonthlyTotalAmount: 0,
  }

  let utilitiesRatesMonthly = 0
  let prevMonthlyUtilitiesAmount = 0

  let utilitiesAndRates = {
    flexType: "Utilities & Rates - OO",
    utilitiesRatesChanges: [],
    monthlyTotalAmount: 0,
    prevMonthlyTotalAmount: 0,
  }

  let insuranceMonthly = 0
  let prevInsuranceMonthy = 0

  let insuranceChanges = {
    flexType: "Insurance",
    insuranceChangesArr: [],
    monthlyTotalAmount: 0,
    prevMonthlyTotalAmount: 0,
  }

  let currTelephoneMonthly = 0
  let prevTelephoneMonthly = 0

  let telephoneInternetChanges = {
    flexType: "Telephone & Internet",
    telephoneChangesArr: [],
    monthlyTotalAmount: 0,
    prevMonthlyTotalAmount: 0,
  }

  let currTransportMonthly = 0
  let prevTransportMonthly = 0

  let transportChanges = {
    flexType: "Transport",
    transportChangesArr: [],
    monthlyTotalAmount: 0,
    prevMonthlyTotalAmount: 0,
  }

  let currEducationMonthly = 0
  let prevEducationMonthly = 0

  let educationChanges = {
    flexType: "Education",
    educationChangesArr: [],
    monthlyTotalAmount: 0,
    prevMonthlyTotalAmount: 0,
  }

  let currChildMonthly = 0
  let prevChildMonthly = 0

  let childcareChanges = {
    flexType: "Childcare",
    childcareChangesArr: [],
    monthlyTotalAmount: 0,
    prevMonthlyTotalAmount: 0,
  }

  let currOtherLivingMonthly = 0
  let prevOtherLivingMonthly = 0

  let otherLivingChanges = {
    flexType: "Other Living Expenses",
    otherLivingChangesArr: [],
    monthlyTotalAmount: 0,
    prevMonthlyTotalAmount: 0,
  }

  let currGroceriesMonthly = 0
  let prevGroceriesMonthly = 0

  let groceriesChanges = {
    flexType: "Groceries",
    groceriesChangesArr: [],
    monthlyTotalAmount: 0,
    prevMonthlyTotalAmount: 0,
  }

  let currClothingMonthly = 0
  let prevClothingMonthly = 0

  let clothingChanges = {
    flexType: "Clothing & Personal Care",
    clothingChangesArr: [],
    monthlyTotalAmount: 0,
    prevMonthlyTotalAmount: 0,
  }

  let currMedicalMonthly = 0
  let prevMedicalMonthly = 0

  let medicalChanges = {
    flexType: "Medical & Health(ex Insurance)",
    medicalChangesArr: [],
    monthlyTotalAmount: 0,
    prevMonthlyTotalAmount: 0,
  }

  let currRecreationMonthly = 0
  let prevRecreationMonthly = 0

  let recreationChanges = {
    flexType: "Recreation & Entertainment",
    recreationChangesArr: [],
    monthlyTotalAmount: 0,
    prevMonthlyTotalAmount: 0,
  }

  let currUtilitiesInvMonthly = 0
  let prevUtilitiesInvMonthly = 0

  let utilitiesInvestmentChanges = {
    flexType: "Utilities & Rates - INV",
    monthlyTotalAmount: 0,
    prevMonthlyTotalAmount: 0,
    utilitiesInvestmentChangesArr: [],
  }

  for (let i = 0; i < fetchedExpenses.length; i++) {
    let utilitiesPrevAmount = 0
    let utilitiesCurrAmount = 0

    //Rental Changes
    if (
      !_.isNil(fetchedExpenses[i]) &&
      !_.isNil(passedExpenses[i]) &&
      fetchedExpenses[i].flexType === "Rental Expense" &&
      passedExpenses[i].flexType === "Rental Expense"
    ) {
      fetchedExpenses[i].items.forEach(expense => {
        const rentUpdates = passedExpenses[i].items.find(
          item => item.summaryId === expense.summaryId
        )
        rentChangesMonthly = passedExpenses[i].monthlyAmount
        prevMonthlyRentAmount = fetchedExpenses[i].monthlyAmount
        const defaultPrevAmount = expense.monthlyAmount ?? 0
        const defaultCurrAmount = rentUpdates?.monthlyAmount ?? 0
        if (defaultPrevAmount !== defaultCurrAmount) {
          rentChanges.rentChangesArr.push({
            prevAmount: defaultPrevAmount,
            currAmount: defaultCurrAmount,
            summaryId: expense.summaryId,
            desc: rentUpdates.desc,
          })
        }
      })
    }
    //End Rental Changes

    // Start Utilities Rates

    if (
      !_.isNil(fetchedExpenses[i]) &&
      !_.isNil(passedExpenses[i]) &&
      fetchedExpenses[i].flexType === "Utilities & Rates - OO" &&
      passedExpenses[i].flexType === "Utilities & Rates - OO"
    ) {
      fetchedExpenses[i].items.forEach(expense => {
        const rentUpdates = passedExpenses[i].items.find(
          item => item.desc === expense.desc
        )
        utilitiesRatesMonthly = passedExpenses[i].monthlyAmount
        prevMonthlyUtilitiesAmount = fetchedExpenses[i].monthlyAmount
        const defaultPrevAmount = expense.monthlyAmount ?? 0
        const defaultCurrAmount = rentUpdates.monthlyAmount ?? 0
        if (defaultPrevAmount !== defaultCurrAmount) {
          utilitiesPrevAmount = defaultPrevAmount
          utilitiesCurrAmount = defaultCurrAmount
          utilitiesAndRates.utilitiesRatesChanges.push({
            prevAmount: utilitiesPrevAmount,
            currAmount: utilitiesCurrAmount,
            summaryId: rentUpdates.summaryId,
            desc: rentUpdates.desc,
          })
        }
      })
    }
    //End utility rates

    // Start Insurance Rates

    if (
      !_.isNil(fetchedExpenses[i]) &&
      !_.isNil(passedExpenses[i]) &&
      fetchedExpenses[i].flexType === "Insurance" &&
      passedExpenses[i].flexType === "Insurance"
    ) {
      fetchedExpenses[i].items.forEach(expense => {
        const rentUpdates = passedExpenses[i].items.find(
          item => item.summaryId === expense.summaryId
        )
        insuranceMonthly = passedExpenses[i].monthlyAmount
        prevInsuranceMonthy = fetchedExpenses[i].monthlyAmount
        const defaultPrevAmount = expense.monthlyAmount ?? 0
        const defaultCurrAmount = rentUpdates?.monthlyAmount ?? 0
        if (defaultPrevAmount !== defaultCurrAmount) {
          insuranceChanges.insuranceChangesArr.push({
            prevAmount: defaultPrevAmount,
            currAmount: defaultCurrAmount,
            summaryId: expense.summaryId,
            desc: rentUpdates.desc,
          })
        }
      })
    }
    //End Insurance rates

    // Start Telephone & Internet

    if (
      !_.isNil(fetchedExpenses[i]) &&
      !_.isNil(passedExpenses[i]) &&
      fetchedExpenses[i].flexType === "Telephone & Internet" &&
      passedExpenses[i].flexType === "Telephone & Internet"
    ) {
      fetchedExpenses[i].items.forEach(expense => {
        const rentUpdates = passedExpenses[i].items.find(
          item => item.summaryId === expense.summaryId
        )
        currTelephoneMonthly = passedExpenses[i].monthlyAmount
        prevTelephoneMonthly = fetchedExpenses[i].monthlyAmount
        const defaultPrevAmount = expense.monthlyAmount ?? 0
        const defaultCurrAmount = rentUpdates?.monthlyAmount ?? 0
        if (defaultPrevAmount !== defaultCurrAmount) {
          telephoneInternetChanges.telephoneChangesArr.push({
            prevAmount: defaultPrevAmount,
            currAmount: defaultCurrAmount,
            summaryId: expense.summaryId,
            desc: rentUpdates.desc,
          })
        }
      })
    }
    // End Telephone & Internet

    // Start Transport

    if (
      !_.isNil(fetchedExpenses[i]) &&
      !_.isNil(passedExpenses[i]) &&
      fetchedExpenses[i].flexType === "Transport" &&
      passedExpenses[i].flexType === "Transport"
    ) {
      fetchedExpenses[i].items.forEach(expense => {
        const rentUpdates = passedExpenses[i].items.find(
          item => item.summaryId === expense.summaryId
        )
        currTransportMonthly = passedExpenses[i].monthlyAmount
        prevTransportMonthly = fetchedExpenses[i].monthlyAmount
        const defaultPrevAmount = expense.monthlyAmount ?? 0
        const defaultCurrAmount = rentUpdates?.monthlyAmount ?? 0
        if (defaultPrevAmount !== defaultCurrAmount) {
          transportChanges.transportChangesArr.push({
            prevAmount: defaultPrevAmount,
            currAmount: defaultCurrAmount,
            summaryId: expense.summaryId,
            desc: rentUpdates.desc,
          })
        }
      })
    }
    //End Transport

    //Start Education

    if (
      !_.isNil(fetchedExpenses[i]) &&
      !_.isNil(passedExpenses[i]) &&
      fetchedExpenses[i].flexType === "Education" &&
      passedExpenses[i].flexType === "Education"
    ) {
      fetchedExpenses[i].items.forEach(expense => {
        const rentUpdates = passedExpenses[i].items.find(
          item => item.summaryId === expense.summaryId
        )
        currEducationMonthly = passedExpenses[i].monthlyAmount
        prevEducationMonthly = fetchedExpenses[i].monthlyAmount
        const defaultPrevAmount = expense.monthlyAmount ?? 0
        const defaultCurrAmount = rentUpdates?.monthlyAmount ?? 0
        if (defaultPrevAmount !== defaultCurrAmount) {
          educationChanges.educationChangesArr.push({
            prevAmount: defaultPrevAmount,
            currAmount: defaultCurrAmount,
            summaryId: expense.summaryId,
            desc: rentUpdates.desc,
          })
        }
      })
    }
    //End Education

    //Start Childcare

    if (
      !_.isNil(fetchedExpenses[i]) &&
      !_.isNil(passedExpenses[i]) &&
      fetchedExpenses[i].flexType === "Childcare" &&
      passedExpenses[i].flexType === "Childcare"
    ) {
      fetchedExpenses[i].items.forEach(expense => {
        const rentUpdates = passedExpenses[i].items.find(
          item => item.summaryId === expense.summaryId
        )
        currChildMonthly = passedExpenses[i].monthlyAmount
        prevChildMonthly = fetchedExpenses[i].monthlyAmount
        const defaultPrevAmount = expense.monthlyAmount ?? 0
        const defaultCurrAmount = rentUpdates?.monthlyAmount ?? 0
        if (defaultPrevAmount !== defaultCurrAmount) {
          childcareChanges.childcareChangesArr.push({
            prevAmount: defaultPrevAmount,
            currAmount: defaultCurrAmount,
            summaryId: expense.summaryId,
            desc: rentUpdates.desc,
          })
        }
      })
    }
    //End Childcare

    //Start Other Living Expenses

    if (
      !_.isNil(fetchedExpenses[i]) &&
      !_.isNil(passedExpenses[i]) &&
      fetchedExpenses[i].flexType === "Other Living Expenses" &&
      passedExpenses[i].flexType === "Other Living Expenses"
    ) {
      fetchedExpenses[i].items.forEach(expense => {
        const rentUpdates = passedExpenses[i].items.find(
          item => item.summaryId === expense.summaryId
        )
        currOtherLivingMonthly = passedExpenses[i]?.monthlyAmount ?? 0
        prevOtherLivingMonthly = fetchedExpenses[i]?.monthlyAmount ?? 0
        const defaultPrevAmount = expense.monthlyAmount ?? 0
        const defaultCurrAmount = rentUpdates?.monthlyAmount ?? 0
        if (!_.isEqual(defaultPrevAmount, defaultCurrAmount)) {
          otherLivingChanges.otherLivingChangesArr.push({
            prevAmount: defaultPrevAmount,
            currAmount: defaultCurrAmount,
            summaryId: expense.summaryId,
            desc: expense.desc,
          })
        }
      })
    }
    //End Other Living Expenses

    //Start Groceries

    if (
      !_.isNil(fetchedExpenses[i]) &&
      !_.isNil(passedExpenses[i]) &&
      fetchedExpenses[i].flexType === "Groceries" &&
      passedExpenses[i].flexType === "Groceries"
    ) {
      fetchedExpenses[i].items.forEach(expense => {
        const rentUpdates = passedExpenses[i].items.find(
          item => item.summaryId === expense.summaryId
        )
        currGroceriesMonthly = passedExpenses[i].monthlyAmount
        prevGroceriesMonthly = fetchedExpenses[i].monthlyAmount
        const defaultPrevAmount = expense.monthlyAmount ?? 0
        const defaultCurrAmount = rentUpdates?.monthlyAmount ?? 0
        if (defaultPrevAmount !== defaultCurrAmount) {
          groceriesChanges.groceriesChangesArr.push({
            prevAmount: defaultPrevAmount,
            currAmount: defaultCurrAmount,
            summaryId: expense.summaryId,
            desc: rentUpdates.desc,
          })
        }
      })
    }
    //End Groceries

    //Start Clothing Personal

    if (
      !_.isNil(fetchedExpenses[i]) &&
      !_.isNil(passedExpenses[i]) &&
      fetchedExpenses[i].flexType === "Clothing & Personal Care" &&
      passedExpenses[i].flexType === "Clothing & Personal Care"
    ) {
      fetchedExpenses[i].items.forEach(expense => {
        const rentUpdates = passedExpenses[i].items.find(
          item => item.summaryId === expense.summaryId
        )
        currClothingMonthly = passedExpenses[i].monthlyAmount
        prevClothingMonthly = fetchedExpenses[i].monthlyAmount
        const defaultPrevAmount = expense.monthlyAmount ?? 0
        const defaultCurrAmount = rentUpdates?.monthlyAmount ?? 0
        if (defaultPrevAmount !== defaultCurrAmount) {
          clothingChanges.clothingChangesArr.push({
            prevAmount: defaultPrevAmount,
            currAmount: defaultCurrAmount,
            summaryId: expense.summaryId,
            desc: rentUpdates.desc,
          })
        }
      })
    }
    //End Clothing Personal

    //Start Medical Changes

    if (
      !_.isNil(fetchedExpenses[i]) &&
      !_.isNil(passedExpenses[i]) &&
      fetchedExpenses[i].flexType === "Medical & Health(ex Insurance)" &&
      passedExpenses[i].flexType === "Medical & Health(ex Insurance)"
    ) {
      fetchedExpenses[i].items.forEach(expense => {
        const rentUpdates = passedExpenses[i].items.find(
          item => item.summaryId === expense.summaryId
        )
        currMedicalMonthly = passedExpenses[i].monthlyAmount
        prevMedicalMonthly = fetchedExpenses[i].monthlyAmount
        const defaultPrevAmount = expense.monthlyAmount ?? 0
        const defaultCurrAmount = rentUpdates?.monthlyAmount ?? 0
        if (defaultPrevAmount !== defaultCurrAmount) {
          medicalChanges.medicalChangesArr.push({
            prevAmount: defaultPrevAmount,
            currAmount: defaultCurrAmount,
            summaryId: expense.summaryId,
            desc: rentUpdates.desc,
          })
        }
      })
    }
    //End Medical Changes

    //Start "Recreation & Entertainment"

    if (
      !_.isNil(fetchedExpenses[i]) &&
      !_.isNil(passedExpenses[i]) &&
      fetchedExpenses[i].flexType === "Recreation & Entertainment" &&
      passedExpenses[i].flexType === "Recreation & Entertainment"
    ) {
      fetchedExpenses[i].items.forEach(expense => {
        const rentUpdates = passedExpenses[i].items.find(
          item => item.summaryId === expense.summaryId
        )
        currRecreationMonthly = passedExpenses[i].monthlyAmount
        prevRecreationMonthly = fetchedExpenses[i].monthlyAmount
        const defaultPrevAmount = expense.monthlyAmount ?? 0
        const defaultCurrAmount = rentUpdates?.monthlyAmount ?? 0
        if (defaultPrevAmount !== defaultCurrAmount) {
          recreationChanges.recreationChangesArr.push({
            prevAmount: defaultPrevAmount,
            currAmount: defaultCurrAmount,
            summaryId: expense.summaryId,
            desc: rentUpdates.desc,
          })
        }
      })
    }
    //End "Recreation & Entertainment"

    //Start Utilities Investment Changes

    if (
      !_.isNil(fetchedExpenses[i]) &&
      !_.isNil(passedExpenses[i]) &&
      fetchedExpenses[i].flexType === "Utilities & Rates - INV" &&
      passedExpenses[i].flexType === "Utilities & Rates - INV"
    ) {
      fetchedExpenses[i].items.forEach(expense => {
        const rentUpdates = passedExpenses[i].items.find(item => {
          let expenseDescription
          switch (expense.desc) {
            case "Owners/Body Corp Costs":
              expenseDescription = "Owners/Body Corp Costs."
              break
            case "House and contents Insurance Costs":
              expenseDescription = "House and Contents Insurance"
              break
            case "Land Tax Costs":
              expenseDescription = "Land Tax"
              break
            case "Estimated Maintenance Costs":
              expenseDescription = "Maintenance Cost"
              break
            case "Water Rates/Usage":
              expenseDescription = "Water Rates"
              break
            case "Council Rates":
              expenseDescription = "Council Rates"
              break
            case "Management Fees":
              expenseDescription = "Management Fees"
              break
            default:
              expenseDescription = expense.desc
              break
          }
          return item.desc === expenseDescription
        })
        currUtilitiesInvMonthly = passedExpenses[i].monthlyAmount
        prevUtilitiesInvMonthly = fetchedExpenses[i].monthlyAmount
        const defaultPrevAmount = expense.monthlyAmount ?? 0
        const defaultCurrAmount = rentUpdates?.monthlyAmount ?? 0
        if (defaultPrevAmount !== defaultCurrAmount) {
          utilitiesInvestmentChanges.utilitiesInvestmentChangesArr.push({
            prevAmount: defaultPrevAmount,
            currAmount: defaultCurrAmount,
            summaryId: expense.summaryId,
            desc: rentUpdates.desc,
          })
        }
      })
    }
    // End Utilities Investment Changes
  }

  rentChanges.monthlyTotalAmount = rentChangesMonthly
  rentChanges.prevMonthlyTotalAmount = prevMonthlyRentAmount
  utilitiesAndRates.monthlyTotalAmount = utilitiesRatesMonthly
  utilitiesAndRates.prevMonthlyTotalAmount = prevMonthlyUtilitiesAmount
  insuranceChanges.monthlyTotalAmount = insuranceMonthly
  insuranceChanges.prevMonthlyTotalAmount = prevInsuranceMonthy
  telephoneInternetChanges.monthlyTotalAmount = currTelephoneMonthly
  telephoneInternetChanges.prevMonthlyTotalAmount = prevTelephoneMonthly
  transportChanges.monthlyTotalAmount = currTransportMonthly
  transportChanges.prevMonthlyTotalAmount = prevTransportMonthly
  educationChanges.monthlyTotalAmount = currEducationMonthly
  educationChanges.prevMonthlyTotalAmount = prevEducationMonthly
  childcareChanges.monthlyTotalAmount = currChildMonthly
  childcareChanges.prevMonthlyTotalAmount = prevChildMonthly
  otherLivingChanges.monthlyTotalAmount = currOtherLivingMonthly
  otherLivingChanges.prevMonthlyTotalAmount = prevOtherLivingMonthly
  groceriesChanges.monthlyTotalAmount = currGroceriesMonthly
  groceriesChanges.prevMonthlyTotalAmount = prevGroceriesMonthly
  clothingChanges.monthlyTotalAmount = currClothingMonthly
  clothingChanges.prevMonthlyTotalAmount = prevClothingMonthly
  medicalChanges.monthlyTotalAmount = currMedicalMonthly
  medicalChanges.prevMonthlyTotalAmount = prevMedicalMonthly
  recreationChanges.monthlyTotalAmount = currRecreationMonthly
  recreationChanges.prevMonthlyTotalAmount = prevRecreationMonthly
  utilitiesInvestmentChanges.monthlyTotalAmount = currUtilitiesInvMonthly
  utilitiesInvestmentChanges.prevMonthlyTotalAmount = prevUtilitiesInvMonthly

  return {
    rentChanges,
    utilitiesAndRates,
    insuranceChanges,
    telephoneInternetChanges,
    transportChanges,
    educationChanges,
    childcareChanges,
    otherLivingChanges,
    groceriesChanges,
    clothingChanges,
    medicalChanges,
    recreationChanges,
    utilitiesInvestmentChanges,
  }
}

export const findBySummaryId = (arrOfObj, id) => {
  return arrOfObj.find(item => item.summaryId === id)
}

export const compareItems = (first, second) => first === second

export const formatCurrency = formatNumber({
  prefix: "$",
  round: 2,
  padRight: 2,
})

export const isObjectEmpty = obj => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

const checkArrayLength = (obj, prop) => {
  const hasLength = obj[prop].length > 0 ? true : false
  return hasLength
}

export const flexExpenseHasDifferences = checkFlexExpenseUpdates => {
  const {
    childcareChanges,
    clothingChanges,
    educationChanges,
    groceriesChanges,
    insuranceChanges,
    medicalChanges,
    otherLivingChanges,
    recreationChanges,
    rentChanges,
    telephoneInternetChanges,
    utilitiesAndRates,
    utilitiesInvestmentChanges,
    transportChanges,
  } = checkFlexExpenseUpdates

  const listAllTrue = []

  if (
    checkArrayLength(childcareChanges, "childcareChangesArr") ||
    checkArrayLength(clothingChanges, "clothingChangesArr") ||
    checkArrayLength(educationChanges, "educationChangesArr") ||
    checkArrayLength(groceriesChanges, "groceriesChangesArr") ||
    checkArrayLength(insuranceChanges, "insuranceChangesArr") ||
    checkArrayLength(medicalChanges, "medicalChangesArr") ||
    checkArrayLength(transportChanges, "transportChangesArr") ||
    checkArrayLength(otherLivingChanges, "otherLivingChangesArr") ||
    checkArrayLength(recreationChanges, "recreationChangesArr") ||
    checkArrayLength(rentChanges, "rentChangesArr") ||
    checkArrayLength(telephoneInternetChanges, "telephoneChangesArr") ||
    checkArrayLength(utilitiesAndRates, "utilitiesRatesChanges") ||
    checkArrayLength(
      utilitiesInvestmentChanges,
      "utilitiesInvestmentChangesArr"
    )
  ) {
    listAllTrue.push(true)
  }

  return listAllTrue.includes(true)
}

export const arrangeFlexExpenseOrder = flexExpenses => {
  var defaultOrder = [
    "Utilities & Rates - OO",
    "Utilities & Rates - INV",
    "Telephone & Internet",
    "Groceries",
    "Recreation & Entertainment",
    "Clothing & Personal Care",
    "Medical & Health(ex Insurance)",
    "Transport",
    "Education",
    "Childcare",
    "Insurance",
    "Other Living Expenses",
    "Rental Expense",
  ]

  let newFlexOrder = []

  for (var i = 0; i < flexExpenses.length; i++) {
    var flexItem = flexExpenses[i]

    if (defaultOrder.includes(flexItem.flexType)) {
      let newIndex = defaultOrder.indexOf(flexItem.flexType)
      newFlexOrder[newIndex] = flexItem
    }
  }

  // Ensure that there is no skipped index
  let reIndexedFlexExpenses = []
  newFlexOrder.forEach(function(item) {
    reIndexedFlexExpenses.push(item)
  })

  return reIndexedFlexExpenses
}

export const checkFalsy = item => {
  if (item === null || item === undefined) {
    return true
  }
  return false
}

export const objectToQueryString = json => '?' + Object.keys(json).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(json[key])).join('&');

export const validateEmail = (email) => {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return String(email).toLowerCase().match(pattern);
};