
import { Box, Button, IconButton, } from '@material-ui/core'
import { Close } from "@material-ui/icons"
import classNames from 'classnames'
import _ from "lodash"
import React from 'react'
import { cancelEmailFollowups } from '../../lib/services'
import Modal from '../Modal'
import styles from "./CancelEmailFollowupsModal.module.scss"


type CancelEmailFollowupsModalProps = {
    showModal: boolean
    onClose: () => void
    rowData: any
    refreshPage: Function
}
const CancelEmailFollowupsModal: React.FC<CancelEmailFollowupsModalProps> = props => {
    const { showModal, onClose, rowData, refreshPage } = props
    const onCloseModal = () => {
        if (onClose) onClose()
    }
    const handleSubmit = async () => {
        const result = await cancelEmailFollowups(rowData?._id)
        if (result.status === "success") {
            if (onClose) onClose();
            refreshPage()
        }
    }
    return (
        <Modal showModal={showModal} paperClassName={styles.modalPaper}
            onClose={(_, reason) => {
                if (reason === "backdropClick") {
                    onCloseModal();
                }
            }}
        >
            <Box className={styles.headerRow}>
                <Box className={styles.headerTitle}>
                    Cancel Email Follow Up
                </Box>
                <IconButton onClick={onCloseModal}>
                    <Close classes={{ root: styles.closeIcon }} />
                </IconButton>
            </Box>
            <Box className={styles.contentRow}>
                Are you sure you want to cancel the auto email follow-ups for Request {rowData?.name} ?
            </Box>

            <Box className={styles.footerRow}>
                <Button
                    className={classNames(styles.actionButtons, styles.cancelButton)}
                    onClick={onCloseModal}>
                    Cancel
                </Button>
                <Button
                    className={classNames(styles.actionButtons, styles.yesButton)}
                    onClick={handleSubmit}>
                    Yes
                </Button>
            </Box>
        </Modal >
    )
}

export default CancelEmailFollowupsModal;

