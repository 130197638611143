import * as React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import apiService from "../lib/apiService";

export type MyQueryClientProviderProps = {
  apiBaseUrl: string;
  children: React.ReactNode;
};

export default function MyQueryClientProvider({
  children,
  apiBaseUrl,
}: MyQueryClientProviderProps) {
  return (
    <QueryClientProvider
      client={
        new QueryClient({
          defaultOptions: {
            queries: {
              queryFn: async ({ queryKey }) => {
                const isInvalid = queryKey.some((key) => {
                  return !key;
                });

                if (isInvalid) return null;

                return apiService
                  .get(apiBaseUrl + queryKey.join(""))
                  .then((res) => res.data);
              },
            },
          },
        })
      }
    >
      {children}
    </QueryClientProvider>
  );
}
